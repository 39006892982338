import * as React from "react";
import logo from "../assets/logo.png"
import {
    Icon,
    Panel,
    PanelType,
    CommandBar,
    ICommandBarItemProps,
    Link
} from "@fluentui/react";
import NavigationHelper from "../../utils/NavigationHelper";
import { logout } from "../../../state/user/action";
import { RootState } from "../../../state";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { clearOrder } from "../../../state/order/action";
import { getUserInformation } from "../../../state/action";
import { userHasAccess, userLoggedIn } from "../../utils/UserHelper";
import "../../global.style.css";

const Header: React.FunctionComponent<StateProps> = props => {
    const [showpanel, toggleMobile] = React.useState(false);
    const [nav, setActiveMenu] = React.useState(
        NavigationHelper.lookup(window.location.pathname)
    );
    const togglePanel = () => {
        toggleMobile(!showpanel);
    };

    const navigateToPage = (location: RoutingType, params?: string) => (
        ev: React.MouseEvent<HTMLElement>
    ) => {
        if (
            confirm(
                "Du er ved at navigerer til en anden side. \nAlt data som ikke er gemt vil blive slettet."
            )
        ) {
            props.clearOrder();
            toggleMobile(false);
            setActiveMenu(location);
            NavigationHelper.pushRoute(location, params);
        }
    };

    const getMenu = () => {
        const items: ICommandBarItemProps[] = [
            {
                name: "Forside",
                className: nav === "DASHBOARD" ? "active" : "",
                key: "DASHBOARD",
                onClick: navigateToPage("DASHBOARD")
            },
            {
                name: "Opgaver",
                className: nav === "ASSIGNMENTS" ? "active" : "",
                key: "ASSIGNMENTS",
                onClick: navigateToPage("ASSIGNMENTS")
            },
            {
                name: "Tilbud",
                className: nav === "OFFERS" ? "active" : "",
                key: "OFFERS",
                onClick: navigateToPage("OFFERS")
            },
            {
                name: "Webordrer",
                className: nav === "WEB_ORDERS" ? "active" : "",
                key: "WEB_ORDERS",
                onClick: navigateToPage("WEB_ORDERS")
            },
            {
                name: "Mobilservice Kort",
                className: nav === "MOBILE_SERVICE_MAP" ? "active" : "",
                key: "MOBILE_SERVICE_MAP",
                onClick: navigateToPage("MOBILE_SERVICE_MAP")
            }
        ];

        return items;
    };

    const onLogoutClick = () => {
        props.logout();
        NavigationHelper.pushRoute("LOGIN");
    };

    const renderManageUser = () => {
        if (userHasAccess(props.userInformation.roles, ["Adminstrator"])) {
            return (
                <li className={nav === "USERS" ? "active" : ""}>
                    <Link onClick={navigateToPage("USERS")}>
                        Brugeradministration
                    </Link>
                </li>
            );
        }
        return null;
    };
    const renderComplaintUrl = () => {
        if (
            userHasAccess(props.userInformation.roles, [
                "Adminstrator",
                "Complaint"
            ])
        ) {
            return (
                <li className={nav === "COMPLAINTS" ? "active" : ""}>
                    <Link onClick={navigateToPage("COMPLAINTS")}>
                        Reklamation
                    </Link>
                </li>
            );
        }
        return null;
    };

    const renderMenuItems = () => {
        if (userLoggedIn(props.userInformation)) {

            return (
                <>
                    <CommandBar className="main__menu" items={[]} farItems={getMenu()} />

                    <div className="mobile__menu">
                        <Icon iconName="Calendar" onClick={navigateToPage("CALENDAR")} />
                        <Icon iconName="SignOut" onClick={onLogoutClick} />
                        <Icon iconName="ListMirrored" onClick={togglePanel} />
                        <Panel isOpen={showpanel} type={PanelType.smallFixedFar} onDismiss={togglePanel}>
                            <ul className="list__container">
                                <li className={`hide-desktop ${nav === "DASHBOARD" ? "active" : ""}`}>
                                    <Link href={NavigationHelper.getRoute("DASHBOARD")} onClick={navigateToPage("DASHBOARD")}>
                                        Forside
                                    </Link>
                                </li>
                                <li className={nav === "ASSIGNMENTS" ? "active hide-desktop" : "hide-desktop"}>
                                    <Link href={NavigationHelper.getRoute("ASSIGNMENTS")} onClick={navigateToPage("ASSIGNMENTS")}>
                                        Opgaver
                                    </Link>
                                </li>
                                <li className={nav === "OFFERS" ? "active hide-desktop" : "hide-desktop"}>
                                    <Link onClick={navigateToPage("OFFERS")}>
                                        Tilbud
                                    </Link>
                                </li>
                                <li className={nav === "WEB_ORDERS" ? "active hide-desktop" : "hide-desktop"}>
                                    <Link onClick={navigateToPage("WEB_ORDERS")}>
                                        Webordrer
                                    </Link>
                                </li>
                                <li className={nav === "MOBILE_SERVICE_MAP" ? "active hide-desktop" : "hide-desktop"}>
                                    <Link onClick={navigateToPage("MOBILE_SERVICE_MAP")}>
                                        Mobilservice Kort
                                    </Link>
                                </li>

                                <li className={nav === "DELIVERY_TIME" ? "active" : ""}>
                                    <Link onClick={navigateToPage("DELIVERY_TIME")}>
                                        Leveringstid
                                    </Link>
                                </li>
                                <li className={nav === "ORDERS" ? "active" : ""}>
                                    <Link onClick={navigateToPage("ORDERS")}>
                                        Vareflyt
                                    </Link>
                                </li>
                                <li className={nav === "PENDING" ? "active" : ""}>
                                    <Link onClick={navigateToPage("PENDING")}>
                                        Gennemsyn
                                    </Link>
                                </li>
                                <li className={nav === "OLD_PRODUCTS" ? "active" : ""}>
                                    <Link onClick={navigateToPage("OLD_PRODUCTS")}>
                                        Gl. Prisliste
                                    </Link>
                                </li>
                                <li className={nav === "ZIPCODE_AVALABILITY" ? "active" : ""}>
                                    <Link onClick={navigateToPage("ZIPCODE_AVALABILITY")}>
                                        Postnummer
                                    </Link>
                                </li>
                                <li className={nav === "DEPARTMENTS" ? "active" : ""}>
                                    <Link onClick={navigateToPage("DEPARTMENTS")}>
                                        Afdelinger
                                    </Link>
                                </li>
                                <li className={nav === "AVAILABLE_SLOTS" ? "active" : ""}>
                                    <Link onClick={navigateToPage("AVAILABLE_SLOTS")}>
                                        Ledige slots
                                    </Link>
                                </li>
                                <li className={nav === "DEPARTMENT_RULE_SET" ? "active" : ""}>
                                    <Link onClick={navigateToPage("DEPARTMENT_RULE_SET")}>
                                        Afdelinger regelsæt
                                    </Link>
                                </li>
                                <li className={nav === "DEBITOR_RULE_SET" ? "active" : ""}>
                                    <Link onClick={navigateToPage("DEBITOR_RULE_SET")}>
                                        Debitor Styringstabel
                                    </Link>
                                </li>
                                {renderComplaintUrl()}
                                {renderManageUser()}
                            </ul>
                        </Panel>
                    </div>
                </>
            );
        }
        return null;
    };

    return (
        <div className="navigation_menu__container">
            <a onClick={navigateToPage("DASHBOARD")} style={{ cursor: "pointer" }}>
                <img src={logo} />
            </a>
            {renderMenuItems()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        userInformation: getUserInformation(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    logout: () => dispatch<any>(logout()),
    clearOrder: () => dispatch(clearOrder())
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(Header);
