import * as React from "react";
import { RootState } from "~/state";
import { getBookings, getUserInformation } from "../../../state/action";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import NavigationHelper from "../../utils/NavigationHelper";
import {
    postOrderFromState,
    setDiscount,
    removeProduct,
    setProductAmount,
    updateAdditional,
    setProductPrice,
    setProductName,
} from "../../../state/order/action";
import { calulateTotalPrice } from "../../utils/basket";
import OrderLines from "../../products/OrderLines";
import { parsePriceAsString } from "../../utils/vatConverter";
import {
    isModelValid,
    timeAndPlaceFieldConfig,
    debitorFieldConfig,
    productsFieldConfig,
    getCustomerValidation
} from "../../../util/validate";
import { convertUSDateToEU } from "../../../util/DateHelper";
import { isTwoPersonOrder } from "../../utils/orderHelper";
import { useEffect } from "react";
import "./style.css";
import { PrimaryButton, ActionButton, Stack, DefaultButton } from "@fluentui/react";
import { saveSelectedOrder } from "../../../state/worksheet/action";

const ChosenInfo: React.FunctionComponent<StateProps> = props => {
    const [navigation, setNavigation] = React.useState<RoutingType>(
        "DASHBOARD"
    );
    const [toggle, toggleDetails] = React.useState(false);
    const [bookOrderBtn, disableBookOrderBtn] = React.useState(false);
    const [togglefootervisibility, toggleFooter] = React.useState(false);
    const { zipCode, debitorName, address, city } = props.order.debitor;
    const {
        make,
        model,
        modelYear,
        insurance,
        leaser,
        owner,
        vehicleIdentificationNumber
    } = props.order.car;

    useEffect(() => {
        setNavigation(NavigationHelper.lookup(window.location.pathname));
    }, [window.location.pathname]);

    const handleClick = (route: RoutingType) => () => {
        NavigationHelper.pushRoute(route);
    };

    const postOrder = (status: OrderStatus) => () => {
        const { department, mobileServiceLocation } = props.order.timeAndPlace;
        disableBookOrderBtn(true);

        const order = {
            ...props.order,
            additionalData: {
                ...props.order.additionalData,
                orderStatus: status,
                twoManJob: isTwoPersonOrder(props.order.items)
            },
            timeAndPlace: {
                ...props.order.timeAndPlace,
                mobileServiceLocation:
                    department && department.mobileService
                        ? mobileServiceLocation
                        : undefined
            }
        };
        props.postOrder(order);
    };

    const renderLine = () => {
        switch (navigation) {
            case "DASHBOARD":
                return (
                    <PrimaryButton
                        text="Fortsæt"
                        onClick={handleClick("PRODUCTS")}
                    />
                );
            case "PRODUCTS": {
                const disabled = !isModelValid(
                    props.order,
                    productsFieldConfig
                );
                return (
                    <PrimaryButton
                        disabled={disabled}
                        text="Fortsæt"
                        onClick={handleClick("CUSTOMER")}
                    />
                );
            }
            case "CUSTOMER": {
                const disabled =
                    !isModelValid(
                        props.order.customer,
                        getCustomerValidation(
                            props.order.additionalData.whoIsPaying
                        )
                    ) || !isModelValid(props.order.debitor, debitorFieldConfig);
                return (
                    <PrimaryButton
                        disabled={disabled}
                        text="Fortsæt"
                        onClick={handleClick("BOOKING")}
                    />
                );
            }
            case "BOOKING": {
                const { timeAndPlace } = props.order;
                const isValidMobileAddress =
                    !timeAndPlace.department ||
                    !timeAndPlace.department.mobileService ||
                    timeAndPlace.mobileServiceLocation;
                const hasTime =
                    timeAndPlace.date !== "" &&
                    (timeAndPlace.timeSlotId !== undefined ||
                        (timeAndPlace.from !== undefined &&
                            timeAndPlace.to !== undefined));
                const disabled = !isValidMobileAddress || !hasTime;
                return (
                    <PrimaryButton
                        disabled={disabled}
                        text="Fortsæt"
                        onClick={handleClick("CONFIRM")}
                    />
                );
            }
            case "CONFIRM": {
                const timeAndPlaceVal = {
                    ...props.order.timeAndPlace,
                    date: convertUSDateToEU(props.order.timeAndPlace.date)
                };
                const disabledOffer =
                    bookOrderBtn ||
                    !isModelValid(
                        props.order.customer,
                        getCustomerValidation(
                            props.order.additionalData.whoIsPaying
                        )
                    ) ||
                    !isModelValid(props.order.debitor, debitorFieldConfig);
                const disabled =
                    disabledOffer ||
                    !isModelValid(timeAndPlaceVal, timeAndPlaceFieldConfig);
                let saveButton = null;
                if(props.order.additionalData.orderId && props.order.additionalData.orderStatus === "OFFER_REQUEST")
                {
                    saveButton = <PrimaryButton
                        text="Gem"
                        onClick={() => props.updateOrder(props.order)}/> ;
               
                }
                
                return (
                    <div className="confirm-buttons">
                        {saveButton}
                        <PrimaryButton
                            disabled={disabledOffer}
                            text="Email Tilbud"
                            onClick={postOrder("OFFER")}
                        />
                        <PrimaryButton
                            disabled={disabled}
                            text="Booking"
                            onClick={postOrder("PROCESSING")}
                        />
                    </div>
                );
            }
            default:
                return null;
        }
    };
    const { discountAmount, discountPercent } = props.order.additionalData;
    const getPriceTotal = () =>
        parsePriceAsString(
            calulateTotalPrice(
                props.order.items,
                discountAmount,
                discountPercent
            )
        );

    const toggleClick = () => toggleDetails(!toggle);
    const showHideFooter = () => toggleFooter(!togglefootervisibility);
    return (
        <>
            <div className={`mainfooter ${ togglefootervisibility ? "active" : "" } footer__container`} >
                <footer>
                    <div className="footer_toggleAction__container">
                        <ActionButton iconProps={{ iconName: togglefootervisibility ? "ChevronUp"  : "ChevronDown" }} onClick={showHideFooter}></ActionButton>
                    </div>
                    <Stack horizontal>
                        <Stack.Item grow={2} className="footer__info">
                            {make ? (
                                <p className="footer__info-car">
                                    {make} {model} {modelYear}
                                </p>
                            ) : null}
                            {vehicleIdentificationNumber ? (
                                <p>Stelnummer: {vehicleIdentificationNumber}</p>
                            ) : null}
                            {insurance ? <p>{insurance}</p> : null}
                            {leaser && owner ? <p>{owner}</p> : null}
                            {debitorName !== undefined ||
                            debitorName !== null ? (
                                <p style={{ color: "rgba(255,255,255,.5)" }}>
                                    {debitorName}
                                    {address ? ", " + address : null}
                                    {zipCode ? ", " + zipCode : null}
                                    {zipCode ? " " + city + "." : null}
                                </p>
                            ) : null}
                        </Stack.Item>
                        <Stack.Item grow={1}>
                            <p>Total pris: {getPriceTotal()}</p>
                            <Stack horizontal={true} horizontalAlign={"end"}>
                                {navigation !== "CONFIRM" &&
                                props.order.items.length > 0 ? (
                                    <Stack.Item>
                                        <DefaultButton
                                            title="Vis liste"
                                            onClick={toggleClick}
                                        >
                                            {!toggle ? "Vis " : "Skjul "}
                                            <span> liste</span>
                                        </DefaultButton>
                                    </Stack.Item>
                                ) : null}
                                <Stack.Item>{renderLine()}</Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                    <div
                        className={
                            "detailList footerList " +
                            (toggle &&
                            props.order.items.length > 0 &&
                            navigation !== "CONFIRM"
                                ? "active"
                                : "")
                        }
                    >
                        <OrderLines
                            useScrollableWrapper={true}
                            setDiscount={props.setDiscount}
                            removeProduct={props.removeProduct}
                            setAmount={props.setAmount}
                            setPrice={props.setPrice}
                            setName={props.setName}
                            items={props.order.items}
                        />
                    </div>
                </footer>
            </div>
            <div className="footer-padding"></div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        order: getBookings(state),
        user: getUserInformation(state),
       
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateAdditional: (additional: AdditionalData) =>
        dispatch(updateAdditional(additional)),
    postOrder: (order: Order) => dispatch<any>(postOrderFromState(order)),
    updateOrder: (order: Order) => dispatch<any>(saveSelectedOrder(order)),
    setDiscount: (itemNo: string, discount: number) =>
        dispatch(setDiscount(itemNo, discount)),
    removeProduct: (item: PurchaseItem) => dispatch(removeProduct(item.itemNo)),
    setAmount: (itemNo: string, amount: number) =>
        dispatch(setProductAmount(itemNo, amount)),
    setPrice: (itemNo: string, price: number) =>
        dispatch(setProductPrice(itemNo, price)),
    setName: (itemNo: string, name: string) =>
        dispatch(setProductName(itemNo, name))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(ChosenInfo);
