import * as React from "react";
import "./style.css"

const dayButtonText = ["I dag", "I morgen", "+2 dage", "+3 dage", "+4 dage", "+5 dage", "+6 dage"];

type Props = {
    index: number;
    dayNumber: number;
    days: number[];
    updateDays: (x: number) => void;
}

const DaySelector = (props: Props) =>
{
    return (
        <>
            <input
                className="dayInputField"
                id={`input_${props.index}`}
                name={`input_${props.index}`}
                key={"wcbk" + props.index}
                type="checkbox"
                checked={props.days.includes(props.dayNumber)}
                onChange={() => props.updateDays(props.dayNumber)} />
            <label
                htmlFor={`input_${props.index}`}>
                {dayButtonText[props.index]}
            </label>
        </>
    );
};

export default DaySelector;

