import { IDatePickerStrings, DayOfWeek, DatePicker } from "@fluentui/react";
import * as React from "react";
import "./style.css"

export const DayPickerStrings: IDatePickerStrings = {
    months: [
        "Januar",
        "Februar",
        "Marts",
        "April",
        "Maj",
        "Juni",
        "July",
        "August",
        "September",
        "Oktober",
        "November",
        "December"
    ],
    shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Maj",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dec"
    ],
    days: [
        "Søndag",
        "Mandag",
        "Tirsdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "Lørdag"
    ],
    shortDays: ["S", "M", "T", "W", "T", "F", "S"],
    goToToday: "Gå til idag",
    prevMonthAriaLabel: "Gå til forrige måned",
    nextMonthAriaLabel: "Gå til næste måned",
    prevYearAriaLabel: "Gå til forrige år",
    nextYearAriaLabel: "Gå til næste år",
    closeButtonAriaLabel: "Luk"
};

interface Props {
    short?: boolean;
    date?: Date;
    onDateChange?: (date: Date | null | undefined) => void;
}

function CustomDatePicker(props: Props) {
    const onFormatDate = (date: Date): string => {
        const shortDay = DayPickerStrings.days[date.getDay()];
        const shortMonth = DayPickerStrings.months[date.getMonth()];
        const day = shortDay.substr(0, 3);
        const month = date.getMonth() + 1;

        if (props.short) {
            return `${date.getDate()}/${month}/${date.getFullYear()}`;
        }
        return `${day}. d. ${date.getDate()}. ${shortMonth}, ${
            date.getFullYear
        }`;
    };

    return (
        <DatePicker
            className="date_picker__container"
            placeholder="Vælg dato"
            firstDayOfWeek={DayOfWeek.Monday}
            strings={DayPickerStrings}
            highlightCurrentMonth={true}
            highlightSelectedMonth={true}
            formatDate={onFormatDate}
            onSelectDate={props.onDateChange}
            value={props.date}
        />
    );
}

export default CustomDatePicker;
