import * as React from "react";
import { RootState } from "~/state";
import { AnyAction, Dispatch } from "redux";
import { connect } from "react-redux";
import { removeException } from "../../../state/ui/action";
import { getExceptions } from "../../../state/action";
import { ExceptionResults } from "../../typings";
import { DefaultButton, Panel, PanelType } from "@fluentui/react";

const ExceptionPanel = (props?: StateProps) => {
    if (props && props.exceptions && props.exceptions.length > 0) {
        const hidePanel = (exception: ExceptionResults) => () => {
            if (exception) {
                props.removeException(exception);
            }
        };

        const onRenderFooterContent = (
            exception: ExceptionResults
        ) => (): JSX.Element => {
            return (
                <div>
                    <DefaultButton onClick={hidePanel(exception)}>
                        Close
                    </DefaultButton>
                </div>
            );
        };

        const exceptions = props.exceptions.map((exception, index) => {
            let panelContent = exception.stackTrace ? (
                <code>{exception.stackTrace}</code>
            ) : null;

            if (exception.type === "VALIDATION_ERROR") {
                panelContent = (
                    <ul>
                        {exception.errors !== undefined ? exception.errors.map((error, index) => {
                            return <li key={index}>{error}</li>;
                        }) : null}
                    </ul>
                );
            }

            return (
                <Panel
                    key={index}
                    isOpen
                    type={PanelType.medium}
                    onDismiss={hidePanel(exception)}
                    headerText={exception.message}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={onRenderFooterContent(exception)}
                >
                    {panelContent}
                </Panel>
            );
        });

        return <React.Fragment>{exceptions}</React.Fragment>;
    }

    return null;
};

const mapStateToProps = (state: RootState) => {
    return {
        exceptions: getExceptions(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<AnyAction>) => ({
    removeException: (exception: ExceptionResults) =>
        dispatch(removeException(exception))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(ExceptionPanel);
