import * as React from "react";
import BaseCustomer from "./BaseCustomer";
import { Field } from "../../../util/validate";

interface CustomerProps
{
    customer: Customer;
    updateCustomer: (customer: Partial<Customer>) => void;
    updateCustomerCityFromZipCode: (zipcode: string) => void;
    fieldConfig: { [key: string]: Field };
}

const Customer: React.FunctionComponent<CustomerProps> = props =>
{
    return (
        <div>
            <h5>Kundeoplysninger</h5>
            <form action="">
                <BaseCustomer {...props} />
                <br/>
            </form>
        </div>
    );
};

export default Customer;