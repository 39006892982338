import { Stack } from "@fluentui/react";
import * as React from "react";
import MobileServiceLocationRender from "../../booking/Customer/MobileServiceLocation";

import { findParentDepartment } from "../../utils/departmentHelper";
import { canBookNextDay } from "../../utils/orderHelper";
import TimeManagementAlert from "../TimeManagementAlert";
import TimeManagement from "../TimeManagement";
import "./style.css";
import { Action, Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "~/state";
import { getTimeslots, getZipCode } from "../../../state/timeslot/action";
import { getAvailableDepartmentBooking, getZipCodes, getOrder } from "../../../state/action";
import DeparmentSearch from "./departmentSearch";
import { updateDebitorFromWhoIsPaying } from "../../../state/order/action";

interface TimeAndDepartmentManagerProps {
    timeAndPlace: TimeAndPlace;
    additionalData: AdditionalData;
    car: Car;
    orderType: AssignmentType;
    departments: Department[];
    orderItems: PurchaseItem[];
    debitor: Debitor;
    updateTimeOnCashDepartmentChange: boolean;
    updateTimeAndPlace: (timeAndPlace: Partial<TimeAndPlace>) => void;
    updateAdditionalData: (additionalData: AdditionalData) => void;
    updateMobileServiceProduct: (isMobileervice: boolean) => void;
}

const TimeAndDepartmentManager: React.FC<TimeAndDepartmentManagerProps & StateProps> = (props) => {
    const [selectedDate, setSelectedDate] = React.useState<Date>(props.timeAndPlace.date? new Date(props.timeAndPlace.date): new Date());
    const canBookNextDayValue = canBookNextDay(props.orderItems);

    React.useEffect(() => {
        if(props.timeAndPlace.department && props.timeAndPlace.department.id)
        {
            const date = selectedDate ? selectedDate : new Date();
            props.getTimeslots(getFilter(date, props.timeAndPlace.department.id));
        }
        if(!props.zipCodes && props.timeAndPlace.zipCode)
        {
            onZipcodeChange(props.timeAndPlace.zipCode);
        }
      
    }, [props.departments]);
    const getFilter = (date: Date, departmentId: string) :TimeSlotSearchFilter =>
    {
        return {
            departmentId: departmentId,
            inStockLocation: canBookNextDayValue,
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            carMake: props.car.make,
            carModel:  props.car.model,
            OrderType: props.additionalData.orderType
        }
    }
    const updateAvailableDepartmentBookings = (department: Department, year: number, month: number ) => 
    {
        if (!department || !department.id) {
            return;
        }
        
        props.getTimeslots(getFilter(new Date(year, month), department.id))
    };

    const onDepartmentChange = async ( department: Department) => {
        let mobileServiceLocation: MobileServiceLocation | undefined = undefined;
        if (!department.mobileService) {
            // reset an address when switching to department
            mobileServiceLocation = {
                address: "",
                covered: false
            };
        }  
        props.updateTimeAndPlace({
            department,
            mobileServiceLocation,
            timeSlotId: undefined,
            from: undefined,
            to: undefined
        });
        if(props.additionalData.whoIsPaying === "CASH")
        {
            //we need to update the debitor if the payer is CASH
            props.updateDebitorFromWhoIsPaying("CASH", {...props.order, timeAndPlace: {...props.order.timeAndPlace, department}}, true, props.updateTimeOnCashDepartmentChange);
        }
        props.updateMobileServiceProduct(department.mobileService === true);

        // necessary to switch to parent department
        if (department.parentDepartmentId) {
            department = findParentDepartment(department, props.departments);
        }

        const date = selectedDate ? selectedDate : new Date();
        updateAvailableDepartmentBookings(department, date.getFullYear(), date.getMonth());
    };
   

   

    const updateavailableDepartmentBookingsWithDepartment = React.useCallback( async (date: Date) => {
            let department = props.timeAndPlace.department ? { ...props.timeAndPlace.department } : undefined;

            if (department) {
                // necessary to switch to parent department
                if (department.parentDepartmentId) {
                    department = findParentDepartment( department, props.departments );
                }

                updateAvailableDepartmentBookings(department, date.getFullYear(), date.getMonth());
            }
        },
        [props.timeAndPlace.department]
    );

    const changeDate = React.useCallback( (date: Date) => {
            setSelectedDate(date);
            const month = "0" + (date.getMonth() + 1);
            const day = "0" + date.getDate();
            const stringDate = `${month.slice(-2)}/${day.slice(
                -2
            )}-${date.getFullYear()}`;
            props.updateTimeAndPlace({
                date: stringDate,
                timeSlotId: undefined,
                from: undefined,
                to: undefined
            });
        },
        [selectedDate, props.timeAndPlace.department]
    );
    const getDepartments = () => {
        if ( props.orderItems.find( x => x.itemNo === "PK3" || x.itemNo === "PK5" || x.itemNo === "PK6" ) !== undefined ) {
            return props.departments.filter(x => !x.mobileService);
        }
        return props.departments;
    };

    const updateServiceLocation = ( location: MobileServiceLocation | undefined ) => {
        props.updateTimeAndPlace({
            mobileServiceLocation: location
                ? { ...props.timeAndPlace.mobileServiceLocation, ...location }
                : undefined
        });
    };

    const onZipcodeChange = (zipCode: string) =>
    {
        const date = selectedDate ? selectedDate : new Date();
        props.getZipCode(zipCode, props.departments, getFilter(date, ""), props.debitor.no, props.additionalData.orderType);
    }

    let mobileServiceLocationRender = <div style={{ width: "281px" }}></div>;
    if (
        props.timeAndPlace.department &&
        props.timeAndPlace.department.mobileService &&
        !props.timeAndPlace.department.alertMessages
    ) {
        const location: MobileServiceLocation = props.timeAndPlace
            .mobileServiceLocation || {
            address: "",
            covered: false
        };
        mobileServiceLocationRender = (
            <MobileServiceLocationRender
                location={location}
                updateServiceLocation={updateServiceLocation}
                departmentId={
                    props.timeAndPlace.department.id
                        ? props.timeAndPlace.department.id
                        : ""
                }
            />
        );
    }

    return (
        <div className="work__datetime">
            <Stack
                horizontal
                tokens={{ childrenGap: 50 }}
                className="work__datetime__stack"
            >
                <Stack.Item grow={1} className="date__department__selector">
                    <h5>Tid og sted</h5>

                    <DeparmentSearch 
                        departments={props.departments} 
                        onDepartmentChange={onDepartmentChange} 
                        orderItems={props.orderItems} 
                        timeAndPlace={props.timeAndPlace} 
                        updateTimeAndPlace={props.updateTimeAndPlace}
                        zipCodes={props.zipCodes}
                        getZipCode={onZipcodeChange}
                        />
                  

                    {props.timeAndPlace.department ? (
                        <>
                            {props.timeAndPlace.department.alertMessages &&
                            props.timeAndPlace.department.alertMessages.length ? (
                                <TimeManagementAlert
                                    alertMessages={props.timeAndPlace.department.alertMessages}
                                />
                            ) : (
                                <TimeManagement
                                    departments={getDepartments()}
                                    timeAndPlace={props.timeAndPlace}
                                    additionalData={props.additionalData}
                                    availableDepartmentBookings={props.availableDepartmentBookings}
                                    orderType={props.orderType}
                                    updateTimeAndPlace={props.updateTimeAndPlace}
                                    selectedDate={selectedDate}
                                    updateAvailableDepartmentBookings={updateavailableDepartmentBookingsWithDepartment}
                                    changeDate={changeDate}
                                    updateAdditionalData={props.updateAdditionalData}
                                />
                            )}
                        </>
                    ) : null}
                </Stack.Item>

                {props.timeAndPlace.department &&
                props.timeAndPlace.department.mobileService ? (
                    <Stack.Item grow={1}>
                        {mobileServiceLocationRender}
                    </Stack.Item>
                ) : null}
            </Stack>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        availableDepartmentBookings: getAvailableDepartmentBooking(state),
        zipCodes: getZipCodes(state),
        order: getOrder(state),
      
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    getTimeslots: (filter: TimeSlotSearchFilter) =>
        dispatch<any>(getTimeslots(filter)),
    getZipCode: (zipcode: string, departments: Department[], filter: TimeSlotSearchFilter, debitorNo: string, type: AssignmentType ) =>
        dispatch<any>(getZipCode(zipcode, departments, filter, debitorNo, type)),
    updateDebitorFromWhoIsPaying: (whoIsPaying: WhoIsPaying, order: Order, updatePrices: boolean, updateTimeOnCashDepartmentChange: boolean) =>
        dispatch<any>(updateDebitorFromWhoIsPaying(whoIsPaying, order, updatePrices, updateTimeOnCashDepartmentChange)),
});

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(TimeAndDepartmentManager);

