export const defaultExternalOrderComment: { name: string, text: string }[] = [
    { name: "Montøren ringer", text: "Montøren ringer til dig ca. 30 min før ankomst. Husk at vi skal have adgang til bilnøglen" },
    { name: "Betaling viskere", text: "Vær opmærksom på, at viskerbladene skal betales til montøren på dagen" },
    { name: "Aftalt pris", text: "Vi bekræfter, at den aftalt pris er" }
];

export const defaultInternalOrderComment: { name: string, text: string }[] = [
    { name: "Aftalt pris", text: "Der er aftalt en totalpris på kr." },
    { name: "Ring", text: "Ring til kunden når bilen er færdig" },
    { name: "Aflevering før åbningstid", text: "Bilen stilles før åbningstid" }
];