import * as React from "react";
import { useEffect } from "react";
import TableHelper from "../utils/TableHelper";
import api from "../../api";
import "./style.css";
import {
    IColumn,
    IDetailsListProps,
    IDetailsRowStyles,
    DetailsRow,
    DetailsList,
    DetailsListLayoutMode,
    CheckboxVisibility,
    IDetailsHeaderProps,
    IRenderFunction,
    Sticky
} from "@fluentui/react";

const DeliveryTime: React.FunctionComponent = () => {
    const minutes = 60000 * 15;

    const [sjaellandDepartments, setSjaellandDepartments] = React.useState<
        Delivery[]
    >([]);
    const [fynDepartmens, setFynDepartmens] = React.useState<Delivery[]>([]);
    const [julandDepartments, setJulandDepartments] = React.useState<
        Delivery[]
    >([]);
    const [lastUpdate, setLastUpdate] = React.useState<string>("");

    const refreshUpdateTime = () => {
        const d = new Date();
        const hours = d.getHours();
        const minutes = d.getMinutes();
        setLastUpdate(`${hours}:${minutes}`);
    };

    useEffect(() => {
        api.DeliveryTimeApi.getDataAsync().then(data => {
            setSjaellandDepartments(data.sjaellandDepartments);
            setFynDepartmens(data.fynDepartmens);
            setJulandDepartments(data.julandDepartments);
            refreshUpdateTime();
        });

        const interval = setInterval(() => {
            (async () => {
                api.DeliveryTimeApi.getDataAsync().then(data => {
                    setSjaellandDepartments(data.sjaellandDepartments);
                    setFynDepartmens(data.fynDepartmens);
                    setJulandDepartments(data.julandDepartments);
                    refreshUpdateTime();
                });

                refreshUpdateTime();
            })();
        }, minutes);

        return () => clearInterval(interval);
    }, []);

    const zoneCell = (n: number, t: string) => {
        return (
            <div className="deliveryTime_table__date">
                <p
                    className={
                        (n >= 4 && n < 7 ? "alert-warning" : "") +
                        (n >= 7 || n === -1 ? "alert-danger" : "")
                    }
                >
                    {t}
                </p>
            </div>
        );
    };

    const columns: IColumn[] = [
        TableHelper.col("name", "Afdelinger", 400, 0, (e: Delivery) => (
            <p className="deliveryTime_table__text">{e.name}</p>
        )),
        TableHelper.col("replace", "Rudeskift", 150, 150, (e: Delivery) => (
            <p className="deliveryTime_table__number">
                {e.replace === -1 ? "" : e.replace}
            </p>
        )),
        TableHelper.col("repair", "Stenslag", 150, 150, (e: Delivery) => (
            <p className="deliveryTime_table__number">
                {e.repair === -1 ? "" : e.repair}
            </p>
        )),
        TableHelper.col("replace-date", "Rudeskift", 150, 150, (e: Delivery) =>
            zoneCell(e.replace, e.replaceDate)
        ),
        TableHelper.col("repair-date", "Stenslag", 150, 150, (e: Delivery) =>
            zoneCell(e.repair, e.repairDate)
        )
    ];

    const _onRenderRow: IDetailsListProps["onRenderRow"] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            if (props.itemIndex % 2 === 0) {
                customStyles.root = { backgroundColor: "rgba(0,0,0,.05)" };
            }

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    return (
        <>
            <div className="deliveryTime_table__container">
                <div className="table-wrapper">
                    <div className="region-name">jylland</div>
                    <DetailsList
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        columns={columns}
                        items={julandDepartments}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        className="DetailStyling"
                        onRenderDetailsHeader={
                            // tslint:disable-next-line:jsx-no-lambda
                            (
                                detailsHeaderProps: IDetailsHeaderProps,
                                defaultRender: IRenderFunction<
                                    IDetailsHeaderProps
                                >
                            ) => (
                                <Sticky>
                                    {defaultRender(detailsHeaderProps)}
                                </Sticky>
                            )
                        }
                        onRenderRow={_onRenderRow}
                    />
                </div>

                <div className="table-wrapper">
                    <div className="region-name">fyn</div>
                    <DetailsList
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        columns={columns}
                        items={fynDepartmens}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        className="DetailStyling"
                        onRenderDetailsHeader={
                            // tslint:disable-next-line:jsx-no-lambda
                            (
                                detailsHeaderProps: IDetailsHeaderProps,
                                defaultRender: IRenderFunction<
                                    IDetailsHeaderProps
                                >
                            ) => (
                                <Sticky>
                                    {defaultRender(detailsHeaderProps)}
                                </Sticky>
                            )
                        }
                        onRenderRow={_onRenderRow}
                    />
                </div>

                <div className="table-wrapper">
                    <div className="region-name">sjælland</div>
                    <DetailsList
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        columns={columns}
                        items={sjaellandDepartments}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        className="DetailStyling"
                        onRenderDetailsHeader={
                            // tslint:disable-next-line:jsx-no-lambda
                            (
                                detailsHeaderProps: IDetailsHeaderProps,
                                defaultRender: IRenderFunction<
                                    IDetailsHeaderProps
                                >
                            ) => (
                                <Sticky>
                                    {defaultRender(detailsHeaderProps)}
                                </Sticky>
                            )
                        }
                        onRenderRow={_onRenderRow}
                    />
                </div>

                <div className="clear"></div>
                <div className="last-update-time">
                    Sidst opdateret {lastUpdate}
                </div>
            </div>
        </>
    );
};

export default DeliveryTime;
