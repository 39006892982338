import { AnyAction, Dispatch, Store } from "redux";
import { addException } from "../state/ui/action";
import { CustomHttpException } from "../components/typings";

export const errorHandlingMiddleware: any = (store: Store) => (
    next: Dispatch<AnyAction>
) => async (action: any) => {
    try {
        await next(action);
    } catch (ex) {
        if (ex instanceof CustomHttpException ) {
            if (ex.status === 500) {
                addException(ex)(next);
            }
            if (ex.type === "VALIDATION_ERROR") {
                addException(ex)(next);
            }
        }
    }
};
