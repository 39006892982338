import * as React from "react";
import "./style.css"
import TimePicker, { TimePickerProps } from "react-time-picker";

interface Props {
    time: string;
    onChange: (value: string) => void;
}

const TimePickerWrapper: React.FunctionComponent<Props> = props => {
    const [showClock, setShowClock] = React.useState<boolean>(false);

    const onBlur = React.useCallback(() => setShowClock(false), []);

    const onFocus = React.useCallback(() => setShowClock(true), []);

    //we need to define the props so we can set the return value
    const timePickerProps: TimePickerProps = {
        locale: "da-DK",
        value: props.time,
        onChange: props.onChange,
        isOpen: showClock
    };

    return (
        <span onBlur={onBlur} onFocus={onFocus}>
            <TimePicker className="time_picker__wrapper" {...timePickerProps} />
        </span>
    );
};

export default TimePickerWrapper;
