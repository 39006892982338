export const getUserRoleText = (role: UserRole): string => {
    switch (role) {
        case "Adminstrator":
            return "Administrator";
        case "CustomerService":
            return "Kundeservice";
        case "Operation":
            return "Drift";
        case "ReviewManager":
            return "Ordregennemsyn";
        case "Stock":
            return "Lager";
        case "QuickStoneChip":
            return "Stenslag";
        case "VINSearch":
            return "VINSearch";
        case "Complaint":
            return "Reklamation";
        case "TimeManagement":
            return "Tidsstyr";
        case "AutovalidVINSearch":
                return "Autovalid VIN Search";
    }
};
