import * as React from "react";
import { orderToNotificationModel } from "../../utils/smsHelper";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField } from "@fluentui/react";

interface Props {
    order: Order;
    sendSms: (model: NotificationModel) => void;
    notificationType: NotificationType;
    buttonText: string;
    useIcon?: boolean;
    margin?: boolean;
}

const SendTextWindow: React.FunctionComponent<Props> = props => {
    const [showWindow, toggleShowWindow] = React.useState(false);
    const [editstate, toggleSmsEdit] = React.useState(false);
    const [comment, changeComment] = React.useState("");

    const onCommentChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue) {
            changeComment(newValue);
        }
    };
    const changeShowWindow = () => toggleShowWindow(!showWindow);

    const SendText = () => {
        const smsModel = orderToNotificationModel(
            props.order,
            props.notificationType,
            comment
        );
        props.sendSms(smsModel);

        changeShowWindow();
    };

    const toggleEditState = () => {
        toggleSmsEdit(!editstate);
    };

    if (!showWindow) {
        return props.useIcon ? (
            <PrimaryButton
                iconProps={{ iconName: "SkypeMessage" }}
                onClick={changeShowWindow}
            />
        ) : (
            <PrimaryButton
                style={props.margin ? { marginRight: "10px" } : {}}
                onClick={changeShowWindow}
                text={props.buttonText}
            />
        );
    } else {
        return (
            <Dialog
                hidden={false}
                onDismiss={changeShowWindow}
                minWidth={460}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Send besked",
                    subText: "Tryk på rediger, hvis du vil tilføje en kommentar"
                }}
            >
                <DialogFooter>
                    <TextField
                        disabled={!editstate}
                        value={comment}
                        multiline
                        rows={3}
                        onChange={onCommentChange}
                    />
                    {!editstate ? (
                        <div
                            onClick={toggleEditState}
                            style={{ fontSize: "12px", cursor: "pointer" }}
                        >
                            Redigér
                        </div>
                    ) : null}
                    <br />
                    {comment}
                    <DefaultButton onClick={changeShowWindow}>
                        Luk{" "}
                    </DefaultButton>
                    <PrimaryButton onClick={SendText}>Send </PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }
};

export default SendTextWindow;
