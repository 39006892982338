import * as React from "react";
import {
    updateDebitor,
    updateCustomer,
    updateCar,
    updateTimeAndPlace,
    whoIsPayingChange,
    updateOrderDepartment,
    updateDebitorFromWhoIsPaying,
    setDebitor,
    updateMobileServiceProduct,
    updateCustomerCityFromZip,
    updateAdditional
} from "../../../state/order/action";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { RootState } from "~/state";
import { useEffect } from "react";
import { getDebitors as getDebitorsAction } from "../../../state/ui/action";
import NavigationHelper from "../../utils/NavigationHelper";
import {
    getDepartments,
    getCustomer,
    getCar,
    getTimeAndPlace,
    getOrder,
    getLeasers,
    getDebitors,
    getAdditionalData,
    getInsurers
} from "../../../state/action";
import TimeAndPlace from "../TimeAndPlace";
import CustomerOrderDetails from "./CustomerOrderDetails";
import { updateDepartmentsList } from "../../../state/department/action";
import { canBookNextDay } from "../../utils/orderHelper";

const CustomerInfo: React.FunctionComponent<StateProps> = props => {
    useEffect(() => {
        if (props.additionalData.orderType === "REPAIR") {
            props.updateDepartmentsList(
                {},
                props.customer
            );
        } else {
            if (props.car.make && props.car.model) {
                props.updateDepartmentsList(
                    { inStockLocation: canBookNextDay(props.order.items), carMake: props.car.make, carModel: props.car.model, orderType: props.additionalData.orderType},
                    props.customer
                );
            }
        }
    }, [
        props.customer.zipCode,
        props.customer.city,
        props.order.additionalData.orderNr
    ]);

    const updateMobileServiceProduct = (isMobileService: boolean) => {
        if (
            props.order.items.findIndex(
                i =>
                    i.itemNo === "REP6" ||
                    i.itemNo === "REP7" ||
                    i.itemNo === "REP8"
            ) < 0
        ) {
            props.updateMobileServiceProduct(
                isMobileService,
                props.order.debitor.no,
                props.additionalData.orderType
            );
        }
    };

    const updateDebitor = (debitor: Partial<Debitor>) => {
        props.updateDebitor(debitor, props.order);
    };

    const setDebitor = (debitor: Debitor) => {
        props.setDebitor(debitor, props.order);
    };

    if (NavigationHelper.getRoute("CUSTOMER") === window.location.pathname) {
        return (
            <>
                <div className="styled_debitor__form">
                    <CustomerOrderDetails
                        debitor={props.order.debitor}
                        updateDebitor={updateDebitor}
                        car={props.car}
                        insurers={props.insurers}
                        getDebitor={props.getDebitor}
                        updateCar={props.updateCar}
                        order={props.order}
                        departments={props.departments}
                        updateOrderDepartment={props.updateOrderDepartment}
                        whoIsPayingChange={props.whoIsPayingChange}
                        updateDebitorFromWhoIsPaying={
                            props.updateDebitorFromWhoIsPaying
                        }
                        leasers={props.leasers}
                        debitors={props.debitors}
                        setDebitor={setDebitor}
                        customer={props.customer}
                        updateCustomer={props.updateCustomer}
                        updateCustomerCityFromZipCode={
                            props.updateCustomerCityFromZipCode
                        }
                    />
                </div>
            </>
        );
    }
    if (NavigationHelper.getRoute("BOOKING") === window.location.pathname) {
        return (
            <TimeAndPlace
                updateMobileServiceProduct={updateMobileServiceProduct}
                orderType={props.order.additionalData.orderType}
                car={props.order.car}
                debitor={props.order.debitor}
                orderItems={props.order.items}
                timeAndPlace={props.timeAndPlace}
                additionalData={props.additionalData}
                departments={props.departments}
                updateTimeAndPlace={props.updateTimeAndPlace}
                updateAdditionalData={props.updateAdditionalData}
            />
        );
    }
    return null;
};

const mapStateToProps = (state: RootState) => {
    return {
        car: getCar(state),
        customer: getCustomer(state),
        departments: getDepartments(state),
        timeAndPlace: getTimeAndPlace(state),
        order: getOrder(state),
        leasers: getLeasers(state),
        debitors: getDebitors(state),
        additionalData: getAdditionalData(state),
        insurers: getInsurers(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateDepartmentsList: (
        requestModel: TimeslotFilterRequestModel,
        customerInfo: Customer
    ) =>
        dispatch<any>(
            updateDepartmentsList(
                requestModel,
                customerInfo
            )
        ),
    getDebitor: (query: string, type: ApiType) =>
        dispatch<any>(getDebitorsAction(query, type)),
    setDebitor: (debitor: Debitor, order: Order) =>
        dispatch<any>(setDebitor(debitor, order)),
    updateCustomer: (customer: Partial<Customer>) =>
        dispatch(updateCustomer(customer)),
    updateCustomerCityFromZipCode: (zipCode: string) =>
        dispatch<any>(updateCustomerCityFromZip(zipCode)),
    updateCar: (carInfo: Car) => dispatch(updateCar(carInfo)),
    updateDebitor: (debitor: Partial<Debitor>, order: Order) =>
        dispatch<any>(updateDebitor(debitor, order)),
    updateTimeAndPlace: (timeAndPlace: Partial<TimeAndPlace>) =>
        dispatch(updateTimeAndPlace(timeAndPlace)),
    whoIsPayingChange: (whoIsPaying: WhoIsPaying) =>
        dispatch<any>(whoIsPayingChange(whoIsPaying)),
    updateDebitorFromWhoIsPaying: (whoIsPaying: WhoIsPaying, order: Order, updatePrices: boolean) =>
        dispatch<any>(updateDebitorFromWhoIsPaying(whoIsPaying, order, updatePrices, true)),
    updateOrderDepartment: (
        department: Department,
        shouldUpdateDebitor: boolean
    ) => dispatch<any>(updateOrderDepartment(department, shouldUpdateDebitor)),
    updateMobileServiceProduct: (isMobileService: boolean, debitorNo: string, type: AssignmentType) =>
        dispatch<any>(updateMobileServiceProduct(isMobileService, debitorNo, type)),
    updateAdditionalData: (additional: AdditionalData) =>
        dispatch<any>(updateAdditional(additional))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(CustomerInfo);
