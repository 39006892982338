import api from "../../api";
import { Dispatch } from "redux";
import { addMessageStatus } from "../ui/action";
import { MessageBarType } from "@fluentui/react";

export const sendNotificationEmail = (
    notificationModel: NotificationModel
) => async (dispatch: Dispatch) => {
    try {
        await api.EmailApi.sendNotificationEmail(notificationModel);

        const statusMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: `Email sent successfully`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Failed to send email`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const sendReceiptEmail = (model: IOrderReceiptModel) => async (
    dispatch: Dispatch
) => {
    try {
        await api.EmailApi.sendReceiptEmail(model);

        const statusMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: `Email sent successfully`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Failed to send email`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};
