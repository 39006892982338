import * as React from "react";
import SimpleProducts from ".";
import "../style.css"
import { Stack, Dialog, DialogType, List } from "@fluentui/react";

interface Props {
    items: ProductGroup[];
    selectedProducts: (products: Product[]) => void;
}

const SimpleProductGroup: React.FunctionComponent<Props> = props => {
    const [showstorage, toggleStorageWindow] = React.useState(false);
    const [storageList, setStorageList] = React.useState<Array<Status>>([]);

    const hideStorageWindow = () => toggleStorageWindow(false);

    const showStorageWindow = (status: Status[]) => {
        toggleStorageWindow(true);
        setStorageList(status);
    };

    const groups = props.items.map((group, index) => {
        if (group.checked) {
            return (
                <div key={index} className="simple-product__group">
                    <Stack horizontal>
                        <Stack.Item grow={1}>
                            <div className="header__container">{group.name}</div>
                        </Stack.Item>
                    </Stack>
                    <SimpleProducts
                        products={group.items}
                        selectedProducts={props.selectedProducts}
                        showStorageWindow={showStorageWindow}
                    />
                </div>
            );
        }
    });

    return (
        <div className="product_group__container">
            {groups}
            <Dialog
                hidden={!showstorage}
                onDismiss={hideStorageWindow}
                dialogContentProps={{
                    type: DialogType.close,
                    title: "Lageroversigt"
                }}
                modalProps={{
                    isBlocking: false,
                    containerClassName: "ms-dialogMainOverride"
                }}
            >
                <div className="storage_window__container">
                    <div>
                        <div>Lokation</div>
                        <div>Antal på lager</div>
                    </div>
                    <div>
                        <div>SAMLET</div>
                        <div>
                            {storageList.reduce(
                                (sum, current) => sum + current.amount,
                                0
                            )}{" "}
                            stk.
                        </div>
                    </div>
                    <List items={storageList} onRenderCell={onRenderCell} />
                </div>
            </Dialog>
        </div>
    );
};

const onRenderCell = (item: Status, index: number): JSX.Element => {
    return (
        <div className="ms-List-itemCell" data-is-focusable={true}>
            <div>{item.description ? item.description : item.code}</div>
            <div>{item.amount} stk.</div>
        </div>
    );
};

export default SimpleProductGroup;
