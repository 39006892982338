import BaseApi from "./baseApi";

interface ICMSApi {
    getInsurers: () => Promise<Insurer[]>;
    getLeasers: () => Promise<Leaser[]>;
}

export default class CMSApi extends BaseApi implements ICMSApi {
    async getInsurers(): Promise<Insurer[]> {
        const url = `/api/insurers`;
        return await this.fetchGet(url);
    }

    async getLeasers(): Promise<Leaser[]> {
        const url = `/api/leasers`;
        return await this.fetchGet(url);
    }
}
