import * as React from "react";

interface Props {
    rowData: {
        id: string;
        ruleName?: string;
        ruleSetName?: string;
        carMake?: string;
        carModel?: string;
        isWorkAllowed?: boolean;
        minimumWorkLength?: number;
    };
    rowCount: number;
}

function CustomRuleRow(props: Props) {
    const renderColumns = () => {
        if (props.rowData.carMake) {
            return (
                <>
                    <td><p>{props.rowCount}</p></td>
                    <td><p>{props.rowData.ruleName}</p></td>
                    <td><p>{props.rowData.carMake}</p></td>
                    <td><p>{props.rowData.carModel ? props.rowData.carModel : "-"}</p></td>
                </>
            );
        } else {
            return (
                <>
                    <td><p>{props.rowCount}</p></td>
                    <td><p>{props.rowData.ruleSetName}</p></td>
                </>
            );
        }
    };

    return renderColumns();
}

export default CustomRuleRow;
