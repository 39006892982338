import * as React from "react";
import TableHelper from "../../utils/TableHelper";
import "./style.css"
import { IColumn, DetailsList, DetailsListLayoutMode, CheckboxVisibility } from "@fluentui/react";

export const hasTipsAndTricks = (product: Product) =>
{
    return product.tipsCalibrating
        || product.tipsGenerel
        || product.tipsMounting
        || product.subItems && product.subItems.find(sub => sub.tipsCalibrating !== undefined || sub.tipsGenerel !== undefined || sub.tipsMounting !== undefined);
};


export interface Props
{
   products?: Product[];
   cellWidth: number;
}

interface TipsAndTricksModel
{
    no: string;
    tipsCalibrating?: string;
    tipsGenerel?: string;
    tipsMounting?: string;
}

const TipsAndTricks: React.FunctionComponent<Props> = props =>
{
    const [tipsAndTrickData, setTipsAndTrickData] = React.useState<TipsAndTricksModel[] | undefined>(undefined);
    React.useEffect(() =>
    {
        if(props.products && props.products.length > 0)
        {
            const data: TipsAndTricksModel[] = props.products.reduce((result: TipsAndTricksModel[], product: Product)=>
            {
                const { tipsGenerel, tipsMounting, tipsCalibrating, no, subItems } = product;
                const temp: TipsAndTricksModel[] = [];
                if(tipsGenerel || tipsMounting || tipsCalibrating)
                {
                    temp.push({ tipsGenerel, tipsMounting, tipsCalibrating, no });
                }
                subItems && subItems.forEach(item =>
                {
                    if(item.tipsGenerel || item.tipsMounting || item.tipsCalibrating)
                    {
                        temp.push({ tipsGenerel: item.tipsGenerel, tipsMounting: item.tipsMounting, tipsCalibrating: item.tipsCalibrating, no: item.no });
                    }
                });
                return [...result, ...temp];
            }, []);
            setTipsAndTrickData(data);
        }
        else
        {
            setTipsAndTrickData(undefined);
        }
    }, [props.products]);

    if(!tipsAndTrickData)
    {
        return null;
    }
    const { cellWidth } = props;

    const columns: IColumn[] = [
        TableHelper.col("No", "No", 50, 50, (e: TipsAndTricksModel) => <p className="tableContent__container">{e.no}</p>),
        TableHelper.col("Generelt", "Generelt", cellWidth, cellWidth, (e: TipsAndTricksModel) => <p className="tableContent__container">{e.tipsGenerel}</p>),
        TableHelper.col("Calibering", "Calibering", cellWidth, cellWidth, (e: TipsAndTricksModel) => <p className="tableContent__container">{e.tipsCalibrating}</p>),
        TableHelper.col("Montering", "Montering", cellWidth, cellWidth, (e: TipsAndTricksModel) => <p className="tableContent__container">{e.tipsMounting}</p>)
    ];
    return (
        <DetailsList
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            columns={columns}
            items={tipsAndTrickData}
            checkboxVisibility={CheckboxVisibility.hidden}
            className="ProductPriceList" />);
};


export default TipsAndTricks;