import * as React from "react";
import { Field, validate } from "../../../util/validate";
import "./style.css";
import { TextField, Stack } from "@fluentui/react";

interface Props {
    debitor: Debitor;
    updateDebitor: (debitor: Partial<Debitor>) => void;
    fieldConfig: { [key: string]: Field };
}

const DebitorFields: React.FunctionComponent<Props> = props => {
    const { debitor, fieldConfig }: any = props;

    const updateDebitor = (valueType: CustomerValueType) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        props.updateDebitor({ [valueType]: value });
    };
    const renderField = (fieldName: CustomerValueType) => {
        const field: Field = fieldConfig[fieldName];
        return (
            <TextField
                validateOnLoad={true}
                onGetErrorMessage={validate(field.validation)}
                title={field.placeholder}
                type={field.type}
                placeholder={field.placeholder}
                value={debitor[fieldName]}
                onChange={updateDebitor(fieldName)}
            />
        );
    };
    return (
        <>
            <h5>Debitor oplysninger</h5>
            {renderField("name")}
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item grow={1}>
                    {renderField("debitorName")}
                    {renderField("address")}
                    {renderField("email")}
                    {renderField("phone")}
                </Stack.Item>
                <Stack.Item grow={1}>
                    {renderField("cvr")}

                    {renderField("zipCode")}
                    {renderField("city")}
                    {props.debitor.customerPopup && (
                        <>
                            Debitor Info:
                            <div className="customer_popup__alert">
                                {props.debitor.customerPopup}
                            </div>
                        </>
                    )}
                </Stack.Item>
            </Stack>
        </>
    );
};

export default DebitorFields;
