import * as React from "react";
import { useState } from "react";
import "./style.css";
import { Icon } from "@fluentui/react";

interface Props {
    pages: number;
    currentPage: number;
    updatePagination: (step: number) => void;
}

const threshold = 200;

const Pagination: React.FunctionComponent<Props> = props => {
    const [paginate, updatePaginate] = useState(0);
    const [swipe, updateSwipe] = useState(0);

    const keyNavigation = (ev: KeyboardEvent) => {
        if (
            ev.target instanceof HTMLInputElement ||
            ev.target instanceof HTMLButtonElement
        ) {
        } else {
            navigation(ev.key);
        }
    };

    const navigation = (direction: string, page?: number) => {
        switch (direction) {
            case "ArrowRight":
                if (paginate < props.pages - 1) {
                    updatePaginate(paginate + 1);
                    props.updatePagination(paginate + 1);
                }
                break;
            case "next":
                if (paginate < props.pages - 1) {
                    updatePaginate(paginate + 1);
                    props.updatePagination(paginate + 1);
                }
                break;
            case "ArrowLeft":
                if (paginate > 0) {
                    updatePaginate(paginate - 1);
                    props.updatePagination(paginate - 1);
                }
                break;
            case "prev":
                if (paginate > 0) {
                    updatePaginate(paginate - 1);
                    props.updatePagination(paginate - 1);
                }
                break;
            case "go":
                page ? updatePaginate(page) : updatePaginate(0);
                page ? props.updatePagination(page) : props.updatePagination(0);
                break;
            case "begining":
                updatePaginate(0);
                props.updatePagination(0);
                break;
            case "last":
                updatePaginate(props.pages - 1);
                props.updatePagination(props.pages - 1);
                break;
        }
    };

    const swipeNavigation = (ev: TouchEvent) => {
        switch (ev.type) {
            case "touchstart":
                updateSwipe(ev.touches[0].clientX);
                break;
            case "touchend":
                if (ev.changedTouches[0].clientX - swipe < -threshold) {
                    navigation("next");
                } else if (ev.changedTouches[0].clientX - swipe > threshold) {
                    navigation("prev");
                } else {
                }
                break;
        }
    };

    React.useEffect(() => {
        document.addEventListener("keyup", keyNavigation);
        document.addEventListener("touchstart", swipeNavigation);
        document.addEventListener("touchend", swipeNavigation);
        return () => {
            document.removeEventListener("touchstart", swipeNavigation);
            document.removeEventListener("touchend", swipeNavigation);
            document.removeEventListener("keyup", keyNavigation);
        };
    }, [keyNavigation]);

    const setPagination = (dir: string, page?: number) => (
        evt: React.MouseEvent
    ) => {
        navigation(dir, page);
    };

    const getRows = () => {
        return (
            <>
                {paginate - 1 > 0 ? (
                    <div
                        className="page_number"
                        onClick={setPagination("go", paginate - 2)}
                    >
                        <p>{paginate - 1}</p>
                    </div>
                ) : null}
                {paginate > 0 ? (
                    <div
                        className="page_number"
                        onClick={setPagination("go", paginate - 1)}
                    >
                        <p>{paginate}</p>
                    </div>
                ) : null}

                <div className="page_number active">
                    <p>{paginate + 1}</p>
                </div>
                {paginate + 1 < props.pages ? (
                    <div
                        className="page_number"
                        onClick={setPagination("go", paginate + 1)}
                    >
                        <p>{paginate + 2}</p>
                    </div>
                ) : null}
                {paginate + 2 < props.pages ? (
                    <div
                        className="page_number"
                        onClick={setPagination("go", paginate + 2)}
                    >
                        <p onClick={setPagination("go", paginate + 2)}>
                            {paginate + 3}
                        </p>
                    </div>
                ) : null}
            </>
        );
    };

    return (
        <>
            {props.pages >= 1 ? (
                <div className="pagination">
                    <div
                        className="icon left"
                        onClick={setPagination("begining")}
                    >
                        <Icon iconName="DoubleChevronLeft" />
                    </div>
                    <div className="icon left" onClick={setPagination("prev")}>
                        <Icon iconName="ChevronLeft" />
                    </div>
                    {getRows()}
                    <div className="icon right" onClick={setPagination("next")}>
                        <Icon iconName="ChevronRight" />
                    </div>
                    <div className="icon right" onClick={setPagination("last")}>
                        <Icon iconName="DoubleChevronRight" />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Pagination;
