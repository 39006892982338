import produce from "immer";
import { Actions } from "./action";

export interface ZipCodeDepartmentState {
    zipCodeDepartmentAvailabilityList: ZipCodeDepartmentAvailability[];
}

const initialState: ZipCodeDepartmentState = {
    zipCodeDepartmentAvailabilityList: []
};

export const reducer = (
    zipCodeDepartmentState = initialState,
    action: Actions
): ZipCodeDepartmentState =>
    produce(zipCodeDepartmentState, draft => {
        switch (action.type) {
            case "UPDATE_ZIPCODE_DEPARTMENTS": {
                draft.zipCodeDepartmentAvailabilityList =
                    action.zipCodeDepartmentAvailabilityList;
                return;
            }
            case "UPDATE_ZIPCODE_DEPARTMENT": {
                const index = draft.zipCodeDepartmentAvailabilityList.findIndex(
                    x =>
                        x.zipCode ===
                        action.zipCodeDepartmentAvailability.zipCode
                );
                draft.zipCodeDepartmentAvailabilityList[index] =
                    action.zipCodeDepartmentAvailability;
                return;
            }
        }
    });

export const getZipCodeDepartmentAvailabilityList = (
    state: ZipCodeDepartmentState
) => state.zipCodeDepartmentAvailabilityList;

export default reducer;
