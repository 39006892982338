import { IDropdownOption } from "@fluentui/react";

export const logReasonOptionsMoved: IDropdownOption[] = [
    { key: "", text: "Vælg en årsag til ændring" },
    { key: "1", text: "Kunden ønskede at flytte tiden" },
    { key: "2", text: "Kunde dukkede ikke op/glemt tid" },
    { key: "3", text: "Forkert rude, ny tid aftalt" },
    { key: "4", text: "Mobilservice, flyttet pga vind/vejr forhold" },
    { key: "5", text: "Stenslag var revnet/stort, rudeskift booket" },
    { key: "6", text: "Tiden er flyttet grundet over booking" },
    { key: "7", text: "Tiden er flyttet grundet sygdom/ferie/fri" },
    { key: "9", text: "Rudeskift I stedet for reparation" },
    { key: "10", text: "Web booking kalibrering PK3" },
    { key: "8", text: "Andet" }
];

export const logReasonOptionsCancelled: IDropdownOption[] = [
    { key: "", text: "Vælg en årsag til aflysningen" },
    { key: "1", text: "Kunden ønskede at aflyse tiden" },
    { key: "2", text: "Kunde dukkede ikke op/glemt tid" },
    { key: "3", text: "Forkert rude, aflyst" },
    { key: "4", text: "Mobilservice, aflyst pga vind/vejr forhold" },
    { key: "5", text: "Stenslag var en nist" },
    { key: "6", text: "Stenslag var revnet/stort, rudeskift booket" },
    { key: "7", text: "Stenslag var revnet/stort, nyt rudeksift ej booket" },
    { key: "8", text: "Tiden er aflyst grundet over booking" },
    { key: "9", text: "Tiden er aflyst grundet sygdom/ferie/fri" },
    { key: "11", text: "Stenslag var repareret tidligere" },
    { key: "10", text: "Andet" }
];

export const logReasonWindowChangeMoved: IDropdownOption[] =[
    { key: "", text: "Vælg en årsag til aflysningen" },
    { key: "1", text: "Kunden ønskede at flytte tiden" },
    { key: "2", text: "Forkert rude eller tilbehør" },
    { key: "3", text: "Manglende rude eller tilbehør" },
    { key: "4", text: "Ridset eller defekt rude" },
    { key: "5", text: "Dårligt vejr - ny tid" },
    { key: "6", text: "Kunde ej til stede - ny tid" },
    { key: "7", text: "Kalibrering ikke muligt" },
    { key: "8", text: "Kunne ikke nå jobbet - ny tid" },
    { key: "9", text: "Sygdom - ny tid" },
    { key: "10", text: "Overdækning ikke optimal (carport/garage for lille)" },
    { key: "11", text: "Rust i ruderammen" },
    { key: "13", text: "Rudeskift I stedet for reparation" },
    { key: "14", text: "Web booking kalibrering PK3" },
    { key: "12", text: "Andet" },
];

export const logReasonStoneChipMoved: IDropdownOption[] =[
    { key: "", text: "Vælg en årsag til aflysningen" },
    { key: "1", text: "Kunden ønskede at flytte tiden" },
    { key: "2", text: "Rudeskift i stedet for reparation" },
    { key: "3", text: "Kunde ej til stede - ny tid" },
    { key: "4", text: "Dårligt vejr - ny tid" },
    { key: "5", text: "Kunne ej tilstede - ny tid" },
    { key: "6", text: "Sygdom" },
    { key: "7", text: "Sidder i synsfeltet/bil skal snart synes" },
    { key: "9", text: "Rudeskift I stedet for reparation" },
    { key: "10", text: "Web booking kalibrering PK3" },
    { key: "8", text: "Andet" },
];

export const logSaveReasonChangeOL: IDropdownOption[] =[
    { key: "", text: "Vælg en årsag til aflysningen" },
    { key: "1", text: "Forkert rude" },
    { key: "2", text: "Ruden er ikke brugt" },
    { key: "3", text: "Andet" },
];
