import * as React from "react";
import Search from "../../base/Search";
import { useEffect } from "react";
import SimpleProductGroup from "../SimpleProducts/SimpleProductsGroup";
import "../style.css"
import { Stack, Label } from "@fluentui/react";

interface Props {
    query: { searchIn?: string; term?: string };
    productGroups: ProductGroup[];
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => void;
    updateSearchProductQuery: (searchIn?: string, term?: string) => void;
    selectedProducts: (products: Product[]) => void;
    hideSearchBar?: boolean | undefined;
}

const FindProduct = (props: Props) => {
    const [productGroups, setProductGroups] = React.useState<ProductGroup[]>(
        props.productGroups
    );

    useEffect(() => {
        setProductGroups(props.productGroups);
    }, [props.productGroups]);

    useEffect(() => {
        const { query: productSearchQuery } = props;
        if (
            productSearchQuery &&
            productSearchQuery.term &&
            productSearchQuery.searchIn
        ) {
            props.updateSearchList(
                {
                    searchIn: productSearchQuery.searchIn,
                    term: productSearchQuery.term
                },
                false
            );
        }
    }, []);

    const searchProduct = (searchIn: string, term: string) => {
        props.updateSearchList({ searchIn, term }, true);
        props.updateSearchProductQuery(searchIn, term);
    };

    let result: JSX.Element = <></>;
    if (!productGroups) {
        result = <p></p>;
    } else {
        result = (
            <SimpleProductGroup
                items={productGroups}
                selectedProducts={props.selectedProducts}
            />
        );
    }

    return (
        <Stack
            className="find-product__search"
            horizontal={true}
            tokens={{ childrenGap: 15 }}
        >
            <Stack.Item grow={2}>
                <div hidden={props.hideSearchBar === undefined ? false : true}>
                    <Label>Varer</Label>
                    <Search query={props.query} onSearch={searchProduct} />
                </div>
                {result}
            </Stack.Item>
        </Stack>
    );
};
export default FindProduct;
