import { IDropdownOption } from "@fluentui/react";

export default (selectedDay?: number) =>
{
    const options: IDropdownOption[] = [];

    const monday: IDropdownOption = { text: "Mandag", key: 1, selected: selectedDay === 1 };
    options.push(monday);

    const tuesday: IDropdownOption = { text: "Tirsdag", key: 2, selected: selectedDay === 2 };
    options.push(tuesday);

    const wedensday: IDropdownOption = { text: "Onsdag", key: 3, selected: selectedDay === 3 };
    options.push(wedensday);

    const thursday: IDropdownOption = { text: "Torsdag", key: 4, selected: selectedDay === 4 };
    options.push(thursday);

    const friday: IDropdownOption = { text: "Fredag", key: 5, selected: selectedDay === 5 };
    options.push(friday);

    const saturday: IDropdownOption = { text: "Lørdag", key: 6, selected: selectedDay === 6 };
    options.push(saturday);

    const sunday: IDropdownOption = { text: "Søndag", key: 0, selected: selectedDay === 0 };
    options.push(sunday);

    return options;
};

export const deliveringDays = [
    { text: "Mandag",  key: "1" },
    { text: "Tirsdag", key: "2" },
    { text: "Onsdag",  key: "3" },
    { text: "Torsdag", key: "4" },
    { text: "Fredag",  key: "5" },
    { text: "Lørdag",  key: "6" },
    { text: "Søndag",  key: "0" },
];