import * as React from "react";
import PageHeader from "../layout/PageHeader";
import {
    validate,
    createUserFieldConfig,
    isModelValid
} from "../../util/validate";
import NavigationHelper from "../utils/NavigationHelper";
import "./style.css";
import { IDropdownOption, TextField, Dropdown, Stack, Checkbox, PrimaryButton, selectProperties } from "@fluentui/react";

interface CreateUserProps {
    register: (user: UserInformation) => void;
    departments: Department[];
}

const CreateUser: React.FunctionComponent<CreateUserProps> = props => {
    const [email, setEmail] = React.useState("");
    const [name, setName] = React.useState("");
    const [allowEmail, setAllowEmail] = React.useState(Boolean);
    const [allowSMS, setAllowSMS] = React.useState(Boolean);
    const [phone, setPhone] = React.useState("");
    const [roles, setRoles] = React.useState(Array<UserRole>());
    const [departmentGuid, setDepartmentGuid] = React.useState("");
    const [calendarDepartmentGuid, setCalenderDepartmentGuid] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [autoGenPass, setAutoGenPass] = React.useState(true);
    const [initials, setInitials] = React.useState("");

    const updateInitials = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setInitials(value);
        initials;
    };

    const updateEmail = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setEmail(value);
    };

    const updateName = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setName(value);
    };

    const updateAllowEmail = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: boolean
    ) => {
        setAllowEmail(value);
    };

    const updateAllowSMS = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: boolean
    ) => {
        setAllowSMS(value);
    };

    const updatePhone = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setPhone(value);
    };

    const updatePassword = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setPassword(value);
    };

    const updateConfirmPassword = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setConfirmPassword(value);
    };

    const updateRoles = (role: UserRole) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: boolean
    ) => {
        if (value) {
            setRoles([...roles, role]);
        } else {
            setRoles(roles.filter(x => x !== role));
        }
    };

    const updateAutoGenPass = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: boolean
    ) => {
        setAutoGenPass(value);
        if (value) {
            setPassword("");
            setConfirmPassword("");
        }
    };

    const departmentOptions = ():IDropdownOption[] => {
        return props.departments.map(x => {
            return { key: x.id as string, text: x.name };
        });
    };

    const localDepartmentOptions = () => {
        return props.departments
            .filter(x => !x.mobileService)
            .map(x => {
                return { key: x.id as string, text: x.name };
            });
    };

    const updateCustomerBookingDepartment = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ) => {
        if (option) {
            setDepartmentGuid(option.key.toString());
        }
    };

    const updateCalendarDepartment = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ) => {
        if (option) {
            setCalenderDepartmentGuid(option.key.toString());
        }
    };

    const registerUser = () => {
        props.register({
            name,
            initials,
            phone,
            email,
            roles,
            allowEmail,
            allowSMS,
            departmentGuid,
            password,
            confirmPassword,
            calendarDepartmentGuid,
        });

        NavigationHelper.pushRoute("USERS");
    };

    const isSelected = (acceptedRoles: UserRole[]) => {
        let accepted = true;
        acceptedRoles.forEach(element => {
            if (roles.indexOf(element) < 0) {
                accepted = false;
            }
        });
        return accepted;
    };

    const disabled = !isModelValid(
        {
            name,
            initials,
            email,
            phone,
            roles,
            password: autoGenPass ? "123456" : password,
            confirmPassword: autoGenPass ? "123456" : confirmPassword,
            allowSMS,
            allowEmail: autoGenPass ? allowEmail : null
        },
        createUserFieldConfig
    );
    return (
        <>
            <PageHeader currentPage="Opret bruger" noBreadcrumb={true} />
            <div>
                <br />
                <br />
                <div className="text_field__container">
                    <TextField
                        onGetErrorMessage={validate(
                            createUserFieldConfig["initials"].validation
                        )}
                        width={"200px"}
                        required
                        placeholder="Initialer"
                        onChange={updateInitials}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    <TextField
                        onGetErrorMessage={validate(
                            createUserFieldConfig["name"].validation
                        )}
                        width={"200px"}
                        required
                        placeholder="Name"
                        onChange={updateName}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    <TextField
                        onGetErrorMessage={validate(
                            createUserFieldConfig["email"].validation
                        )}
                        width={"200px"}
                        placeholder="Email"
                        onChange={updateEmail}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    <TextField
                        onGetErrorMessage={validate(
                            createUserFieldConfig["phone"].validation
                        )}
                        width={"200px"}
                        placeholder="Phone"
                        onChange={updatePhone}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    <Dropdown
                        calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                        style={{ minWidth: "15em" }}
                        onChange={updateCalendarDepartment}
                        placeholder={"Vælg default kalender afdeling"}
                        options={departmentOptions()}
                        selectedKey={calendarDepartmentGuid}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    <Stack horizontal={true} tokens={{ childrenGap: 20 }}>
                        <Stack.Item>
                            <TextField
                                onGetErrorMessage={validate(
                                    createUserFieldConfig["password"].validation
                                )}
                                type="password"
                                width={"200px"}
                                value={password}
                                required={!autoGenPass}
                                disabled={autoGenPass}
                                placeholder="Password"
                                onChange={updatePassword}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                type="password"
                                width={"200px"}
                                value={confirmPassword}
                                required={!autoGenPass}
                                disabled={autoGenPass}
                                placeholder="Confirm password"
                                onChange={updateConfirmPassword}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Auto pass"
                                onChange={updateAutoGenPass}
                                checked={autoGenPass}
                            />
                            <br />
                        </Stack.Item>
                    </Stack>
                </div>
                <br />
                <div className="user_fields__container">
                    <Stack horizontal={true} tokens={{ childrenGap: 20 }}>
                        <Stack.Item>Type:*</Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Administrator"
                                onChange={updateRoles("Adminstrator")}
                                checked={isSelected(["Adminstrator"])}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Kundeservice"
                                onChange={updateRoles("CustomerService")}
                                checked={isSelected(["CustomerService"])}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Lager"
                                onChange={updateRoles("Stock")}
                                checked={isSelected(["Stock"])}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Ordregennemsyn"
                                onChange={updateRoles("ReviewManager")}
                                checked={isSelected(["ReviewManager"])}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Drift"
                                onChange={updateRoles("Operation")}
                                checked={isSelected(["Operation"])}
                            />
                            <br />
                            {roles.indexOf("Operation") >= 0 &&
                            roles.indexOf("CustomerService") < 0 ? (
                                <Dropdown
                                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                    style={{ minWidth: "15em" }}
                                    onChange={updateCustomerBookingDepartment}
                                    placeholder={"Vælg afdeling"}
                                    options={departmentOptions()}
                                    selectedKey={departmentGuid}
                                />
                            ) : null}
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Stenslag"
                                onChange={updateRoles("QuickStoneChip")}
                                checked={isSelected(["QuickStoneChip"])}
                            />
                            <br />
                            {roles.indexOf("QuickStoneChip") >= 0 ? (
                                <Dropdown
                                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                    style={{ minWidth: "15em" }}
                                    onChange={updateCustomerBookingDepartment}
                                    placeholder={"Vælg afdeling"}
                                    options={localDepartmentOptions()}
                                    selectedKey={departmentGuid}
                                />
                            ) : null}
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="VIN opslag"
                                onChange={updateRoles("VINSearch")}
                                checked={isSelected(["VINSearch"])}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal={true} tokens={{ childrenGap: 20 }}>
                        <Stack.Item>Send kode:</Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Email"
                                onChange={updateAllowEmail}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox label="SMS" onChange={updateAllowSMS} />
                        </Stack.Item>
                    </Stack>
                </div>
                <br />
                <PrimaryButton disabled={disabled} onClick={registerUser}>
                    Opret{" "}
                </PrimaryButton>
                <PrimaryButton
                    style={{ marginLeft: "1em" }}
                    onClick={() => NavigationHelper.pushRoute("USERS")}
                >
                    {" "}
                    Tilbage{" "}
                </PrimaryButton>
            </div>
        </>
    );
};

export default CreateUser;
