import api from "../../api";
import { Action, Dispatch } from "redux";
import { addMessageStatus } from "../ui/action";
import { getCorrectItemPrices } from "../stateUtils/itemPriceHelper";
import { MessageBarType } from "@fluentui/react";
import * as uniqId from "uniqid";
import { NotificationStatusAction } from "../sms/action";
export interface GetOrdersAction extends Action {
    type: "GET_ORDERS";
    orders: Order[];
    pageCount: number;
}

export interface UpdateSelectedOrderAction extends Action {
    type: "UPDATE_SELECT_ORDERS";
    order: Order;
}

let controller = new AbortController();
let isProcessing = false;
export const fetchOrders = (filter: OrderFilter) => async (
    dispatch: Dispatch
) => {
    try {
        if (isProcessing) {
            controller.abort();
        }
        controller = new AbortController();
        isProcessing = true;
        const { orders, pageCount } = await api.OrderAPI.getOrders(
            filter,
            controller.signal
        );
        isProcessing = false;
        const action: GetOrdersAction = {
            type: "GET_ORDERS",
            orders,
            pageCount
        };
        dispatch(action);
    } catch (ex) {
        if ((ex as any).code !== 20) {
            const statusMessage: StatusMessage = {
                messageType: MessageBarType.error,
                message: 'Api: "getOrders" failed',
                dismissTimer: 5000
            };
            addMessageStatus(statusMessage)(dispatch);
        }
    }
};

export const saveSelectedOrder = (order: Order, status?: OrderStatus) => async (
    dispatch: Dispatch
) => {
    const uuid = uniqId();
    const statusMessage: StatusMessage = {
        messageType: MessageBarType.info,
        message:
            "Saving order...",
        dismissTimer: 5000,
        id: uuid
    };

    addMessageStatus(statusMessage)(dispatch);

    if (status) {
        order.additionalData.orderStatus = status;
    }
    try {
        // time validation
        // timeslot or start and end datetime must be selected
        if (
            order.timeAndPlace.timeSlotId === undefined &&
            order.timeAndPlace.from === undefined &&
            order.timeAndPlace.to === undefined
        ) {
            const statusMessage: StatusMessage = {
                messageType: MessageBarType.error,
                message:
                    "Du skal vælge et timeslot før du kan gemme ændringerne.",
                dismissTimer: 5000,
                id: uuid
            };

            addMessageStatus(statusMessage)(dispatch);
            return;
        }

        let items: PurchaseItem[] = [];
        if (order.items !== null) {
            items = getCorrectItemPrices(order.items);
        }

        order = await api.OrderAPI.updateOrder({ ...order, items: items });
        dispatch({ type: "UPDATE_ORDER", order: order });
        if (order.customer.allowEmail && order.customer.email) {
            try {
                
                if (status === "FINISHED") {
                    if (
                        order.additionalData.orderNr &&
                        !order.customer.email.includes("@bilglas.dk") &&
                        order.debitor.no !== 'D037690'
                    ) {
                        await api.EmailApi.sendCustomerSatisfactionEmail(
                            order.customer.name,
                            order.customer.email,
                            order.additionalData.orderNr
                        );
                    }
                }
            } catch {
                const statusMessage: StatusMessage = {
                    messageType: MessageBarType.error,
                    message: "Failed to send Trustpilot review email",
                    dismissTimer: 5000
                };

                addMessageStatus(statusMessage)(dispatch);
            }
        }

        const statusMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: "Order saved",
            dismissTimer: 5000,
            id:uuid
        };

        addMessageStatus(statusMessage)(dispatch);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: 'Api: "saveOrder" failed',
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export type Actions = GetOrdersAction | NotificationStatusAction;
