import { Actions } from "./action";
import produce from "immer";

export interface OldProductsState {
    oldProducts: OldProduct[];
}

const initialState: OldProductsState = {
    oldProducts: [
        {
            agcNr: "",
            autoverNr: "",
            brand: "",
            calibration: "",
            clips: "",
            comment: "",
            coverStrip: "",
            dbNr: "",
            enviroment: "",
            eurocode: "",
            glue: "",
            glueList: "",
            internalComment: "",
            lists: "",
            measurement: "",
            mounting: "",
            oemNr: "",
            pK1: "",
            pK2: "",
            pK3: "",
            pK4: "",
            price1F: "",
            price2F: "",
            priceReady: "",
            productNumber: "",
            rubberList: "",
            sensor: "",
            st1St5St6: "",
            st8: "",
            time: "",
            window1F: "",
            window2F: "",
            windowReady: "",
            year: ""
        }
    ]
};

export const reducer = (
    oldProductsState = initialState,
    action: Actions
): OldProductsState =>
    produce(oldProductsState, draft => {
        switch (action.type) {
            case "UPDATE_OLD_PRODUCTS": {
                draft.oldProducts = action.oldProducts;
                return draft;
            }
        }
    });

export const getOldProducts = (state: OldProductsState) => state.oldProducts;

export default reducer;
