import TableHelper from "../utils/TableHelper";
import SubItems from "./SubItems";
import { parsePriceAsString, vatConverter } from "../utils/vatConverter";
import DebitorPrice from "./DebitorPrice";
import TipsAndTricksDialog from "../base/TipsAndTricksDialog";
import { useState, useCallback } from "react";
import { hasTipsAndTricks } from "../base/TipsAndTricksDialog/TipsAndTricks";
import ProductColumn from "./ProductColumn";
import "./style.css"
import { defaultImage } from "./Assets/defaultImage";
import { ImageLoadState, IColumn, TooltipHost, DefaultButton, Icon, TextField, PrimaryButton, DetailsList, DetailsListLayoutMode, CheckboxVisibility, Image } from "@fluentui/react";
import * as React from "react";

interface Props {
    debitorNo: string;
    products: Product[];
    addProducts: (products: Product[]) => void;
    changeAmount: (productNo: string, amount: number) => void;
    showStorageWindow: (status: Status[]) => void;
    debitorPriceRequest: (request: ProductPriceRequest[]) => void;
}

const Products: React.FunctionComponent<Props> = props => {
    const [showTipsAndTrickDialog, setShowTipsAndTrickDialog] = useState<
        Product[] | undefined
    >(undefined);

    const addProduct = (product: Product) => () => {
        const { subItems, ...rest } = product;
        let products = [rest];
        if (subItems) {
            const otherProducts: Product[] = subItems.map(sub => ({
                ...sub,
                status: [],
                twoPersonJob: false,
                previouslyPurchased: false
            }));
            products = [...products, ...otherProducts];
        }
        props.addProducts(products);
    };

    const onShowTipsAndTrickClick = useCallback(
        (e: Product) => (event: React.MouseEvent<HTMLElement, MouseEvent>) =>
            setShowTipsAndTrickDialog([e]),
        []
    );

    const onCloseTipsAndTrickDialog = useCallback(
        () => setShowTipsAndTrickDialog(undefined),
        []
    );

    const checkImage = (e: ImageLoadState) => {
        if (e === 2) {
        }
    };

    const changeAmount = (product: Product) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => props.changeAmount(product.no, parseInt(value, 10));

    const showStorageWindow = (status: Status[]) => () =>
        props.showStorageWindow(status);
   
    const columns: IColumn[] = [
        TableHelper.col(
            "image",
            "",
            46,
            46,
            (e: Product) => ( 
                <Image
                    src={defaultImage}
                    alt={e.no}
                    shouldFadeIn={true}
                    onLoadingStateChange={checkImage} />
            ),
            "col-image"
        ), // TODO
        TableHelper.col(
            "id",
            "Varenummer",
            16,
            76,
            (e: Product) => {
                if (e.isService) {
                    return null;
                }

                return <ProductColumn product={e} />;
            },
            "col-id"
        ),
        TableHelper.col(
            "name",
            "Navn",
            16,
            220,
            (e: Product) => (
                <TooltipHost
                    content={
                        e.name +
                        (e.description ? " (" + e.description + ")" : "")
                    }
                    id={"name"}
                    calloutProps={{ gapSpace: 0 }}
                >
                    <p>{e.name}</p>
                    {e.description ? <p>{e.description}</p> : null}
                </TooltipHost>
            ),
            "col-name"
        ),
        TableHelper.col(
            "stock",
            "Lager",
            16,
            55,
            (e: Product) => {
                if (e.isService || e.isVINitem === true) {
                    return null;
                }

                return (
                    <>
                        <label>Lager:</label>
                        <div className="storage_button__container">
                            <DefaultButton
                                text={e.status
                                    .reduce(
                                        (sum, current) => sum + current.amount,
                                        0
                                    )
                                    .toString()}
                                onClick={showStorageWindow(e.status)}
                            />
                        </div>
                    </>
                );
            },
            "col-storage"
        ),
        TableHelper.col(
            "price",
            "Listepris",
            16,
            150,
            (e: Product) => {
                if (e.isVINitem === true) {
                    return null;
                }

                let price = e.price;
                let calibration = null;
                if (e.subItems) {
                    price += e.subItems.reduce(
                        (sum, sub) => sum + sub.price * sub.amount,
                        0
                    );
                    if (e.subItems.find(x => x.type === 3)) {
                        calibration = (
                            <span className="calibration__icon">
                                <Icon iconName="LocationDot"></Icon>
                            </span>
                        );
                    }
                }
                return (
                    <div className="price__list">
                        <div>
                            <label>Inkl. moms:</label>
                            <p>
                                {vatConverter(price)} {calibration}
                            </p>
                        </div>
                        <div>
                            <label>Eksl. moms:</label>
                            <p>
                                {parsePriceAsString(price)} {calibration}
                            </p>
                        </div>
                    </div>
                );
            },
            "col-price"
        ),
        TableHelper.col(
            "debitorPrice",
            "Kundepris",
            16,
            100,
            (e: Product) => {
                if (e.isVINitem === true) {
                    return null;
                }

                const { debitorNo, debitorPriceRequest } = props;
                const { no, subItems, amount, debitorPrice } = e;
                return (
                    <div className="price__list">
                        <DebitorPrice
                            {...{
                                debitorNo,
                                debitorPriceRequest,
                                no,
                                subItems,
                                amount,
                                debitorPrice
                            }}
                        />
                    </div>
                );
            },
            "col-deb-price"
        ),
        TableHelper.col(
            "tips",
            "",
            16,
            100,
            (e: Product) => {
                if (e.isVINitem === true) {
                    return null;
                }

                if (hasTipsAndTricks(e)) {
                    return (
                        <Icon
                            aria-valuetext={e.no}
                            onClick={onShowTipsAndTrickClick(e)}
                            iconName="Repair"
                        ></Icon>
                    );
                }
                return <></>;
            },
            "col-deb-price"
        ),
        TableHelper.col(
            "ordernr",
            "",
            150,
            150,
            (e: Product) => {
                if (e.isVINitem === true) {
                    return null;
                }

                let subItem = null;
                if (e.subItems && e.subItems.length > 0) {
                    const { debitorNo, debitorPriceRequest } = props;
                    subItem = (
                        <SubItems
                            storageSum={e.status
                                .reduce(
                                    (sum, current) => sum + current.amount,
                                    0
                                )
                                .toString()}
                            showStorageWindow={showStorageWindow(e.status)}
                            items={[e as SubItem].concat(e.subItems)}
                            productName={e.name}
                            {...{ debitorNo, debitorPriceRequest }}
                        />
                    );
                }
                return (
                    <>
                        {subItem}
                        {e.twoPersonJob ? (
                            <TooltipHost
                                content="To-mands job"
                                id={"tip0"}
                                calloutProps={{ gapSpace: 0 }}
                            >
                                <Icon
                                    title="To-mands job"
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "10px"
                                    }}
                                    iconName="People"
                                />
                            </TooltipHost>
                        ) : null}
                        {e.previouslyPurchased ? (
                            <TooltipHost
                                content="Purchased"
                                id={"tip1"}
                                calloutProps={{ gapSpace: 0 }}
                            >
                                <Icon
                                    title="Purchased"
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                        color: "#43a943"
                                    }}
                                    iconName="FavoriteStarFill"
                                />
                            </TooltipHost>
                        ) : null}
                        <TextField
                            style={{ maxWidth: "50px" }}
                            placeholder="Antal"
                            type="number"
                            value={e.amount.toString()}
                            onChange={changeAmount(e)}
                        />
                        <PrimaryButton
                            text={e.amount >= 0 ? "Tilføj" : "Fjern"}
                            onClick={addProduct(e)}
                        />
                    </>
                );
            },
            "col-buttons"
        )
    ];
    return (
        <>
            <TipsAndTricksDialog
                products={showTipsAndTrickDialog}
                onCloseClick={onCloseTipsAndTrickDialog}
            />
            <DetailsList
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                columns={columns}
                items={props.products}
                checkboxVisibility={CheckboxVisibility.hidden}
                className="ProductPriceList"
            />
        </>
    );
};

export default Products;
