import api from "../../api";

export const getCorrectItemPrices = (items: PurchaseItem[]): PurchaseItem[] => {
    return items.map(i => {
        return {
            no: i.no,
            amount: i.amount,
            description: i.description,
            discountPercent: i.debitorPrice
                ? i.debitorPrice.discountPercent
                : i.discountPercent,
            itemNo: i.itemNo,
            itemPrice: i.debitorPrice ? i.debitorPrice.price : i.itemPrice,
            type: i.type,
            name: i.name,
            twoPersonJob: i.twoPersonJob || false,
            isMainItem: i.isMainItem || false
        } as PurchaseItem;
    });
};

export async function updateDebitorPrices(
    debitor: Partial<Debitor>,
    order: Order
) {
    if (
        order.items.length > 0 &&
        debitor.no &&
        debitor.no !== order.debitor.no
    ) {
        const debitorPrices = await api.DebitorApi.getDebitorPrices(
            debitor.no,
            order.items.map<ProductPriceRequest>(p => ({
                productNo: p.itemNo,
                amount: p.amount
            }))
        );
        order.items.forEach(product => 
        {
            const debitorPrice = debitorPrices.find(
                d => d.itemNo === product.itemNo
            );
            product.debitorPrice = debitorPrice;
            product.discountPercent = debitorPrice
                ? debitorPrice.discountAmount
                : product.discountPercent;
        });
        return order.items;
    }
    return order.items;
}
