export const getCurrentBookingCount = (timeslot: DamageSpecificTimeSlot, alreadyUsedTimeslot?: AlreadyUsedTimeslot) => {
    if (!alreadyUsedTimeslot || !timeslot.id) return 0;

    let bookings = alreadyUsedTimeslot[timeslot.id] || [];

    if (timeslot.originalId) {
        const originalBookings = alreadyUsedTimeslot[timeslot.originalId] || [];
        bookings = [...bookings, ...originalBookings];
    }

    if (timeslot.date) {
        return bookings
            .filter(booking => booking.date === timeslot.date)
            .reduce((total, booking) => total + booking.bookings, 0);
    }

    return bookings.reduce((maxBookings, booking) => Math.max(maxBookings, booking.bookings), 0);
}