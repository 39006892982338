import BaseApi from "./baseApi";

interface IMediaAPI {
    uploadImage: (image: any, orderId: string) => Promise<string>;
}

export default class MediaAPI extends BaseApi implements IMediaAPI {
    async uploadImage(image: any, orderId: string): Promise<string> {
        const formData = new FormData();
        formData.append("file", image);
        formData.append("orderId", orderId);
        const url = `/api/media`;
        return await this.fetchPutFile(url, formData);
    }
}
