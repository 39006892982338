import * as React from "react";
import SearchPane from "../base/SearchPane";
import DebitorLookupByName from "./DebitorLookupByName";

interface Props {
    getDebitor: (query: string, type: ApiType) => void;
    debitors?: Debitor[];
    selectedDebitor: Debitor;
    onDebitorChange: (debitor: Debitor) => void;
    whoIsPayingChange: (whoIsPaying: WhoIsPaying) => void;
}

const DebitorLookup: React.FunctionComponent<Props> = props => {
    const [searchType, setSearchType] = React.useState<undefined | ApiType>(
        undefined
    );
    const [phone, setPhone] = React.useState<string>("");
    const [debitorNr, setDebitorNr] = React.useState<string>("");
    const [cvr, setCVR] = React.useState<string>("");
    const [debitorName, setDebitorName] = React.useState<string>("");

    React.useEffect(() => {
        setPhone(props.selectedDebitor.phone);
        setDebitorNr(props.selectedDebitor.no);
        setDebitorName(props.selectedDebitor.debitorName);
        setCVR(props.selectedDebitor.cvr);
    }, [props.selectedDebitor]);

    const onDebitorSearch = (query: string, type: ApiType) => {
        setSearchType(type);
        props.getDebitor(query, type);
    };
    const search = (type: ApiType) => (query: string) => {
        if (type === "PHONE") {
            setPhone(query);
        }
        if (type === "NAV") {
            setDebitorNr(query);
        }
        if (type === "CVR") {
            setCVR(query);
        }
        if (type === "NAME") {
            setDebitorName(query);
        }
        onDebitorSearch(query, type);
    };

    const onDebitorSelect = (debitor: Debitor) => {
        props.onDebitorChange(debitor);
        props.whoIsPayingChange("DEBITOR");
    };

    const onValueSelected = (item: string, index: number) => {
        if (props.debitors) {
            onDebitorSelect(props.debitors[index]);
        }
    };

    let searchField: string[] = [];
    if (props.debitors) {
        searchField = props.debitors.map(
            debitor => `${debitor.debitorName} ${debitor.cvr} ${debitor.phone}`
        );
    }

    return (
        <>
            <h5>Søg efter kunde/virksomhed</h5>
            <SearchPane
                title="Tlf. nr."
                updateValueOnChange={true}
                placeholder="Tlf. nr."
                value={phone}
                onValueChange={search("PHONE")}
                onValueSelected={onValueSelected}
                items={searchType === "PHONE" ? searchField : []}
            />
            <SearchPane
                title="Kunde nr."
                updateValueOnChange={true}
                placeholder="Kunde nr."
                value={debitorNr}
                onValueChange={search("NAV")}
                onValueSelected={onValueSelected}
                items={searchType === "NAV" ? searchField : []}
            />
            <SearchPane
                title="CVR-nummer"
                updateValueOnChange={true}
                placeholder="CVR-nummer"
                value={cvr}
                onValueChange={search("CVR")}
                onValueSelected={onValueSelected}
                items={searchType === "CVR" ? searchField : []}
            />
            <DebitorLookupByName
                title="CVR. navn."
                debitors={searchType === "NAME" ? props.debitors : []}
                getDebitor={onDebitorSearch}
                onDebitorChange={onDebitorSelect}
                selectedDebitor={props.selectedDebitor}
            />
        </>
    );
};

export default DebitorLookup;
