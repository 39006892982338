import { Dispatch } from "redux";
import api from "../../api";
import { addMessageStatus } from "../ui/action";
import NavigationHelper from "../../components/utils/NavigationHelper";
import {
    WhoIsPayingAction,
    UpdateDebitorAction,
    UpdateTimeAndPlaceAction,
    UpdateCarAction,
    LoadOrderAction,
    UpdateImageAction,
    ClearOrderAction,
    UpdateCustomerAction,
    UpdateAdditionalAction,
    UpdateItemsAction,
    AddProductsAction,
    SetProductDiscountAction,
    SetProductAmountAction,
    SetProductPriceAction,
    RemoveProductAction,
    UpdateOrderAction,
    RemoveImagesAction,
    AddProductAction,
    SetProductNameAction,
    UpdateCustomerCityFromZipAction,
    UpdateComplaintAction
} from "./ActionInterfaces";
import {
    orderToNotificationModel,
    getRepairTypeFromOrder
} from "../../components/utils/smsHelper";
import { orderToOrderConfirmationModel } from "../../components/utils/emailHelper";
import {
    getCorrectItemPrices,
    updateDebitorPrices
} from "../stateUtils/itemPriceHelper";
import { sendSms } from "../../state/sms/action";
import { MessageBarType } from "@fluentui/react";
import * as uniqId from "uniqid";
import * as cloneDeep from 'lodash';

export const updateItems = (items: PurchaseItem[]): UpdateItemsAction => ({
    type: "UPDATE_ITEMS",
    items
});

let fetchingDebitor = false;
let debitorSearch: Debitor | undefined = undefined;
export const updateDebitorFromWhoIsPaying = (
    whoIsPaying: WhoIsPaying,
    order: Order,
    updatePrice: boolean,
    clearBookingTime:boolean
) => async (dispatch: Dispatch) => {
    let debitor = order.debitor;
    debitorSearch = debitor;
    if (fetchingDebitor) return;
    fetchingDebitor = true;
    let retDebitor: Debitor | undefined = undefined;
    try {
        do {
            debitor = debitorSearch;
            if (whoIsPaying === "INSURANCE" && order.car.insurance) {
                retDebitor = (
                    await api.DebitorApi.getDebitor(order.car.insurance, "NAME")
                )[0];
            } else if (
                whoIsPaying === "CASH" &&
                order.timeAndPlace.department
            ) {
                const departmentDebitor = (
                    await api.DebitorApi.getDebitor(
                        order.timeAndPlace.department.debitorId,
                        "NAV"
                    )
                )[0];
                retDebitor = {
                    ...order.customer,
                    debitorName: departmentDebitor.debitorName,
                    cvr: departmentDebitor.cvr,
                    no: departmentDebitor.no
                };
            } else if (whoIsPaying === "LEASING" && order.car.leaser) {
                retDebitor = (
                    await api.DebitorApi.getDebitor(order.car.leaser, "NAME")
                )[0];
            } else {
                retDebitor = debitor;
            }
        } while (debitor != debitorSearch);
        debitorSearch = undefined;
        if (retDebitor) {
            const updateDebitoraction: UpdateDebitorAction = {
                type: "UPDATE_DEBITOR",
                debitor: retDebitor,
                whoIsPaying
            };

            if (updatePrice) {
                const items = await updateDebitorPrices(retDebitor, order);
                dispatch(updateItems(items));
            }
            dispatch(updateDebitoraction);
        }
        dispatch(whoIsPayingChange(whoIsPaying));
        if(whoIsPaying === "CASH" && clearBookingTime)
        {
            dispatch(updateTimeAndPlace({
                timeSlotId: undefined,
                from: undefined,
                to: undefined
            }));
         
        }
        fetchingDebitor = false;
    } catch (ex) {
        fetchingDebitor = false;
    }
};

export const updateDebitor = (
    debitor: Partial<Debitor>,
    order: Order
) => async (dispatch: Dispatch) => {
    await updateDebitorPrices(debitor, order);
    dispatch(updateItems(order.items));
    const updateDebitoraction: UpdateDebitorAction = {
        type: "UPDATE_DEBITOR",
        debitor
    };
    dispatch(updateDebitoraction);
};

export const setDebitor = (debitor: Debitor, order: Order) => async (
    dispatch: Dispatch
) => {
    await updateDebitorPrices(debitor, order);
    dispatch(updateItems(order.items));
    const updateDebitoraction: UpdateDebitorAction = {
        type: "UPDATE_DEBITOR",
        debitor
    };
    dispatch(updateDebitoraction);
};

export const updateOrderDepartment = (
    department: Department,
    shouldUpdateDebitor: boolean
) => async (dispatch: Dispatch) => {
    const action: UpdateTimeAndPlaceAction = {
        type: "UPDATE_TIME_AND_PLACE",
        timeAndPlace: { department },
        worker: ""
    };
    dispatch(action);
    if (shouldUpdateDebitor) {
        //TODO Find debitor from department
    }
};

export const removeProduct = (itemNo: string): RemoveProductAction => ({
    type: "REMOVE_PRODUCT",
    itemNo
});

export const updateMobileServiceProduct = (
    isMobileService: boolean,
    debitorNo: string,
    type: AssignmentType
) => async (dispatch: Dispatch) => {
    if (isMobileService && type !== "REPAIR") {
        const product = await api.ProductAPI.getProduct("UD");
        product.amount = 1;
        if (debitorNo) {
            const debitorPrices = await api.DebitorApi.getDebitorPrices(
                debitorNo,
                [{ productNo: "UD", amount: 1 }]
            );
            product.debitorPrice = debitorPrices[0];
        }
        const action: AddProductAction = { type: "ADD_PRODUCT", product };
        dispatch(action);
    } else {
        const action = removeProduct("UD");
        dispatch(action);
    }
};

export const updateCarFromReg = (reg: string) => async (dispatch: Dispatch) => {
    try {
      
        const car = await api.CarAPI.getInformationFromReg(reg);
        const action: UpdateCarAction = { type: "UPDATE_CAR", car };
        dispatch(action);

    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "getInformationFromReg" failed with input ${reg}`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const postOrderFromState = (order: Order) => async (
    dispatch: Dispatch
) => {
    try {
        const orderStatus = order.additionalData.orderStatus;
        const items = getCorrectItemPrices(order.items);
        const postOrder = await api.OrderAPI.postOrder({
            ...order,
            items: items,
            additionalData:{...order.additionalData, orderStatus: orderStatus}
        });
        const action: UpdateOrderAction = {
            type: "UPDATE_ORDER",
            order: postOrder
        };
        dispatch(action);
        const notificationType = getRepairTypeFromOrder(postOrder);

        if ( postOrder.customer.allowSms && postOrder.customer.phone) 
        {
            if (postOrder.additionalData.orderStatus !== "OFFER")
            {
                const sms = orderToNotificationModel(postOrder, notificationType);
                sendSms(sms)(dispatch);
            }
        }

        if (postOrder.customer.allowEmail && postOrder.customer.email) {
            try {
                if (
                    order.additionalData.orderStatus === "OFFER" ||
                    order.additionalData.orderStatus === "OFFER_REQUEST"
                ) {
                    await api.EmailApi.sendOfferEmail({
                        ...postOrder,
                        items: items
                    });
                    const statusMessage: StatusMessage = {
                        messageType: MessageBarType.success,
                        message: `Tilbuds mail sent successfully`,
                        dismissTimer: 5000
                    };
                    addMessageStatus(statusMessage)(dispatch);
                } else {
                    await api.EmailApi.sendConfirmationEmail(orderToOrderConfirmationModel(postOrder, notificationType));
                    const statusMessage: StatusMessage = {
                        messageType: MessageBarType.success,
                        message: `Email sent successfully`,
                        dismissTimer: 5000
                    };
                    addMessageStatus(statusMessage)(dispatch);
                }
            } catch {
                const statusMessage: StatusMessage = {
                    messageType: MessageBarType.error,
                    message: `Failed to send email`,
                    dismissTimer: 5000
                };

                addMessageStatus(statusMessage)(dispatch);
            }
        }

        const statusMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: `Order accepted`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);
        NavigationHelper.pushRoute("RECEIPT");
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "postOrder" failed`,
            dismissTimer: 5000
        };
        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const updateCarId = (car: Car) => async (dispatch: Dispatch) => {
    try {
        const carId = await api.CarAPI.getCarIdFromCar(car);

        const action: UpdateCarAction = {
            type: "UPDATE_CAR",
            car: { ...car, carId }
        };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "getCarId" failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const loadOrder = (orderId: string) => async (dispatch: Dispatch) => {
    try {
        const order = await api.OrderAPI.getOrder(orderId);
        const action: LoadOrderAction = { type: "LOAD_ORDER", order };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Loading order failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const uploadImagesAndUpdateOrder = (order: Order, files: FileList, type: ImageType
) => async (dispatch: Dispatch) => {
    if (order.additionalData && order.additionalData.orderId) {
        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            const uuid = uniqId();
            const uploading: StatusMessage = {
                id: uuid,
                messageType: MessageBarType.info,
                message: `Uploading image ...`
            };
            addMessageStatus(uploading)(dispatch);
            const imagePath = await api.MediaApi.uploadImage(
                file,
                order.additionalData.orderId
            );
            const statusMessage: StatusMessage = {
                id: uuid,
                messageType: MessageBarType.success,
                message: `Image Uploaded`,
                dismissTimer: 5000
            };
            addMessageStatus(statusMessage)(dispatch);
            order.additionalData[type]
                ? (order.additionalData[type] = [
                    ...order.additionalData[type],
                    imagePath
                ])
                : (order.additionalData[type] = [imagePath]);
        }
        const postOrder = await api.OrderAPI.updateOrder(order);
        const action: UpdateOrderAction = {
            type: "UPDATE_ORDER",
            order: postOrder
        };
        dispatch(action);
        const orderUpdateMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: `OrderUpdated`,
            dismissTimer: 5000
        };
        addMessageStatus(orderUpdateMessage)(dispatch);
    }
};

export const uploadImage = (image: any, imageType: ImageType, orderId: string) => async (dispatch: Dispatch) => {
    try {
        const uuid = uniqId();
        const uploading: StatusMessage = {
            id: uuid,
            messageType: MessageBarType.info,
            message: `Uploading image ...`
        };
        addMessageStatus(uploading)(dispatch);
        const imagePath = await api.MediaApi.uploadImage(image, orderId);
        const action: UpdateImageAction = {
            type: "UPDATE_IMAGES",
            imageType,
            imagePath
        };
        dispatch(action);

        const statusMessage: StatusMessage = {
            id: uuid,
            messageType: MessageBarType.success,
            message: `Image Uploaded`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "imageUpload" failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const addProducts = (products: Product[], debitorNo: string) => async (
    dispatch: Dispatch
) => {
    if (!debitorNo || debitorNo === "") {
        const action: AddProductsAction = { type: "ADD_PRODUCTS", products };
        dispatch(action);
    } else {
        const debitorPrices = await api.DebitorApi.getDebitorPrices(
            debitorNo,
            products
                .filter(p => !p.debitorPrice)
                .map<ProductPriceRequest>(p => ({
                    productNo: p.no,
                    amount: p.amount
                }))
        );

        products.forEach(async product => {
            let debitorPrice = product.debitorPrice;
            if (!product.debitorPrice) {
                debitorPrice = debitorPrices.find(d => d.itemNo === product.no);
            }
            const action: AddProductsAction = {
                type: "ADD_PRODUCTS",
                products: [{ ...product, debitorPrice }]
            };
            dispatch(action);
        });
    }
};

export const addProductsAndUpdateOrder = (
    order: Order,
    products: Product[],
    debitorNo: string
) => async (dispatch: Dispatch) => {
    let debitorPrices: DebitorPrice[] = []
    if (debitorNo || debitorNo !== "") {
        debitorPrices = await api.DebitorApi.getDebitorPrices(
            debitorNo,
            products
                .filter(p => !p.debitorPrice)
                .map<ProductPriceRequest>(p => ({
                    productNo: p.no,
                    amount: p.amount
                }))
        );
    }
    let orderCopy = cloneDeep(order).cloneDeepWith();
    products.forEach(product => {
        const index = orderCopy.items.findIndex(
            e => e.itemNo === product.no
        );
        if (index > -1) {
            orderCopy.items[index].amount = orderCopy.items[index].amount + product.amount;
        } else {
            const { no, price, ...rest } = product;
            let debitorPrice = product.debitorPrice;
            if (!product.debitorPrice) {
                debitorPrice = debitorPrices.find(d => d.itemNo === product.no);
            }
            orderCopy.items.push({
                ...rest,
                no: no,
                itemNo: no,
                description: product.description,
                itemPrice: debitorPrice? debitorPrice.price: price,
                debitorPrice: debitorPrice,
                discountPercent: debitorPrice
                    ? debitorPrice.discountPercent
                    : 0
            } as PurchaseItem);
        }
    });

    const postOrder = await api.OrderAPI.updateOrder(orderCopy);
    const action: UpdateOrderAction = {
        type: "UPDATE_ORDER",
        order: postOrder
    };
    dispatch(action);
    const orderUpdateMessage: StatusMessage = {
        messageType: MessageBarType.success,
        message: `OrderUpdated`,
        dismissTimer: 5000
    };
    addMessageStatus(orderUpdateMessage)(dispatch);
};

export const updateCustomerCityFromZip = (zipCode: string) => async (
    dispatch: Dispatch
) => {
    const city = await api.AddressApi.getCityByZip(zipCode);
    if (city.length === 0) {
        return;
    }

    const action: UpdateCustomerCityFromZipAction = {
        type: "UPDATE_CUSTOMER_CITY_FROM_ZIP",
        city
    };

    dispatch(action);
};

export const createMoreSaleOrder = (order: Order) => async (
    dispatch: Dispatch
) => {
    try {
        const orderId = order.additionalData.orderId;
        if (orderId) {
            const newOrder = await api.OrderAPI.createMoreSaleOrder(orderId);
            window.location.href = `/worksheet/${newOrder.additionalData.orderId}`;
        }
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "Create Moresale order" failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const clearOrder = (): ClearOrderAction => ({ type: "CLEAR_ORDER" });

export const updateCustomer = (
    customer: Partial<Customer>
): UpdateCustomerAction => ({ type: "UPDATE_CUSTOMER", customer });

export const updateAdditional = (
    additional: AdditionalData
): UpdateAdditionalAction => ({ type: "UPDATE_ADDITIONAL", additional });

export const updateCar = (car: Car): UpdateCarAction => ({
    type: "UPDATE_CAR",
    car
});

export const setDiscount = (itemNo: string, discount: number): SetProductDiscountAction => ({ type: "SET_PRODUCT_DISCOUNT", itemNo, discount });

export const setProductAmount = (
    itemNo: string,
    amount: number
): SetProductAmountAction => ({
    type: "SET_ORDRE_PRODUCT_AMOUNT",
    itemNo,
    amount
});

export const setProductPrice = (
    itemNo: string,
    price: number
): SetProductPriceAction => ({
    type: "SET_ORDRE_PRODUCT_PRICE",
    itemNo,
    price
});

export const setProductName = (
    itemNo: string,
    name: string
): SetProductNameAction => ({ type: "SET_ORDRE_PRODUCT_NAME", itemNo, name });

export const updateTimeAndPlace = (timeAndPlace: Partial<TimeAndPlace>): UpdateTimeAndPlaceAction => ({ type: "UPDATE_TIME_AND_PLACE", timeAndPlace, worker: "" });

export const updateOrder = (order: Order): UpdateOrderAction => ({ type: "UPDATE_ORDER", order });

export const removeImages = (images: string[], imageType: ImageType): RemoveImagesAction => ({ type: "REMOVE_IMAGES", images, imageType });

export const whoIsPayingChange = (whoIsPaying: WhoIsPaying): WhoIsPayingAction => ({ type: "WHO_IS_PAYING", whoIsPaying });

export const updateComplaint = (complaint: Partial<Complaint>): UpdateComplaintAction => ({ type: "UPDATE_COMPLAINT", complaint });
