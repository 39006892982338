import * as React from "react";
import Search from "../../base/Search";

interface Props {
    onUpdateSearchProductQueryClick: (searchIn?: string, term?: string) => void;
    query: { searchIn?: string; term?: string };
}

function Lookup(props: Props) {
    return (
        <div className="custom_container">
            <h2>Prisforespørgsel</h2>
            <div className="single_column">
                <Search
                    query={props.query}
                    onSearch={props.onUpdateSearchProductQueryClick}
                />
            </div>
        </div>
    );
}
export default Lookup;
