import * as React from "react";
import FindProduct from "../FindProduct";
import "../style.css"
import { PrimaryButton, Dialog, DialogType, DialogFooter, DefaultButton } from "@fluentui/react";

interface Props {
    addProducts: (products: Product[]) => void;
    query: { searchIn?: string; term?: string };
    products: ProductGroup[];
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => void;
    updateSearchProductQuery: (searchIn?: string, term?: string) => void;
    openDialogButtonText?: string | undefined;
    openDialogButtonClassName?: string | undefined;
    hideSearchBar?: boolean | undefined;
    orderStatus?: OrderStatus;
}

const AddProductDialog = (props: Props) => {
    const [showDialog, setShowDialog] = React.useState(false);
    const [products, setProducts] = React.useState<Product[]>([]);
    const toggleDialog = () => setShowDialog(!showDialog);

    const addProductClick = () => {
        let allProducts: Product[] = [];
        products.forEach(product => {
            const { subItems, ...rest } = product;
            if (subItems) {
                const otherProducts: Product[] = subItems.map(sub => ({
                    ...sub,
                    status: [],
                    twoPersonJob: false,
                    previouslyPurchased: false
                }));
                allProducts = allProducts.concat(otherProducts);
                allProducts.push(rest);
            }
        });
        props.addProducts(allProducts);
        toggleDialog();
    };

    const onSelectedProductChange = (products: Product[]) => {
        setProducts(products);
    };
    const isDisabled =
        props.orderStatus === "SERVICE_CALL" ||
        props.orderStatus === "FINISHED";
    return (
        <>
            <PrimaryButton
                disabled={isDisabled}
                onClick={toggleDialog}
                text={
                    props.openDialogButtonText === undefined
                        ? "Tilføj"
                        : props.openDialogButtonText
                }
                className={props.openDialogButtonClassName}
            />
            <Dialog
                hidden={!showDialog}
                onDismiss={toggleDialog}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Tilføj produkt"
                }}
                minWidth={"100%"}
                modalProps={{
                    isBlocking: false,

                    styles: {
                        main: {
                            selectors: {
                                ["@media (min-width: 480px)"]: {
                                    width: 700,
                                    minWidth: 450,
                                    maxWidth: "1000px"
                                }
                            }
                        }
                    }
                }}
            >
                <div className="create_container">
                    <div className="create-style-content">
                        <FindProduct
                            selectedProducts={onSelectedProductChange}
                            productGroups={props.products}
                            updateSearchList={props.updateSearchList}
                            updateSearchProductQuery={
                                props.updateSearchProductQuery
                            }
                            query={props.query}
                            hideSearchBar={props.hideSearchBar}
                        />
                    </div>
                </div>
                <DialogFooter>
                    <DefaultButton onClick={toggleDialog} text="Annuller" />
                    <PrimaryButton onClick={addProductClick} text="Tilføj" />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default AddProductDialog;
