import { vatValueConverter } from "./vatConverter";

export const calulateTotalPrice = (
    items: PurchaseItem[],
    discountAmount: number,
    discountPercent: number
): number => {
    if (items) {
        return (
            items.reduce((sum, current) => {
                const itemPrice = current.debitorPrice
                    ? current.debitorPrice.price
                    : current.itemPrice;
                const discountPercent = current.debitorPrice
                    ? current.debitorPrice.discountPercent
                    : current.discountPercent;
                return (
                    sum +
                    current.amount *
                        vatValueConverter(itemPrice) *
                        (1 - discountPercent / 100)
                );
            }, 0) *
                (1 - discountPercent / 100) -
            discountAmount
        );
    }
    return 0;
};

export const calulateTotalPriceExVat = (
    items: PurchaseItem[],
    discountAmount: number,
    discountPercent: number
): number => {
    if (items) {
        return (
            items.reduce((sum, current) => {
                const itemPrice = current.debitorPrice
                    ? current.debitorPrice.price
                    : current.itemPrice;
                const discountPercent = current.debitorPrice
                    ? current.debitorPrice.discountPercent
                    : current.discountPercent;
                return (
                    sum +
                    current.amount * itemPrice * (1 - discountPercent / 100)
                );
            }, 0) *
                (1 - discountPercent / 100) -
            discountAmount
        );
    }
    return 0;
};
