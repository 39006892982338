import { Checkbox, CheckboxVisibility, DetailsList, DetailsListLayoutMode, DetailsRow, Dropdown, IColumn, IDetailsRowProps, IDetailsRowStyles, IDropdownOption, PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { RootState } from "~/state";
import TableHelper from "../utils/TableHelper";
import { getDebitorRuleSets } from "../../state/action";
import { addOrUpdateDebitorRuleSet, deleteDebitorRuleSet, getAllDebitorRuleSets, sortDebitorRuleSet } from "../../state/debitorRuleSets/action";
import TextFieldDelayedSave from "./TextFieldDelayedSave";

const debitorRulesetTypeDropdown:IDropdownOption<any>[] = [
    {key: "", text: "Not selected" },
    {key: "OFFER", text: "Tilbud" },
    {key: "INSURANCE", text: "Forsikring" },
    {key: "LEASING", text: "Leasing" },
    {key: "MIXED", text: "Mixed" },
]

function debitorRuleset(props: StateProps )
{
    const [sort, setSort] = React.useState<MapObject<SortDirection>>({});
    React.useEffect(() =>props.getAllDebitorRuleSets(), []);

    const dropDownTypeChange = (e: DebitorRuleSet) => (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined, index?: number | undefined) => 
    {
        if(option && option.text)
        {
            const type:DebitorRuleSetType = option.key as DebitorRuleSetType;
            props.addOrUpdateDebitorRuleSet({...e, type});
        }
    }

    const onIsMandatoryChange = (e: DebitorRuleSet) => (ev: React.FormEvent<HTMLInputElement>, checked: boolean) => 
    {
        props.addOrUpdateDebitorRuleSet({...e, isMandatory: checked});
    }

    const  onColumnClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => 
    {
        let sortDirection: SortDirection = "UP";
        if(sort[column.key] === "UP")
        {
            sortDirection = "DOWN";
        }
        setSort({[column.key]:sortDirection})
        props.sortDebitorRuleSet(column.key as DebitorRuleSetSortList, sortDirection);
    }
    const sortedDirection = (key: string) =>
    {
        if(sort[key] === undefined)
        {
            return undefined;
        }
        return sort[key] === "DOWN";
    }

    const isSorted =  (key: string) => sort[key] === undefined ? undefined : true;
    
    const columns: IColumn[] = [
            TableHelper.col("type", "Type", 100, 100, (e: DebitorRuleSet) =>
            {
                return <Dropdown selectedKey={e.type}  options={debitorRulesetTypeDropdown} onChange={dropDownTypeChange(e)} />;
            }, undefined, onColumnClick, isSorted("type"),  sortedDirection("type")),
            TableHelper.col("user", "Bruger", 207, 207, (e: DebitorRuleSet) =>
            {
                return <TextFieldDelayedSave value={e.user} onChange={(value: string) => props.addOrUpdateDebitorRuleSet({...e, user: value}) } />;
            }, undefined, onColumnClick, isSorted("user"),  sortedDirection("user")),
            TableHelper.col("leasing", "Leasing", 207, 207, (e: DebitorRuleSet) =>
            {
                return <TextFieldDelayedSave value={e.leasing} onChange={(value: string) => props.addOrUpdateDebitorRuleSet({...e, leasing: value}) } />;
            }, undefined, onColumnClick, isSorted("leasing"),  sortedDirection("leasing")),
            TableHelper.col("insurrance", "Forsikring", 207, 207, (e: DebitorRuleSet) =>
            {
                return <TextFieldDelayedSave value={e.insurrance} onChange={(value: string) => props.addOrUpdateDebitorRuleSet({...e, insurrance: value}) } />;
            }, undefined, onColumnClick, isSorted("insurrance"),  sortedDirection("insurrance")),
            TableHelper.col("debitor", "Debitor", 100, 100, (e: DebitorRuleSet) =>
            {
                return <TextFieldDelayedSave value={e.debitor} onChange={(value: string) => props.addOrUpdateDebitorRuleSet({...e, debitor: value}) } />;
            }, undefined, onColumnClick, isSorted("debitor"), sortedDirection("debitor")),
            TableHelper.col("isMandatory", "Obligatorisk?", 90, 90, (e: DebitorRuleSet) =>
            {
                return <Checkbox checked={e.isMandatory} onChange={onIsMandatoryChange(e) } />;
            }),
            TableHelper.col("slet", "Slet", 100, 100, (e: DebitorRuleSet) =>
            {
                return <PrimaryButton onClick={() =>props.deleteDebitorRuleSet(e.id)} >Slet</PrimaryButton>;
            })
        ];
        const onRenderRow = (props: IDetailsRowProps): JSX.Element => {
            const customStyles: Partial<IDetailsRowStyles> = {};
    
            const item = props.item as DebitorRuleSet;
            if(item.type === "OFFER")
            {
                customStyles.cell = { background: "rgba(226, 219, 190, 0.3)" };
            }
            if(item.type === "INSURANCE")
            {
                customStyles.cell = { background: "rgba(157, 187, 174, 0.3)" };
            }
            if(item.type === "LEASING")
            {
                customStyles.cell = { background: "rgba(24, 143, 167, 0.3)" };
            }
            if(item.type === "MIXED")
            {
                customStyles.cell = { background: "rgba(213, 214, 170, 0.3)" };
            }
            
            return <DetailsRow {...props} styles={customStyles} />;
        };
    return <>
        <DetailsList 
            compact={true}
            indentWidth={0}
            className="hidden-xs assignments"
            layoutMode={DetailsListLayoutMode.fixedColumns}
            columns={columns}
            items={props.debitorRuleset}
            checkboxVisibility={CheckboxVisibility.hidden}
            onRenderRow={onRenderRow}
            />
        <PrimaryButton onClick={() =>props.addOrUpdateDebitorRuleSet()} >Add</PrimaryButton>
    </>;
}

const mapStateToProps = (state: RootState) =>
{
    return {
        debitorRuleset: getDebitorRuleSets(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) =>
({
    getAllDebitorRuleSets: () => dispatch<any>(getAllDebitorRuleSets()),
    addOrUpdateDebitorRuleSet: (debitorRuleSet?: DebitorRuleSet) => dispatch<any>(addOrUpdateDebitorRuleSet(debitorRuleSet)),
    deleteDebitorRuleSet: (id: string) => dispatch<any>(deleteDebitorRuleSet(id)),
    sortDebitorRuleSet: (sortBy: DebitorRuleSetSortList, sortDirection: SortDirection) => dispatch<any>(sortDebitorRuleSet(sortBy, sortDirection)),
});

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(debitorRuleset);