import { Action, Dispatch } from "redux";

import api from "../../api";
import { addMessageStatus } from "../ui/action";
import { MessageBarType } from "@fluentui/react";

export interface CarManufactureAction extends Action {
    type: "CAR_MANUFACTURES";
    carManufactures: CarManufacture[];
}

export interface CarManufactureModelAction extends Action {
    type: "CAR_MANUFACTURE_MODELS";
    carManufactureModels: CarManufactureModel[];
}

export const updateCarManufactures = () => async (dispatch: Dispatch<any>) => {
    try {
        const carManufactures = await api.CarAPI.getCarManufactures();
        const action: CarManufactureAction = {
            type: "CAR_MANUFACTURES",
            carManufactures
        };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "getCarManufactures" failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const updateCarManufactureModels = (manuId: number) => async (
    dispatch: Dispatch<any>
) => {
    try {
        const carManufactureModels = await api.CarAPI.getCarManufactureModels(
            manuId
        );
        const action: CarManufactureModelAction = {
            type: "CAR_MANUFACTURE_MODELS",
            carManufactureModels
        };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "getCarManufactureModels" failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export type Actions = CarManufactureAction | CarManufactureModelAction;
