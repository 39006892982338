import * as React from "react";
import PageHeader from "../../layout/PageHeader";
import DebitorLookup from "../../customer/DebitorLookup";
import DebitorFields from "./DebitorFields";
import {
    debitorFieldConfig,
    getCustomerValidation
} from "../../../util/validate";
import Customer from "./index";
import WhoIsPaying from "../../dashboard/WhoIsPaying";
import { Stack, TextField } from "@fluentui/react";

interface Props {
    debitor: Debitor;
    car: Car;
    order: Order;
    departments: Department[];
    insurers?: Insurer[];
    leasers: Leaser[];
    debitors?: Debitor[];
    customer: Customer;
    setDebitor: (debitor: Debitor) => void;
    whoIsPayingChange: (whoIsPaying: WhoIsPaying) => void;
    updateDebitorFromWhoIsPaying: (
        whoIsPaying: WhoIsPaying,
        order: Order,
        updatePrices: boolean
    ) => void;
    updateOrderDepartment: (
        department: Department,
        shouldUpdateDebitor: boolean
    ) => void;
    getDebitor: (query: string, type: ApiType) => void;
    updateDebitor: (debitor: Partial<Debitor>) => void;
    updateCar: (car: Car) => void;
    updateCustomer: (customer: Customer) => void;
    updateCustomerCityFromZipCode: (zipCode: string) => void;
}

const CustomerOrderDetails: React.FunctionComponent<Props> = props => {
    const { registrationNumber, insurance } = props.car;

    const updateCar = (valueType: CarValueType) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        props.updateCar({ ...props.car, [valueType]: value });
    };

    return (
        <>
            <div className="styled_customer__form">
                <PageHeader currentPage="Kontaktinformation" />
                <Stack horizontal tokens={{ childrenGap: 50 }}>
                    <Stack.Item grow={1}>
                        <DebitorLookup
                            getDebitor={props.getDebitor}
                            selectedDebitor={props.order.debitor}
                            debitors={props.debitors}
                            onDebitorChange={props.setDebitor}
                            whoIsPayingChange={props.whoIsPayingChange}
                        />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <WhoIsPaying
                            order={props.order}
                            departments={props.departments}
                            onDepartmentChange={props.updateOrderDepartment}
                            onWhoIsPayingChange={props.whoIsPayingChange}
                            car={props.car}
                            insurers={props.insurers}
                            leasers={props.leasers}
                            updateCar={props.updateCar}
                            updateDebitorFromWhoIsPaying={
                                props.updateDebitorFromWhoIsPaying
                            }
                            debitors={props.debitors}
                            getDebitor={props.getDebitor}
                            onDebitorChange={props.setDebitor}
                            selectedDebitor={props.order.debitor}
                        />
                        <DebitorFields
                            fieldConfig={debitorFieldConfig}
                            debitor={props.debitor}
                            updateDebitor={props.updateDebitor}
                        />
                        <br />
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack.Item grow={1}>
                                <TextField
                                    title="Forsikringsselskab"
                                    placeholder="Forsikringsselskab"
                                    value={insurance}
                                    onChange={updateCar("insurance")}
                                />
                                <TextField
                                    title="Registrerings nummer"
                                    placeholder="Registrerings nummer"
                                    value={registrationNumber}
                                    onChange={updateCar("registrationNumber")}
                                />
                            </Stack.Item>
                        </Stack>
                        <div className="styled_customer__stack">
                            <div className="customer__detailsForm">
                                <Customer
                                    fieldConfig={getCustomerValidation(
                                        props.order.additionalData.whoIsPaying
                                    )}
                                    customer={props.customer}
                                    updateCustomer={props.updateCustomer}
                                    updateCustomerCityFromZipCode={
                                        props.updateCustomerCityFromZipCode
                                    }
                                />
                            </div>
                        </div>
                    </Stack.Item>
                </Stack>
            </div>
        </>
    );
};

export default CustomerOrderDetails;
