import produce from "immer";
import { Actions } from "./action";

export interface CarState {
    carManufactures: CarManufacture[];
    carManufactureModels: CarManufactureModel[];
}

const initialState: CarState = {
    carManufactures: [],
    carManufactureModels: []
};

export const reducer = (uiState = initialState, action: Actions): CarState =>
    produce(uiState, draft => {
        if (action.type === "CAR_MANUFACTURES") {
            draft.carManufactures = action.carManufactures;
            return;
        }

        if (action.type === "CAR_MANUFACTURE_MODELS") {
            draft.carManufactureModels = action.carManufactureModels;
            return;
        }
    });

export const getCarManufactures = (state: CarState) => state.carManufactures;

export const getCarManufactureModels = (state: CarState) =>
    state.carManufactureModels;

export default reducer;
