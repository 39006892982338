import * as React from "react";
import "../../global.style.css"
import { Icon, AnimationClassNames } from "@fluentui/react";

interface Props {
    title: string;
    changed: boolean;
    children?: JSX.Element;
    open?: boolean;
    titleColor?: string;
    titleBackgroundColor?: string;
}

function DropdownPanel(props: Props) {
    const [showPannel, togglePannel] = React.useState(
        props.open ? true : false
    );

    const onToogleClick = () => togglePannel(!showPannel);

    return (
        <div className="dropdown_panel__container">
            <div
                className="panel-title"
                onClick={onToogleClick}
                style={{
                    backgroundColor: props.titleBackgroundColor,
                    color: props.titleColor
                }}
            >
                <h5>
                    {props.title}{" "}
                    {props.changed ? <span className="change__container">Ændret</span> : null}
                </h5>
                <Icon
                    iconName={
                        showPannel ? "ChevronUpSmall" : "ChevronDownSmall"
                    }
                />
            </div>
            {showPannel ? (
                <div className={`panel ${AnimationClassNames.slideDownIn20}`}>
                    {props.children}
                </div>
            ) : null}
        </div>
    );
}

export default DropdownPanel;
