import api from "../../api";
import { Action, Dispatch } from "redux";
import { addMessageStatus } from "../ui/action";
import { MessageBarType } from "@fluentui/react";

export interface NotificationStatusAction extends Action
{
    type: "SMS_NOTFICATION_STATUS";
    status: NotificationStatus;
    orderNumber: string;
   
}

export const sendSms = (model: NotificationModel) => async (
    dispatch: Dispatch
) => {
    try {
        const notificationStatus = await api.SmsApi.sendMessage(model);

        const statusMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: `SMS sent successfully`,
            dismissTimer: 5000
        };
        await addMessageStatus(statusMessage)(dispatch);
        dispatch({type : "SMS_NOTFICATION_STATUS", status: notificationStatus, orderNumber: model.orderNumber} as NotificationStatusAction)
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Failed to send SMS`,
            dismissTimer: 5000
        };

        await addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};
