import BaseApi from "./baseApi";

export default class DeliveryTimeApi extends BaseApi {
    async getAllData(): Promise<AllAvailableSlots> {
        const url = "/api/availableslots/getAll";
        return await this.fetchGet(url);
    }

    async getDataByRegion(
        date: Date | undefined,
        region: string
    ): Promise<{ data: DepartmentAvailableSlots[] }> {
        const startDateTime = date
            ? date.toISOString()
            : new Date().toISOString();
        const url = `/api/availableslots/getByRegion?region=${region}&startDateTime=${startDateTime}`;
        return await this.fetchGet(url);
    }
}
