import BaseApi from "./baseApi";

interface IEmailApi {
    sendConfirmationEmail: (order: IOrderConfirmationModel) => Promise<void>;
    sendNotificationEmail: (notification: NotificationModel) => Promise<void>;
    sendCustomerSatisfactionEmail: (
        name: string,
        email: string,
        orderNr: string
    ) => Promise<void>;
}

export default class EmailApi extends BaseApi implements IEmailApi {
    async sendConfirmationEmail(order: IOrderConfirmationModel): Promise<void> {
        const url = `/api/email/sendconfirmation`;
        return await this.fetchPost(url, order);
    }
    async sendOfferEmail(order: Order): Promise<void> {
        const url = `/api/email/sendoffer`;
        return await this.fetchPost(url, order);
    }

    async sendNotificationEmail(
        notification: NotificationModel
    ): Promise<void> {
        const url = `/api/email/sendnotification`;
        return await this.fetchPost(url, notification);
    }

    async sendCustomerSatisfactionEmail(
        name: string,
        email: string,
        orderNr: string
    ): Promise<void> {
        const url = `/api/email/sendtrustpilotinvitation?name=${name}&email=${email}&ordernr=${orderNr}`;
        await this.fetchPost(url);
    }

    async sendReceiptEmail(order: IOrderReceiptModel): Promise<void> {
        const url = `/api/email/sendreceipt`;
        return await this.fetchPost(url, order);
    }
}
