import * as React from "react";
import { orderToNotificationModel } from "../../utils/smsHelper";
import { PrimaryButton, Dialog, DialogType, DialogFooter, TextField, DefaultButton } from "@fluentui/react";

interface Props {
    order: Order;
    sendNotificationEmail: (model: NotificationModel) => void;
    notificationType: NotificationType;
    buttonText: string;
}

const SendEmailWindow: React.FunctionComponent<Props> = props => {
    const [showWindow, toggleShowWindow] = React.useState(false);
    const [editstate, toggleEmailEdit] = React.useState(false);
    const [comment, changeComment] = React.useState("");

    const onCommentChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue) {
            changeComment(newValue);
        }
    };

    const SendEmail = () => {
        const notificationModel = orderToNotificationModel(
            props.order,
            props.notificationType,
            comment
        );
        props.sendNotificationEmail(notificationModel);

        changeShowWindow();
    };

    const toggleEditState = () => {
        toggleEmailEdit(!editstate);
    };

    const changeShowWindow = () => toggleShowWindow(!showWindow);

    if (!showWindow) {
        return (
            <PrimaryButton onClick={changeShowWindow} text={props.buttonText} />
        );
    } else {
        return (
            <Dialog
                hidden={false}
                onDismiss={changeShowWindow}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Send email",
                    subText: "Tryk på rediger, hvis du vil tilføje en kommentar"
                }}
            >
                <DialogFooter>
                    <TextField
                        disabled={!editstate}
                        value={comment}
                        multiline
                        rows={3}
                        onChange={onCommentChange}
                    />
                    {!editstate ? (
                        <div
                            onClick={toggleEditState}
                            style={{ fontSize: "12px", cursor: "pointer" }}
                        >
                            Redigér
                        </div>
                    ) : null}
                    <br />
                    {comment}
                    <DefaultButton onClick={changeShowWindow}>
                        Luk{" "}
                    </DefaultButton>
                    <PrimaryButton onClick={SendEmail}>Send </PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }
};

export default SendEmailWindow;
