import * as React from "react";
import Marker from "../Marker";

/*
rudeskift
mørk blå: 007AAE
mørk grøn: 138535
mørk gul: BFB500
mørk rød: B21016
mørk lilla: 7A297B

stenslag
lys blå: 94D8F6
lys grøn: 98E0AD
lys gul: FFFA99
lys rød: F8A1A4
lys lilla: DAAADB
*/

export const dateColors = [
    {
        type: "R",
        dates: [
            "#94D8F6",
            "#98E0AD",
            "#FFFA99",
            "#F8A1A4",
            "#DAAADB",
            "#FFA500",
            "#D3D3D3"
        ]
    },
    {
        type: "S",
        dates: [
            "#94D8F6",
            "#98E0AD",
            "#FFFA99",
            "#F8A1A4",
            "#DAAADB",
            "#FFA500",
            "#D3D3D3"
        ]
    }
];

export const departmentColor = [
    {
        id: "icon-circle",
        path: "M32,64C32,64 51.914,44.376 51.914,21.339C51.914,10.348 42.991,1.425 32,1.425C21.009,1.425 12.086,10.348 12.086,21.339C12.086,44.376 32,64 32,64ZM32.1,10.717C37.955,10.717 42.708,15.471 42.708,21.326C42.708,27.181 37.955,31.934 32.1,31.934C26.245,31.934 21.491,27.181 21.491,21.326C21.491,15.471 26.245,10.717 32.1,10.717Z",
    },
    {
        id: "icon-drop",
        path: "M32,64C32,64 51.914,44.376 51.914,21.339C51.914,10.348 42.991,1.425 32,1.425C21.009,1.425 12.086,10.348 12.086,21.339C12.086,44.376 32,64 32,64ZM32,12.229L41.387,22.048L32,31.867L22.613,22.048L32,12.229Z",
    },
    {
        id: "icon-square",
        path: "M32,64C32,64 51.914,44.376 51.914,21.339C51.914,10.348 42.991,1.425 32,1.425C21.009,1.425 12.086,10.348 12.086,21.339C12.086,44.376 32,64 32,64ZM40.614,18.939C40.614,16.561 38.684,14.632 36.307,14.632L27.693,14.632C25.316,14.632 23.386,16.561 23.386,18.939L23.386,27.553C23.386,29.93 25.316,31.86 27.693,31.86L36.307,31.86C38.684,31.86 40.614,29.93 40.614,27.553L40.614,18.939Z",
    },
    {
        id: "icon-triangle",
        path: "M32,64C32,64 51.914,44.376 51.914,21.339C51.914,10.348 42.991,1.425 32,1.425C21.009,1.425 12.086,10.348 12.086,21.339C12.086,44.376 32,64 32,64ZM31.963,31.934L22.149,12.307L41.776,12.307L31.963,31.934Z",
    }
];

type Props = {
    number: number;
    date: Date;
    lat: number;
    lng: number;
    type: string;
    showInfo?: (e: boolean) => void;
    calibration: boolean;
    twoManJob: boolean;
}

const ServicePoint = (props: Props) =>
{

    const getDateColor = () =>
    {
        const today = new Date();
        const colors = dateColors.filter(x => x.type === props.type);
        const date = today.getDate();
        const thedate = props.date.getDate();
        const date1 = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        date1.setDate(date1.getDate() + 1);
        const date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        date2.setDate(date2.getDate() + 2);
        const date3 = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        date3.setDate(date3.getDate() + 3);
        const date4 = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        date4.setDate(date4.getDate() + 4);

        if(thedate === date)
        {
            return colors[0].dates[0];
        }
        if(thedate === date1.getDate())
        {
            return colors[0].dates[1];
        }
        if(thedate === date2.getDate())
        {
            return colors[0].dates[2];
        }
        if(thedate === date3.getDate())
        {
            return colors[0].dates[3];
        }
        if(thedate === date4.getDate())
        {
            return colors[0].dates[4];
        }
        return "#A6A6A6";
    };

    return (
        <Marker path={departmentColor[props.number].path}
            lat={props.lat} lng={props.lng}
            number ={props.number}
            text={props.type === "R" ? "R" : "S"}
            fillColor={getDateColor()}
            showInfo={props.showInfo}
            calibration={props.calibration}
            twoManJob={props.twoManJob}
            twoManJobIcon={props.twoManJob ? "mk-twoManJob" : ""}
            title={departmentColor[props.number].id}
            calibrationIcon={props.calibration ? "mk-calibration" : ""}/>
    );
};

export default ServicePoint;