import { Checkbox, Stack, TextField } from "@fluentui/react";
import * as React from "react";
import DepartmentSelector from "../../booking/DepartmentSelector";
interface Props {
    timeAndPlace: TimeAndPlace;
    orderItems: PurchaseItem[];
    zipCodes?: ZipCodeDepartmentAvailability[];
    departments: Department[];
    onDepartmentChange: (department: Department) => void;
    updateTimeAndPlace: (timeAndPlace: Partial<TimeAndPlace>) => void;
    getZipCode: (zipcode: string) => void;
}
let timer = 0;
const DeparmentSearch: React.FC<Props> = (props) => {
    const [departmentSearchValue, setDepartmentSearchValue] = React.useState<string>("");
    const [searchInMobileDepartments, setSearchInMobileDepartments] = React.useState<boolean>(props.timeAndPlace.department ? props.timeAndPlace.department.mobileService : false);
    React.useEffect(() => {
        if (props.timeAndPlace.department) {
            setSearchInMobileDepartments(props.timeAndPlace.department.mobileService);
            if ( props.timeAndPlace.zipCode &&  props.timeAndPlace.department.mobileService ) {
                setDepartmentSearchValue(props.timeAndPlace.zipCode);
            }
        }

    }, []);

    const onMobileServiceCheckboxClick = () =>
    {
        const newValue = !searchInMobileDepartments;
        if(!newValue)
        {
            props.getZipCode("");
        }
      
        setSearchInMobileDepartments(newValue);
    }

    const onSearchValueChanged = (e: React.SyntheticEvent<HTMLInputElement>, value: string) => {
        setDepartmentSearchValue(value);
        clearTimeout(timer);
        timer = window.setTimeout(async () => {
            props.updateTimeAndPlace({ zipCode: value });
            props.getZipCode(value);
        }, 500);
    };

    const getDepartments = () => {
        if ( props.orderItems.find( x => x.itemNo === "PK3" || x.itemNo === "PK5" || x.itemNo === "PK6" ) !== undefined ) {
            return props.departments.filter(x => !x.mobileService);
        }
        return props.departments;
    };

    const searchAndUpdateDepartment = () => {
        if (departmentSearchValue) {
            if (searchInMobileDepartments) {
                if (props.zipCodes && props.zipCodes.length > 0) {
                    if (props.zipCodes.length === 1) {
                        const departmentId = props.zipCodes[0].departmentId;
                        const department = props.departments.find(
                            x => x.id && x.id.startsWith(departmentId)
                        );

                        if (department) {
                            return (
                                <div className="search__result__message">
                                    Opdateret afdeling til: {department.name}
                                </div>
                            );
                        }
                    }

                    return (
                        <div>
                            Vælg Afdeling:
                            {props.zipCodes.map((zipDep, index) => {
                                const department = props.departments.find(
                                    x =>
                                        x.id &&
                                        x.id.startsWith(zipDep.departmentId)
                                );
                                if (department) {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() =>
                                                props.onDepartmentChange(department)
                                            }
                                        >
                                            {department.name}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={"no_department_available"}>
                                            Der er ingen mobil afdeling, der kan
                                            arbejde på den valgte bil.
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    );
                }
                return <div>Kunne ikke finde noget match</div>;
            } else {
                if (props.departments && props.departments.length > 0) {
                    return (
                        <div>
                            Vælg Afdeling:
                            {props.departments
                                .filter(x => !x.mobileService)
                                .map((department, index) => {
                                    return (
                                        <div key={index} onClick={() => props.onDepartmentChange(department)} >
                                            {department.name}
                                        </div>
                                    );
                                })}
                        </div>
                    );
                }
                return <div>Kunne ikke finde noget match</div>;
            }
        }
        return null;
    };
    let mobileSearch = null;
    if (props.orderItems.find(x => x.itemNo === "PK3" || x.itemNo === "PK5" || x.itemNo === "PK6") === undefined) {
        mobileSearch= (
            <div className="department__search">
                <Checkbox
                    className="department__search__checkbox"
                    label="Mobilafdeling"
                    checked={searchInMobileDepartments}
                    onChange={onMobileServiceCheckboxClick}
                />
                <TextField
                    className="department__search__textfield"
                    value={departmentSearchValue}
                    placeholder="Søg efter afdeling (brug udelukkende postnummer til mobilafdelingssøgning)"
                    onChange={onSearchValueChanged}
                />
                {searchAndUpdateDepartment()}
            </div>
        );
    }

    return <>
        {mobileSearch}
        {!searchInMobileDepartments || searchInMobileDepartments ? (
            <DepartmentSelector
                department={props.timeAndPlace.department}
                departments={getDepartments()}
                onDepartmentChange={props.onDepartmentChange}
            />
        ) : null}
    </>;
};

export default DeparmentSearch; 