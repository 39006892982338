import * as React from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { DefaultButton, Spinner, Stack, TextField } from "@fluentui/react";
import ServicePoint, { dateColors, departmentColor } from "../ServicePoint";
import ServiceDetails from "../ServiceDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../../state";
import { getOperators } from "../../../state/action";
import { useEffect } from "react";
import api from "../../../api";
import { DayPickerStrings } from "../../base/CustomDatePicker";
import { convertUSDateToEU } from "../../../util/DateHelper";
import { needsCalibration } from "../../utils/orderHelper";
import MarkerPoint from "../Marker";
import IcPlus from "../assets/ic-plus.svg";
import IcMinus from "../assets/ic-minus.svg";
import DaySelector from "../DaySelector";
import CustomTabs from "../CustomTabs";
import "./style.css";

const markerPointPath =
    "M32,64C32,64 51.914,44.376 51.914,21.339C51.914,10.348 42.991,1.425 32,1.425C21.009,1.425 12.086,10.348 12.086,21.339C12.086,44.376 32,64 32,64ZM32.1,10.717C37.955,10.717 42.708,15.471 42.708,21.326C42.708,27.181 37.955,31.934 32.1,31.934C26.245,31.934 21.491,27.181 21.491,21.326C21.491,15.471 26.245,10.717 32.1,10.717Z";

const containerStyle = {
    width: "100%",
    height: "800px"
};

export interface ListDepartment extends Department {
    selected?: boolean;
}

export interface Worker {
    name: string;
    selected: boolean;
}

export interface MarkerOrder extends Order {
    lat?: number;
    lng?: number;
}

interface Coord {
    lat: number;
    lng: number;
    address: string;
}

export const formatServiceDate = (date: string) => {
    const d = date;
    const y = d.split("-")[1];
    const m = d.split("-")[0].split("/");
    return new Date(`${y},${m[1]},${m[0]}`);
};

export const formatServiceDateToString = (date: Date) => {
    return `${date.getDate()}/${date.getMonth() + 1}-${date.getFullYear()}`;
};

export const gpsCoordsCache = (address: string) => {
    const coords = localStorage.getItem("gpsCache");
    if (coords) {
        const arr: Coord[] = JSON.parse(coords);
        const foundGps = arr.filter(x => x.address === address);
        if (arr.filter(x => x.address === address).length > 0) {
            return foundGps[0];
        }
    }
    return null;
};

export const setGpsCache = (coord: Coord) => {
    const coords = localStorage.getItem("gpsCache");
    if (coords) {
        const c = JSON.parse(coords);
        localStorage.setItem("gpsCache", JSON.stringify([...c, coord]));
    } else {
        localStorage.setItem("gpsCache", JSON.stringify([coord]));
    }
};
const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
};

const getDistanceFromLatLonInKm = (
    lat1: number,
    lon1: number,
    centerLat: number,
    centerLng: number
) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(centerLat - lat1); // deg2rad below
    const dLon = deg2rad(centerLng - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(centerLat)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
};

const MobileServiceMap = () => {
    const { isLoaded } = useLoadScript({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyCP9jHb6scMPBopfAAk3RGDKURcnyCRVWY"
    });

    const state = useSelector((state: RootState) => state);
    const mobileDepartments = state.department.departments.filter(
        x => x.mobileService === true
    );
    const query = new URLSearchParams(window.location.search);
    const isDefaultView = query ? query.get("isCustomMapView") : null;
    const fallbackLatLng = { lat: 56.87028, lng: 10.950315 }; //if invalid gps coord, place in the ocean so they can see it's a invalid coord.

    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    const [info, showInfo] = React.useState(false);
    const [items, updateItems] = React.useState<ListDepartment[]>([]);
    const [workers, updateWorkers] = React.useState<Worker[]>([]);
    const [orders, updateOrders] = React.useState<MarkerOrder[]>([]);
    const [loading, toggleLoading] = React.useState(false);
    const [initialOrders, updateInitial] = React.useState<MarkerOrder[]>([]);
    const [currentOrder, updateCurrentOrder] = React.useState<MarkerOrder>();
    const [isCustomMapView, setCustomMapView] = React.useState(false);
    const [zoomIndex, setCustomZoom] = React.useState(8);
    const [days, setDays] = React.useState<number[]>([]);
    const [center, setMapCenter] = React.useState<google.maps.LatLngLiteral>({
        lat: 56.1508469,
        lng: 10.2128301
    });
    const [distanceFromCenter, updateDistanceFromCenter] = React.useState("20");
    const [customAddress, setCustomAddress] = React.useState("");

    const operators = getOperators(state);

    const getDepartmentCoords = (x: ListDepartment): ListDepartment => {
        let department = x;
        const address = department.address;
        if (address) {
            const cache = gpsCoordsCache(address); //check for localstorage gps cache. This is to avoid calling google api forward geocode each time list is updated
            if (cache === null) {
                api.DepartmentApi.getCoordinates(address).then(result => {
                    if (result) {
                        const match = {
                            address: address as string,
                            lat: parseFloat(result.lat.toString()),
                            lng: parseFloat(result.lng.toString())
                        };
                        department = {
                            ...department,
                            lat: match.lat,
                            lng: match.lng
                        };

                        setGpsCache(match);
                    }
                });
            } else {
                department = { ...department, lat: cache.lat, lng: cache.lng };
            }
        }

        return department;
    };

    useEffect(() => {
        if (mobileDepartments.length > 0 && items.length === 0) {
            const departments: ListDepartment[] = mobileDepartments;
            departments.forEach(department => {
                if (department.id === query.get("deptId")) {
                    department.selected = true;
                }
            });
            updateItems([...departments.map(x => getDepartmentCoords(x))]);
        }
    }, [mobileDepartments]);

    const onLoad = React.useCallback(function callback(
        map: React.SetStateAction<google.maps.Map | null>
    ) {
        setMap(map);
    },
    []);

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null);
    }, []);

    const getGuides = () => {
        return items
            .filter(x => x.selected === true)
            .slice(0, 4)
            .map((x, i) => {
                return (
                    <span
                        key={"guide-" + i}
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                            marginRight: "10px"
                        }}
                    >
                        <svg
                            width="26"
                            height="26"
                            viewBox="0 0 64 64"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill="white"
                                stroke="#000"
                                strokeWidth="2"
                                d={departmentColor[i].path}
                            />
                        </svg>
                        &nbsp; {x.name}
                    </span>
                );
            });
    };

    const testDate = (days: number) => {
        const result = new Date();
        result.setDate(result.getDate() + days);
        return result;
    };

    const getWorkers = (orders: MarkerOrder[]) => {
        const workers: Worker[] = [];
        orders.map(x =>
            workers.push({ name: x.additionalData.worker, selected: false })
        );
        const filteredWorkers = workers.filter(
            (v, i, a) => a.findIndex(t => t.name === v.name) === i
        );
        updateWorkers(filteredWorkers);
    };

    const getCoords = (orders: MarkerOrder[]) => {
        orders.map((x, i) => {
            let order = x;

            let address =
                order.timeAndPlace.mobileServiceLocation &&
                order.timeAndPlace.mobileServiceLocation.address;
            if (!address) {
                address = `${order.customer.address.replace(" ", "+")}+${
                    order.customer.zipCode
                }+${order.customer.city}`;
            }

            const cache = gpsCoordsCache(address); //check for localstorage gps cache. This is to avoid calling google api forward geocode each time list is updated
            if (cache === null) {
                api.DepartmentApi.getCoordinates(address).then(result => {
                    if (result) {
                        const match = {
                            address: address as string,
                            lat: parseFloat(result.lat.toString()),
                            lng: parseFloat(result.lng.toString())
                        };
                        match.address = address as string;
                        order = {
                            ...order,
                            lat: parseFloat(match.lat.toString()),
                            lng: parseFloat(match.lng.toString())
                        };
                        orders[i] = order;

                        updateOrders([...orders]);
                        updateInitial([...orders]);
                        setGpsCache(match);
                        getWorkers([...orders]);
                    }
                });
            } else {
                order = { ...order, lat: cache.lat, lng: cache.lng };
                orders[i] = order;
                updateOrders([...orders]);
                updateInitial([...orders]);
                getWorkers([...orders]);
            }
        });
    };

    const updateList = (e: ListDepartment) => (ev: React.ChangeEvent) => {
        const item = items.filter(x => x.id === e.id);
        item[0].selected = !item[0].selected;
        updateItems([...items]);
    };

    const fetchData = async () => {
        showInfo(false);

        toggleLoading(true);

        const departmentIds = items
            .filter(c => c.selected === true)
            .map(c => c.id) as string[];

        let result: MarkerOrder[] = await (
            await api.OrderAPI.getMobileServiceOrders(departmentIds, days)
        ).orders;

        getCoords(result);

        if (isCustomMapView) {
            result = result.filter(
                order =>
                    getDistanceFromLatLonInKm(
                        order.lat as number,
                        order.lng as number,
                        center.lat,
                        center.lng
                    ) < parseInt(distanceFromCenter)
            );
            const defaultSelectedDepartment = items.find(
                department => department.id === query.get("deptId")
            );
            const departments: ListDepartment[] = mobileDepartments;
            departments.forEach(department => {
                if (defaultSelectedDepartment) {
                    if (
                        getDistanceFromLatLonInKm(
                            defaultSelectedDepartment.lat,
                            defaultSelectedDepartment.lng,
                            center.lat,
                            center.lng
                        ) >
                        getDistanceFromLatLonInKm(
                            department.lat,
                            department.lng,
                            center.lat,
                            center.lng
                        )
                    ) {
                        const item = items.filter(x => x.id === department.id);
                        item[0].selected = true;
                    }
                }
            });
        }

        updateOrders(result);
        updateInitial(result);
        getWorkers(result);
        toggleLoading(false);
    };

    const updateWorkerList = (e: Worker) => (ev: React.ChangeEvent) => {
        const item = workers.filter(x => x.name === e.name);
        item[0].selected = !item[0].selected;

        const active = workers.filter(x => x.selected);

        if (active.length > 0) {
            const filtered = initialOrders.filter(item => {
                return workers.some(f => {
                    return (
                        f.name === item.additionalData.worker &&
                        f.selected === true
                    );
                });
            });
            updateOrders(filtered);
        } else {
            updateOrders(initialOrders);
        }
        updateWorkers([...workers]);
    };

    const getDepartmentNumber = (e: string | undefined) => {
        let index = 0;
        items
            .filter(x => x.selected === true)
            .map((y, i) => (y.id === e ? (index = i) : 0));
        return index;
    };

    const getGuideDate = (arr: string[]) => {
        return arr.map((y, i) => {
            return (
                <div key={i}>
                    <p style={{ font: "16px/21px Roboto" }}>
                        {DayPickerStrings.days[testDate(i).getDay()].substring(
                            0,
                            1
                        )}
                    </p>
                    <div
                        style={{
                            backgroundColor: y,
                            borderRadius: "15px",
                            width: "20px",
                            height: "20px"
                        }}
                    />
                </div>
            );
        });
    };

    const updateDays = (x: number) => {
        if (days.some(c => c === x)) {
            setDays(days.filter(c => c !== x));
        } else {
            setDays([...days, x]);
        }
    };

    const onDistanceValueChanged = React.useCallback(
        async (value: any) => {
            updateDistanceFromCenter(value);
            if (parseInt(value) > 30) setCustomZoom(9);
        },
        [distanceFromCenter]
    );

    const increaseDistance = () => {
        onDistanceValueChanged(parseInt(distanceFromCenter) + 1);
    };

    const decreaseDistance = () => {
        onDistanceValueChanged(parseInt(distanceFromCenter) - 1);
    };

    const setViewState = () => {
        if (query && query.get("isCustomMapView") === "true") {
            setCustomZoom(11);
            setCustomMapView(true);
            setDays([0, 1, 2, 3, 4, 5, 6]);
            setMapCenter({
                lat: parseFloat(query.get("lat") as string),
                lng: parseFloat(query.get("lng") as string)
            });
            setCustomAddress(query.get("addr") as string);
        } else {
            setCustomZoom(8);
            setCustomMapView(false);
            setDays([]);
            setMapCenter({ lat: 56.1508469, lng: 10.2128301 });
            setCustomAddress("");
            const departments: ListDepartment[] = mobileDepartments;
            departments.forEach(department => {
                if (department.selected === true) department.selected = false;
            });
            updateItems([...departments]);
        }
    };

    useEffect(() => setViewState(), [isDefaultView]);

    useEffect(() => {
        updateDistanceFromCenter(distanceFromCenter);
        fetchData();
    }, [distanceFromCenter]);

    useEffect(() => {
        fetchData();
    }, [items, days]);

    const refreshWorkers = () => {
        fetchData();
    };

    return (
        <>
            <hr />
            <section className="mapView">
                <Stack
                    horizontal
                    tokens={{ childrenGap: 20 }}
                    className="StiledStack"
                >
                    <Stack.Item className="ControlPanelStack">
                        <div>
                            <div className="StiledLabels">
                                {[0, 1, 2, 3, 4, 5, 6].map((x, i) => {
                                    return (
                                        <DaySelector
                                            key={`stile_day_label_${i}`}
                                            dayNumber={x}
                                            days={days}
                                            index={i}
                                            updateDays={updateDays}
                                        />
                                    );
                                })}
                            </div>
                            {isCustomMapView ? (
                                <div className="StiledRadius">
                                    <>
                                        <h2>Radius</h2>
                                        <div className="StiledRadiusUtil">
                                            <DefaultButton
                                                className="decreaseButton"
                                                onClick={decreaseDistance}
                                            >
                                                <img src={IcMinus}></img>
                                            </DefaultButton>
                                            <TextField
                                                value={`${distanceFromCenter} km`}
                                                disabled={true}
                                                onChange={(event, value) =>
                                                    onDistanceValueChanged(
                                                        value
                                                    )
                                                }
                                            />
                                            <DefaultButton
                                                className="increaseButton"
                                                onClick={increaseDistance}
                                            >
                                                <img src={IcPlus} />
                                            </DefaultButton>
                                        </div>
                                    </>
                                </div>
                            ) : null}
                            <div className="CustomStiledTabs">
                                <CustomTabs
                                    tabs={["Afdeling", "Montør"]}
                                    items={items}
                                    updateList={updateList}
                                    workers={workers}
                                    updateWorkerList={updateWorkerList}
                                />
                            </div>
                        </div>
                        <div className="StiledMapLegend">
                            <div className="MapSignes">
                                <p>R</p>
                                <p>S</p>
                            </div>
                            <div className="MapSignsMeaning">
                                <p>Rudeskift</p>
                                <p>Stenslag</p>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    minWidth: "235px"
                                }}
                            >
                                {getGuideDate(
                                    dateColors.filter(x => x.type === "R")[0]
                                        .dates
                                )}
                            </div>
                        </div>
                    </Stack.Item>
                    <Stack.Item className="MapStack" grow={1}>
                        <div style={{ position: "relative" }}>
                            {loading ? (
                                <Spinner className="map_loading__spinner" />
                            ) : null}
                            {isLoaded ? (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={center}
                                    zoom={zoomIndex}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                    options={{
                                        streetViewControl: false,
                                        mapTypeControl: false
                                    }}
                                >
                                    {info ? (
                                        <ServiceDetails
                                            operators={operators}
                                            lat={
                                                currentOrder
                                                    ? currentOrder.lat
                                                    : fallbackLatLng.lat
                                            }
                                            lng={
                                                currentOrder
                                                    ? currentOrder.lng
                                                    : fallbackLatLng.lng
                                            }
                                            order={currentOrder}
                                            showInfo={e => showInfo(e)}
                                            refreshWorkers={refreshWorkers}
                                        />
                                    ) : null}
                                    {orders.map((x, i) => (
                                        <ServicePoint
                                            key={"s-" + i}
                                            lat={
                                                x.lat
                                                    ? x.lat
                                                    : fallbackLatLng.lat
                                            }
                                            lng={
                                                x.lng
                                                    ? x.lng
                                                    : fallbackLatLng.lng
                                            }
                                            number={
                                                x.timeAndPlace.department
                                                    ? getDepartmentNumber(
                                                          x.timeAndPlace
                                                              .department.id
                                                      )
                                                    : -1
                                            }
                                            type={
                                                x.additionalData.orderType ===
                                                "REPAIR"
                                                    ? "S"
                                                    : "R"
                                            }
                                            calibration={needsCalibration(x)}
                                            twoManJob={
                                                x.additionalData.twoManJob
                                            }
                                            date={formatServiceDate(
                                                convertUSDateToEU(
                                                    x.timeAndPlace.date
                                                )
                                            )}
                                            showInfo={e => {
                                                const thisOrder = orders.filter(
                                                    o =>
                                                        o.additionalData
                                                            .orderId ===
                                                        x.additionalData.orderId
                                                )[0];
                                                updateCurrentOrder(thisOrder);
                                                showInfo(e);
                                            }}
                                        />
                                    ))}
                                    {isCustomMapView ? (
                                        <MarkerPoint
                                            path={markerPointPath}
                                            lat={center.lat}
                                            lng={center.lng}
                                            fillColor={"#4e9be1"}
                                            text={customAddress}
                                            calibration={false}
                                            twoManJob={false}
                                            title={"icon-circle"}
                                        />
                                    ) : null}
                                </GoogleMap>
                            ) : null}
                        </div>
                        <p>{getGuides()}</p>
                    </Stack.Item>
                </Stack>
            </section>
        </>
    );
};

export default MobileServiceMap;
