import { Stack, TextField, Checkbox } from "@fluentui/react";
import * as React from "react";
import CustomCalendar from "../CustomCalendar"
import TimeSlots from "../TimeSlots";
import "./style.css";

interface TimeManagementProps {
    timeAndPlace: TimeAndPlace;
    additionalData: AdditionalData;
    availableDepartmentBookings?: AvailableDepartmentBooking[];
    orderType: AssignmentType;
    updateTimeAndPlace: (timeAndPlace: Partial<TimeAndPlace>) => void;
    selectedDate?: Date;
    updateAvailableDepartmentBookings: (date: Date) => void;
    changeDate: (date: Date) => void;
    updateAdditionalData: (additionalData: AdditionalData) => void;
    departments: Department[];
}

const TimeManagement: React.FC<TimeManagementProps> = props => {
    const onInternalCommentChange = React.useCallback(
        (e: React.SyntheticEvent<HTMLInputElement>, value: string) => {
            props.updateAdditionalData({
                ...props.additionalData,
                internalOrderComment: value
            });
        },
        []
    );

    const onBookingCalenderTimeChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        props.updateTimeAndPlace({ bookingCalenderTime: e.target.value });
    };

    const onWaitingListChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        props.updateTimeAndPlace({ waitingList: checked });
    };
    const getDepartment = (): Department | undefined =>
    {
        if(props.timeAndPlace.department)
        {
            const id = props.timeAndPlace.department.id;
            return props.departments.find(x=>x.id === id)
        }
        return undefined;
       
    }

    return (
        <Stack className="stack__container" horizontal={true} tokens={{ childrenGap: 30 }}>
            <Stack.Item>
                <CustomCalendar
                    department={getDepartment()}
                    departments={props.departments}
                    changeDate={props.changeDate}
                    selectedDate={props.selectedDate}
                    availableDepartmentBookings={ props.availableDepartmentBookings}
                    updateAvailableDepartmentBookings={props.updateAvailableDepartmentBookings}
                    orderType={props.orderType}
                />
            </Stack.Item>
            <Stack.Item grow={true} disableShrink={true}>
                {props.selectedDate ? (
                    <TimeSlots
                        departments={props.departments}
                        orderType={props.orderType}
                        timeAndPlace={props.timeAndPlace}
                        availableDepartmentBookings={
                            props.availableDepartmentBookings
                        }
                        updateTimeAndPlace={props.updateTimeAndPlace}
                        selectedDate={props.selectedDate}
                    />
                ) : null}
                <div className="timeSlot">
                    <strong>Intern Kommentar</strong>
                    <TextField
                        multiline={true}
                        onChange={onInternalCommentChange}
                        defaultValue={props.additionalData.internalOrderComment}
                        style={{ width: "100%" }}
                    />
                </div>
                <div className="timeSlot">
                    <Stack horizontal tokens={{ padding: 0, childrenGap: 10 }}>
                        <Stack.Item grow={true} disableShrink={true}>
                            <strong>Outlook calender</strong>
                            <TextField
                                prefix="Start"
                                type="time"
                                onChange={onBookingCalenderTimeChange}
                                defaultValue={
                                    props.timeAndPlace.bookingCalenderTime
                                        ? props.timeAndPlace.bookingCalenderTime
                                        : "00:00"
                                }
                            />
                        </Stack.Item>
                        {props.timeAndPlace.department &&
                            props.timeAndPlace.department.hasWaitingList ===
                                true && (
                            <Stack.Item className="waiting_list__container" grow={true} disableShrink={true}>
                                <strong>Skriv på Venteliste</strong>
                                <Checkbox
                                    checked={props.timeAndPlace.waitingList}
                                    onChange={onWaitingListChange}
                                ></Checkbox>
                            </Stack.Item>
                        )}
                    </Stack>
                </div>
            </Stack.Item>
        </Stack>
    );
};

export default TimeManagement;
