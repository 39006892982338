import { DialogType, IDropdownOption, Dialog, Label, Dropdown, TextField, DialogFooter, DefaultButton, PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useCallback, useState } from "react";

interface SetOnHoldProps {
    title: string;
    subText: string;
    hideDialog: boolean;
    onConfirm: (onholdMessage: string) => void;
    onDismiss: () => void;

    confirmText: string;
    cancelText: string;
    switchButtons?: boolean;
}

const SetOnHoldDialog: React.FunctionComponent<SetOnHoldProps> = props => {
    const dialogContentProps = {
        type: DialogType.normal,
        title: props.title,
        subText: props.subText
    };

    const [comment, setComment] = useState<string>("");
    const [reason, setReason] = useState<string>("");

    const getReasons = (): IDropdownOption[] => {
        const reasons = [
            { key: "1", text: "Mangler reservedel" },
            { key: "2", text: "Kalibreringsudfordringer" },
            { key: "3", text: "Andet" }
        ];
        return reasons;
    };

    const onConfirm = useCallback(() => {
        if (comment) {
            props.onConfirm("på hold - " + reason + " - " + comment);
        } else {
            alert("Kommentar skal udfyldes");
        }
    }, [comment]);

    const onCommentChange = useCallback(
        (e: React.SyntheticEvent<HTMLInputElement>, value: string) => {
            setComment(value);
        },
        [reason, comment]
    );

    const onReasonChange = useCallback(
        (
            e: React.SyntheticEvent<HTMLInputElement>,
            option: IDropdownOption
        ) => {
            setReason(option.text);
        },
        [comment]
    );

    return (
        <Dialog
            hidden={props.hideDialog}
            dialogContentProps={dialogContentProps}
            onDismiss={props.onDismiss}
        >
            <Label>Årsag</Label>
            <Dropdown
                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                dropdownWidth={200}
                options={getReasons()}
                onChange={onReasonChange}
            />
            <br />
            <Label>Kommentar</Label>
            <TextField required multiline rows={3} onChange={onCommentChange} />
            <DialogFooter>
                {props.switchButtons ? (
                    <>
                        <DefaultButton
                            onClick={props.onDismiss}
                            text={props.cancelText}
                        />
                        <PrimaryButton
                            onClick={onConfirm}
                            text={props.confirmText}
                        />
                    </>
                ) : (
                    <>
                        <PrimaryButton
                            onClick={onConfirm}
                            text={props.confirmText}
                        />
                        <DefaultButton
                            onClick={props.onDismiss}
                            text={props.cancelText}
                        />
                    </>
                )}
            </DialogFooter>
        </Dialog>
    );
};

export default SetOnHoldDialog;
