import { InfoBox } from "@react-google-maps/api";
import * as React from "react";
import { convertUSDateToEU } from "../../../util/DateHelper";
import { getAssignmentTypeText } from "../../utils/assignmentTypeHelper";
import { getStatusText } from "../../utils/statusHelper";
import { MarkerOrder } from "../MobileServiceMap";
import { setInStrict } from "../../utils/NestetPropertyHelper";
import API from "../../../api";
import { needsCalibration } from "../../utils/orderHelper";
import { getDropdownForOperators } from "../../utils/operatorsHelper";
import "./style.css"
import { IDropdownOption, Dropdown } from "@fluentui/react";


type Props = {
    showInfo: (e: boolean) => void;
    lat?: number;
    lng?: number;
    order: MarkerOrder | undefined;
    operators: UserInformation[];
    refreshWorkers: () => void;
}

const ServiceDetails = (props: Props) => {
    const updateWorker = async (worker: string | number) => {
        const order = props.order;
        if (order !== undefined && order.additionalData.orderId != undefined) {
            const postOrder = await API.OrderAPI.getOrder(order.additionalData.orderId);

            const updatedOrderValue: Order = setInStrict({ ...postOrder }, ["additionalData", "worker"], worker);
            const changes = [...updatedOrderValue.additionalData.changes, "worker"];
            const uniqueChanges = Array.from(new Set(changes));
            const additionalData = { ...updatedOrderValue.additionalData, changes: uniqueChanges };
            const fullyUpdatedOrder = { ...updatedOrderValue, additionalData };

            await API.OrderAPI.updateOrder(fullyUpdatedOrder);

            props.refreshWorkers();
        }
    };

    const setWorker = (e: React.SyntheticEvent<HTMLInputElement>, option: IDropdownOption) => {
        updateWorker(option.key);
    }

    return (
        <InfoBox
            position={new google.maps.LatLng(props.lat ? props.lat : 0, props.lng ? props.lng : 0)}
            options={{ disableAutoPan: false }}
        >
            <div className="details_table__container" style={{ backgroundColor: "white", opacity: 1, padding: "12px", fontSize: "1.5em", borderRadius: "10px", minWidth: "400px" }}>
                <div onClick={() => props.showInfo(false)} style={{ position: "absolute", top: 0, right: 0, cursor: "pointer", margin: "2px", width: "14px", height: "14px" }}></div>
                {props.order ? <table>
                    <tbody>
                        <tr><td>Status</td><td>{getStatusText(props.order.additionalData.orderStatus)}</td></tr>
                        <tr><td>Ordrenummer</td><td>{props.order.additionalData.orderNr}</td></tr>
                        <tr><td>Montør</td><td>
                            <Dropdown
                                calloutProps={{ calloutMaxHeight: 250, calloutMinWidth: 100 }}
                                options={getDropdownForOperators(props.operators)}
                                onChange={setWorker}
                                defaultSelectedKey={props.order.additionalData.worker}
                            />
                        </td></tr>
                        <tr><td>Udemonteringsadresse</td><td>{props.order.timeAndPlace.mobileServiceLocation ? props.order.timeAndPlace.mobileServiceLocation.address : ""}</td></tr>
                        <tr><td>Monteringsdato</td><td>{convertUSDateToEU(props.order.timeAndPlace.date)}</td></tr>
                        <tr><td>Start tid</td><td>{props.order.timeAndPlace.from}</td></tr>
                        <tr><td>Slut tid</td><td>{props.order.timeAndPlace.to}</td></tr>
                        <tr><td>Ordretype</td><td>{getAssignmentTypeText(props.order.additionalData.orderType)}</td></tr>
                        <tr><td>Kundenavn</td><td>{props.order.customer.name}</td></tr>
                        <tr><td>Kundetelefon</td><td>{props.order.customer.phone}</td></tr>
                        <tr><td>Bilmærke</td><td>{props.order.car.make}</td></tr>
                        <tr><td>Bilmodel</td><td>{props.order.car.model}</td></tr>
                        <tr><td>Afdeling</td><td>{props.order.timeAndPlace.department ? props.order.timeAndPlace.department.name : ""}</td></tr>
                        <tr><td>Kalibrering</td><td>{needsCalibration(props.order) ? "Ja" : "Nej"}</td></tr>
                        <tr><td>Tomandsjob</td><td>{props.order.additionalData.twoManJob ? "Ja" : "Nej"}</td></tr>
                        <tr><td>Intern kommentar</td><td>{props.order.additionalData.internalOrderComment}</td></tr>
                        <tr><td>Link direkte til ordre</td><td><a href={"/worksheet/" + props.order.additionalData.orderId} target="_blank" rel="noreferrer">her</a></td></tr>
                    </tbody>
                </table> : null}
            </div>
        </InfoBox>
    );
};


export default ServiceDetails;