import * as React from "react";
import FindProduct from "../../products/FindProduct";
import "./style.css"
import { IDropdownOption, Dialog, DialogType, Label, Stack, Dropdown, DialogFooter, DefaultButton, PrimaryButton } from "@fluentui/react";

interface Props {
    showWindow: boolean;
    toggleCreate: () => void;
    createTransfer: (transfers: Transfer[]) => void;
    departments: Department[];
    products: ProductGroup[];
    query: { searchIn?: string; term?: string };
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => void;
    updateSearchProductQuery: (searchIn?: string, term?: string) => void;
}

export const toLocation: { key: string; text: string }[] = [
    { key: "NØR-LAGER", text: "NØR-LAGER" },
    { key: "BRØ-LAGER", text: "BRØ-LAGER" },
    { key: "VEJ-LAGER", text: "VEJ-LAGER" },
    { key: "ESB-LAGER", text: "ESB-LAGER" },
    { key: "ODE-LAGER", text: "ODE-LAGER" }
];

const CreateStorage = (props: Props) => {
    const [productlist, updateList] = React.useState<Product[]>([]);
    const [departmentFrom, updateDepartmentFrom] = React.useState<string>("");
    const [departmentTo, updateDepartmentTo] = React.useState<string>("");

    const onDepartmentFromChange = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        updateDepartmentFrom(option.key.toString());
    };

    const onDepartmentToChange = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        updateDepartmentTo(option.key.toString());
    };

    const options = props.departments.map(department => ({
        key: department.navLocationCode,
        text: department.name
    }));

    const createTransfer = () => {
        const transfers: Transfer[] = productlist.map(prod => ({
            order_No: "",
            line_No: 0,
            transfer_from_Code: departmentFrom,
            transfer_To_Code: departmentTo,
            item_No: prod.no,
            description: prod.name,
            measure_Unit: "",
            qty_in_Transit: prod.amount,
            qty_Shipped: 0,
            qty_Received: 0,
            nav_Need_Update: false,
            is_Receipt: false,
            in_DB: false
        }));

        props.createTransfer(transfers);
        props.toggleCreate();
    };

    const onSelectedProductChange = (products: Product[]) => {
        updateList(products);
    };

    return (
        <Dialog
            hidden={!props.showWindow}
            onDismiss={props.toggleCreate}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: "Opret overflytning"
            }}
            minWidth={"100%"}
            modalProps={{
                isBlocking: false,
                styles: {
                    main: {
                        selectors: {
                            ["@media (min-width: 480px)"]: {
                                width: 700,
                                minWidth: 450,
                                maxWidth: "1000px"
                            }
                        }
                    }
                }
            }}
        >
            <div className="create_container">
                <div className="create-style-content">
                    <Label>Lokation</Label>
                    <Stack horizontal={true} tokens={{ childrenGap: 15 }}>
                        <Stack.Item className="half">
                            <Dropdown
                                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                placeholder="Fra"
                                options={options}
                                onChange={onDepartmentFromChange}
                            />
                        </Stack.Item>
                        <Stack.Item className="half">
                            <Dropdown
                                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                placeholder="Til"
                                options={[...options, ...toLocation]}
                                onChange={onDepartmentToChange}
                            />
                        </Stack.Item>
                    </Stack>

                    <FindProduct
                        selectedProducts={onSelectedProductChange}
                        updateSearchProductQuery={
                            props.updateSearchProductQuery
                        }
                        updateSearchList={props.updateSearchList}
                        productGroups={props.products}
                        query={props.query}
                    />
                </div>
            </div>
            <DialogFooter>
                <DefaultButton onClick={props.toggleCreate} text="Annuller" />
                <PrimaryButton onClick={createTransfer} text="Opret" />
            </DialogFooter>
        </Dialog>
    );
};

export default CreateStorage;
