import { IDropdownOption } from "@fluentui/react";

export const getDropdownForWindshieldManufacturer = (
    manufacturers: WindowManufacturer[]
): IDropdownOption[] => {
    const tmp = [...manufacturers];
    const emptyManufacturer = {} as WindowManufacturer;
    emptyManufacturer.name = "";
    tmp.splice(0, 0, emptyManufacturer);
    return tmp.map(manufacturer => ({
        key: manufacturer.name,
        text: manufacturer.text
    }));
};
