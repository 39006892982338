import * as React from "react";
import { getDepartmentDropdownOptions } from "../../utils/departmentHelper";
import { IDropdownOption, Dropdown } from "@fluentui/react";
interface Props {
    department?: Department;
    departments: Department[];
    onDepartmentChange: (department: Department) => void;
}

function DepartmentSelector(props: Props) {
    const departmentOptions = (): IDropdownOption[] =>
        getDepartmentDropdownOptions(props.departments);

    const onDepartmentChange = () => (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ) => {
        if (option) {
            const department = props.departments.find(x => x.id === option.key);
            if (department) {
                props.onDepartmentChange(department);
            }
        }
    };
    const selectedKey = props.department ? props.department.id : "";
    return (
        <Dropdown
            calloutProps={{calloutMaxHeight: 500, calloutMinWidth: 100}}
            title="Vælg værksted"
            placeholder="Vælg værksted"
            defaultSelectedKey={selectedKey}
            options={departmentOptions()}
            onChange={onDepartmentChange()}
            style={{ minWidth: "300px" }}
        />
    );
}

export default DepartmentSelector;
