import { Marker } from "@react-google-maps/api";
import * as React from "react";

type Props = {
    title: string;
    path: string;
    fillColor: string;
    lat: number;
    lng: number;
    text: string;
    number?: number;
    showInfo?: (e: boolean) => void;
    calibration: boolean;
    calibrationIcon?: string;
    twoManJob: boolean;
    twoManJobIcon?: string;
}

const MarkerPoint = (props: Props) =>
{
    return (
        <Marker key={props.number ? "marker-" + props.number : "addressMarker-1"}
            position={{ lat: props.lat, lng: props.lng }}
            icon={{
                path: props.path,
                fillColor: props.fillColor,
                fillOpacity:0.85,
                strokeColor: "#fff",
                anchor: new google.maps.Point(32, 64), scale: .7,
                labelOrigin: new google.maps.Point(32, 42)
            }}
            onClick={() => {props.showInfo ? props.showInfo(true) : null; }}
            label={{
                text: props.text,
                className: props.calibrationIcon + " " + props.twoManJobIcon
            }}
            title={props.title}
        />
    );
};
export default MarkerPoint;