import { TextField } from "@fluentui/react";
import * as React from "react";

interface Props
{
    value?: string;
    onChange: (newValue: string) => void;
}

let setTimeOutvariable:any;
function textFieldDelayedSave(props: Props)
{
    let [value, setValue]  = React.useState(props.value);
    const save = () =>
    {
        clearTimeout(setTimeOutvariable);
        if(setTimeOutvariable !== -1)
        {
            props.onChange(value === undefined ? "" : value);
        }
    }
    const onValueChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string) =>
    {
        setValue(newValue);
        clearTimeout(setTimeOutvariable);
        setTimeOutvariable = setTimeout(() => {
           
            props.onChange(newValue);
            setTimeOutvariable = -1;
         
        }, 500);
        
    }

    return  <TextField onBlur={save} onChange={onValueChange} value={value} />;
}

export default textFieldDelayedSave;