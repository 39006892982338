import * as React from "react";
import "./style.css";

interface TimeManagementAlertProps {
    alertMessages: string[];
}

const TimeManagementAlert: React.FC<TimeManagementAlertProps> = props => {
    return (
        <>
            {props.alertMessages.map((alert, index) => {
                if (alert || alert !== null) {
                    return (
                        <div key={index} className="time_management__alert">
                            {alert}
                        </div>
                    );
                } else {
                    return (
                    <div key={index} className="time_management__alert">
                        Nuværende afdeling kan ikke acceptere biler af det
                        valgte bilmærke. Vælg venligst en anden afdeling.
                    </div>
                    );
                }
            })}
        </>
    );
};

export default TimeManagementAlert;
