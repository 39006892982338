import * as React from "react";
import { Action, Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../../state";
import { getSelectList, getUserInformation } from "../../../../state/action";
import { createFeedback, loadSelectList } from "../../../../state/ui/action";
import { DefaultButton, Dialog, DialogType, Dropdown, IDropdownOption, PrimaryButton, TextField } from "@fluentui/react";
import { useState } from "react";

interface Props {
    orderId?: string;
    worker?: string;
}

const Feedback: React.FunctionComponent<Props & StateProps> = props => {

    const [showFeedbackDialog, setShowFeedbackDialog] = useState<boolean>(false);
    const [selectList, setSelectList] = useState<SelectListContainer | undefined>();
    const [selectValue, setSelectedValue] = useState<string>();
    const [description, setDescription] = useState<string>();
    const onFeedbackClick = () =>
    {
        if(!props.selectList)
        {
            props.loadSelectList();
        }
        toggleDialog();
      
    }
    const toggleDialog = () => setShowFeedbackDialog(!showFeedbackDialog);

    const onListClick = (result: SelectListContainer) => () => setSelectList(result);

    const getSelectListOptions = (): IDropdownOption[] =>
    {
        if(selectList)
        {
            return selectList.values.sort((a, b) => a.key.localeCompare(b.key)).map(value => ({ key: value.key, text : value.value}));
        }
        return [];
    }
    const onSetSeletedValue = ( event: React.FormEvent<HTMLDivElement>, option: IDropdownOption ) => {
        setSelectedValue(option.key as string);
    };
    const onTextChange = (   e: React.SyntheticEvent<HTMLInputElement>, text: string ) => {
        setDescription(text);
    };
    const onSubmit = () =>
    {
        if(description && selectValue && selectList && props.orderId && props.user.id)
        {
            props.createFeedback({
                description: description,
                subType:selectValue,
                type: selectList.title,
                orderId: props.orderId,
                userId: props.user.id,
                userName: props.user.name
            });
            setDescription(undefined);
            setSelectedValue(undefined);
            setSelectList(undefined);
            toggleDialog();
        }
     
    }
    return <>
        <Dialog minWidth={450} hidden={!showFeedbackDialog} onDismiss={toggleDialog}    dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Feedback",
                 
                }}>
           
            {props.selectList && props.selectList.map(list => <DefaultButton key={list.id} checked={selectList? list.id === selectList.id: false} onClick={onListClick(list)} text={list.title} className="" />)} 
            {selectList &&
                <>
                <Dropdown  selectedKey={selectValue} onChange={onSetSeletedValue} options={getSelectListOptions()}></Dropdown>
                <br/>
                Kommentar:
                <br/>
                <TextField placeholder="Fri tekst" value={description} multiline={true} onChange={onTextChange}></TextField>
                {selectList && description && <PrimaryButton onClick={onSubmit}>Indsend</PrimaryButton>}
               </>
            }

        </Dialog>

        <DefaultButton title="Du skal vælge en montør før du kan give feedback" disabled={props.worker === null || props.worker === undefined || props.worker === ""} onClick={onFeedbackClick} text="Feedback" className="feedback" />
    </>;
};

const mapStateToProps = (state: RootState) => {
    return {
        selectList: getSelectList(state, "Feedback"),
        user: getUserInformation(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    loadSelectList: () =>  dispatch<any>(loadSelectList()),
    createFeedback: (feedback: Feedback) =>  dispatch<any>(createFeedback(feedback)),
    
  
});

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(Feedback);
    