import { isArray } from "util";

export interface Field
{
    placeholder?: string;
    validation?: ValidationOption;
    type?: string;

}

export const getCustomerValidation = (whoIsPaying: WhoIsPaying) =>
{
    switch (whoIsPaying)
    {
        case "INSURANCE":
            return customerFieldConfig;
        default:
            return customerFieldConfigWithoutRequire;
    }
}

export const createUserFieldConfig: { [key: string]: Field } =
{
    name: {
        validation: { required: true }
    },
    initials: {
        validation: { required: true }
    },
    email: {
        validation: { type: "email" }
    },
    phone: {
        validation: { type: "phone" }
    },
    roles: {
        validation: { required: true }
    },
    confirmPassword: {
        validation: { type: "match", fields: ["password"] }
    },
    password: {
        validation: {
            type: "password"
        }
    },
    allowEmail: {
        validation: { type: "requireOne", fields: ["allowSMS", "allowEmail"] }
    }
}

export const editUserFieldConfig: { [key: string]: Field } =
{
    name: {
        validation: { required: true }
    },
    initials: {
        validation: { required: true }
    },
    email: {
        validation: { type: "email" }
    },
    phone: {
        validation: { type: "phone" }
    },
    roles: {
        validation: { required: true }
    },
    confirmPassword: {
        validation: { type: "match", fields: ["password"] }
    },
    password: {
        validation: { type: "password" }
    }
}

export const productsFieldConfig: { [key: string]: Field } =
{
    items: {
        validation: { minLength: 1 }
    }
}

export const customerFieldConfigWithoutRequire: { [key: string]: Field } =
{
    name: {
        placeholder: "Navn"
    },
    address: {
        placeholder: "Adresse"
    },
    city: {
        placeholder: "By",
    },
    zipCode: {
        placeholder: "Postnummer",
        validation: { type: "number" }
    },
    email: {
        placeholder: "E-mail",
        validation: { type: "email" },
        type: "email"
    },
    phone: {
        placeholder: "Tlf. nr.",
        validation: { type: "phone" }
    }
};

export const customerFieldConfig: { [key: string]: Field } =
{
    name: {
        validation: { required: true },
        placeholder: "Navn"
    },
    address: {
        validation: { required: true },
        placeholder: "Adresse"
    },
    city: {
        placeholder: "By",
        validation: { required: true }
    },
    zipCode: {
        placeholder: "Postnummer",
        validation: { required: true, type: "number" }
    },
    email: {
        placeholder: "E-mail",
        validation: { required: true, type: "email" },
        type: "email"
    },
    phone: {
        placeholder: "Tlf. nr.",
        validation: { required: true, type: "phone" }
    }
};

export const debitorFieldConfig: { [key: string]: Field } =
{
    debitorName: {
        validation: { required: true },
        placeholder: "Debitornavn"
    },
    name: {
        placeholder: "Navn"
    },
    email: {
        placeholder: "E-mail",
        type: "email"
    },
    address: {
        validation: { required: true },
        placeholder: "Adresse"
    },
    city: {
        placeholder: "By",
    },
    zipCode: {
        placeholder: "Postnummer",
    },
    phone: {
        placeholder: "Tlf. nr.",
        validation: { required: true, type: "phone" }
    },
    cvr: {
        placeholder: "CVR-nummer"
    }
};


export const timeAndPlaceFieldConfig: { [key: string]: Field } =
{
    department: {
        validation: { required: true },
    },
    date: {
        validation: { required: true, type: "date" },
    },
    from: {
        validation: { required: true }
    },
    to: {
        validation: { required: true, }
    }
};

export const carFieldConfig: { [key: string]: Field } =
{
    make: {
        validation: { required: true },
    },
    model: {
        validation: { required: true },
    }
};

export interface ValidationOption
{
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    type?: "number" | "email" | "text" | "phone" | "date" | "requireOne" | "match" | "password";
    data?: any[];
    fields?: string[];
}

export const isModelValid = (model: any, validationConfig: { [key: string]: Field }): boolean =>
{
    let error = true;
    Object.keys(validationConfig).forEach(key => 
    {
        const field = validationConfig[key];
        const validation: ValidationOption = { ...field.validation };
        validation.data = [];
        if (validation.fields && (validation.type === "match" || validation.type === "requireOne"))
        {
            validation.fields.forEach((item) =>
            {
                validation.data ? validation.data.push(model[item]) : null;
            });
        }
        if (validateAllErrors(validation, model[key]).length > 0)
        {
            error = false;
        }
    });

    return error;
};


export const validateAllErrors = (options: ValidationOption | undefined, value: string): string[] =>
{
    if (options === undefined)
    {
        return [];
    }
    const errors: (string | undefined)[] = [];
    if (options.required)
    {
        errors.push(required(value));
    }

    if (options.minLength)
    {
        errors.push(minLength(options.minLength, value));
    }

    if (options.maxLength)
    {
        errors.push(maxLength(options.maxLength, value));
    }

    if (options.type === "number")
    {
        errors.push(isNumber(value));
    }

    if (options.type === "email")
    {
        errors.push(email(value));
    }

    if (options.type === "requireOne")
    {
        errors.push(requireOne(options.data ? options.data : [""]));
    }

    if (options.type === "match")
    {
        errors.push(match(options.data ? options.data : [""], value));
    }

    if (options.type === "password")
    {
        errors.push(password(value));
    }

    if (options.type === "date")
    {
        errors.push(date(value));
    }
    return errors.filter(x => x !== undefined) as string[];
};



export const validate = (options: ValidationOption | undefined) => (value: string): string | undefined =>
{

    const errors = validateAllErrors(options, value);
    return errors.length > 0 ? errors[0] : "";

};
export const isNumber = (value: string) =>
{
    if (value.length > 0 && !(/^[0-9]*$/.test(value)))
    {
        return `"Field must be a number`;
    }
};

export const email = (value: string) =>
{
    //https://stackoverflow.com/a/1373724/451571
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (value.length > 0 && !re.test(value))
    {
        return `"Field must be a email`;
    }
};

export const password = (value: string) =>
{
    if (isArray(value))
    {
        if (!value[1] && value[0].length < 6)
        {
            return `"Kodeordet skal indeholde minimum 6 karakter`;
        }
    } else
    {
        if (value.length > 0 && value.length < 6)
        {
            return `"Kodeordet skal indeholde minimum 6 karakter`;
        }
    }
}

export const requireOne = (values: boolean[]) =>
{
    if (values.every((item) => item === false))
    {
        return `"Pick one of the options"`;
    }
};

export const date = (value: string) =>
{
    const re = /^(0[1-9]|[12][0-9]|3[01])[/.](0[1-9]|1[012])[-.](19|20)\d\d$/;
    if (!re.test(value))
    {
        return `"Field must be a date dd/MM-YYYY`;
    }
};

export const required = (value: string) =>
{
    if (!value || value.length < 1)
    {
        return `"Field is required`;
    }
};

export const match = (values: string[], compareValue: string) =>
{
    if (values.every(item => item.length > 0 && item !== compareValue))
    {
        return `"Fields does not match`;
    }
};

export const minLength = (minLength: number, value: string) =>
{
    if (value.length < minLength)
    {
        return `"Minimum ${minLength} characters required`;
    }
};

export const maxLength = (maxLength: number, value: string) =>
{
    if (value.length > maxLength)
    {
        return `"Maximum ${minLength} characters required`;
    }
};