import * as React from "react";
import ImageViewer from "./ImageViewer";
import "./style.css"
import { PrimaryButton, DefaultButton, ActionButton, Icon, Panel, PanelType, Stack, IconButton } from "@fluentui/react";

interface Props {
    upload: (e: React.SyntheticEvent<HTMLInputElement>, t: ImageType) => void;
    removeImages: (images: string[], ImageType: ImageType) => void;
    internal: string[];
    external: string[];
    save: () => void;
}

const MediaManager = (props: Props) => {
    const zoomLevel = 1;
    const [panel, togglePanel] = React.useState(false);
    const [viewer, toggleViewer] = React.useState(false);
    const [edit, toggleEdit] = React.useState(false);
    const [zoom, currentZoom] = React.useState(zoomLevel);
    const [current, toggleCurrent] = React.useState<ImageType>(
        "internalImages"
    );
    const [currentImage, toggleCurrentImage] = React.useState("");
    const [selected, selectedImage] = React.useState<string[]>([]);
    const [photos, updatePhotos] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (current === "internalImages") {
            updatePhotos(props.internal || []);
        } else {
            updatePhotos(props.external || []);
        }

        window.addEventListener("popstate", historyBack);

        if (panel && window.location.hash.indexOf("#gallery") === -1) {
            window.history.pushState(
                null,
                "gallery",
                window.location.href + "#gallery"
            );
        }

        return () => {
            window.removeEventListener("popstate", historyBack);
        };
    }, [props.internal, props.external, current, panel]);

    const historyBack = () => {
        if (window.location.hash.indexOf("#gallery") === -1) {
            togglePanel(false);
        }
    };

    const toggleClick = () => {
        if (!viewer) {
            window.location.hash = "";
        }
      
        togglePanel(!panel);
        

    };

    const toggleModal = () => {
        currentZoom(1);
        toggleViewer(!viewer);
    };
    const toggleEditClick = () => {
        selectedImage([]);
        toggleEdit(!edit);
    };

    const selectImage = (id: string) => (e: React.SyntheticEvent) => {
        const exists = selected.filter(x => x === id);
        if (exists.length === 0) {
            selectedImage([...selected, id]);
        } else {
            selectedImage([...selected.filter(x => x !== id)]);
        }
    };

    const getFooter = () => {
        return (
            <div className="panel_footer">
                <PrimaryButton
                    text={edit ? "Annullér" : "Redigér"}
                    onClick={toggleEditClick}
                />
                {!edit ? (
                    <div className="images__upload">
                        <DefaultButton text="Upload" />
                        <input multiple type="file" onChange={uploadClick(current)} />
                    </div>
                ) : null}
                {edit ? (
                    <ActionButton
                        data-automation-id="test"
                        iconProps={{ iconName: "Delete" }}
                        allowDisabledFocus={true}
                        disabled={false}
                        checked={false}
                        onClick={deleteSelected}
                    >
                        Slet
                    </ActionButton>
                ) : null}
                <DefaultButton
                    text={"Gem"}
                    onClick={toggleClick}
                />
            </div>
        );
    };

    const deleteSelected = () => {
        const toKeepArr: string[] = [];
        const toDeleteArr: string[] = [];
        const photosLeft = photos.map(x => {
            if (selected.indexOf(x) === -1) {
                toKeepArr.push(x);
            } else {
                toDeleteArr.push(x);
            }
        });
        updatePhotos(toKeepArr);
        selectedImage([]);
        props.removeImages(toDeleteArr, current);
    };

    const deleteOpenImage = () => {
        props.removeImages([currentImage], current);
        toggleModal();
    };

    const showImage = (e: string) => (
        ev: React.MouseEvent<HTMLImageElement>
    ) => {
        toggleCurrentImage(e);
        toggleModal();
    };
    const closeModal = () => {
        toggleViewer(false);
        togglePanel(true);
    };
    const getImage = (e: string, index: number) => {
        return (
            <div
                key={index}
                className={`${edit ? "editable" : ""} ${
                    selected.filter(x => x === e).length > 0 ? "selected" : ""
                } `}
            >
                {edit ? (
                    <Icon
                        iconName={
                            selected.filter(x => x === e).length > 0
                                ? "SkypeCircleCheck"
                                : "LocationCircle"
                        }
                    />
                ) : null}
                {edit ? (
                    <img onClick={selectImage(e)} src={e} />
                ) : (
                    <img onClick={showImage(e)} src={e} />
                )}
            </div>
        );
    };
    const getHeader = (): string => {
        return `${
            current === "internalImages" ? "Interne" : "Eksterne"
        } fotos ${
            selected.length > 0
                ? ": " +
                  selected.length +
                  (selected.length > 1 ? " valgte" : " valgt")
                : ""
        }`;
    };

    const toggleButtonClick = (e: ImageType) => (
        ev: React.MouseEvent<HTMLButtonElement>
    ) => {
        toggleCurrent(e);
        togglePanel(!panel);
    };

    const uploadClick = (type: ImageType) => (
        e: React.SyntheticEvent<HTMLInputElement>
    ) => {
        props.upload(e, type);
    };

    return (
        <>
            <Panel
                isOpen={panel}
                onDismiss={toggleClick}
                type={PanelType.medium}
                isFooterAtBottom={true}
                onRenderFooterContent={getFooter}
                headerText={getHeader()}
                style={{ display: !viewer ? "block" : "none" }}
            >
                <div className="images_container">
                    <div className="images">
                        {photos
                            ? photos.map((e, index) => getImage(e, index))
                            : null}
                    </div>
                </div>
            </Panel>
            {viewer ? (
                <ImageViewer
                    src={currentImage}
                    toggleModal={closeModal}
                    deleteOpenImage={deleteOpenImage}
                />
            ) : null}

            <Stack horizontal tokens={{ childrenGap: 0 }} horizontalAlign="end">
                <Stack.Item>
                    <div className="media_container">
                        <PrimaryButton
                            className=""
                            text="Interne fotos"
                            onClick={toggleButtonClick("internalImages")}
                        />
                        <IconButton
                            style={{
                                backgroundColor: "#0078D4",
                                color: "white"
                            }}
                            disabled={false}
                            checked={false}
                            iconProps={{ iconName: "Photo2Add" }}
                            title="Tilføj"
                        />
                        <input
                            type="file"
                            id="fileUploadInternal"
                            accept=".png, .jpg, .jpeg"
                            multiple
                            onChange={uploadClick("internalImages")}
                        />
                    </div>
                </Stack.Item>
            </Stack>
            <br />
            <Stack horizontal tokens={{ childrenGap: 0 }} horizontalAlign="end">
                <Stack.Item>
                    <div className="media_container">
                        <PrimaryButton
                            className=""
                            text="Eksterne fotos"
                            onClick={toggleButtonClick("externalImages")}
                        />
                        <IconButton
                            id="external"
                            style={{
                                backgroundColor: "#0078D4",
                                color: "white"
                            }}
                            disabled={false}
                            checked={false}
                            iconProps={{ iconName: "Photo2Add" }}
                            title="Tilføj"
                        />
                        <input
                            type="file"
                            id="fileUploadExternal"
                            accept=".png, .jpg, .jpeg"
                            multiple
                            onChange={uploadClick("externalImages")}
                        />
                    </div>
                </Stack.Item>
            </Stack>
        </>
    );
};

export default MediaManager;
