import BaseApi from "./baseApi";

interface ICarAPI {
    getCarManufactures: () => Promise<CarManufacture[]>;
    getCarManufactureModels: (manuId: number) => Promise<CarManufactureModel[]>;
    getInformationFromReg: (reg: string) => Promise<Car>;
    getCarIdFromCar: (car: Car) => Promise<number>;
}

export default class CarAPI extends BaseApi implements ICarAPI {
    async getCarManufactures(): Promise<CarManufacture[]> {
        const url = `/api/car/manufacturers`;
        return await this.fetchGet(url);
    }

    async getCarManufactureModels(
        manuId: number
    ): Promise<CarManufactureModel[]> {
        const url = `/api/car/models?manuId=${manuId}`;
        return await this.fetchGet(url);
    }

    async getInformationFromReg(reg: string): Promise<Car> {
        const url = `/api/car/search/${reg}`;
        return await this.fetchGet(url);
    }

    async getCarIdFromCar(car: Car): Promise<number> {
        const url = `/api/car/carid/${car.manuId}/${car.modId}/${car.modelYear}`;
        return await this.fetchGet(url);
    }
}
