import { Label, Stack, TextField } from "@fluentui/react";
import * as React from "react";
import { Fragment } from "react";
import {
    canBookAtDate,
    getFirstAvailableDay,
    mapDecimalToTimeString
} from "../../utils/DateHelper";
import { findParentDepartment } from "../../utils/departmentHelper";
import "./style.css";

interface TimeSlotProps {
    timeAndPlace: TimeAndPlace;
    departments: Department[];
    availableDepartmentBookings?: AvailableDepartmentBooking[];
    orderType: AssignmentType;
    updateTimeAndPlace: (timeAndPlace: Partial<TimeAndPlace>) => void;
    selectedDate: Date;
}

const TimeSlots: React.FC<TimeSlotProps> = props => {
    const onTimeSlotClick = (timeSlot: DamageSpecificTimeSlot) => (
        ev: React.MouseEvent
    ) => {
        props.updateTimeAndPlace({
            from: mapDecimalToTimeString(timeSlot.from),
            to: mapDecimalToTimeString(timeSlot.to),
            timeSlotId: timeSlot.id
        });
    };
    const getDepartment = (): Department | undefined =>
    {
        if(props.timeAndPlace.department)
        {
            const id = props.timeAndPlace.department.id;
            return props.departments.find(x=>x.id === id)
        }
        return undefined;
       
    }
    const renderTimeSlot = React.useCallback(
        (timeSlot: DamageSpecificTimeSlot): JSX.Element => {
            return (
                <div
                    onClick={onTimeSlotClick(timeSlot)}
                    className={`timeSlotItem 
                        ${timeSlot.id === props.timeAndPlace.timeSlotId
                    ? "is__selected"
                    : undefined }
                    `}
                >
                    {mapDecimalToTimeString(timeSlot.from)} -{" "}
                    {mapDecimalToTimeString(timeSlot.to)}
                    {timeSlot.hasFreeCar && (
                        <div>Inkl. gratis lånebil</div>
                    )}
                </div>
            );
        },
        [props.timeAndPlace]
    );

    const renderSlotsWithReperationTime = React.useCallback(
        (
            availableTimeSlot: AvailableTimeSlot
        ): JSX.Element => {
            const { reperationTime, from, to } = availableTimeSlot.timeslot;
            const jsxArray: JSX.Element[] = [];
          
            if (reperationTime > 0) {
                let i = from;
                for (i; i + reperationTime <= to; ) {
                    if (availableTimeSlot && availableTimeSlot.alreadyBooked) {
                        if (
                            availableTimeSlot.alreadyBooked.find(
                                x => x.from <= i && i < x.to
                            ) !== undefined
                        ) {
                            i = i + reperationTime;
                            continue;
                        }
                    }
                    jsxArray.push(
                        renderTimeSlot({
                            ...availableTimeSlot.timeslot,
                            from: i,
                            to: i + reperationTime
                        })
                    );
                    i = i + reperationTime;
                }
            } else {
                jsxArray.push(renderTimeSlot(availableTimeSlot.timeslot));
            }

            return (
                <div>
                    {jsxArray && jsxArray.length > 0
                        ? jsxArray.map((x, index) => {
                            return (
                                <Fragment key={`jsx_${index}`}>{x}</Fragment>
                            );
                        })
                        : null}
                </div>
            );
        },
        [props.timeAndPlace]
    );

    const renderDamageSpecificTimeSlots = React.useCallback(
        (
            availableTimeSlots: AvailableTimeSlot[]
        ): JSX.Element => {
            const { selectedDate } = props;

            let timeSlots: DamageSpecificTimeSlot[] = [];
            const currentDate = new Date();
            timeSlots = timeSlots.filter(x => {
                const result =
                    availableTimeSlots.findIndex(
                        y => y.id === x.id && y.available
                    ) !== -1;
                if (result === true) {
                    if (
                        selectedDate.toDateString() ===
                        currentDate.toDateString()
                    ) {
                        if (
                            x.from <
                            currentDate.getHours() +
                                currentDate.getMinutes() / 60
                        ) {
                            return false;
                        }
                    }
                }
                return result;
            });

            if (availableTimeSlots.length > 0) {
                return (
                    <div>
                        {availableTimeSlots.map((x, index) => {
                            return (
                                <Fragment key={`timeSlots_${index}`}>
                                    {renderSlotsWithReperationTime(x)}
                                </Fragment>
                            );
                        })}
                    </div>
                );
            }
            return <></>;
        },
        [props.selectedDate, props.timeAndPlace]
    );

    const renderTimeSlots = React.useCallback((): JSX.Element => {
        const { orderType, availableDepartmentBookings, selectedDate } = props;
        let department = getDepartment();

        if (department && availableDepartmentBookings) {
            // necessary to switch to parent department
            if (department.parentDepartmentId) {
                department = findParentDepartment(
                    department,
                    props.departments
                );
            }

            const firstAvailableDay = getFirstAvailableDay(
                props.orderType,
                department
            );

            const canBook = canBookAtDate(
                selectedDate,
                availableDepartmentBookings,
                props.orderType,
                firstAvailableDay,
            );

            if (!canBook) {
                return <></>;
            }

            const availableBooking = availableDepartmentBookings.find(
                x => x.dayOfMonth === selectedDate.getDate()
            );

            if (availableBooking) {
                
                if (orderType === "REPAIR") {
                    return renderDamageSpecificTimeSlots(availableBooking.stoneChipSlots);
                } else  {
                    return renderDamageSpecificTimeSlots(availableBooking.windowReplaceSlots);
                }
            }
        }
        return <></>;
    }, [props]);

    const onFromChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        props.updateTimeAndPlace({ from: value });
    };

    const onToChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        props.updateTimeAndPlace({ to: value });
    };

    const onBookOutsideTimeslot = (event?: React.MouseEvent) => {
        props.updateTimeAndPlace({ timeSlotId: "" });
    };

    const renderOverrides = React.useCallback((): JSX.Element => {
        const { to, from, timeSlotId } = props.timeAndPlace;
        let department = props.timeAndPlace.department
            ? { ...props.timeAndPlace.department }
            : undefined;
        const { availableDepartmentBookings, selectedDate } = props;

        if (department && availableDepartmentBookings) {
            // necessary to switch to parent department
            if (department.parentDepartmentId) {
                department = findParentDepartment(
                    department,
                    props.departments
                );
            }

            const firstAvailableDay = getFirstAvailableDay(
                props.orderType,
                department
            );

            const canBook = canBookAtDate(
                selectedDate,
                availableDepartmentBookings,
                props.orderType,
                firstAvailableDay,
            );

            const timeSlotSummary = (
                <div className="timeSlot">
                    <Label>Tidspunkt</Label>
                    <Stack horizontal tokens={{ padding: 0, childrenGap: 10 }}>
                        <Stack.Item grow={true} disableShrink={true}>
                            <TextField
                                prefix="Fra"
                                type="time"
                                onChange={onFromChange}
                                value={from}
                                disabled={
                                    !(
                                        !!timeSlotId ||
                                        timeSlotId === "" ||
                                        props.orderType === "SALE"
                                    )
                                }
                            />
                        </Stack.Item>
                        <Stack.Item grow={true} disableShrink={true}>
                            <TextField
                                prefix="Til"
                                type="time"
                                onChange={onToChange}
                                value={to}
                                disabled={
                                    !(
                                        !!timeSlotId ||
                                        timeSlotId === "" ||
                                        props.orderType === "SALE"
                                    )
                                }
                            />
                        </Stack.Item>
                    </Stack>
                </div>
            );
            const outsideTimeSlots =
                !timeSlotId ||
                timeSlotId === "00000000-0000-0000-0000-000000000000";
            if (!canBook) {
                return (
                    <>
                        <div
                            onClick={() => onBookOutsideTimeslot()}
                            className={`timeSlotItem 
                                ${outsideTimeSlots ? "is__selected" : undefined }
                            `}
                        >
                            Book udenfor tidsslot
                        </div>
                        {timeSlotSummary}
                    </>
                );
            }
            return <>{timeSlotSummary}</>;
        }
        return <></>;
    }, [
        props.timeAndPlace,
        props.availableDepartmentBookings,
        props.selectedDate,
        props.orderType
    ]);

    return (
        <>
            <Label>Tilgængelige tider:</Label>
            {renderTimeSlots()}
            {renderOverrides()}
        </>
    );
};

export default TimeSlots;
