import * as React from "react";
import { useState } from "react";
import api from "../../api";
import TimeAndDepartmentManager from "../base/TimeAndDepartmentManager";
import { userHasAccess } from "../utils/UserHelper";
import { getComplaintTypeText } from "./helpers/complaintHelper";
import "./style.css";
import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";

interface ComplaintDialogProps {
    order: Order;
    departments: Department[];
    user: UserInformation;
}
let doubleClickController = false;
const ComplaintDialog: React.FunctionComponent<ComplaintDialogProps> = props => {
    const [hideComplaintDialog, setHideComplaintDialog] = useState(true);
    const [hideTimeAndPlaceDialog, setHideTimeAndPlaceDialog] = useState(true);
    const [type, setType] = useState<ComplaintSlotType>("WITHOUT_SLOT");
    const [timeAndPlace, setTimeAndPlace] = useState<TimeAndPlace>(
        props.order.timeAndPlace
    );
    const [internalComment, setInternalComment] = useState<string>("");

    React.useEffect(() => {
        if (hideTimeAndPlaceDialog) {
            setTimeAndPlace(props.order.timeAndPlace);
        }
    }, [props.order.timeAndPlace, hideComplaintDialog]);

    const updateTimeAndPlace = (timeAndPlaceUpdate: Partial<TimeAndPlace>) =>
        setTimeAndPlace({ ...timeAndPlace, ...timeAndPlaceUpdate });

    const toggleComplaintDialog = () =>
        setHideComplaintDialog(!hideComplaintDialog);

    const createOrder = async (
        currentType: ComplaintSlotType = type,
        timeAndPlaceResult: TimeAndPlace = timeAndPlace
    ) => {
        const { orderId, worker } = props.order.additionalData;
        if (!worker) {
            alert("Du mangler at vælge en montør");
            return;
        }
        const model: CreateComplaintModel = {
            internalComment,
            type: currentType,
            worker,
            timeAndPlace: timeAndPlaceResult
        };
        if (orderId) {
            if (!doubleClickController) {
                doubleClickController = true;
                const newOrder = await api.OrderAPI.createComplaint(
                    orderId,
                    model
                );
                window.location.href = `/worksheet/${newOrder.additionalData.orderId}`;
            }
        }
    };

    const onComplaintTypeClick = (type: ComplaintSlotType) => async () => {
        setType(type);

        if (type === "WITHOUT_SLOT") {
            const temp = { ...timeAndPlace };
            temp.timeSlotId = undefined;
            await createOrder(type, temp);
        } else {
            setTimeAndPlace({
                ...timeAndPlace,
                ...{ from: undefined, to: undefined, timeSlotId: undefined }
            });
            setHideComplaintDialog(true);
            setHideTimeAndPlaceDialog(false);
        }
    };

    const onCreateComplaintClick = async () => {
        if (timeAndPlace.timeSlotId && timeAndPlace.from && timeAndPlace.to) {
            await createOrder();
        } else {
            alert("Du mangler at vælge en tid");
        }
    };

    const createInternalComplaint = async () => {
        const temp = { ...timeAndPlace };
        temp.timeSlotId = undefined;
        createOrder("INTERNAL_COMPLAINT", temp);
    };

    const updateAdditionalDataObject = (additionalData: AdditionalData) =>
        setInternalComment(additionalData.internalOrderComment);
    const updateMobileServiceProduct = () => {};
    const closeAllDialogs = () => {
        setHideComplaintDialog(true);
        setHideTimeAndPlaceDialog(true);
    };

    if (!props.order.additionalData.orderId) {
        return null;
    }

    return (
        <>
            <DefaultButton onClick={toggleComplaintDialog}>
                Opret Reklamation
            </DefaultButton>
            <Dialog
                minWidth={545}
                hidden={hideComplaintDialog}
                onDismiss={toggleComplaintDialog}
                title="Opret Reklamation"
            >
                <div className="complaint_dialog_button">
                    <DefaultButton
                        onClick={createInternalComplaint}
                        text={getComplaintTypeText("INTERNAL_COMPLAINT")}
                    />
                    {userHasAccess(props.user.roles, [
                        "Complaint",
                        "CustomerService",
                        "Adminstrator"
                    ]) && (
                        <>
                            <DefaultButton
                                onClick={onComplaintTypeClick("REPLACE_SLOT")}
                                text={getComplaintTypeText("REPLACE_SLOT")}
                            />
                            <DefaultButton
                                onClick={onComplaintTypeClick("REPAIR_SLOT")}
                                text={getComplaintTypeText("REPAIR_SLOT")}
                            />
                            <DefaultButton
                                onClick={onComplaintTypeClick("WITHOUT_SLOT")}
                                text={getComplaintTypeText("WITHOUT_SLOT")}
                            />
                        </>
                    )}
                </div>
                <DialogFooter>
                    <DefaultButton
                        onClick={toggleComplaintDialog}
                        text={"Luk"}
                    />
                </DialogFooter>
            </Dialog>
            <Dialog
                minWidth={800}
                hidden={hideTimeAndPlaceDialog}
                onDismiss={closeAllDialogs}
                title="Opret Reklamation"
            >
                <TimeAndDepartmentManager
                    updateTimeOnCashDepartmentChange={false}
                    orderItems={props.order.items}
                    timeAndPlace={timeAndPlace}
                    additionalData={props.order.additionalData}
                    car={props.order.car}
                    departments={props.departments}
                    debitor={props.order.debitor}
                    orderType={
                        type === "REPLACE_SLOT" ? "REPLACE_FRONT" : "REPAIR"
                    }
                    updateTimeAndPlace={updateTimeAndPlace}
                    updateAdditionalData={updateAdditionalDataObject}
                    updateMobileServiceProduct={updateMobileServiceProduct}
                />
                <DialogFooter>
                    <DefaultButton onClick={closeAllDialogs} text={"Luk"} />
                    <PrimaryButton
                        onClick={onCreateComplaintClick}
                        text={"Opret Reklamation"}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default ComplaintDialog;
