import * as React from "react";
import SearchPane from "../../base/SearchPane";
import API from "../../../api";
import {
    IDropdownOption,
    TextField,
    PrimaryButton,
    Stack,
    Checkbox,
    Dropdown
} from "@fluentui/react";

interface CustomerProps {
    location: MobileServiceLocation;
    updateServiceLocation: (
        location: MobileServiceLocation | undefined
    ) => void;
    departmentId: string;
}

const MobileServiceLocationRender: React.FunctionComponent<CustomerProps> = props => {
    const [addresses, setAddresses] = React.useState([] as string[]);
    const { covered, address } = props.location;
    const [useCustomAddress, setUseCustomAddress] = React.useState(false);

    const updateCorver = (
        e: React.SyntheticEvent<HTMLDivElement>,
        value: IDropdownOption
    ) => {
        props.updateServiceLocation({
            ...props.location,
            covered: value.key === "A"
        });
    };

    const onAddressChange = async (address: string) => {
        if (address) {
            const result = await API.AddressApi.addressLookup(
                address +
                    (address.toLowerCase().indexOf("denmark") !== -1
                        ? ",Denmark"
                        : "")
            );
            setAddresses(result.map(x => x.description));
        } else {
            props.updateServiceLocation(undefined);
        }
    };

    const onUseCustomAddressChange = () => {
        setUseCustomAddress(!useCustomAddress);
    };

    const updateAddressFromCustomInput = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        props.updateServiceLocation(
            value ? { ...props.location, address: value } : undefined
        );
    };

    const onSetAddressValue = (address: string) =>
        props.updateServiceLocation({ ...props.location, address });

    const onShowMapView = async () => {
        const data = await API.DepartmentApi.getCoordinates(
            props.location.address
        );

        if (data) {
            window.open(
                `mobile-service-map?isCustomMapView=true&lat=${data.lat}&lng=${data.lng}&deptId=${props.departmentId}&addr=${props.location.address}`
            );
        }
    };

    const renderCustomAddressField = () => {
        if (useCustomAddress) {
            return (
                <TextField
                    placeholder="Indtast en adresse manuelt"
                    onChange={updateAddressFromCustomInput}
                    defaultValue={props.location.address}
                />
            );
        }
        return (
            <>
                <div className="search_panel__toolbar">
                    <SearchPane
                        placeholder="Adresse"
                        value={address}
                        items={addresses}
                        onValueChange={onAddressChange}
                        onValueSelected={onSetAddressValue}
                    />
                    <PrimaryButton
                        iconProps={{ iconName: "MapPin" }}
                        disabled={address === ""}
                        onClick={onShowMapView}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            <div className="styled_customer__stack">
                <Stack horizontal tokens={{ childrenGap: 50 }}>
                    <Stack.Item grow={1}>
                        <h5>Mobil Service location</h5>
                        <Stack.Item grow={1}>
                            {renderCustomAddressField()}
                        </Stack.Item>
                        <Stack.Item grow={1}>
                            <Checkbox
                                label="Adressen kan ikke findes"
                                defaultChecked={useCustomAddress}
                                onChange={onUseCustomAddressChange}
                            />
                        </Stack.Item>
                        <Stack.Item grow={1}>
                            <Dropdown
                                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                label="Er Lokation Overdækket?"
                                options={[
                                    { key: "A", text: "Ja" },
                                    { key: "B", text: "Nej" }
                                ]}
                                selectedKey={covered ? "A" : "B"}
                                onChange={updateCorver}
                            />
                        </Stack.Item>
                        <br />
                    </Stack.Item>
                </Stack>
            </div>
        </>
    );
};

export default MobileServiceLocationRender;
