import {
    DefaultButton,
    Dropdown,
    IColumn,
    IDropdownOption,
    Label,
    PrimaryButton,
    Stack,
    Link
} from "@fluentui/react";
import TableHelper from "../utils/TableHelper";
import api from "../../api";
import { useEffect } from "react";
import * as React from "react";
import CustomDatePicker from "../base/CustomDatePicker";
import CustomSlotsTable from "./CustomSlotsTable";
import "./style.css";
import NavigationHelper from "../utils/NavigationHelper";

const dataFetchIntervalMinutes = 60000 * 15;

const complaintTypeOptions: IDropdownOption[] = [
    { key: "", text: "" },
    { key: "Fyn", text: "Fyn" },
    { key: "Jylland", text: "Jylland" },
    { key: "Sjaelland", text: "Sjælland" }
];

const AvailableSlots: React.FunctionComponent = () => {
    const [julandDepartments, setJulandDepartments] = React.useState<
        DepartmentAvailableSlots[]
    >([]);
    const [fynDepartments, setFynDepartmens] = React.useState<
        DepartmentAvailableSlots[]
    >([]);
    const [sjaellandDepartments, setSjaellandDepartments] = React.useState<
        DepartmentAvailableSlots[]
    >([]);
    const [startingDate, setStartingDate] = React.useState<Date | undefined>();
    const [region, setRegion] = React.useState<string>("");

    useEffect(() => {
        const getAllData = async () => {
            api.AvailableSlotsApi.getAllData().then(data => {
                setJulandDepartments(data.julandDepartments);
                setFynDepartmens(data.fynDepartmens);
                setSjaellandDepartments(data.sjaellandDepartments);
            });
        
        };

        getAllData();

        const interval = setInterval(() => {
            getAllData();
        }, dataFetchIntervalMinutes);

        return () => clearInterval(interval);
    }, []);

    const getFormattedDate = (stringDate: string) => {
        const date = new Date(stringDate);
        const day = date.getDate();

        return `${day}.`;
    };

    const generateColumn = (
        slot: AvailableSlots,
        index: number,
        isStoneChipColumn: boolean
    ) => {
        return TableHelper.col(
            `slot${index}`,
            getFormattedDate(slot.date),
            70,
            80,
            (e: DepartmentAvailableSlots) => (
                <p className="textStyle">
                    {isStoneChipColumn
                        ? e.availableSlots[index].stoneChipAvailableSlots
                        : e.availableSlots[index]
                            .windowReplacementAvailableSlots}
                </p>
            )
        );
    };

    const getColumnsStoneChips = (
        deptAvailbeleSlots: DepartmentAvailableSlots[]
    ): IColumn[] => {
        const columns: IColumn[] = [];

        if (
            deptAvailbeleSlots.length > 0 &&
            deptAvailbeleSlots[0].availableSlots.length === 7
        ) {
            for (let i = 0; i < 7; i++) {
                columns.push(
                    generateColumn(
                        deptAvailbeleSlots[0].availableSlots[i],
                        i,
                        true
                    )
                );
            }
        }

        if (
            deptAvailbeleSlots.length > 0 &&
            deptAvailbeleSlots[0].availableSlots.length === 1
        ) {
            columns.push(
                generateColumn(deptAvailbeleSlots[0].availableSlots[0], 0, true)
            );
        }

        return columns;
    };

    const getColumnsWindowReplacemet = (
        deptAvailbeleSlots: DepartmentAvailableSlots[]
    ): IColumn[] => {
        const columns: IColumn[] = [];

        if (
            deptAvailbeleSlots.length > 0 &&
            deptAvailbeleSlots[0].availableSlots.length === 7
        ) {
            for (let i = 0; i < 7; i++) {
                columns.push(
                    generateColumn(
                        deptAvailbeleSlots[0].availableSlots[i],
                        i,
                        false
                    )
                );
            }
        }

        if (
            deptAvailbeleSlots.length > 0 &&
            deptAvailbeleSlots[0].availableSlots.length === 1
        ) {
            columns.push(
                generateColumn(
                    deptAvailbeleSlots[0].availableSlots[0],
                    0,
                    false
                )
            );
        }

        return columns;
    };

    const onRegionChange = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        setRegion(option.key as string);
    };

    const onDateChange = (date: Date | undefined) => {
        setStartingDate(date);
    };

    const Search = async () => {
        const result = await api.AvailableSlotsApi.getDataByRegion(
            startingDate,
            region
        );
        let displayData = result.data.filter(
            dept =>
                dept.availableSlots[0].stoneChipAvailableSlots !== 0 ||
                dept.availableSlots[0].windowReplacementAvailableSlots !== 0
        );

        if (!displayData || displayData.length === 0) {
            displayData = [
                {
                    name: "Ingen afdelinger blev fundet",
                    availableSlots: [
                        {
                            date: startingDate
                                ? startingDate.toISOString()
                                : "",
                            stoneChipAvailableSlots: 0,
                            windowReplacementAvailableSlots: 0,
                            closed: false
                        }
                    ],
                    zipCode: 0
                }
            ];
        }

        switch (region) {
            case "Jylland":
                setJulandDepartments(displayData);
                break;
            case "Sjaelland":
                setSjaellandDepartments(displayData);
                break;
            case "Fyn":
                setFynDepartmens(displayData);
                break;
        }
    };

    const Clear = () => {
        api.AvailableSlotsApi.getAllData().then(data => {
            setSjaellandDepartments(data.sjaellandDepartments);
            setFynDepartmens(data.fynDepartmens);
            setJulandDepartments(data.julandDepartments);
            setRegion("");
            setStartingDate(new Date());
        });
    };

    const navigateToPage = (location: RoutingType) => (
    ) => {
        const shouldNavigate = window.confirm(
            "Du er ved at navigerer til en anden side. \nAlt data som ikke er gemt vil blive slettet."
        );

        if (shouldNavigate) {
            const url = NavigationHelper.getRoute(location);
            const newTab = window.open(url, "_blank");
            if (newTab) {
                newTab.focus();
            }
        }
    }

    return (
        <>
            <h1>Ledige slots</h1>
            <div className="divStyle">
                <Stack
                    className="filter__menu"
                    horizontal
                    tokens={{ childrenGap: 5 }}
                    horizontalAlign="start"
                >
                    <div className="filter-buttons">
                        <Stack.Item>
                            <Label>Region</Label>
                            <Dropdown
                                calloutProps={{ calloutMaxHeight: 250, calloutMinWidth: 100 }}
                                placeholder="Region"
                                options={complaintTypeOptions}
                                onChange={onRegionChange}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Label>Dato</Label>
                            <CustomDatePicker
                                date={startingDate}
                                short={true}
                                onDateChange={onDateChange}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <PrimaryButton
                                target="_blank"
                                text="Søg"
                                onClick={Search}
                                disabled={!region}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton
                                target="_blank"
                                text="Tilbage"
                                onClick={Clear}
                                disabled={region === ""}
                            />
                        </Stack.Item>
                    </div>
                    <div className="navigation-buttons">
                        <Link onClick={navigateToPage("AVAILABLE_SLOTS_JUTLAND")}>
                            <Stack.Item>
                                <DefaultButton
                                    target="_blank"
                                    text="Jylland"
                                />
                            </Stack.Item>
                        </Link>
                        <Link onClick={navigateToPage("AVAILABLE_SLOTS_FYN_SJAELLAND")}>
                            <Stack.Item>
                                <DefaultButton
                                    target="_blank"
                                    text="Fyn og Sjælland"
                                />
                            </Stack.Item>
                        </Link>
                    </div>
                </Stack >
            </div >

            <div hidden={region !== "Jylland" && region !== ""}>
                <CustomSlotsTable
                    regionName="Jylland"
                    items={julandDepartments}
                    stoneChipsColumns={getColumnsStoneChips(julandDepartments)}
                    windowReplacementColumns={getColumnsWindowReplacemet(
                        julandDepartments
                    )}

                />
            </div>

            <div hidden={region !== "Sjaelland" && region !== ""}>
                <CustomSlotsTable
                    regionName="Sjaelland"
                    items={sjaellandDepartments}
                    stoneChipsColumns={getColumnsStoneChips(
                        sjaellandDepartments
                    )}
                    windowReplacementColumns={getColumnsWindowReplacemet(
                        sjaellandDepartments
                    )}

                />
            </div>
            <div hidden={region !== "Fyn" && region !== ""}>
                <CustomSlotsTable
                    regionName="Fyn"
                    items={fynDepartments}
                    stoneChipsColumns={getColumnsStoneChips(fynDepartments)}
                    windowReplacementColumns={getColumnsWindowReplacemet(
                        fynDepartments
                    )}
                />
            </div>
        </>
    );
};

export default AvailableSlots;