import api from "../../api";
import { Action, Dispatch } from "redux";
import { addMessageStatus } from "../ui/action";
import { MessageBarType } from "@fluentui/react";
import { updateMobileServiceProduct, updateTimeAndPlace } from "../order/action";
import { getDepartmentRequestStatus } from "../department/action";

export interface updateTimeslotsAction extends Action {
    month: number;
    year: number;
    type: "UPDATE_AVAILABLE_TIMESLOTS";
    timeslots: AvailableDepartmentBooking[];
}

export interface zipCodeAction extends Action {
    type: "ZIPCODE_DEPARTMENT";
    zipCodeDepartments: ZipCodeDepartmentAvailability[] | undefined;
}



export const getTimeslots = (filter: TimeSlotSearchFilter) => async (dispatch: Dispatch) => {
    try {
        if(!getDepartmentRequestStatus)
        {
            const timeslots = await api.DepartmentApi.getReservedTimeSlots(filter);
            const action: updateTimeslotsAction = { type: "UPDATE_AVAILABLE_TIMESLOTS", timeslots, year: filter.year, month: filter.month };
            dispatch(action);
        }
       
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "get timeslots" failed`,
            dismissTimer: 5000
        };
        addMessageStatus(statusMessage)(dispatch);
        throw ex;
    }
};

export const getZipCode = (zipcode: string, departments: Department[], filter: TimeSlotSearchFilter, debitorNo: string, type: AssignmentType) => async (dispatch: Dispatch) => {
 
    try {
        let action: zipCodeAction = { type: "ZIPCODE_DEPARTMENT", zipCodeDepartments: undefined };
        if(zipcode)
        {
            const zipCodeDepartments = await api.ZipCodeDepartmentApi.zipCodeSearch(zipcode);
            action.zipCodeDepartments = zipCodeDepartments;
            if (zipCodeDepartments.length > 0)
            {
                var department = departments.find(x=>x.id ===  zipCodeDepartments[0].departmentId);
                if(department && department.id)
                {
                    filter.departmentId = department.id;
                    getTimeslots(filter)(dispatch);
                    dispatch(updateTimeAndPlace({department}));
                    updateMobileServiceProduct(department.mobileService === true, debitorNo, type)(dispatch);
                    
                 
                
                }
            }
        }
        dispatch(action);

      
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "zipCodeSearch" failed`,
            dismissTimer: 5000
        };
        addMessageStatus(statusMessage)(dispatch);
    }
};


export type Actions = updateTimeslotsAction | zipCodeAction;