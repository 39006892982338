import * as dayjs from "dayjs";

export default <T>(givenObject: any, value: any, property: string): T =>
{
    const result = givenObject || {} as T;

    switch (property)
    {
        case "day":
            // Map string to int
            givenObject[property] = parseInt(value.key);
            break;
        case "date":
            // Map Date to Date
            givenObject[property] = dayjs(value).toDate();
            givenObject["day"] = dayjs(value).toDate().getDay();
            break;
        case "from":
        case "to":
            // Map string to float
            const today = dayjs().startOf("day");
            const valueInDate = dayjs(`${today.format("YYYY-MM-DD")}T${value}`);
            givenObject[property] = valueInDate.diff(today, "hour", true);
            break;
        case "maxBookings":
        case "stoneChipAlternativeWinReplace":
            // Map string to int
            givenObject[property] = parseInt(value);
            break;
        case "reperationTime":
            // Map string to int
            givenObject[property] = parseFloat(value);
            break;
        case "hasFreeCar":
            givenObject[property] = value; 
    }

    return result;
};