import {
    IColumn,
    Stack,
    Label,
    Dropdown,
    TextField,
    DetailsList,
    CheckboxVisibility,
    Checkbox,
    IDropdownOption
} from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { RootState } from "~/state";
import { getDepartments } from "../../state/action";
import SearchButton from "../base/Search/SearchButton";
import PageHeader from "../layout/PageHeader";
import TableHelper from "../utils/TableHelper";
import { useCallback, useEffect } from "react";
import { getZipCodeDepartmentAvailabilityList } from "../../state/action";
import {
    searchByDepartmentId,
    searchZipCode,
    updateZipCodeDepartment
} from "../../state/zipCode/action";
import "./style.css";

const ZipcodeAvailability = (props: StateProps) => {
    const [departments, updateDepartments] = React.useState<IDropdownOption[]>(
        []
    );
    const [search, updateSearch] = React.useState("");

    useEffect(() => {
        const depts = props.departments
            .filter(x => x.mobileService)
            .reduce((pev: IDropdownOption[], value) => {
                if (value.id) {
                    const department: IDropdownOption = {
                        text: value.name,
                        key: value.id
                    };
                    return [...pev, department];
                }
                return pev;
            }, [] as IDropdownOption[]);
        updateDepartments(depts);
    }, [props.departments]);

    const updateZipCodeDepartmentWithOption = useCallback(
        (zcda: ZipCodeDepartmentAvailability) => (
            event: React.FormEvent<HTMLDivElement>,
            option: IDropdownOption | undefined
        ) => {
            if (option) {
                const department = props.departments.find(
                    x => x.id === option.key
                );
                if (department && department.id) {
                    props.updateZipCodeDepartment({
                        ...zcda,
                        navResponsibilityCenter:
                            department.navResponsibilityCenter,
                        departmentId: department.id
                    });
                }
            }
        },
        [props.departments]
    );

    const updateZipCodeDepartment = useCallback(
        (zcda: ZipCodeDepartmentAvailability) => (
            event: React.FormEvent<HTMLDivElement>
        ) => {
            props.updateZipCodeDepartment(zcda);
        },
        []
    );

    const columns: IColumn[] = [
        TableHelper.col(
            "zipcode",
            "Post nr.",
            40,
            40,
            (e: ZipCodeDepartmentAvailability) => <p>{e.zipCode}</p>
        ),
        TableHelper.col(
            "location",
            "By",
            40,
            40,
            (e: ZipCodeDepartmentAvailability) => <p>{e.city}</p>
        ),
        TableHelper.col(
            "department",
            "Afdeling",
            150,
            150,
            (e: ZipCodeDepartmentAvailability) => {
                return (
                    <Dropdown
                        calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                        dropdownWidth={200}
                        placeholder=""
                        selectedKey={e.departmentId}
                        options={departments}
                        onChange={updateZipCodeDepartmentWithOption(e)}
                    />
                );
            }
        ),
        TableHelper.col(
            "monday",
            "Mandag",
            60,
            60,
            (e: ZipCodeDepartmentAvailability) => (
                <>
                    <span className="mobile-header">Mandag</span>
                    <Checkbox
                        checked={e.monday}
                        onChange={updateZipCodeDepartment({
                            ...e,
                            monday: !e.monday
                        })}
                    />
                </>
            )
        ),
        TableHelper.col(
            "tuesday",
            "Tirsdag",
            60,
            60,
            (e: ZipCodeDepartmentAvailability) => (
                <>
                    <span className="mobile-header">Tirsdag</span>
                    <Checkbox
                        checked={e.tuesday}
                        onChange={updateZipCodeDepartment({
                            ...e,
                            tuesday: !e.tuesday
                        })}
                    />
                </>
            )
        ),
        TableHelper.col(
            "wednesday",
            "Onsdag",
            60,
            60,
            (e: ZipCodeDepartmentAvailability) => (
                <>
                    <span className="mobile-header">Onsdag</span>
                    <Checkbox
                        checked={e.wednesday}
                        onChange={updateZipCodeDepartment({
                            ...e,
                            wednesday: !e.wednesday
                        })}
                    />
                </>
            )
        ),
        TableHelper.col(
            "thursday",
            "Torsdag",
            60,
            60,
            (e: ZipCodeDepartmentAvailability) => (
                <>
                    <span className="mobile-header">Torsdag</span>
                    <Checkbox
                        checked={e.thursday}
                        onChange={updateZipCodeDepartment({
                            ...e,
                            thursday: !e.thursday
                        })}
                    />
                </>
            )
        ),
        TableHelper.col(
            "friday",
            "Fredag",
            60,
            60,
            (e: ZipCodeDepartmentAvailability) => (
                <>
                    <span className="mobile-header">Fredag</span>
                    <Checkbox
                        checked={e.friday}
                        onChange={updateZipCodeDepartment({
                            ...e,
                            friday: !e.friday
                        })}
                    />
                </>
            )
        ),
        TableHelper.col(
            "saturday",
            "Lørdag",
            60,
            60,
            (e: ZipCodeDepartmentAvailability) => (
                <>
                    <span className="mobile-header">Lørdag</span>
                    <Checkbox
                        checked={e.saturday}
                        onChange={updateZipCodeDepartment({
                            ...e,
                            saturday: !e.saturday
                        })}
                    />
                </>
            )
        ),
        TableHelper.col(
            "sunday",
            "Søndag",
            60,
            60,
            (e: ZipCodeDepartmentAvailability) => (
                <>
                    <span className="mobile-header">Søndag</span>
                    <Checkbox
                        checked={e.sunday}
                        onChange={updateZipCodeDepartment({
                            ...e,
                            sunday: !e.sunday
                        })}
                    />
                </>
            )
        ),
        TableHelper.col(
            "active",
            "Aktiv",
            60,
            60,
            (e: ZipCodeDepartmentAvailability) => (
                <>
                    <span className="mobile-header">Aktiv</span>
                    <Checkbox
                        checked={e.isActive}
                        onChange={updateZipCodeDepartment({
                            ...e,
                            isActive: !e.isActive
                        })}
                    />
                </>
            )
        )
    ];

    const searchIt = useCallback(
        () => () => {
            props.searchZipCode(search);
        },
        [search]
    );

    const searchByDepartmentId = useCallback(
        () => (
            event: React.FormEvent<HTMLDivElement>,
            option: IDropdownOption | undefined
        ) => {
            if (option) {
                props.searchByDepartmentId(option.key.toString());
            }
        },
        []
    );

    const onTextChnage = useCallback(
        () => (
            e: React.FormEvent<HTMLDivElement | HTMLTextAreaElement>,
            value?: string | undefined
        ) => {
            value ? updateSearch(value) : updateSearch("");
        },
        []
    );

    const searchOnEnter = useCallback(
        () => (
            e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => {
            if (e.key === "Enter") {
                searchIt();
            }
        },
        []
    );

    return (
        <div>
            <PageHeader
                currentPage={"Postnummer styring"}
                noBreadcrumb={true}
            />
            <div>
                <Stack
                    className="filter__menu"
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign="end"
                    verticalAlign="end"
                >
                    <Stack.Item>
                        <Label>Lokation</Label>
                        <Dropdown
                            calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                            dropdownWidth={200}
                            placeholder="Vælg afdeling"
                            options={departments}
                            onChange={searchByDepartmentId()}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Post nr.</Label>
                        <TextField
                            onChange={onTextChnage()}
                            onKeyUp={searchOnEnter()}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <SearchButton type={"Søg"} onClick={searchIt()} />
                    </Stack.Item>
                </Stack>
            </div>

            <div className="details_list__container">
                <DetailsList
                    className="zipcode-list"
                    columns={columns}
                    items={props.available}
                    checkboxVisibility={CheckboxVisibility.hidden}
                />
            </div>
            {props.available.length === 0 ? (
                <p style={{ textAlign: "center", marginTop: "20px" }}>
                    Vi ikke tilbyder mobilservice i det pågældende område.{" "}
                </p>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        departments: getDepartments(state),
        available: getZipCodeDepartmentAvailabilityList(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    searchZipCode: (zipCode: string) => dispatch<any>(searchZipCode(zipCode)),
    searchByDepartmentId: (departmentId: string) =>
        dispatch<any>(searchByDepartmentId(departmentId)),
    updateZipCodeDepartment: (department: ZipCodeDepartmentAvailability) =>
        dispatch<any>(updateZipCodeDepartment(department))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(
    mapStateToProps,
    mapToDispatchProps
)(ZipcodeAvailability);
