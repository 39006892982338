import { IColumn } from "@fluentui/react";

export default class TableHelper {
    public static col(
        col: string,
        name: string,
        minWidth: number,
        maxWidth: number,
        render?: (item?: any, index?: number, column?: IColumn) => any,
        className?: string,
        onColumnClick?: (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => void,
        isSorted?: boolean,
        isSortedDescending?: boolean,
    ) {
        return {
            key: col,
            name,
            fieldName: col,
            minWidth,
            maxWidth,
            onRender: render,
            className,
            onColumnClick,
            isSorted,
            isSortedDescending,
            sortDescendingAriaLabel: "SortDown"
            
        } as IColumn;
    }
}