export const findUserDepartment = (
    user: UserInformation,
    departments: Department[]
) => {
    let userDepartment = departments.find(
        x => x.id === user.calendarDepartmentId || x.id === user.departmentId
    );
    if (!userDepartment) {
        userDepartment = departments.find(
            x => x.id === user.departmentGuid
        );
    }
    return userDepartment;
};
