import BaseApi from "./baseApi";

interface ISmsApi {
    sendMessage: (model: NotificationModel) => Promise<NotificationStatus>;
}

export default class SmsApi extends BaseApi implements ISmsApi {
    async sendMessage(model: NotificationModel): Promise<NotificationStatus> {
        const url = `/api/sms/send`;
        return await this.fetchPost(url, model);
    }
}
