import {
    BaseButton,
    Checkbox,
    DatePicker,
    DayOfWeek,
    DefaultButton,
    Dropdown,
    Icon,
    Label,
    Stack,
    StackItem,
    TextField
} from "@fluentui/react";
import * as React from "react";
import convertFloatToTime from "./helpers/convertFloatToTime";
import convertStringDateToDate from "./helpers/convertStringDateToDate";
import mapPropertyValue from "./helpers/mapPropertyValue";
import openingHoursDayDropdown from "./helpers/openingHoursDayDropdown";
import { FormEvent } from "react";
import "./style.css";
import TimeslotCopy from "./TimeslotCopy";
import CreateNewTimeslot from "./CreateNewTimeslot";
import { getCurrentBookingCount } from "./helpers/alreadyUsedTimeslotHelper";

interface DepartmentTimeSlotsProps {
    timeSlots: DamageSpecificTimeSlot[];
    alreadyUsedTimeslot?: AlreadyUsedTimeslot;
    departmentId?: string;
    propertyName: string;
    enableDate: boolean;
    pageTitle: string;
    deleteTimeslot: (type: string, timeslotId: string) => void;
    addOrUpdateTimeslot: (type: string, timeslot: DamageSpecificTimeSlot) => void;
}

const DepartmentTimeSlots: React.FC<DepartmentTimeSlotsProps> = (
    props
): JSX.Element => {
    
    const [showCreateForm, setShowCreateForm] = React.useState<boolean>(false);
    const [showCopyTimeslot, setShowCopyTimeslot] = React.useState<boolean>(false);
    const [lock, setLock] = React.useState<boolean>(true);

    const toggleLock = () => setLock(!lock);
    const toggleCopyTimeslot = () => setShowCopyTimeslot(!showCopyTimeslot);
    const [isWindowReplaceTimeSlots] = React.useState<boolean>(
        props.propertyName === "windowReplaceTimeSlots" ||
            props.propertyName === "specificWindowReplaceTimeSlots"
    );
    let timeslotValue:any =0;
    const onTimeSlotChanged = React.useCallback((value: any, property: string, id?: string) => {
        clearTimeout(timeslotValue);
        timeslotValue = setTimeout(() => {
            const { timeSlots, propertyName } = props;
            let row =timeSlots.find((value) => value.id === id);;;
            row = mapPropertyValue<DamageSpecificTimeSlot>(
                row,
                value,
                property
            );
            props.addOrUpdateTimeslot(propertyName, row);
        }, 500);
           
    }, [props.timeSlots]);

    const onTimeSlotChecked = React.useCallback((e: FormEvent<HTMLInputElement | HTMLElement> | undefined, value: boolean, id?: string) => {
            const { timeSlots, propertyName } = props;
            let row = timeSlots.find((value) => value.id === id);;
           
            row = mapPropertyValue<DamageSpecificTimeSlot>(
                row,
                value,
                "hasFreeCar"
            );

            if (value) {
                row = mapPropertyValue<DamageSpecificTimeSlot>(
                    row,
                    1,
                    "maxBookings"
                );
            }
         
            props.addOrUpdateTimeslot(propertyName, row);
           
        },
        [props.timeSlots]
    );

    const onDeleteTimeSlot = React.useCallback( (id?: string) => {
            if (confirm("Du er ved at slette en standard åbningstid.\nØnsker du at udføre handlingen?")) {
                const { timeSlots, propertyName } = props;
                const result = timeSlots.find((value) => value.id === id);
                if(result && result.id)
                {
                    props.deleteTimeslot(propertyName, result.id);
                }
              
               
            }
        },
        [props.timeSlots]
    );
    const renderTimeSlots = () => {
        const { timeSlots } = props;
        if (Array.isArray(timeSlots)) 
        {
            const tempTimeslots =[...timeSlots];
            var sortedTimeslots = tempTimeslots.sort((a, b) => {
                    if (props.enableDate && a.date && b.date) {
                        return (
                            (convertStringDateToDate(
                                a.date
                            ) as Date).getTime() -
                            (convertStringDateToDate(b.date) as Date).getTime()
                        );
                    }
                    const value = a.day - b.day;
                    return value;
            });
            return sortedTimeslots.map((entry: DamageSpecificTimeSlot, index: number) => {
                const currentBookingCount = getCurrentBookingCount(entry, props.alreadyUsedTimeslot);
                const disableEditDate = lock ? currentBookingCount > 0: false;
                return (
                    <Stack className={currentBookingCount > 0 ? "containsBookings" : ""}  key={`doh_${entry.id}`} horizontal tokens={{ padding: 5, childrenGap: 10 }} >
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            {props.enableDate ? (
                                <DatePicker
                                    disabled={disableEditDate}
                                    className="date_picker_department__container"
                                    placeholder="Vælg dato"
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    value={convertStringDateToDate(
                                        entry.date
                                    )}
                                    onSelectDate={newDate =>
                                        onTimeSlotChanged(
                                            newDate,
                                            "date",
                                            entry.id
                                        )
                                    }
                                />
                            ) : (
                                <Dropdown
                                    disabled={disableEditDate}
                                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                    placeholder="Vælg dag"
                                    selectedKey={entry.day}
                                    options={openingHoursDayDropdown(
                                        entry.day
                                    )}
                                    onChange={(event, value) =>
                                        onTimeSlotChanged(
                                            value,
                                            "day",
                                            entry.id
                                        )
                                    }
                                />
                            )}
                        </StackItem>
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            <TextField
                                disabled={disableEditDate}
                                prefix="Fra"
                                type="time"
                                defaultValue={convertFloatToTime(entry.from)}
                                onChange={(event, value) =>
                                    onTimeSlotChanged(value, "from", entry.id)
                                }
                            />
                        </StackItem>
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            <TextField
                                disabled={disableEditDate}
                                prefix="Til"
                                type="time"
                                defaultValue={convertFloatToTime(entry.to)}
                                onChange={(event, value) =>
                                    onTimeSlotChanged(value, "to", entry.id)
                                }
                            />
                        </StackItem>
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            <TextField
                                disabled={entry.hasFreeCar}
                                prefix="Antal"
                                type="number"
                                min={currentBookingCount}
                                defaultValue={entry.maxBookings.toString()}
                                onChange={(event, value) =>
                                    onTimeSlotChanged(
                                        value,
                                        "maxBookings",
                                        entry.id
                                    )
                                }
                            />
                        </StackItem>
                        <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                            <TextField
                                prefix="Timer"
                                type="number"
                                step="0.5"
                                defaultValue={entry.reperationTime.toString()}
                                onChange={(event, value) =>
                                    onTimeSlotChanged(
                                        value,
                                        "reperationTime",
                                        entry.id
                                    )
                                }
                            />
                        </StackItem>
                        {isWindowReplaceTimeSlots && (
                            <>
                                <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                    <TextField
                                        prefix="Antal"
                                        type="number"
                                        defaultValue={entry.stoneChipAlternativeWinReplace.toString()}
                                        onChange={(event, value) =>
                                            onTimeSlotChanged(
                                                value,
                                                "stoneChipAlternativeWinReplace",
                                                entry.id
                                            )
                                        }
                                    />
                                </StackItem>
                                <StackItem
                                    className="departmentStackItem hasFreeCar_checkbox"
                                    grow={false}
                                    disableShrink={true}
                                >
                                    <Checkbox
                                        defaultChecked={
                                            entry.hasFreeCar
                                                ? entry.hasFreeCar
                                                : false
                                        }
                                        onChange={event =>
                                            onTimeSlotChecked(
                                                event,
                                                !entry.hasFreeCar,
                                                entry.id
                                            )
                                        }
                                    />
                                </StackItem>
                            </>
                        )}
                        <StackItem className="departmentStackItem" grow={false} disableShrink={true} >
                            <BaseButton
                                disabled={disableEditDate}
                                className="departmentDeleteButton"
                                onClick={() => onDeleteTimeSlot(entry.id)}
                                title={currentBookingCount > 0? "Der er bookings tilknyttet dette tidslot og det kan derfor ikke slettet": ""}
                            >
                                <Icon iconName="Delete" />
                            </BaseButton>
                        </StackItem>
                    </Stack>
                );
            });
        }
    };
    return (
        <div className="custom_container">
        <div className="header__container header__container__timeslot">
          <h5>{props.pageTitle} </h5>
            {props.enableDate && <DefaultButton onClick={toggleCopyTimeslot} iconProps={{iconName : "Add"}}>Undtagelse</DefaultButton>}
        </div>
        <hr />
            {props.enableDate && showCopyTimeslot && <><TimeslotCopy timeslotType={props.propertyName} departmentId={props.departmentId} /><hr /></>}
            <Stack
                horizontal
                tokens={{ padding: 5, childrenGap: 7 }}
                className="departmentStackItem_header"
            >
               
                <StackItem
                    className="departmentStackItem"
                    grow={true}
                    disableShrink={true}
                >
                    {props.enableDate ? (
                        <Label>Dato</Label>
                    ) : (
                        <Label>Dag</Label>
                    )}
                </StackItem>
                <StackItem
                    className="departmentStackItem"
                    grow={true}
                    disableShrink={true}
                >
                    <Label>Tid fra</Label>
                </StackItem>
                <StackItem
                    className="departmentStackItem"
                    grow={true}
                    disableShrink={true}
                >
                    <Label>Tid til</Label>
                </StackItem>
                <StackItem
                    className="departmentStackItem"
                    grow={true}
                    disableShrink={true}
                >
                    <Label>Max. bookings</Label>
                </StackItem>
                <StackItem
                    className="departmentStackItem"
                    grow={true}
                    disableShrink={true}
                >
                    <Label>Reperations tid</Label>
                </StackItem>
                {isWindowReplaceTimeSlots && (
                    <>
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            <Label>Konverteringsværdi</Label>
                        </StackItem>
                        <StackItem
                            className="departmentStackItem"
                            grow={false}
                            disableShrink={false}
                        >
                            <Label className="hasFreeCar_checkboxTitle">
                                bil?
                            </Label>
                        </StackItem>
                    </>
                )}
                 <StackItem
                    className="departmentStackItem"
                    grow={false}
                    disableShrink={false}
                >
                        <Icon className="department-lock-icon" onClick={toggleLock} iconName={lock? "Lock": "UnLock"} />
                </StackItem>
                       
            </Stack>

            {renderTimeSlots()}

            {!showCreateForm && (
                <Stack tokens={{ padding: 5, childrenGap: 10 }}>
                    <DefaultButton onClick={() => setShowCreateForm(true)}>
                        Tilføj ekstra Tidsslot
                    </DefaultButton>
                </Stack>
            )}
            {showCreateForm && <CreateNewTimeslot 
                enableDate={props.enableDate} 
                addOrUpdateTimeslot={(timeslot) => props.addOrUpdateTimeslot(props.propertyName, timeslot)} 
                isWindowReplaceTimeSlots={isWindowReplaceTimeSlots} />}
        </div>
    );
};
    

export default DepartmentTimeSlots;
