import { TooltipHost } from "@fluentui/react";
import * as React from "react";

interface Props {
    product: Product;
}
const ProductColumn: React.FunctionComponent<Props> = props => {
    const product = props.product;

    const content = (
        <>
            <label>Varenummer</label>
            <p style={{ userSelect: "all" }}>{product.no}</p>
        </>
    );
    if (
        product.material ||
        product.oEnumber ||
        product.euroCode ||
        product.description2 ||
        product.description3 ||
        product.description4
    ) {
        const toolTip = (
            <div>
                {product.isVINitem && product.oEnumber ? (
                    <p>OE number: {product.oEnumber}</p>
                ) : null}
                {product.isVINitem && product.material ? (
                    <p>Material: {product.material}</p>
                ) : null}
                {product.euroCode ? <p>Euro code: {product.euroCode}</p> : null}
                {product.description2 ? (
                    <p>Description2: {product.description2}</p>
                ) : null}
                {product.description3 ? (
                    <p>Description3: {product.description3}</p>
                ) : null}
                {product.description4 ? (
                    <p>Description4: {product.description4}</p>
                ) : null}
            </div>
        );

        return (
            <TooltipHost
                content={toolTip}
                id={"tip0"}
                calloutProps={{ gapSpace: 0 }}
            >
                {content}
            </TooltipHost>
        );
    }
    return content;
};
export default ProductColumn;
