import * as React from "react";
import "./style.css";

interface Props {
    regionName: string;
    stoneChipsColumns: unknown; 
    windowReplacementColumns: unknown; 
    items: DepartmentAvailableSlots[];
}

function getCurrentYearAndMonth() {
    const currentDate = new Date();
    const options = { year: "numeric", month: "short" } as any;
    return currentDate.toLocaleString('da-DK', options);
}

function CustomSlotsTable(props: Props) {
    const renderTable = () => {
        if (
            props.items &&
            props.regionName
        ) {
            return (
                <>
                    <div className="table">
                        <div className="table-row header">
                            <div className="table-header">Afdelinger  <span className="region-name">{props.regionName}</span></div>
                            <div className="table-header">Montering</div>
                            <div className="table-header">Reparationer</div>
                        </div>

                        <div className="table-row dates-wrapper">
                            <div className="table-data current-date">{getCurrentYearAndMonth()}</div>
                            <div className="table-data dates">
                                {Array.isArray(props.windowReplacementColumns) && props.windowReplacementColumns.map((column: any, columnIndex: number) => (
                                    <span className="table-date" key={columnIndex}>
                                        {column.name}
                                    </span>
                                ))}
                            </div>
                            <div className="table-data dates">
                                {Array.isArray(props.stoneChipsColumns) && props.stoneChipsColumns.map((column: any, columnIndex: number) => (
                                    <span className="table-date" key={columnIndex}>
                                        {column.name}
                                    </span>
                                ))}
                            </div>
                        </div>

                        {Array.isArray(props.items) && props.items.map((item, index) => (
                            <div key={index} className="table-row">
                                <div className="table-data">{item.name}</div>
                                <div className="table-data slots stone-chip-slot">
                                    {Array.isArray(item.availableSlots) && item.availableSlots.map((slot, slotIndex) => (
                                        <span className={`${slot.windowReplacementAvailableSlots === 0 ? "empty-slot" : ""} ${slot.closed ? "closed-department" : ""}`} key={slotIndex}>
                                            {slot.closed ? "" : slot.windowReplacementAvailableSlots}
                                        </span>
                                    ))}
                                </div>
                                <div className="table-data slots ">
                                    {item.availableSlots.map((slot, slotIndex) => (
                                        <span className={`${slot.stoneChipAvailableSlots === 0 ? "empty-slot" : ""} ${slot.closed ? "closed-department" : ""}`} key={slotIndex}>
                                           {slot.closed ? "" : slot.stoneChipAvailableSlots}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        }
    };

    return <div className="availableSlots__table">{renderTable()}</div>;
}

export default CustomSlotsTable;