
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { useEffect } from "react";
import API from "../../api";
import './style.css'
import { Checkbox, TextField, PrimaryButton } from "@fluentui/react";

const CustomerSatisfaction: React.FunctionComponent<RouteComponentProps<{ orderId: string; score: string }>> = (props) =>
{
    const [posted, setPosted] = React.useState<boolean | undefined>(undefined);

    const [problemOnlineBooking, setProblemOnlineBooking] = React.useState<boolean>(false);
    const [problemCustomerservice, setProblemCustomerservice] = React.useState<boolean>(false);
    const [problemMountingJob, setProblemMountingJob] = React.useState<boolean>(false);
    const [problemOther, setProblemOther] = React.useState<boolean>(false);
    const [answerText, setAnswerText] = React.useState<string>("");
    const [contactCustomer, setContactCustomer] = React.useState<boolean>(false);
    const score = parseInt(props.match.params.score);
    const orderId = props.match.params.orderId;

    const postOrderSatisfaction = async () =>
    {
        setPosted(true);
        const orderSatisfaction: OrderSatisfaction = {
            problemOnlineBooking,
            problemCustomerservice,
            problemMountingJob,
            problemOther,
            answerText,
            contactCustomer,
            score
        };
        await API.OrderSatisfactionApi.addOrderSatisfaction(orderId, orderSatisfaction);

    };

    useEffect(() =>
    {
        (async () =>
        {
            const result = await API.OrderSatisfactionApi.getOrderSatisfaction(orderId);
            setPosted(result);
        })();
        if (score > 3 && orderId)
        {
            postOrderSatisfaction();
        }

    }, []);

    if (posted === undefined)
    {
        return null;
    }

    const handleSubmit = (event: React.FormEvent) =>
    {
        if (!posted)
        {
            event.preventDefault();
            postOrderSatisfaction();
        }

    };

    const customerUnsatisfiedForm = <form onSubmit={handleSubmit}>
        <h2>Hov, du var under middel tilfreds. Det er vi kede af. <br />Hvor gik det galt ?</h2>
        <Checkbox label="Bookingen på nettet."
            checked={problemOnlineBooking ? false : true}
            onChange={() => setProblemOnlineBooking(!problemOnlineBooking)} />
        <Checkbox label="Jeres kundeservice."
            checked={problemCustomerservice ? false : true}
            onChange={() => setProblemCustomerservice(!problemCustomerservice)} />
        <Checkbox label="Jeres montør."
            checked={problemMountingJob ? false : true}
            onChange={() => setProblemCustomerservice(!problemMountingJob)} />
        <Checkbox label="Andet."
            checked={problemOther ?  false : true}
            onChange={() => setProblemOther(!problemOther)} />
        <TextField label="Ønsker du at uddybe dit svar."
            multiline rows={4}
            value={answerText}
            onChange={(event: React.FormEvent, newValue: string) => setAnswerText(newValue)} />
        <Checkbox label="I må gerne kontakte mig for uddybende info"
            checked={contactCustomer ?  false : true}
            onChange={() => setContactCustomer(!contactCustomer)} />
        <PrimaryButton type="submit" text="Indsend svar" />
    </form >;

    const customerSatisfiedForm = <form onSubmit={handleSubmit}>
        <h2>Tusind tak for din feedback.</h2>
        <TextField label="Ønsker du at uddybe dit svar."
            multiline rows={4}
            value={answerText}
            onChange={(event: React.FormEvent, newValue: string) => setAnswerText(newValue)} />
        <Checkbox label="I må gerne kontakte mig for uddybende info"
            checked={contactCustomer ? false : true}
            onChange={() => setContactCustomer(!contactCustomer)} />
        <PrimaryButton type="submit" text="Indsend svar" />
    </form >;

    return (
        <div className="customer_satisfaction">
            {posted ? <h2>Tusind tak for din feedback.</h2> : score > 3 ? customerSatisfiedForm : customerUnsatisfiedForm}
        </div>
    );
};

export default CustomerSatisfaction;