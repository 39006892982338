import * as React from "react";
import ChooseCar from "./ChooseCar";
import CustomerLookup from "../../customer/DebitorLookup";
import NavigationHelper from "../../utils/NavigationHelper";
import OrderType from "../../fields/OrderType";
import { isModelValid, carFieldConfig } from "../../../util/validate";
import "../style.css"
import WhoIsPaying from "../WhoIsPaying";
import { Stack, PrimaryButton } from "@fluentui/react";

export interface Props {
    carManufactures: CarManufacture[];
    carManufactureModels?: CarManufactureModel[];
    order: Order;
    insurers: Insurer[];
    leasers: Leaser[];
    departments: Department[];
    car: Car;
    debitors?: Debitor[];
    updateCar: (car: Car) => void;
    setDebitor: (debitor: Debitor) => void;
    updateCarManufactureModels: (manuId: number) => void;
    updateCarFromReg: (reg: string) => void;
    updateCarId: (car: Car) => void;
    updateOrderType: (type: AssignmentType) => void;
    getDebitor: (query: string, type: ApiType) => void;
    whoIsPayingChange: (whoIsPaying: WhoIsPaying) => void;
    updateDebitorFromWhoIsPaying: (
        whoIsPaying: WhoIsPaying,
        order: Order,
        updatePrices: boolean
    ) => void;
    updateOrderDepartment: (
        department: Department,
        shouldUpdateDebitor: boolean
    ) => void;
}

const CreateAssignments: React.FunctionComponent<Props> = props => {
    const {
        order,
        carManufactures,
        carManufactureModels,
        updateCar,
        updateCarFromReg,
        updateCarManufactureModels,
        updateCarId,
        insurers,
        leasers
    } = props;

    const navigateToProducts = () => {
        NavigationHelper.pushRoute("PRODUCTS");
    };
    const disabled = !isModelValid(props.order.car, carFieldConfig);
    return (
        <div className="custom_container">
            <h2>Oprettelse af opgave*</h2>
            <div className="triple_column">
                <Stack horizontal tokens={{ childrenGap: 50 }}>
                    <Stack.Item grow={1}>
                        <h5>Type:</h5>
                        <OrderType
                            title="Odre type"
                            onChange={props.updateOrderType}
                            value={order.additionalData.orderType}
                        />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <CustomerLookup
                            getDebitor={props.getDebitor}
                            selectedDebitor={props.order.debitor}
                            debitors={props.debitors}
                            onDebitorChange={props.setDebitor}
                            whoIsPayingChange={props.whoIsPayingChange}
                        />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <ChooseCar
                            {...{
                                car: order.car,
                                carManufactures,
                                carManufactureModels,
                                updateCar,
                                updateCarFromReg,
                                updateCarManufactureModels,
                                updateCarId,
                                insurers,
                                leasers
                            }}
                        />
                        <WhoIsPaying
                            order={props.order}
                            departments={props.departments}
                            onDepartmentChange={props.updateOrderDepartment}
                            onWhoIsPayingChange={props.whoIsPayingChange}
                            updateDebitorFromWhoIsPaying={(whoIsPaying: WhoIsPaying, order: Order) =>props.updateDebitorFromWhoIsPaying(whoIsPaying, order, true)
                            }
                            car={props.car}
                            updateCar={props.updateCar}
                            insurers={props.insurers}
                            leasers={props.leasers}
                            debitors={props.debitors}
                            getDebitor={props.getDebitor}
                            onDebitorChange={props.setDebitor}
                            selectedDebitor={props.order.debitor}
                        />
                        <PrimaryButton
                            disabled={disabled}
                            text="Fortsæt"
                            onClick={navigateToProducts}
                        />
                    </Stack.Item>
                </Stack>
            </div>
        </div>
    );
};

export default CreateAssignments;
