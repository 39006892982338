import * as React from "react";
import { RootState } from "~/state";
import { Action, Dispatch } from "redux";
import { connect } from "react-redux";
import {
    getOrder,
    getCurrentCalendarBookingId,
    getOperators
} from "../../state/action";
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
    Label,
    Icon,
    Dropdown,
    IDropdownOption,
    TextField,
    Checkbox
} from "@fluentui/react";
import { loadOrder } from "../../state/order/action";
import NavigationHelper from "../utils/NavigationHelper";
import { assignmentTypeText } from "../utils/assignmentTypeHelper";
import { getMapsUrl } from "../utils/LocationHelper";
import { setCurrentBooking } from "../../state/calendar/action";
import { updateOperators } from "../../state/user/action";
import { saveSelectedOrder } from "../../state/worksheet/action";
import { getDropdownForOperators } from "../utils/operatorsHelper";
interface Props {
    onSave: (departmentId: string) => void;
}

const BookingDialog: React.FunctionComponent<StateProps & Props> = props => {
    const [booking, setBooking] = React.useState(props.booking);

    React.useEffect(() => {
        if (props.id) {
            props.loadOrder(props.id);
        }
        if (props.operators.length === 0) {
            props.updateOperators();
        }
    }, [props.id]);

    React.useEffect(() => {
        setBooking(props.booking);
    }, [props.booking]);

    const onNavigateClick = React.useCallback(() => {
        props.setCurrentBooking();
        window.open(
            `${NavigationHelper.getRoute("WORKSHEET")}/${props.id}`,
            "_blank"
        );
    }, [props.id]);

    const onClose = React.useCallback(() => {
        props.setCurrentBooking();
    }, [props.id]);

    const onSaveAndClose = React.useCallback(async () => {
        await props.saveOrder(booking);
        props.setCurrentBooking();
        if (
            booking.timeAndPlace.department &&
            booking.timeAndPlace.department.id
        ) {
            props.onSave(booking.timeAndPlace.department.id);
        }
    }, [booking]);

    const setWorker = (
        e: React.SyntheticEvent<HTMLInputElement>,
        option: IDropdownOption
    ) => {
        const temp = { ...booking };
        temp.additionalData = {
            ...temp.additionalData,
            worker: option.key as string
        };
        setBooking(temp);
    };

    const onOBSChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: boolean
    ) => {
        const temp = { ...booking };
        temp.additionalData = { ...temp.additionalData, obs: value };
        setBooking(temp);
    };

    const onChangeInternalComment = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        const temp = { ...booking };
        temp.additionalData = {
            ...temp.additionalData,
            internalOrderComment: value
        };
        setBooking(temp);
    };

    const onChangePriority = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        const temp = { ...booking };
        temp.additionalData = {
            ...temp.additionalData,
            priority: parseInt(value)
        };
        setBooking(temp);
    };

    if (!booking) {
        return null;
    }
    const renderMobileAddress = React.useCallback(() => {
        if (
            booking.timeAndPlace.mobileServiceLocation &&
            booking.timeAndPlace.department &&
            booking.timeAndPlace.department.mobileService
        ) {
            const covered = booking.timeAndPlace.mobileServiceLocation.covered;
            const address = booking.timeAndPlace.mobileServiceLocation.address;
            const addressLink = address ? (
                <a
                    className="map_link"
                    rel="noreferrer"
                    target="_blank"
                    href={getMapsUrl(address)}
                >
                    {" "}
                    <Icon iconName="Nav2DMapView" />{" "}
                </a>
            ) : null;
            return (
                <div>
                    <Label>Ude adresse</Label>
                    {address}
                    {addressLink}
                    <Label>Overdækket</Label>
                    {covered ? "Ja" : "Nej"}
                </div>
            );
        }
        return null;
    }, [booking]);

    const title = assignmentTypeText.find(
        x => x.key === booking.additionalData.orderType
    );
    return (
        <Dialog
            hidden={props.id === undefined}
            onDismiss={onClose}
            minWidth={400}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: title ? title.text : "",
                subText: ""
            }}
        >
            <div className="booking_dialog">
                <div>
                    <Label>Montør:</Label>{" "}
                    <Dropdown
                        calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                        options={getDropdownForOperators(props.operators)}
                        onChange={setWorker}
                        defaultSelectedKey={booking.additionalData.worker}
                    />
                </div>
                <div>
                    <Label>Kommentar:</Label>
                    <div>
                        <TextField
                            value={booking.additionalData.internalOrderComment}
                            multiline={true}
                            onChange={onChangeInternalComment}
                        />
                    </div>
                </div>
                <div>
                    <Label>Prioritet:</Label>
                    <div>
                        <TextField
                            type="number"
                            value={booking.additionalData.priority + ""}
                            onChange={onChangePriority}
                        />
                    </div>
                </div>
                <div>
                    <Label>Bil:</Label>
                    <div>
                        {booking.car.make} {booking.car.model}
                    </div>
                </div>
                <div>
                    <Label>vare:</Label>
                    <ul>
                        {booking.items.map(x => (
                            <li key={x.itemNo}>{x.itemNo}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <Label>OBS!:</Label>
                    <div>
                        <Checkbox
                            checked={booking.additionalData.obs ? true : false}
                            onChange={onOBSChange}
                        />
                    </div>
                </div>
            </div>
            {renderMobileAddress()}
            <DialogFooter>
                <DefaultButton onClick={onClose}>Luk</DefaultButton>
                <PrimaryButton onClick={onSaveAndClose}>
                    Gem og Luk
                </PrimaryButton>
                <PrimaryButton onClick={onNavigateClick}>
                    Vis Booking
                </PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        operators: getOperators(state),
        booking: getOrder(state),
        id: getCurrentCalendarBookingId(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    loadOrder: (bookingId: string) => dispatch<any>(loadOrder(bookingId)),
    setCurrentBooking: () => dispatch<any>(setCurrentBooking()),
    updateOperators: () => dispatch<any>(updateOperators()),
    saveOrder: (order: Order) => dispatch<any>(saveSelectedOrder(order))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(BookingDialog);
