import * as React from "react";
import "../../style.css";
import { ActionButton } from "@fluentui/react";

export interface BaseProps {
    title: string;
    previewValue: string | JSX.Element;
    changed: boolean;
    editDisabled: boolean;
    icon?: string;
    linkStyle?: string;
}
const EditWorksheetFields: React.FunctionComponent<{children: any}> = props => {
    const [actives, setActives] = React.useState(Array<Boolean>());

    const onActiveClick = (index: number, disabled: boolean) => () => {
        if (!disabled) {
            const newList = [...actives];
            newList[index] = !newList[index];
            setActives(newList);
        }
    };

    return (
        <>
            {props.children &&
                (props.children as React.Component[]).map((child, index) => {
                    if (child) {
                        const childProps = child.props as BaseProps;
                        const icon = childProps.icon
                            ? childProps.icon
                            : childProps.editDisabled
                            ? ""
                            : "Edit";
                        const linkStyle = childProps.linkStyle
                            ? childProps.linkStyle
                            : childProps.changed
                            ? "changed"
                            : "";

                        return (
                            <div
                                className="action__container"
                                key={childProps.title}
                            >
                                <div className="action__edit">
                                    <ActionButton
                                        iconProps={{
                                            iconName: actives[index]
                                                ? "BoxCheckmarkSolid"
                                                : icon
                                        }}
                                        onClick={onActiveClick(
                                            index,
                                            childProps.editDisabled
                                        )}
                                    />
                                    {childProps.title}
                                    <div className={linkStyle}>
                                        {actives[index] ? ( child as any) : (
                                            <span className="value">
                                                {childProps.previewValue}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
        </>
    );
};

export default EditWorksheetFields;
