import * as React from "react";
import { getDepartments, getUsers } from "../../state/action";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { RootState } from "~/state";
import { useEffect } from "react";
import NavigationHelper from "../utils/NavigationHelper";
import CreateUser from "./CreateUser";
import { registerUser, updateUsers, removeUser } from "../../state/user/action";
import TableHelper from "../utils/TableHelper";
import PageHeader from "../layout/PageHeader";
import EditUser from "./EditUser";
import { Route } from "react-router";
import { getUserRoleText } from "../utils/userRoleHelper";
import { IColumn, DefaultButton, DetailsList, CheckboxVisibility } from "@fluentui/react";

const Users: React.FunctionComponent<StateProps> = props => {
    useEffect(() => {
        props.updateUsers();
    }, []);

    const removeUser = (user: UserInformation) => () => {
        if (
            confirm(
                `Du er ved at slette brugeren "${user.name}" \nØnsker du at udføre handlingen?`
            )
        ) {
            props.removeUser(user);
        }
    };

    const editUser = (id?: string) => () => {
        if (id) {
            NavigationHelper.pushRoute("EDIT", `/${id}`);
        }
    };

    const columns: IColumn[] = [
        TableHelper.col("name", "Navn", 90, 120, (u: UserInformation) => (
            <p>{u.name}</p>
        )),
        TableHelper.col("label", "Initialer", 80, 80, (u: UserInformation) => (
            <p>{u.initials}</p>
        )),
        TableHelper.col("email", "Email", 56, 156, (u: UserInformation) => (
            <p>{u.email}</p>
        )),
        TableHelper.col("phone", "Telefon", 56, 80, (u: UserInformation) => (
            <p>{u.phone}</p>
        )),
        TableHelper.col(
            "departmentId",
            "Afdelings Id",
            56,
            156,
            (u: UserInformation) => { 
                let location = props.departments.find(dep => dep.id === u.departmentGuid); 
                return <p>{ location && location.navLocationCode ? location.navLocationCode :"" }</p>}
        ),
        TableHelper.col("roles", "Roller", 56, 156, (u: UserInformation) => (
            <p>{u.roles.map(role => getUserRoleText(role))}</p>
        )),
        TableHelper.col(
            "lastLogin",
            "Sidst Logget ind",
            56,
            156,
            (u: UserInformation) => <p>{u.lastLogin}</p>
        ),
        TableHelper.col("delete", "", 80, 80, (u: UserInformation) => (
            <DefaultButton onClick={removeUser(u)} text={"Slet"} />
        )),
        TableHelper.col("edit", "", 80, 80, (u: UserInformation) => (
            <DefaultButton onClick={editUser(u.id)} text={"Rediger"} />
        ))
    ];

    const renderCreateUser = () => {
        return (
            <CreateUser
                register={props.register}
                departments={props.departments}
            />
        );
    };

    const onCreateUserClick = () => {
        NavigationHelper.pushRoute("CREATE");
    };

    const renderUserList = () => {
        return (
            <>
                <PageHeader currentPage="Brugerstyring" noBreadcrumb={true} />
                <h5>Bruger Liste</h5>
                <DefaultButton onClick={onCreateUserClick}>
                    Opret Bruger
                </DefaultButton>
                <DetailsList
                    className="hidden-xs"
                    columns={columns}
                    items={props.users}
                    checkboxVisibility={CheckboxVisibility.hidden}
                ></DetailsList>
            </>
        );
    };

    return (
        <>
            <Route
                path={NavigationHelper.getRoute("CREATE")}
                exact={true}
                component={renderCreateUser}
            />
            <Route
                path={`${NavigationHelper.getRoute("EDIT")}/:userId`}
                component={EditUser}
            />
            <Route
                path={NavigationHelper.getRoute("USERS")}
                exact={true}
                component={renderUserList}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        departments: getDepartments(state),
        users: getUsers(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    register: (user: UserInformation) => dispatch<any>(registerUser(user)),
    updateUsers: () => dispatch<any>(updateUsers()),
    removeUser: (user: UserInformation) => dispatch<any>(removeUser(user))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(Users);
