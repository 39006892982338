import * as React from "react";
import TipsAndTricks from "./TipsAndTricks";
import { Dialog, DialogType, DialogFooter, DefaultButton } from "@fluentui/react";

interface Props {
    onCloseClick: () => void;
    products?: Product[];
}

const TipsAndTricksDialog: React.FunctionComponent<Props> = props => {
    if (!props.products || props.products.length === 0) {
        return null;
    }
    return (
        <Dialog
            minWidth={600}
            hidden={false}
            onDismiss={props.onCloseClick}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: "Tips og Tricks"
            }}
        >
            <TipsAndTricks {...props} cellWidth={120} />
            <DialogFooter>
                <DefaultButton onClick={props.onCloseClick}>Luk </DefaultButton>
            </DialogFooter>
        </Dialog>
    );
};

export default TipsAndTricksDialog;
