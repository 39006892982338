import * as React from "react";
import PageHeader from "../layout/PageHeader";
import { RouteComponentProps } from "react-router";
import { RootState } from "~/state";
import {
    getDepartments,
    getUserInformation,
    getUsers
} from "../../state/action";
import { updateUsers, editUser } from "../../state/user/action";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { loadUser } from "../utils/UserHelper";
import { useEffect } from "react";
import NavigationHelper from "../utils/NavigationHelper";
import {
    validate,
    isModelValid,
    editUserFieldConfig
} from "../../util/validate";
import "./style.css";
import { IDropdownOption, TextField, Dropdown, Stack, Checkbox, PrimaryButton } from "@fluentui/react";

const EditUser: React.FunctionComponent<StateProps &
    RouteComponentProps<{ userId: string }>> = props => {
    const [selectedUser, changeSelectedUser] = React.useState({
        name: "",
        initials: "",
        email: "",
        phone: "",
        roles: [],
        departmentId: "",
        departmentGuid: "",
        calendarDepartmentId: "",
        calendarDepartmentGuid: "",
    } as UserInformation);
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");

    useEffect(() => {
        props.updateUsers();
    }, []);

    useEffect(() => {
        const userId = props.match.params.userId;
        const user = loadUser(props.users, userId);
        if (user) {
            changeSelectedUser(user);
        }
    }, [props.match.params.userId, props.users]);

    const editTextfield = (field: string) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        const user = { ...selectedUser, [field]: value };
        changeSelectedUser(user);
    };

    const updateRoles = (role: UserRole) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: boolean
    ) => {
        if (value) {
            const user = {
                ...selectedUser,
                roles: [...selectedUser.roles, role]
            };
            changeSelectedUser(user);
        } else {
            const user = {
                ...selectedUser,
                roles: [...selectedUser.roles.filter(r => r !== role)]
            };
            changeSelectedUser(user);
        }
    };

    const isRoleSelected = (role: UserRole): boolean => {
        return selectedUser.roles.indexOf(role) >= 0;
    };

    const departmentOptions = React.useCallback((): IDropdownOption[] => {
        return props.departments.map(x => {
            return { key: x.id, text: x.name } as IDropdownOption;
        });
    }, [props.departments]);

    const localDepartmentOptions = (): IDropdownOption[] => {
        return props.departments
            .filter(x => !x.mobileService)
            .map(x => {
                return {
                    key: x.id,
                    text: x.name
                } as IDropdownOption;
            });
    };

    const updateCustomerBookingDepartment = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ) => {
        if (option) {
            const user = {
                ...selectedUser,
                departmentGuid: option.key.toString()
            };
            changeSelectedUser(user);
        }
    };

    const updateCalendarDepartment = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ) => {
        if (option) {
            const user = {
                ...selectedUser,
                calendarDepartmentGuid: option.key.toString()
            };
            changeSelectedUser(user);
        }
    };

    const updatePassword = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setPassword(value);
    };

    const updateConfirmPassword = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setConfirmPassword(value);
    };

    const onSaveUserClick = () => {
        password ? (selectedUser.password = password) : null;
        props.editUser(selectedUser);

        NavigationHelper.pushRoute("USERS");
    };

    const { name, initials, email, phone, roles } = selectedUser;

    const disabled = !isModelValid(
        {
            name,
            initials,
            email,
            phone,
            roles,
            password,
            confirmPassword
        },
        editUserFieldConfig
    );
    var headerWidth = 75;
    var checkbox = 175;
    return (
        <>
            <PageHeader currentPage="Rediger Bruger" noBreadcrumb={true} />
            <div>
                <br />
                <br />
                <div className="text_field__container">
                    <TextField
                        onGetErrorMessage={validate(
                            editUserFieldConfig["initials"].validation
                        )}
                        width={"200px"}
                        required
                        value={
                            selectedUser.initials ? selectedUser.initials : ""
                        }
                        placeholder="Initialer"
                        onChange={editTextfield("initials")}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    <TextField
                        onGetErrorMessage={validate(
                            editUserFieldConfig["name"].validation
                        )}
                        width={"200px"}
                        required
                        value={selectedUser.name}
                        placeholder="Name"
                        onChange={editTextfield("name")}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    <TextField
                        width={"200px"}
                        value={selectedUser.email}
                        placeholder="Email"
                        onChange={editTextfield("email")}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    <TextField
                        width={"200px"}
                        value={selectedUser.phone}
                        placeholder="Phone"
                        onChange={editTextfield("phone")}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    <Dropdown
                        calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                        style={{ minWidth: "15em" }}
                        onChange={updateCalendarDepartment}
                        selectedKey={selectedUser.calendarDepartmentGuid}
                        placeholder={"Vælg default kalender afdeling"}
                        options={departmentOptions()}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    <TextField
                        onGetErrorMessage={validate(
                            editUserFieldConfig["password"].validation
                        )}
                        type="password"
                        width={"200px"}
                        value={password}
                        placeholder="Password"
                        onChange={updatePassword}
                    />
                    <br />
                </div>
                <div className="text_field__container">
                    {password.length > 0 ? (
                        <>
                            <TextField
                                type="password"
                                width={"200px"}
                                value={confirmPassword}
                                placeholder="Confirm password"
                                onChange={updateConfirmPassword}
                            />
                            <br />
                        </>
                    ) : null}
                </div>
                <br />
                <div className="user_fields__container">
                    <Stack horizontal={true} tokens={{ childrenGap: 20 }}>
                        <Stack.Item style={{width:headerWidth}}>Rolle:</Stack.Item>
                        <Stack.Item style={{width:checkbox}}>
                            <Checkbox
                                label="Administrator"
                                onChange={updateRoles("Adminstrator")}
                                checked={isRoleSelected("Adminstrator")}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Kundeservice"
                                onChange={updateRoles("CustomerService")}
                                checked={isRoleSelected("CustomerService")}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Lager"
                                onChange={updateRoles("Stock")}
                                checked={isRoleSelected("Stock")}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Ordregennemsyn"
                                onChange={updateRoles("ReviewManager")}
                                checked={isRoleSelected("ReviewManager")}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Drift"
                                onChange={updateRoles("Operation")}
                                checked={isRoleSelected("Operation")}
                            />
                            {selectedUser.roles.indexOf("Operation") >= 0 &&
                            selectedUser.roles.indexOf("CustomerService") <
                                0 ? (
                                <Dropdown
                                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                    onChange={updateCustomerBookingDepartment}
                                    placeholder={"Vælg afdeling"}
                                    options={departmentOptions()}
                                    selectedKey={selectedUser.departmentGuid}
                                />
                            ) : null}
                        </Stack.Item>
                        <Stack.Item style={{width:100}}>
                            <Checkbox
                                label="Stenslag"
                                onChange={updateRoles("QuickStoneChip")}
                                checked={isRoleSelected("QuickStoneChip")}
                            />
                            <br />
                            {roles.indexOf("QuickStoneChip") >= 0 ? (
                                <Dropdown
                                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                    style={{ minWidth: "15em" }}
                                    onChange={updateCustomerBookingDepartment}
                                    placeholder={"Vælg afdeling"}
                                    options={localDepartmentOptions()}
                                    selectedKey={selectedUser.departmentGuid}
                                />
                            ) : null}
                        </Stack.Item>
                       
                    </Stack>
                    <br/>
                    <Stack horizontal={true} tokens={{ childrenGap: 20 }}>
                        <Stack.Item style={{width:headerWidth}}>Må bruge:</Stack.Item>
                        <Stack.Item style={{width:checkbox}}>
                            <Checkbox 
                                label="b2bautover VIN opslag"
                                onChange={updateRoles("VINSearch")}
                                checked={isRoleSelected("VINSearch")}
                            />
                        </Stack.Item>
                        <Stack.Item style={{width:checkbox}}>
                            <Checkbox
                                label="Autovalid VIN opslag"
                                onChange={updateRoles("AutovalidVINSearch")}
                                checked={isRoleSelected("AutovalidVINSearch")}
                            />
                        </Stack.Item>
                    </Stack>
                    <br/>
                    <br/>
                    <Stack horizontal={true} tokens={{ childrenGap: 50 }}>
                        <Stack.Item style={{width:headerWidth}}>Må se:</Stack.Item>
                        <Stack.Item style={{width:checkbox, marginLeft: 20}}>
                            <Checkbox
                                label="Reklamations menu"
                                onChange={updateRoles("Complaint")}
                                checked={isRoleSelected("Complaint")}
                            />
                        </Stack.Item>
                        <Stack.Item style={{width:checkbox, marginLeft: 20}}>
                            <Checkbox
                                label="Afdelings menu"
                                onChange={updateRoles("TimeManagement")}
                                checked={isRoleSelected("TimeManagement")}
                            />
                        </Stack.Item>
                    </Stack>
                </div>
                <br />
                <PrimaryButton disabled={disabled} onClick={onSaveUserClick}>
                    {" "}
                    Gem Ændringer{" "}
                </PrimaryButton>
                <PrimaryButton
                    style={{ marginLeft: "1em" }}
                    onClick={() => NavigationHelper.pushRoute("USERS")}
                >
                    {" "}
                    Tilbage{" "}
                </PrimaryButton>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        departments: getDepartments(state),
        userInformation: getUserInformation(state),
        users: getUsers(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateUsers: () => dispatch<any>(updateUsers()),
    editUser: (user: UserInformation) => dispatch<any>(editUser(user))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(EditUser);
