import * as React from "react";
import PageHeader from "../layout/PageHeader";
import Storage from "../dashboard/Storage";
import { RootState } from "../../state";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import {
    getCarManufactures,
    getCarManufactureModels,
    getOrders,
    getInsurers,
    getDepartments,
    getTransfers,
    getOrder,
    getProductSearchQuery,
    getBinList,
    getUserInformation,
    getProductList
} from "../../state/action";
import {
    acceptTransfer,
    updateTransfers,
    createTransfer,
    updateBinList
} from "../../state/transfer/action";
import {
    updateSearchList,
} from "../../state/product/action";
import { updateProductSearchQuery } from "../../state/ui/action";

const Orders = (props: StateProps) => {
    /*
    Client hasn't decided what data columns they want yet,
    so just using the same component as dashboard on this page for now.
    */

    return (
        <>
            <PageHeader currentPage={"Vareflyt"} noBreadcrumb={true} />

            <Storage
                products={props.products}
                updateSearchList={props.updateSearchList}
                updateSearchProductQuery={props.updateSearchProductQuery}
                query={props.searchProductQuery}
                departments={props.departments}
                transfers={props.transfers}
                createTransfer={props.createTransfer}
                updateTransfers={props.updateTransfers}
                acceptTransfer={props.acceptTransfer}
                departmentGuid={props.user.departmentGuid}
                binlist={props.binlist}
                updateBinList={props.updateBinList}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        transfers: getTransfers(state),
        departments: getDepartments(state),
        carManufactures: getCarManufactures(state),
        carManufactureModels: getCarManufactureModels(state),
        orders: getOrders(state),
        order: getOrder(state),
        insurers: getInsurers(state),
        searchProductQuery: getProductSearchQuery(state),
        binlist: getBinList(state),
        user: getUserInformation(state),
        products: getProductList(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => dispatch<any>(updateSearchList(query, merge)),
    updateSearchProductQuery: (searchIn?: string, term?: string) =>
        dispatch<any>(updateProductSearchQuery(searchIn, term)),
    updateTransfers: (
        departmentGuid: string,
        orderId: string,
        fromDate?: Date,
        toDate?: Date
    ) =>
        dispatch<any>(updateTransfers(departmentGuid, orderId, fromDate, toDate)),
    acceptTransfer: (
        isReceipt: boolean,
        orderNo: string,
        orderLine: number,
        amount: number,
        departmentGuid: string
    ) =>
        dispatch<any>(
            acceptTransfer(isReceipt, orderNo, orderLine, amount, departmentGuid)
        ),
    createTransfer: (transfers: Transfer[]) =>
        dispatch<any>(createTransfer(transfers)),
    updateBinList: (departmentGuid: string) => dispatch<any>(updateBinList(departmentGuid))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(Orders);
