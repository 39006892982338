import produce from "immer";
import { Actions } from "./ActionInterfaces";

interface OrderState extends Order {}

const initialState: OrderState = {
    customer: {
        name: "",
        city: "",
        address: "",
        phone: "",
        email: "",
        zipCode: "",
        allowSms: true,
        allowEmail: true
    },
    debitor: {
        no: "",
        name: "",
        city: "",
        address: "",
        phone: "",
        email: "",
        zipCode: "",
        debitorName: "",
        cvr: "",
        allowEmail: true,
        allowSms: true
    },
    car: {},
    additionalData: {
        orderId: undefined,
        bookingNr: "",
        externalOrderComment: "",
        internalOrderComment: "",
        discountAmount: 0,
        discountPercent: 0,
        changes: [],
        smsStatus: "",
        orderStatus: "DRAFT",
        orderType: "REPLACE_FRONT", // Default er denne sat til REPLACE, men kan også sættes til REPAIR
        orderNr: "",
        rekvNr: "",
        createdBy: "",
        created: "",
        createdDateTime: "",
        twoManJob: false,
        internalImages: [],
        externalImages: [],
        guaranteeId: "",
        timeSpent: 0,
        workCompleted: false,
        worker: "",
        helper: "",
        workInsuranceNr: "",
        workKM: 0,
        whoIsPaying: "INSURANCE",
        onHoldComment: "",
        obs: false
    },
    items: [],
    timeAndPlace: {
        date: "",
        waitingList: false
    }
};

export const reducer = (state = initialState, action: Actions): OrderState =>
    produce(state, draft => {
        switch (action.type) {
            case "LOAD_ORDER": {
                draft = { ...draft, ...action.order };
                return draft;
            }
            case "CLEAR_ORDER": {
                draft = initialState;
                return draft;
            }
            case "UPDATE_CUSTOMER": {
                draft.customer = { ...draft.customer, ...action.customer };
                return;
            }
            case "UPDATE_CAR": {
                draft.car = action.car;
                if (action.car.debitor)
                {
                    draft.debitor = action.car.debitor;
                }
                if (action.car.whoIsPaying)
                {
                    draft.additionalData.whoIsPaying = action.car.whoIsPaying;
                }
                return;
            }
            case "UPDATE_ITEMS": {
                draft.items = action.items;
                return;
            }
            case "UPDATE_ADDITIONAL": {
                draft.additionalData = action.additional;
                return;
            }
            case "UPDATE_DEBITOR": {
                draft.debitor = { ...draft.debitor, ...action.debitor };
                return;
            }
            case "SET_PRODUCT_DISCOUNT": {
                const { discount, itemNo } = action;
                const index = draft.items.findIndex(x => x.itemNo === itemNo);
                if (index > -1) {
                    const item = draft.items[index];
                    if (item.debitorPrice) {
                        item.debitorPrice.discountPercent = discount;
                    } else {
                        item.discountPercent = discount;
                    }
                }
                return;
            }
            case "ADD_PRODUCTS": {
                const { products } = action;
                products.forEach(product => {
                    const index = draft.items.findIndex(
                        e => e.itemNo === product.no
                    );
                    if (index > -1) {
                        draft.items[index].amount += product.amount;
                    } else {
                        const { no, price, ...rest } = product;
                        draft.items.push({
                            ...rest,
                            itemNo: no,
                            no: no,
                            description: product.description,
                            itemPrice: price,
                            discountPercent: product.debitorPrice
                                ? product.debitorPrice.discountPercent
                                : 0
                        } as PurchaseItem);
                    }
                });

                return;
            }
            case "ADD_PRODUCT": {
                const { product } = action;
                const index = draft.items.findIndex(
                    e => e.itemNo === product.no
                );
                if (index > -1) {
                    draft.items[index].amount = product.amount;
                } else {
                    const { no, price, ...rest } = product;
                    draft.items.push({
                        ...rest,
                        itemNo: no,
                        description: product.description,
                        itemPrice: price,
                        discountPercent: product.debitorPrice
                            ? product.debitorPrice.discountPercent
                            : 0
                    } as PurchaseItem);
                }
                return;
            }
            case "REMOVE_PRODUCT": {
                draft.items = draft.items.filter(
                    x => x.itemNo !== action.itemNo
                );
                return;
            }
            case "SET_ORDRE_PRODUCT_AMOUNT": {
                const { itemNo, amount } = action;
                const index = draft.items.findIndex(x => x.itemNo === itemNo);
                draft.items[index].amount = amount;
                return;
            }
            case "SET_ORDRE_PRODUCT_PRICE": {
                const { itemNo, price } = action;
                const index = draft.items.findIndex(x => x.itemNo === itemNo);
                const item = draft.items[index];
                if (!item.debitorPrice) {
                    item.itemPrice = price;
                } else {
                    item.itemPrice = price;
                    item.debitorPrice.price = price;
                }
                return;
            }
            case "SET_ORDRE_PRODUCT_NAME": {
                const { itemNo, name } = action;
                const index = draft.items.findIndex(x => x.itemNo === itemNo);
                draft.items[index].name = name;
                return;
            }
            case "UPDATE_TIME_AND_PLACE": {
                draft.timeAndPlace = {
                    ...draft.timeAndPlace,
                    ...action.timeAndPlace
                };
                if(state.additionalData.orderStatus !== "FINISHED")
                {
                    draft.additionalData.worker = action.worker;
                }
                return;
            }
            case "UPDATE_IMAGES": {
                draft.additionalData[action.imageType]
                    ? draft.additionalData[action.imageType].push(
                          action.imagePath
                      )
                    : (draft.additionalData[action.imageType] = [
                          action.imagePath
                      ]);
                return;
            }
            case "UPDATE_ORDER": {
                draft = {...state,...action.order};
                return draft;
            }
            case "WHO_IS_PAYING": {
                draft.additionalData.whoIsPaying = action.whoIsPaying;
                return draft;
            }
            case "REMOVE_IMAGES": {
                if (action.imageType === "externalImages") {
                    draft.additionalData.externalImages = draft.additionalData.externalImages.filter(
                        x => !action.images.includes(x)
                    );
                } else if (action.imageType === "internalImages") {
                    draft.additionalData.internalImages = draft.additionalData.internalImages.filter(
                        x => !action.images.includes(x)
                    );
                }
                return;
            }
            case "UPDATE_CUSTOMER_CITY_FROM_ZIP": {
                draft.customer.city = action.city;
                return draft;
            }
            case "UPDATE_COMPLAINT": {
                draft.complaint = { ...draft.complaint, ...action.complaint };
                return draft;
            }
            case "AUTO_VALID_TICKETID": {
                draft.additionalData.autoValidTicketId = action.ticketId;
                return draft;
            }
            default:
                return;
        }
    });

export const getOrder = (state: OrderState) => state;

export const getOrderById = (state: OrderState, orderId: string) => state;

export const getCustomer = (state: OrderState) => state.customer;

export const getCar = (state: OrderState) => state.car;

export const getDebitor = (state: OrderState) => state.debitor;

export const getTimeAndPlace = (state: OrderState) => state.timeAndPlace;

export const getAdditionalData = (state: OrderState) => state.additionalData;

export const getWhoIsPaying = (state: OrderState) =>
    state.additionalData.whoIsPaying;

export default reducer;
