import * as React from "react";
import { ListDepartment, Worker } from "../MobileServiceMap";
import "./style.css"

type Props = {
    tabs: string[];
    items: ListDepartment[];
    workers: Worker[];
    updateList: (e: ListDepartment) => (ev: React.ChangeEvent) => void;
    updateWorkerList: (e: Worker) => (ev: React.ChangeEvent) => void;
}

const CustomTabs = (props: Props) =>
{
    const [active, setActive] = React.useState("Afdeling");

    const checkStatus = (e: ListDepartment) =>
    {
        if(!e.selected && props.items.filter(x => x.selected === true).length > 3) //prevent selecting more than 4 departments
        {
            return true;
        }
        return false;
    };

    const renderTabs = () =>
    {
        return React.Children.map(props.tabs, (item, i) =>
        {
            return (
                <>
                    <input
                        className="tabInputField"
                        id={`tab_${i}`}
                        name={`tab_${i}`}
                        key={`tab_${i}`}
                        type="checkbox"
                        checked={item === active}
                        onChange={() => item === "Afdeling" ? setActive("Afdeling") : setActive("Montør")} />
                    <label
                        htmlFor={`tab_${i}`}>
                        {item}
                    </label>
                </>
            );
        });
    };

    const renderContent = () =>
    {
        if (active === "Afdeling")
        {
            return (
                <>
                    <h3 className="tabSubTitle">Vælg op til 4 afdelinger</h3>
                    <div className="tabContentList">
                        <ul className="details_list__container">
                            {props.items.map((x, i) =>
                            {
                                return <div className="contentRow" key={"cbk" + i}>
                                    <input type="checkbox"
                                        className="inputTabContent"
                                        data-id={x.id}
                                        disabled={checkStatus(x)}
                                        checked={x.selected || false}
                                        id={"cb-" + i}
                                        onChange={props.updateList(x)}/>
                                    <label
                                        htmlFor={"cb-" + i}
                                        className="inputTabContentLabel">
                                        {x.name}
                                    </label>
                                </div>;
                            })}
                        </ul>
                    </div></>
            );
        }
        else if (active === "Montør")
        {
            return (
                <>
                    <h3 className="tabSubTitle">Filtrér efter montør</h3>
                    <div className="tabContentList">
                        <ul className="details_list__container">
                            {props.workers.map((x, i) =>
                            {
                                return <li className="tabContentList" key={"wcbk" + i}>
                                    <input type="checkbox"
                                        className="inputTabContent"
                                        checked={x.selected || false}
                                        id={"wcb-" + i}
                                        onChange={props.updateWorkerList(x)} />
                                    <label
                                        htmlFor={"wcb-" + i}
                                        className="inputTabContentLabel">
                                        {x.name}
                                    </label>
                                </li>;
                            })}
                        </ul>
                    </div></>
            );
        }
    };

    return (
        <div>
            <div className="stiledTabs">
                {renderTabs()}
            </div>
            {renderContent()}
        </div>
    );
};

export default CustomTabs;