import { PrimaryButton } from "@fluentui/react";
import * as React from "react";

interface Props {
    text?: string;
    type?: string;
    onClick?: () => void;
}

const SearchButton = (props: Props) => {
    return (
        <PrimaryButton
            iconProps={{ iconName: "Search" }}
            style={{ minWidth: "40px" }}
            {...props}
        />
    );
};

export default SearchButton;
