import * as React from "react";

import {
    DefaultButton,
    Dialog,
    DialogFooter,
    TextField,
    Label,
    createTheme
} from "@fluentui/react";

import API from "../../api";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { RootState } from "~/state";
import { getCalendarDefaultEvent } from "../../state/action";
import { setShowEvent, updateEvent } from "../../state/calendar/action";
import { CustomCalendarEvent } from "~/state/calendar";

interface Props {
    onSave: (departmentId: string) => void;
}
const dangerButtonTheme = createTheme({
    palette: {
        white: "#f00",
        neutralPrimary: "#fff"
    }
});
const DefaultEventDialog: React.FunctionComponent<Props &
    StateProps> = props => {
    const [event, setEvent] = React.useState(props.event);
    React.useEffect(() => {
        setEvent(props.event);
    }, [props.event]);

    const onRemoveClick = React.useCallback(async () => {
        if (props.event) {
            await API.CalendarApi.DeleteEvent(props.event.id);
            props.onSave(props.event.departmentId);
            props.setShowEvent();
        }
    }, [props.event]);
    const updateEvent = React.useCallback(
        (field: string) => (e: React.FormEvent<any>, newValue: string) => {
            if (event) {
                setEvent({ ...event, [field]: newValue });
            }
        },
        [event]
    );

    const onSaveClick = React.useCallback(() => {
        if (event) {
            props.updateEvent(event);
            props.onSave(event.departmentId);
        }
    }, [event]);

    if (!props.event || !event) {
        return null;
    }
    return (
        <Dialog
            hidden={props.event === undefined}
            onDismiss={props.setShowEvent}
        >
            <div>
                <Label>Title:</Label>
                <TextField
                    borderless={true}
                    value={event.title}
                    onChange={updateEvent("title")}
                />
            </div>
            <div>
                <Label>Beskrivelse:</Label>
                <TextField
                    borderless={true}
                    multiline={true}
                    value={event.description}
                    onChange={updateEvent("description")}
                />
            </div>
            <div>
                <Label>Oprettet af:</Label>
                {props.event.createdBy}
            </div>

            <DialogFooter>
                <DefaultButton text="Gem" onClick={onSaveClick} />
                <DefaultButton
                    theme={dangerButtonTheme}
                    text="Slet"
                    onClick={onRemoveClick}
                />
                <DefaultButton onClick={props.setShowEvent}>Luk</DefaultButton>
            </DialogFooter>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        event: getCalendarDefaultEvent(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    setShowEvent: () => dispatch<any>(setShowEvent()),
    updateEvent: (event: CustomCalendarEvent) =>
        dispatch<any>(updateEvent(event))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(DefaultEventDialog);
