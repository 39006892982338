import BaseApi from "./baseApi";

export default class DepartmentRuleSetApi extends BaseApi {
    async getRulesPaginated(
        pageSize: number,
        pageNumber: number
    ): Promise<{ totalPages: number; rules: Rule[] }> {
        const url = `/api/departmentRuleSet/rules?pageSize=${pageSize}&pageNumber=${pageNumber}`;
        return await this.fetchGet(url);
    }

    async getRuleSets(
        pageSize: number,
        pageNumber: number
    ): Promise<{ totalPages: number; ruleSets: RuleSet[] }> {
        const url = `/api/departmentRuleSet/ruleSet?pageSize=${pageSize}&pageNumber=${pageNumber}`;
        return await this.fetchGet(url);
    }

    async deleteRule(id: string): Promise<unknown> {
        const url = `/api/departmentRuleSet/rule?id=${id}`;
        return await this.fetchDelete(url);
    }

    async deleteRuleSet(id: string): Promise<unknown> {
        const url = `/api/departmentRuleSet/ruleSet?id=${id}`;
        return await this.fetchDelete(url);
    }

    async deleteRuleFromSet(id: string, ruleId: string): Promise<boolean> {
        const url = `/api/departmentRuleSet/ruleSet/${id}/removeRule?ruleId=${ruleId}`;
        return await this.fetchDelete(url);
    }

    async deleteDepartmentFromSet(
        id: string,
        departmentId: string
    ): Promise<boolean> {
        const url = `/api/departmentRuleSet/ruleSet/${id}/removeDepartment?departmentId=${departmentId}`;
        return await this.fetchDelete(url);
    }

    async createOrUpdateRule(
        id: string | undefined,
        rule: BaseRule
    ): Promise<unknown> {
        const url = `/api/departmentRuleSet/rule?id=${id}`;
        return await this.fetchPost(url, rule);
    }

    async createOrUpdateRuleSet(
        id: string | undefined,
        ruleSet: RuleSetCreateModel
    ): Promise<RuleSet> {
        const url = `/api/departmentRuleSet/ruleSet?id=${id}`;
        return await this.fetchPost(url, ruleSet);
    }
}
