import BaseApi from "./baseApi";

interface IUserAPI {
    login: (userName: string, password: string) => Promise<void>;
    isUserInRole: (role: string) => Promise<boolean>;
    register: (user: UserInformation) => Promise<void>;
    logout: () => Promise<void>;
    resetPassword: (email: string) => Promise<void>;
    getUserInformation: () => Promise<UserInformation>;
    getUsersInRole: (role: UserRole) => Promise<UserInformation[]>;
    getUsers: () => Promise<UserInformation[]>;
    editUser: (user: UserInformation) => Promise<void>;
    removeUser: (user: UserInformation) => Promise<void>;
}

export default class UserAPI extends BaseApi implements IUserAPI {
    async login(userName: string, password: string): Promise<void> {
        const url = `/api/user/login`;
        return await this.fetchPost(url, {
            Email: userName,
            Password: password
        });
    }

    async isUserInRole(role: UserRole): Promise<boolean> {
        const url = `/api/user/roles/${role}`;
        return await this.fetchGet(url);
    }

    async register(user: UserInformation): Promise<void> {
        const url = `/api/user/register`;
        return await this.fetchPost(url, user);
    }

    async logout(): Promise<void> {
        const url = `/api/user/logout`;
        return await this.fetchGet(url);
    }

    async resetPassword(identifier: string | number): Promise<void> {
        const url = `/api/user/resetpassword`;
        return await this.fetchPost(url, { Identifier: identifier.toString() });
    }

    async getUserInformation(): Promise<UserInformation> {
        const url = `/api/user/userinfo`;
        return await this.fetchGet(url);
    }

    async getUsersInRole(role: UserRole): Promise<UserInformation[]> {
        const url = `/api/user/usersinrole/${role}`;
        return await this.fetchGet(url);
    }

    async getUsers(): Promise<UserInformation[]> {
        const url = `/api/user/users`;
        return await this.fetchGet(url);
    }

    async editUser(user: UserInformation): Promise<void> {
        const url = `/api/user/update`;
        return await this.fetchPost(url, user);
    }

    async removeUser(user: UserInformation): Promise<void> {
        const url = `/api/user/remove`;
        return await this.fetchPost(url, user);
    }
}
