import * as React from "react";
import { getDepartments, getUserInformation } from "../../state/action";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { RootState } from "~/state";
import NavigationHelper from "../utils/NavigationHelper";
import LoginUser from "./LoginUser";
import Forgot from "./Forgot";
import { login, resetPassword } from "../../state/user/action";
import UnauthorizedView from "./UnauthorizedView";
import { userHasAccess } from "../utils/UserHelper";

const UserInfo: React.FunctionComponent<StateProps> = props => {
    const render = () => {
        if (NavigationHelper.getRoute("LOGIN") === window.location.pathname) {
            if (
                userHasAccess(props.userInformation.roles, [
                    "Adminstrator",
                    "CustomerService",
                    "Operation",
                    "ReviewManager",
                    "Stock"
                ])
            ) {
                NavigationHelper.pushRoute("DASHBOARD");
            }
            return <LoginUser login={props.login} />;
        }
        if (NavigationHelper.getRoute("FORGOT") === window.location.pathname) {
            return <Forgot resetPassword={props.resetPassword} />;
        }
        return <UnauthorizedView login={props.login} />;
    };

    return <>{render()}</>;
};

const mapStateToProps = (state: RootState) => {
    return {
        departments: getDepartments(state),
        userInformation: getUserInformation(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    login: (userName: string, password: string) =>
        dispatch<any>(login(userName, password)),
    resetPassword: (identifier: string | number) =>
        dispatch<any>(resetPassword(identifier))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(UserInfo);
