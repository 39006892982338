
import { DialogType, Dialog, DialogFooter, DefaultButton, PrimaryButton } from "@fluentui/react";
import * as React from "react";

interface ConfirmDialogProps
{
    title: string;
    subText: string;
    hideDialog: boolean;
    onConfirm: () => void;
    onDismiss: () => void;
    confirmText: string;
    cancelText: string;
    switchButtons?: boolean;
}

const ConfirmDialog: React.FunctionComponent<ConfirmDialogProps> = (props) =>
{

    const dialogContentProps = {
        type: DialogType.normal,
        title: props.title,
        subText: props.subText,
    };

    return (
        <Dialog hidden={props.hideDialog} dialogContentProps={dialogContentProps} onDismiss={props.onDismiss}>
            <DialogFooter>
                {props.switchButtons ?
                    <>
                        <DefaultButton onClick={props.onDismiss} text={props.cancelText} />
                        <PrimaryButton onClick={props.onConfirm} text={props.confirmText} />
                    </> :
                    <>
                        <PrimaryButton onClick={props.onConfirm} text={props.confirmText} />
                        <DefaultButton onClick={props.onDismiss} text={props.cancelText} />
                    </>}
            </DialogFooter>
        </Dialog>
    );
};

export default ConfirmDialog;
