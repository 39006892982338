import * as React from "react";
import { RootState } from "~/state";
import { AnyAction, Dispatch } from "redux";
import { connect } from "react-redux";
import { removeMessageStatus } from "../../../state/ui/action";
import { getStatusMessages } from "../../../state/action";
import "./style.css";
import { MessageBar } from "@fluentui/react";

function RenderStatusMessage(statusMessage: StatusMessage, props: StateProps) {
    const { message, messageType, id } = statusMessage;

    const dismissMessage = () => {
        if (id) {
            props.removeMessageStatus(id);
        }
    };

    return (
        <MessageBar
            messageBarType={messageType}
            isMultiline={statusMessage.isMultiline}
            onDismiss={dismissMessage}
            key={id}
        >
            {message}
        </MessageBar>
    );
}

function StatusMessageBar(props?: StateProps) {
    const [scrolled, toggleScroll] = React.useState(false);
    React.useEffect(() => {
        if (window.scrollY > 80) {
            toggleScroll(true);
        }
        if (props && props.statusMessages.length > 0) {
            window.addEventListener("scroll", onScroll);
        }
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    });

    const onScroll = (e: Event) => {
        const w = e.currentTarget as Window;
        if (w !== undefined && w.scrollY > 80) {
            toggleScroll(true);
        } else {
            toggleScroll(false);
        }
    };
    if (props && props.statusMessages) {
        return (
            <div
                className={`statusMessage_container ${
                    scrolled ? "ms-MessageBar--scrolling" : ""
                }`}
            >
                {props.statusMessages.map((statusMessage, index) => {
                    return RenderStatusMessage(statusMessage, props);
                })}
            </div>
        );
    }
    return null;
}

const mapStateToProps = (state: RootState) => {
    return {
        statusMessages: getStatusMessages(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<AnyAction>) => ({
    removeMessageStatus: (id: string) => dispatch(removeMessageStatus(id))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(StatusMessageBar);
