import * as React from "react";
import { useState, useCallback } from "react";
import CustomRuleRow from "./CustomRuleRow";
import CustomAcordeonRow from "./CustomRuleAcordeonRow";
import { Icon } from "@fluentui/react";
import CustomRuleDialog from "./CustomRuleDialog";
import api from "../../api";

interface Props {
    data: {
        id: string;
        ruleName?: string;
        ruleSetName?: string;
        carMake?: string;
        carModel?: string;
        isWorkAllowed?: boolean;
        minimumWorkLength?: number;
        departments?: DepartmentRuleSet[];
        rules?: Rule[];
        alertMessage?: string;
    }[];
    columns: { name: string }[];
    rule: boolean;
}

function CustomRuleTable(props: Props) {
    const [expandedRows, setExpandedRows] = useState<string[]>([]);
    const [selectedRow, setSelectedRow] = useState<RowData | null>(null);
    const [showEditEntityDialog, setShowEditEntityDialog] = useState<boolean>(
        false
    );

    React.useEffect(() => {
        if (!selectedRow) return;
        setShowEditEntityDialog(value => !value);
    }, [selectedRow]);

    const handleExpand = (row: { id: string }) => {
        let idxFound = expandedRows.findIndex(id => {
            return id === row.id;
        });

        if (idxFound > -1) {
            setExpandedRows(expandedRows.filter(item => item !== row.id));
        } else {
            setExpandedRows([...expandedRows, row.id]);
        }
    };

    const isExpanded = (row: { id: string }) => {
        const idx = expandedRows.find(id => {
            return id === row.id;
        });

        return idx !== undefined;
    };

    const expandAll = () => {
        if (expandedRows.length === props.data.length) {
            setExpandedRows([]);
        } else {
            let newExpandedRows = props.data.map(data => data.id);
            setExpandedRows(newExpandedRows);
        }
    };

    const handleEdit = (row: RowData) => {
        setSelectedRow(row);
    };

    const handleDelete = (row: RowData) => {
        if (props.rule) {
            api.DepartmentRuleSetApi.deleteRule(row.id)
                .then(e => {
                    document.dispatchEvent(new Event("RULES_REFRESH"));
                })
                .catch(e => {
                    alert(
                        "Reglen kunne ikke slettes. Kontroller venligst, at ingen regelsæt bruger den valgte regler !"
                    );
                });
        } else {
            api.DepartmentRuleSetApi.deleteRuleSet(row.id)
                .then(e => {
                    document.dispatchEvent(new Event("RULESET_REFRESH"));
                })
                .catch(e => {
                    alert(
                        "Regelsæt kunne ikke slettes. Kontroller venligst udvalgte data !"
                    );
                });
        }
    };

    const onToggleEditEntityDialog = useCallback(() => {
        setShowEditEntityDialog(!showEditEntityDialog);
    }, [showEditEntityDialog]);

    const renderCustomRuleTable = () => {
        const rows = props.data.map((row, index) => {
            return (
                <React.Fragment key={row.id}>
                    <tr key={`primaryRow-${row.id}`}>
                        <CustomRuleRow rowData={row} rowCount={index} />

                        <td>
                            <button
                                className="custom__table_button"
                                onClick={() => handleExpand(row)}
                            >
                                <Icon
                                    iconName={
                                        isExpanded(row)
                                            ? "ChevronUpMed"
                                            : "ChevronDownMed"
                                    }
                                ></Icon>
                            </button>
                            <button
                                className="custom__table_button"
                                onClick={() => handleEdit(row)}
                            >
                                <Icon iconName="Edit" />
                            </button>
                            <button
                                className="custom__table_button"
                                onClick={() => handleDelete(row)}
                            >
                                <Icon iconName="Delete" />
                            </button>
                        </td>
                    </tr>

                    {isExpanded(row) && (
                        <CustomAcordeonRow
                            row={row}
                            isRuleRow={row.carMake ? true : false}
                        />
                    )}
                </React.Fragment>
            );
        });

        return (
            <>
                <table className="custom__table">
                    <tbody className="custom__table_body">
                        <tr>
                            {props.columns.map((column, index) => {
                                return (
                                    <th key={`table_column_${index}`}>
                                        <p>{column.name}</p>
                                    </th>
                                );
                            })}
                            <th onClick={() => expandAll()}>
                                <button className="custom__table_button">
                                    <Icon
                                        iconName={
                                            props.data.length ===
                                            expandedRows.length
                                                ? "ChevronUpMed"
                                                : "ChevronDownMed"
                                        }
                                    ></Icon>
                                </button>
                            </th>
                        </tr>
                        {rows}
                    </tbody>
                </table>
            </>
        );
    };

    return (
        <div className="department_ruleset__table">
            {renderCustomRuleTable()}
            {selectedRow && (
                <CustomRuleDialog
                    isRuleDialog={selectedRow.ruleSetName ? false : true}
                    hidden={!showEditEntityDialog}
                    onToggleDialog={onToggleEditEntityDialog}
                    row={selectedRow}
                ></CustomRuleDialog>
            )}
        </div>
    );
}

export default CustomRuleTable;
