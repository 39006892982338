import * as React from "react";
import TableHelper from "../../utils/TableHelper";
import { parsePriceAsString, vatConverter } from "../../utils/vatConverter";
import DebitorPrice from "../DebitorPrice";
import "../style.css";
import { IColumn, DefaultButton, Dialog, DetailsList, DetailsListLayoutMode, CheckboxVisibility, ActionButton } from "@fluentui/react";

interface Props {
    items: SubItem[];
    productName: string;
    debitorNo: string;
    debitorPriceRequest: (request: ProductPriceRequest[]) => void;
    storageSum: string;
    showStorageWindow: (status: Status[]) => void;
}

const ProductGroup: React.FunctionComponent<Props> = props => {
    const [showSubItems, setShowSubItems] = React.useState(false);
    const onToggleSubItemView = () => setShowSubItems(!showSubItems);
    const showStorageWindow = (status: Status[]) => () =>
        props.showStorageWindow(status);

    const columns: IColumn[] = [
        TableHelper.col(
            "id",
            "Varenummber",
            16,
            66,
            (e: SubItem) => <p>{e.no}</p>,
            "col-id"
        ),
        TableHelper.col(
            "name",
            "Navn",
            16,
            220,
            (e: SubItem) => <p>{e.name}</p>,
            "col-name"
        ),
        TableHelper.col(
            "amount",
            "Antal",
            16,
            30,
            (e: SubItem) => (
                <>
                    <label>Antal</label>
                    <p>{e.amount}</p>
                </>
            ),
            "col-amount"
        ),
        TableHelper.col(
            "stock",
            "Lager",
            16,
            50,
            (
                e: Product //TODO , do not show if a service and add correct storage counter
            ) => {
                return !e.isService ? (
                    <>
                        <label>Lager:</label>
                        <div className="subitem__storage storage_button__container">
                            <DefaultButton
                                text={props.storageSum}
                                onClick={showStorageWindow(e.status)}
                            />
                        </div>
                    </>
                ) : null;
            },
            "col-storage"
        ),
        TableHelper.col(
            "price",
            "Listepris",
            16,
            120,
            (e: SubItem) => {
                return (
                    <div className="price__list">
                        <div>
                            <label>Inkl. moms:</label>
                            <p>{vatConverter(e.price)}</p>
                        </div>
                        <div>
                            <label>Eksl. moms:</label>
                            <p>{parsePriceAsString(e.price)}</p>
                        </div>
                    </div>
                );
            },
            "col-price"
        ),
        TableHelper.col(
            "debitorPrice",
            "Kundepris",
            46,
            50,
            (e: Product) => {
                const { debitorNo, debitorPriceRequest } = props;
                const { no, subItems, amount, debitorPrice } = e;
                return (
                    <div className="price__list">
                        <DebitorPrice
                            {...{
                                debitorNo,
                                debitorPriceRequest,
                                no,
                                amount,
                                debitorPrice
                            }}
                        />
                    </div>
                );
            },
            "col-price"
        )
    ];
    if (showSubItems) {
        return (
            <Dialog
                minWidth={700}
                maxWidth={900}
                hidden={false}
                onDismiss={onToggleSubItemView}
                title={`Produkter til ${props.productName}`}
                styles={{
                    main: {
                        selectors: {
                            ["@media (max-width: 780px)"]: {
                                minWidth: "100%"
                            },
                            [".ms-Dialog-inner"]: {
                                padding: 0
                            }
                        }
                    }
                }}
            >
                <div className="dialog__list">
                    <DetailsList
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        columns={columns}
                        items={props.items}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        className="subitems-list"
                    />
                </div>
            </Dialog>
        );
    }
    return (
        <ActionButton
            iconProps={{ iconName: "Settings" }}
            onClick={onToggleSubItemView}
        ></ActionButton>
    );
};

export default ProductGroup;
