import * as React from "react";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../../state";
import OrderLines from "../../../products/OrderLines";
import {
    setDiscount,
    removeProduct,
    setProductAmount,
    addProducts,
    setProductName,
    setProductPrice
} from "../../../../state/order/action";
import AddProductDialog from "../../../products/AddProductDialog";
import "./style.css";
import { Dialog, DialogFooter, DefaultButton } from "@fluentui/react";

interface Props {
    order: Order;
    hideDialog: boolean;
    onDismiss: () => void;
    onFinishOrder: () => void;
    products: ProductGroup[];
    updateSearchProductQuery: (searchIn?: string, term?: string) => void;
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => void;
    query: { searchIn?: string; term?: string };
}

const FinishReplaceFrontOrderDialog: React.FunctionComponent<Props &
    StateProps> = props => {
    // the list of item no for limpuder
    const limpuder = [
        "337",
        "341",
        "342",
        "344",
        "345",
        "346",
        "348",
        "350",
        "351",
        "352",
        "353",
        "354",
        "355",
        "356",
        "357",
        "358"
    ];

    const hasLimpuder = props.order.items.some(
        c => limpuder.indexOf(c.itemNo) >= 0
    );
    // .net is smart to take this array as numbers and in case that we have a navchar on DB it's neccessary to change them to string values manually
    const limpuderStr = limpuder
        .map(c => {
            return "'" + c + "'";
        })
        .join(",");

    const onAddProducts = (products: Product[]) =>
        props.addProducts(products, props.order.debitor.no);

    return (
        <Dialog
            minWidth={985}
            dialogContentProps={{
                title: "Order afslutning",
                subText:
                    "Har du husket at tjekke dine varelinjer for lister, clips, småting mv.?"
            }}
            styles={{
                main: {
                    selectors: {
                        ["@media (max-width: 985px)"]: {
                            minWidth: "100%"
                        }
                    }
                }
            }}
            hidden={props.hideDialog}
            onDismiss={props.onDismiss}
        >
            <OrderLines
                useScrollableWrapper={false}
                setDiscount={props.setDiscount}
                removeProduct={props.removeProduct}
                setAmount={props.setAmount}
                setPrice={props.setPrice}
                setName={props.setName}
                items={props.order.items}
            />

            <DialogFooter>
                <div className="custom_command__buttons">
                    <div className="footer-dialogs-wrapper">
                        <AddProductDialog
                            products={props.products}
                            updateSearchProductQuery={
                                props.updateSearchProductQuery
                            }
                            updateSearchList={props.updateSearchList}
                            query={props.query}
                            addProducts={onAddProducts}
                            openDialogButtonClassName="btn-add-products"
                        />

                        {!hasLimpuder && (
                            <AddProductDialog
                                products={props.products}
                                updateSearchProductQuery={
                                    props.updateSearchProductQuery
                                }
                                updateSearchList={props.updateSearchList}
                                query={{
                                    searchIn: "NO",
                                    term: limpuderStr
                                }}
                                openDialogButtonText="Tilføj limpuder"
                                openDialogButtonClassName="btn-add-limpuder"
                                hideSearchBar={true}
                                addProducts={onAddProducts}
                            />
                        )}
                    </div>

                    <DefaultButton
                        onClick={props.onDismiss}
                        text="Annuller"
                        className="btn-close"
                    />
                    <DefaultButton
                        onClick={props.onFinishOrder}
                        text="Afslut order"
                        className="btn-finish-order"
                    />
                </div>
            </DialogFooter>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    setDiscount: (itemNo: string, discount: number) =>
        dispatch(setDiscount(itemNo, discount)),
    removeProduct: (item: PurchaseItem) => dispatch(removeProduct(item.itemNo)),
    setAmount: (itemNo: string, amount: number) =>
        dispatch(setProductAmount(itemNo, amount)),
    setPrice: (itemNo: string, price: number) =>
        dispatch(setProductPrice(itemNo, price)),
    setName: (itemNo: string, name: string) =>
        dispatch(setProductName(itemNo, name)),
    addProducts: (products: Product[], debitorNo: string) =>
        dispatch<any>(addProducts(products, debitorNo))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(
    mapStateToProps,
    mapToDispatchProps
)(FinishReplaceFrontOrderDialog);
