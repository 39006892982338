export const vatConverter = (price: number): string => {
    return parsePriceAsString(vatValueConverter(price));
};

export const vatValueConverter = (price: number): number => price * 1.25;

export const parsePriceAsString = (price: number): string => {
    return price.toLocaleString("da-DK", {
        style: "currency",
        currency: "DKK"
    });
};

export const roundToTwoDecimals = (price: number) => {
    return Math.round(price * 100) / 100;
};