import * as React from "react";
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Label,
    PrimaryButton,
    TextField,
    Checkbox,
    Dropdown,
    IDropdownOption,
    DayOfWeek,
    DatePicker
} from "@fluentui/react";
import TimePicker from "./util/TimePicker";
import { getDepartmentDropdownOptions } from "../utils/departmentHelper";
import API from "../../api";
import { findUserDepartment } from "./util/departmentHelper";
import "./style.css";

interface Props {
    close: () => void;
    departments: Department[];
    user: UserInformation;
    onSave: (departmentId: string) => void;
    selectedDepartment?: {
        id: string;
        updateId: string;
    };
}

const CreateEventDialog: React.FunctionComponent<Props> = props => {
    const [
        departmentsDropdownOptions,
        setDepartmentsDropdownOptions
    ] = React.useState<IDropdownOption[]>([]);
    const [event, setEvent] = React.useState<DepartmentCalendarEvent>({
        fromTime: "12:00",
        toTime: "13:00",
        department: "",
        repeat: false,
        date: new Date(),
        repeatOn: [],
        title: "",
        description: "",
        allDay: false
    });

    React.useEffect(() => {
        if (props.departments) {
            const menu = getDepartmentDropdownOptions(props.departments);
            setDepartmentsDropdownOptions(menu);
            if (props.selectedDepartment) {
                setEvent({
                    ...event,
                    department: props.selectedDepartment.id as string
                });
            } else {
                if (props.user.departmentGuid !== "00000000-0000-0000-0000-000000000000") {
                    const userDepartment = findUserDepartment(
                        props.user,
                        props.departments
                    );
                    if (userDepartment) {
                        setEvent({
                            ...event,
                            department: userDepartment.id as string
                        });
                    }
                }
            }
        }
    }, [props.departments, props.user, props.selectedDepartment]);

    const onSave = React.useCallback(async () => {
        const errorList: string[] = [];

        if (event.title.trim() === "") {
            errorList.push("Du skal indtaste en title");
        }
        if (
            !event.allDay &&
            (event.fromTime === null ||
                event.toTime === null ||
                event.date === null)
        ) {
            errorList.push("Du skal vælge en dato og en tid");
        }
        if (event.allDay && event.date === null) {
            errorList.push("Du skal vælge en dato");
        }

        if (errorList.length !== 0) {
            alert(errorList.join("\n"));
        } else {
            await API.CalendarApi.CreateEvent(event);
            props.onSave(event.department);
        }
    }, [event]);

    const onRepeatToggleClick = React.useCallback(
        () => setEvent({ ...event, repeat: !event.repeat }),
        [event]
    );

    const toggleAllDay = React.useCallback(
        () =>
            setEvent({
                ...event,
                allDay: !event.allDay,
                fromTime: "00:00",
                toTime: "23:59"
            }),
        [event]
    );

    const onSetWeekDay = React.useCallback(
        (dayOfWeek: DayOfWeek) => () => {
            //we will toggle the day from the list:
            const repeatOn =
                event.repeatOn.indexOf(dayOfWeek) > -1
                    ? event.repeatOn.filter(x => x !== dayOfWeek)
                    : [...event.repeatOn, dayOfWeek];
            setEvent({ ...event, repeatOn: repeatOn });
        },
        [event]
    );

    const onSetDepartment = React.useCallback(
        (e: React.FormEvent<HTMLDivElement>, option: IDropdownOption) =>
            setEvent({ ...event, department: option.key as string }),
        [event]
    );

    const updateKey = React.useCallback(
        (key: string) => (value: any) => {
            setEvent({ ...event, [key]: value });
        },
        [event]
    );

    const onTitleChange = React.useCallback(
        (e: React.FormEvent<HTMLInputElement>, newValue: string) =>
            setEvent({ ...event, title: newValue }),
        [event]
    );
    const onDescriptionChange = React.useCallback(
        (e: React.FormEvent<HTMLTextAreaElement>, newValue: string) =>
            setEvent({ ...event, description: newValue }),
        [event]
    );

    const renderOneTimeEvent = React.useCallback(() => {
        return (
            <>
                <Label>Dato</Label>
                <DatePicker
                    className="date_picker__container"
                    value={event.date}
                    onSelectDate={updateKey("date")}
                    minDate={new Date()}
                />
                {event.allDay ? null : (
                    <>
                        <div className="time_picker_container">
                            <TimePicker
                                time={event.fromTime}
                                onChange={updateKey("fromTime")}
                            />
                            <TimePicker
                                time={event.toTime}
                                onChange={updateKey("toTime")}
                            />
                        </div>
                    </>
                )}
            </>
        );
    }, [event]);

    const renderRepeatableEvent = React.useCallback(() => {
        return (
            <>
                <div>
                    <Label>Tidspunkt</Label>
                    {event.allDay ? null : (
                        <>
                            <div className="time_picker_container">
                                <TimePicker
                                    time={event.fromTime}
                                    onChange={updateKey("fromTime")}
                                />
                                <TimePicker
                                    time={event.toTime}
                                    onChange={updateKey("toTime")}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className="monday_friday__checkbox_list">
                    <Label>Gentage på følgende dage</Label>
                    <Checkbox
                        onChange={onSetWeekDay(DayOfWeek.Monday)}
                        checked={event.repeatOn.indexOf(DayOfWeek.Monday) > -1}
                        label="Mandag"
                    />
                    <Checkbox
                        onChange={onSetWeekDay(DayOfWeek.Tuesday)}
                        checked={event.repeatOn.indexOf(DayOfWeek.Tuesday) > -1}
                        label="Tirsdag"
                    />
                    <Checkbox
                        onChange={onSetWeekDay(DayOfWeek.Wednesday)}
                        checked={
                            event.repeatOn.indexOf(DayOfWeek.Wednesday) > -1
                        }
                        label="Onsdag"
                    />
                    <Checkbox
                        onChange={onSetWeekDay(DayOfWeek.Thursday)}
                        checked={
                            event.repeatOn.indexOf(DayOfWeek.Thursday) > -1
                        }
                        label="Torsdag"
                    />
                    <Checkbox
                        onChange={onSetWeekDay(DayOfWeek.Friday)}
                        checked={event.repeatOn.indexOf(DayOfWeek.Friday) > -1}
                        label="Fredag"
                    />
                </div>
            </>
        );
    }, [event]);

    return (
        <Dialog
            hidden={false}
            onDismiss={props.close}
            minWidth={525}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: "Ny aftale",
                subText: ""
            }}
        >
            <div>
                <Label>Title</Label>
                <TextField onChange={onTitleChange} />
            </div>
            <div>
                <Label>Beskrivelse</Label>
                <TextField onChange={onDescriptionChange} multiline={true} />
            </div>
            <div>
                <Label>Afdeling</Label>
                <Dropdown
                    calloutProps={{
                        calloutMaxHeight: 250,
                        calloutMinWidth: 100
                    }}
                    onChange={onSetDepartment}
                    selectedKey={event.department}
                    options={departmentsDropdownOptions}
                />
            </div>
            <div>
                <Label>Hele dagen</Label>
                <Checkbox checked={event.allDay} onChange={toggleAllDay} />
            </div>
            <div>
                <Label>Gentag</Label>
                <Checkbox
                    checked={event.repeat}
                    onChange={onRepeatToggleClick}
                />
            </div>
            {event.repeat ? renderRepeatableEvent() : renderOneTimeEvent()}
            <DialogFooter>
                <DefaultButton onClick={props.close}>Luk</DefaultButton>
                <PrimaryButton onClick={onSave}>Opret</PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
};

export default CreateEventDialog;
