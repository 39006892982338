import * as React from "react";
import Calendar, {
    CalendarTileProperties,
    ViewCallbackProperties
} from "react-calendar";
import { getFirstAvailableDay, canBookAtDate } from "../../utils/DateHelper";
import { useEffect, useState } from "react";
import { findParentDepartment } from "../../utils/departmentHelper";
import "./style.css"
import { Stack } from "@fluentui/react";

interface Props {
    department?: Department;
    departments: Department[];
    changeDate: (date: Date) => void;
    selectedDate?: Date;
    availableDepartmentBookings?: AvailableDepartmentBooking[];
    updateAvailableDepartmentBookings: (date: Date) => void;
    orderType: AssignmentType;
}

function CustomCalendar(props: Props) {
    const [currentMonth, setCurrentMonth] = useState<number>(-1);
    const [firstAvailableDay, setFirstAvailableDay] = useState<Date>(
        new Date()
    );

    useEffect(() => {
        const { department, orderType } = props;
        const firstDate = getFirstAvailableDay(orderType, department);
        setFirstAvailableDay(firstDate);
    }, [props.department, props.orderType]);
    useEffect(() => {
        if (props.selectedDate) {
            setCurrentMonth(props.selectedDate.getMonth());
        }
    }, [props.selectedDate]);
    const tileDisabled = ({
        date
    }: CalendarTileProperties & { activeStartDate: Date }) => {
        const currentDate = new Date();
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        if (date.getTime() < currentDate.getTime()) {
            return true;
        }
        return false;
    };

    const TileClassNameFunc = ({ date }: CalendarTileProperties) => {
        let className = "";
        let department = props.department ? { ...props.department } : undefined;

        if (department) {
            // necessary to switch to parent department
            if (department.parentDepartmentId) {
                department = findParentDepartment(
                    department,
                    props.departments
                );
            }

            if (!props.availableDepartmentBookings ||
                !canBookAtDate(
                    date,
                    props.availableDepartmentBookings,
                    props.orderType,
                    firstAvailableDay)
            ) {
                className = "tile_disabled";
            }
        }
        return className;
    };

    const OnActiveDateChange = ({
        activeStartDate
    }: ViewCallbackProperties) => {
        props.updateAvailableDepartmentBookings(activeStartDate);
        setCurrentMonth(activeStartDate.getMonth());
    };

    const onDateChange = React.useCallback(
        (date: Date) => {
            let department = props.department
                ? { ...props.department }
                : undefined;

            if (department) {
                // necessary to switch to parent department
                if (department.parentDepartmentId) {
                    department = findParentDepartment(
                        department,
                        props.departments
                    );
                }

                if (!props.availableDepartmentBookings ||
                    !canBookAtDate(
                        date,
                        props.availableDepartmentBookings,
                        props.orderType,
                        firstAvailableDay,
                    )
                ) {
                    alert(
                        "Der er ikke flere ledige tider på den valgte dag, ønsker du at fortsætte?"
                    );
                }
            }
            props.changeDate(date);

            if (
                props.selectedDate &&
                (date.getFullYear() !== props.selectedDate.getFullYear() ||
                    date.getMonth() !== props.selectedDate.getMonth())
            ) {
                props.updateAvailableDepartmentBookings(date);
                setCurrentMonth(date.getMonth());
            }
        },
        [props.availableDepartmentBookings, props.selectedDate]
    );

    const renderCalendar = () => {
        if (props.department) {
            return (
                <Stack className="calendar-holder" horizontal={true}>
                    {props.department ? (
                        <Calendar
                            locale="da-DK"
                            onChange={onDateChange}
                            tileDisabled={tileDisabled}
                            value={props.selectedDate}
                            tileClassName={TileClassNameFunc}
                            onActiveDateChange={OnActiveDateChange}
                            showNeighboringMonth={false}
                        />
                    ) : null}
                </Stack>
            );
        }
    };

    return <div className="calendar__container">{renderCalendar()}</div>;
}

export default CustomCalendar;
