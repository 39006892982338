import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { errorHandlingMiddleware } from "../middleware/errorHandlingMiddleware";
import car from "./car";
import order from "./order";
import product from "./product";
import transfer from "./transfer";
import ui from "./ui";
import user from "./user";
import oldProducts from "./old_products";
import zipCode from "./zipCode";
import department from "./department";
import calendar from "./calendar";
import worksheet from "./worksheet";
import debitorRuleSets from "./debitorRuleSets";
import timeslot from "./timeslot";
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

const reducer = combineReducers({
    order,
    ui,
    transfer,
    car,
    product,
    user,
    worksheet,
    oldProducts,
    zipCode,
    department,
    calendar,
    debitorRuleSets,
    timeslot
});

export type RootState = ReturnType<typeof reducer>;

const middlewares = applyMiddleware(errorHandlingMiddleware, thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// create redux store
const createStore_ = () => createStore(reducer, composeEnhancers(middlewares));

export { createStore_ as createStore };
