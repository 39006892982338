import * as React from "react";
import PageHeader from "../../layout/PageHeader";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../state";
import {
    getBookings,
    getProductSearchQuery,
    getProductList
} from "../../../state/action";
import {
    updateAdditional,
    updateItems,
    setDiscount,
    removeProduct,
    setProductAmount,
    addProducts,
    setProductName,
    setProductPrice
} from "../../../state/order/action";
import OrderLines from "../../products/OrderLines";
import AddProductDialog from "../../products/AddProductDialog";
import Comment from "../../fields/Comment";
import {
    defaultExternalOrderComment,
    defaultInternalOrderComment
} from "../../fields/defaultComments";
import { updateSearchList } from "../../../state/product/action";
import { updateProductSearchQuery } from "../../../state/ui/action";
import { calulateTotalPrice } from "../../utils/basket";
import { useEffect } from "react";
import { FreeCarSku } from "../../typings";
import "./style.css";
import "../../worksheet/style.css";
import { Stack, TextField } from "@fluentui/react";

function Confirmation(props: StateProps) {
    const [percentage, setPercentage] = React.useState("");
    const [discountAmount, setDiscountAmount] = React.useState("");

    let selectedTimeSlot: DamageSpecificTimeSlot | undefined = undefined;
    if(props.order.timeAndPlace.department && props.order.timeAndPlace.department.windowReplaceTimeSlots)
    {
        selectedTimeSlot = props.order.timeAndPlace.department
        ? props.order.timeAndPlace.department.windowReplaceTimeSlots.find(
              x => x.id === props.order.timeAndPlace.timeSlotId
          ) ||
          props.order.timeAndPlace.department.specificWindowReplaceTimeSlots.find(
              x => x.id === props.order.timeAndPlace.timeSlotId
          )
        : undefined;
    }
       

    useEffect(() => {
        if (selectedTimeSlot && selectedTimeSlot.hasFreeCar) {
            props.order.items.push({
                name: "INKL. GRATIS LÅNEBIL",
                description: "",
                isService: true,
                twoPersonJob: false,
                previouslyPurchased: false,
                itemNo: FreeCarSku.CAR_ONE,
                discountPercent: 0,
                amount: 1,
                itemPrice: 0,
                type: 2,
                no: FreeCarSku.CAR_ONE,
                price: 0,
                status: []
            });
        }
    }, []);

    useEffect(() => {
        const { discountPercent } = props.order.additionalData;
        setPercentage(
            discountPercent ? discountPercent.toLocaleString("da-DK") : ""
        );
    }, []);

    const setValueDiscountAmount = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        if (!value) {
            const additional = {
                ...props.order.additionalData,
                discountAmount: 0
            };
            props.updateAdditional(additional);
            setDiscountAmount("");
        }

        if (!isNaN(parseFloat(value.replace(",", ".")))) {
            setDiscountAmount(value);

            const discount: number = parseFloat(value.replace(",", "."));
            const discountAmount: number = Math.min(
                calulateTotalPrice(props.order.items, 0, 0),
                discount
            );

            props.updateAdditional({
                ...props.order.additionalData,
                discountAmount
            });
        }
    };

    const setRekvNr = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        const additional = { ...props.order.additionalData, rekvNr: value };
        props.updateAdditional(additional);
    };

    const setValueDiscountPercent = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        if (!value) {
            const additional = {
                ...props.order.additionalData,
                discountPercent: 0
            };
            props.updateAdditional(additional);
            setPercentage("");
        }

        if (
            /^\d{1,2}(\.|\,)?\d{0,2}$/.test(value) &&
            !isNaN(parseFloat(value.replace(",", ".")))
        ) {
            setPercentage(value);

            const additional = { ...props.order.additionalData };
            const discount: number = parseFloat(value.replace(",", "."));

            if (discount > 100) {
                additional.discountPercent = 100;
            } else {
                additional.discountPercent = !isNaN(discount) ? discount : 0;
            }

            props.updateAdditional(additional);
        }
    };

    const setComment = (fieldName: keyof AdditionalData) => (value: string) => {
        const additional: any = { ...props.order.additionalData };
        additional[fieldName] = value;
        props.updateAdditional(additional);
    };

    const onAddProducts = (products: Product[]) =>
        props.addProducts(products, props.order.debitor.no);

    const {
        externalOrderComment,
        internalOrderComment
    } = props.order.additionalData;

    return (
        <>
            <div className="styled_customer__confirmation">
                <PageHeader currentPage="Bekræftelse" />
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack.Item grow={1}>
                        <Comment
                            onChange={setComment("externalOrderComment")}
                            predefindedText={defaultExternalOrderComment}
                            title="Extern kommentar"
                            value={externalOrderComment}
                        />
                        <br />
                        <Comment
                            onChange={setComment("internalOrderComment")}
                            predefindedText={defaultInternalOrderComment}
                            title="Intern kommentar"
                            value={internalOrderComment}
                        />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <h5>Giv rabat</h5>
                        <TextField
                            title="Giv rabat %"
                            value={percentage}
                            placeholder="Giv rabat %"
                            onChange={setValueDiscountPercent}
                            maxLength={4}
                            suffix="%"
                            disabled={!!discountAmount}
                        />
                        <TextField
                            title="Giv rabat kr"
                            value={discountAmount}
                            placeholder="Giv rabat kr"
                            onChange={setValueDiscountAmount}
                            suffix="kr"
                            disabled={!!percentage}
                        />
                        <h5>Info</h5>
                        <TextField
                            title="Rekv. nr"
                            value={props.order.additionalData.rekvNr}
                            placeholder="Rekv. nr"
                            onChange={setRekvNr}
                        />
                    </Stack.Item>
                </Stack>
                <br />
                <br />
                <div className="product_order__container">
                    <OrderLines
                        useScrollableWrapper={true}
                        setDiscount={props.setDiscount}
                        removeProduct={props.removeProduct}
                        setAmount={props.setAmount}
                        setPrice={props.setPrice}
                        setName={props.setName}
                        items={props.order.items}
                    />
                </div>
                <AddProductDialog
                    products={props.products}
                    updateSearchList={props.updateSearchList}
                    updateSearchProductQuery={props.updateSearchProductQuery}
                    query={props.searchProductQuery}
                    addProducts={onAddProducts}
                />
            </div>
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        order: getBookings(state),
        searchProductQuery: getProductSearchQuery(state),
        products: getProductList(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => dispatch<any>(updateSearchList(query, merge)),
    updateSearchProductQuery: (searchIn?: string, term?: string) =>
        dispatch<any>(updateProductSearchQuery(searchIn, term)),
    updateAdditional: (additional: AdditionalData) =>
        dispatch(updateAdditional(additional)),
    updateItems: (items: PurchaseItem[]) => dispatch(updateItems(items)),
    setDiscount: (itemNo: string, discount: number) =>
        dispatch(setDiscount(itemNo, discount)),
    removeProduct: (item: PurchaseItem) => dispatch(removeProduct(item.itemNo)),
    setAmount: (itemNo: string, amount: number) =>
        dispatch(setProductAmount(itemNo, amount)),
    setPrice: (itemNo: string, price: number) =>
        dispatch(setProductPrice(itemNo, price)),
    setName: (itemNo: string, name: string) =>
        dispatch(setProductName(itemNo, name)),
    addProducts: (products: Product[], debitorNo: string) =>
        dispatch<any>(addProducts(products, debitorNo))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(Confirmation);
