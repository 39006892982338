import * as React from "react";
import { validate, Field } from "../../../util/validate";
import { TextField, Stack, Checkbox } from "@fluentui/react";

interface CustomerProps {
    customer: Customer;
    updateCustomer: (customer: Partial<Customer>) => void;
    updateCustomerCityFromZipCode: (zipcode: string) => void;
    fieldConfig: { [key: string]: Field };
}

const BaseCustomer: React.FunctionComponent<CustomerProps> = props => {
    const { customer, fieldConfig }: any = props;
    const { allowEmail, allowSms } = customer;

    const updateCustomer = (valueType: CustomerValueType) => async (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        if (valueType === "zipCode" && value.length === 4) {
            props.updateCustomerCityFromZipCode(value);
        }
        props.updateCustomer({ [valueType]: value });
    };

    const updateCustomerBool = (valueType: CustomerValueType) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: boolean
    ) => {
        props.updateCustomer({ [valueType]: value });
    };

    const renderField = (
        fieldName: CustomerValueType,
        tabIndexNumber: number
    ) => {
        const field: Field = fieldConfig[fieldName];
        return (
            <TextField
                validateOnLoad={true}
                onGetErrorMessage={validate(field.validation)}
                title={field.placeholder}
                type={field.type}
                placeholder={field.placeholder}
                value={customer[fieldName]}
                onChange={updateCustomer(fieldName)}
                tabIndex={tabIndexNumber}
            />
        );
    };

    return (
        <>
            {renderField("name", 1)}
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item grow={1} className="customer__formStack">
                    <div className="customer__formFields">
                        {renderField("address", 2)}
                        {renderField("email", 4)}
                        {renderField("phone", 5)}
                    </div>
                    <div className="customer__formFields">
                        {renderField("zipCode", 3)}
                        {renderField("city", 6)}
                        <Checkbox
                            className="checkbox-space--bottom"
                            label="Tillad Email Notifikationer"
                            defaultChecked={allowEmail}
                            onChange={updateCustomerBool("allowEmail")}
                        />
                        <Checkbox
                            label="Tillad SMS Notifikationer"
                            defaultChecked={allowSms}
                            onChange={updateCustomerBool("allowSms")}
                        />
                    </div>
                </Stack.Item>
            </Stack>
        </>
    );
};

export default BaseCustomer;
