import BaseApi from "./baseApi";

interface ITaskJournalApi {
    getRecords: (taskId: string) => Promise<TaskJournal[]>;
}

export default class TaskJournalApi extends BaseApi implements ITaskJournalApi {
    async getRecords(taskId?: string): Promise<TaskJournal[]> {
        const url = `/api/taskjournal/${taskId}`;
        return await this.fetchGet(url);
    }
}
