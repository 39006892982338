import * as React from "react";
import {
    getProductList,
    getCar,
    getBookings,
    getProductSearchQuery
} from "../../../state/action";
import {
    updateProductList,
    showProductGroup,
    changeProductAmount,
    updateRepList,
    updateSearchList,
    debitorPriceRequest,
    updateVINSearchList
} from "../../../state/product/action";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { RootState } from "../../../state";
import { useEffect } from "react";
import { addProducts } from "../../../state/order/action";
import ProductGroup from "../ProductGroup";

import { updateProductSearchQuery } from "../../../state/ui/action";
import Search from "../../base/Search";
import { getUserInformation } from "../../../state/action";
import { userHasAccess } from "../../utils/UserHelper";
import PageHeader from "../../layout/PageHeader";
import "../style.css"
import { Spinner, SpinnerSize, Stack, Checkbox, DefaultButton } from "@fluentui/react";
import AutoValidVinSearch from "../AutoValidVinSearch";

let isVinSearching = false;
const ProductOverview: React.FunctionComponent<StateProps> = props => {
    const [showList, updateShowList] = React.useState<boolean>(false);
    const [products, setProducts] = React.useState<ProductGroup[]>(
        props.products
    );

    useEffect(() => {
        filterBySearchTerm();
    }, [props.products]);

    useEffect(() => {
        const { productSearchQuery } = props;
        if (
            productSearchQuery &&
            productSearchQuery.term &&
            productSearchQuery.searchIn
        ) {
            props.updateSearchList(
                {
                    searchIn: productSearchQuery.searchIn,
                    term: productSearchQuery.term
                },
                false
            );
        } else if (props.order.additionalData.orderType === "REPAIR") {
            props.updateRepList();
        } else if (props.car.carId) {
            props.updateProductList(
                props.car.carId,
                props.car.vehicleIdentificationNumber
                    ? props.car.vehicleIdentificationNumber
                    : ""
            );
        }
        updateShowList(true);
    }, []);

    const createOnWindowSelect = (genericArticleId: number) => (
        ev: undefined,
        checked?: boolean
    ) => {
        props.showProductGroup(checked ? true : false, genericArticleId);
    };

    const debitorPriceRequest = (request: ProductPriceRequest[]) =>
        props.debitorPriceRequest(props.order.debitor.no, request);

    const addProducts = (products: Product[]) =>
        props.addProducts(products, props.order.debitor.no);

    const search = (searchIn: string, term: string) => {
        props.updateSearchList({ searchIn, term }, true);
        props.updateSearchProductQuery(searchIn, term);
    };

    const filterBySearchTerm = () => {
        setProducts(props.products);
    };
    const onClickVINSearch = async () => {
        if (!isVinSearching) {
            isVinSearching = true;
            await props.updateVINSearchList(
                props.car.vehicleIdentificationNumber
            );
            isVinSearching = false;
        }
    };

    const render = () => {
        if (showList) {
            let result: JSX.Element = <></>;
            if (props.car.carId !== undefined && props.products.length === 0) {
                result = <Spinner size={SpinnerSize.large} />;
            } else if (products.length === 0) {
                result = <p>Ingen produkter fundet</p>;
            } else {
                result = (
                    <ProductGroup
                        items={products}
                        order={props.order}
                        addProducts={addProducts}
                        changeAmount={props.changeProductAmount}
                        debitorPriceRequest={debitorPriceRequest}
                    />
                );
            }
            return (
                <Stack horizontal tokens={{ childrenGap: 50 }}>
                    <Stack.Item grow={1}>
                        <h5>Filtering</h5>
                        <div>
                            {props.products.map((e, index) => (
                                <Checkbox
                                    key={index}
                                    label={e.name}
                                    checked={e.checked}
                                    onChange={createOnWindowSelect(e.id)}
                                />
                            ))}
                        </div>
                    </Stack.Item>
                    <Stack.Item grow={2} className="product__search">
                        <div className="product__search-bar-wrapper">
                            {userHasAccess(props.userInformation.roles, [
                                "VINSearch"
                            ]) && (
                                <DefaultButton onClick={onClickVINSearch}>
                                    VIN opslag
                                </DefaultButton>
                            )}
                             {userHasAccess(props.userInformation.roles, ["AutovalidVINSearch"]) && (
                               <AutoValidVinSearch />
                            )}
                            <Search
                                query={props.productSearchQuery}
                                onSearch={search}
                            />
                        </div>
                        {result}
                    </Stack.Item>
                </Stack>
            );
        }
    };

    return (
        <div>
            <PageHeader currentPage="Udskiftning af rude" />
            <div className="products__container">{render()}</div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        productSearchQuery: getProductSearchQuery(state),
        order: getBookings(state),
        products: getProductList(state),
        car: getCar(state),
        userInformation: getUserInformation(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => dispatch<any>(updateSearchList(query, merge)),
    updateVINSearchList: (vinNumber: string | undefined) =>
        dispatch<any>(updateVINSearchList(vinNumber)),
    updateSearchProductQuery: (searchIn?: string, term?: string) =>
        dispatch<any>(updateProductSearchQuery(searchIn, term)),
    updateProductList: (carId: number, vin: string) =>
        dispatch<any>(updateProductList(carId, vin)),
    showProductGroup: (checked: boolean, genericArticleId: number) =>
        dispatch(showProductGroup(checked, genericArticleId)),
    addProducts: (products: Product[], debitorNo: string) =>
        dispatch<any>(addProducts(products, debitorNo)),
    changeProductAmount: (productNo: string, amount: number) =>
        dispatch(changeProductAmount(productNo, amount)),
    updateRepList: () => dispatch<any>(updateRepList()),
    debitorPriceRequest: (debitor: string, request: ProductPriceRequest[]) =>
        dispatch<any>(debitorPriceRequest(debitor, request))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(ProductOverview);
