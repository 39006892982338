import * as React from "react";
import { Route, RouteComponentProps, withRouter } from "react-router";
import NavigationHelper from "../../utils/NavigationHelper";
import { userHasAccess } from "../../utils/UserHelper";
import UnauthorizedView from "../../user/UnauthorizedView";
import {
    getUserInformation,
} from "../../../state/action";
import { RootState } from "../../../state";
import { login } from "../../../state/user/action";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";


export interface AuthRouteProps extends RouteComponentProps {
    allowedUserRoles: UserRole[];
    component?:
        | React.ComponentType<RouteComponentProps<any>>
        | React.ComponentType<any>;
    render?: (props: RouteComponentProps<any>) => React.ReactNode;
    path?: string | string[];
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;
}

const AuthRoute: React.FunctionComponent<StateProps & AuthRouteProps> = props => {
    

    NavigationHelper.setHistory(props.history);
    if (userHasAccess(props.userInformation.roles, props.allowedUserRoles)) {
        return (
            <Route
                path={props.path}
                exact={props.exact}
                component={props.component}
            />
        );
    }
    return (
        <Route
            path={props.path}
            exact={props.exact}
            component={() => <UnauthorizedView login={props.login} />}
        />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        userInformation: getUserInformation(state),
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    login: (userName: string, password: string) =>
        dispatch<any>(login(userName, password)),
  
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(
    mapStateToProps,
    mapToDispatchProps
)(withRouter(AuthRoute));
