import { PrimaryButton, Modal, DetailsList, DetailsListLayoutMode, CheckboxVisibility } from "@fluentui/react";
import * as React from "react";


interface Props {
    oldProduct: OldProduct;
    buttonText: string;
    useIcon?: boolean;
    margin?: boolean;
}

const OldProductDetailDialog: React.FunctionComponent<Props> = props => {
    const [showDialog, toggleShowDialog] = React.useState(false);

    const { oldProduct, buttonText, useIcon, margin } = props;

    const changeShowDialog = () => toggleShowDialog(!showDialog);

    if (!showDialog) {
        return useIcon ? (
            <PrimaryButton
                iconProps={{ iconName: "SkypeMessage" }}
                onClick={changeShowDialog}
            />
        ) : (
            <PrimaryButton
                style={margin ? { marginRight: "10px" } : {}}
                onClick={changeShowDialog}
                text={buttonText}
            />
        );
    } else {
        return (
            <Modal
                titleAriaId={oldProduct.productNumber}
                isOpen={showDialog}
                onDismiss={changeShowDialog}
            >
                <DetailsList
                    layoutMode={DetailsListLayoutMode.justified}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    items={[oldProduct]}
                    columns={[
                        {
                            key: oldProduct.dbNr,
                            minWidth: 40,
                            maxWidth: 40,
                            name: "DB Nr",
                            fieldName: "dbNr"
                        },
                        {
                            key: oldProduct.eurocode,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Eurocode",
                            fieldName: "eurocode"
                        },
                        {
                            key: oldProduct.oemNr,
                            minWidth: 40,
                            maxWidth: 40,
                            name: "OEM",
                            fieldName: "oemNr"
                        },
                        {
                            key: oldProduct.autoverNr,
                            minWidth: 70,
                            maxWidth: 70,
                            name: "Autover Nr",
                            fieldName: "autoverNr"
                        },
                        {
                            key: oldProduct.agcNr,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "AGC Nr",
                            fieldName: "agcNr"
                        },
                        {
                            key: oldProduct.measurement,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Mål",
                            fieldName: "measurement"
                        },
                        {
                            key: oldProduct.time,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Tid",
                            fieldName: "time"
                        },
                        {
                            key: oldProduct.windowReady,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Klar rude",
                            fieldName: "windowReady"
                        },
                        {
                            key: oldProduct.window1F,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "1F rude",
                            fieldName: "window1F"
                        },
                        {
                            key: oldProduct.window2F,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "2F rude",
                            fieldName: "window2F"
                        },
                        {
                            key: oldProduct.clips,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Clips",
                            fieldName: "clips"
                        },
                        {
                            key: oldProduct.lists,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Lister",
                            fieldName: "lists"
                        },
                        {
                            key: oldProduct.st1St5St6,
                            minWidth: 70,
                            maxWidth: 70,
                            name: "St1,St5,St6",
                            fieldName: "st1St5St6"
                        }
                    ]}
                />
                <DetailsList
                    layoutMode={DetailsListLayoutMode.justified}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    items={[oldProduct]}
                    columns={[
                        {
                            key: oldProduct.st8,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "St8",
                            fieldName: "st8"
                        },
                        {
                            key: oldProduct.rubberList,
                            minWidth: 70,
                            maxWidth: 70,
                            name: "Gummiliste",
                            fieldName: "rubberList"
                        },
                        {
                            key: oldProduct.glueList,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Limliste",
                            fieldName: "glueList"
                        },
                        {
                            key: oldProduct.coverStrip,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Dækliste",
                            fieldName: "coverStrip"
                        },
                        {
                            key: oldProduct.sensor,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Sensor",
                            fieldName: "sensor"
                        },
                        {
                            key: oldProduct.glue,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Lim",
                            fieldName: "glue"
                        },
                        {
                            key: oldProduct.mounting,
                            minWidth: 70,
                            maxWidth: 70,
                            name: "Montering",
                            fieldName: "mounting"
                        },
                        {
                            key: oldProduct.enviroment,
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Miljø",
                            fieldName: "enviroment"
                        },
                        {
                            key: oldProduct.pK1,
                            minWidth: 40,
                            maxWidth: 40,
                            name: "PK1",
                            fieldName: "pK1"
                        },
                        {
                            key: oldProduct.pK2,
                            minWidth: 40,
                            maxWidth: 40,
                            name: "PK2",
                            fieldName: "pK2"
                        },
                        {
                            key: oldProduct.pK3,
                            minWidth: 40,
                            maxWidth: 40,
                            name: "PK3",
                            fieldName: "pK3"
                        },
                        {
                            key: oldProduct.pK4,
                            minWidth: 40,
                            maxWidth: 40,
                            name: "PK4",
                            fieldName: "pK4"
                        },
                        {
                            key: oldProduct.calibration,
                            minWidth: 50,
                            name: "Kaliberingstype",
                            fieldName: "calibration"
                        }
                    ]}
                />
            </Modal>
        );
    }
};

export default OldProductDetailDialog;
