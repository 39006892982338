import { IDropdownOption, IContextualMenuItem } from "@fluentui/react";
import { addDays } from "./DateHelper";

export const getDepartmentDropdownOptions = (
    departments: Department[] | undefined
): IDropdownOption[] => {
    if (departments === undefined) {
        return [] as IDropdownOption[];
    }

    const dropdownOptions: IDropdownOption[] = [...departments]
        .sort(sortDepartment)
        .reduce((result: IDropdownOption[], value) => {
            if (value.id) {
                const department: IDropdownOption = {
                    text: value.name,
                    key: value.id
                };
                return [...result, department];
            }
            return result;
        }, [] as IDropdownOption[]);

    return dropdownOptions;
};

export const sortDepartment = (a: Department, b: Department) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
};

export const getDepartmentContextualMenuItemOptions = (
    departments: Department[]
): IContextualMenuItem[] => {
    const dropdownOptions: IContextualMenuItem[] = departments
        .sort(sortDepartment)
        .reduce((result: IContextualMenuItem[], value) => {
            if (value.id) {
                const department: IContextualMenuItem = {
                    text: value.name,
                    key: value.id
                };
                return [...result, department];
            }
            return result;
        }, [] as IContextualMenuItem[]);
    return dropdownOptions;
};

export const findParentDepartment = (
    department: Department | undefined,
    departments: Department[]
): Department => {
    let result = {} as Department;

    if (department && department.parentDepartmentId) {
        const temp = departments.find(
            c => c.id === (department as Department).parentDepartmentId
        );
        if (temp) {
            result = temp;
        }
    }

    return result;
};

export const addStringTimeToDate = (date: Date, time: string): Date => {
    const copiedDate = new Date(date.getTime());
    const hourMin = time.split(":");
    copiedDate.setHours(
        parseInt(hourMin[0], 10),
        parseInt(hourMin[1], 10),
        0,
        0
    );
    return copiedDate;
};

export const getNextWeekDay = (date: Date) => {
    const temp = addDays(date, 1);
    let copiedDate = new Date(temp.getTime());
    if (copiedDate.getDay() === 5 || copiedDate.getDay() === 6) {
        copiedDate = getNextWeekDay(date);
    }
    return copiedDate;
};
