import * as React from "react";
import CustomRuleTable from "./CustomRuleTable";
import { useCallback, useEffect, useState } from "react";
import api from "../../api";
import "./style.css";
import PageHeader from "../layout/PageHeader";
import { PrimaryButton } from "@fluentui/react";
import CustomRuleDialog from "./CustomRuleDialog";
import Pagination from "../base/Pagination";

interface CustomRuleTableColumn {
    name: string;
}

const ruleTableColumns: CustomRuleTableColumn[] = [
    { name: "#" },
    { name: "Regl Navn" },
    { name: "Bilmærke" },
    { name: "Bil Model" }
];

const ruleSetColumns: CustomRuleTableColumn[] = [
    { name: "#" },
    { name: "Reglsæt Navn" }
];



const DepartmentRuleSet: React.FunctionComponent = props => {
    const [rules, setRules] = useState<Rule[]>([]);
    const [ruleSets, setRuleSets] = useState<RuleSet[]>([]);

    const [rulesPageSize, setRulesPageSize] = useState(10);
    const [rulesSetPageSize, setRuleSetsPageSize] = useState(10);
    const [rulesPage, setRulesPage] = useState(0);
    const [ruleSetsPage, setRuleSetsPage] = useState(0);
    const [rulesTotalPages, setRulesTotalPages] = useState(0);
    const [ruleSetsTotalPages, setRuleSetsTotalPages] = useState(0);

    const [showNewRuleDialog, setShowNewRuleDialog] = useState<boolean>(false);
    const [showNewRuleSetDialog, setShowNewRuleSetDialog] = useState<boolean>(
        false
    );

    const fetchRules =  () => {
        api.DepartmentRuleSetApi.getRulesPaginated(rulesPageSize, rulesPage).then(
            data => {
                setRules(data.rules);
                setRulesTotalPages(data.totalPages);
            }
        );
    }

    const fetchRuleSets = () => {
        api.DepartmentRuleSetApi.getRuleSets(rulesSetPageSize, ruleSetsPage).then(
            data => {
                setRuleSets(data.ruleSets);
                setRuleSetsTotalPages(data.totalPages);
            }
        );
    }
    
    useEffect(() => {
        function handleRuleSetRefreshEvent () {
            fetchRuleSets();
        }

        document.addEventListener('RULESET_REFRESH', handleRuleSetRefreshEvent)
        
        return () => {
            document.removeEventListener('RULESET_REFRESH', handleRuleSetRefreshEvent)
        }
    }, [])

    useEffect(() => {
        function handleRulesRefreshEvent () {
            fetchRules();
        }

        document.addEventListener('RULES_REFRESH', handleRulesRefreshEvent)
        
        return () => {
            document.removeEventListener('RULES_REFRESH', handleRulesRefreshEvent)
        }
    })

    useEffect(() => {
        fetchRules();
    }, [rulesPage]);

    useEffect(() => {
        fetchRuleSets();
    }, [ruleSetsPage]);

    const onToggleNewRuleDialog = useCallback(() => {
        setShowNewRuleDialog(!showNewRuleDialog);
    }, [showNewRuleDialog]);

    const onToggleNewRuleSetDialog = useCallback(() => {
        setShowNewRuleSetDialog(!showNewRuleSetDialog);
    }, [showNewRuleSetDialog]);

    return (
        <>
            <PageHeader
                currentPage={"Afdeling reglersæt"}
                noBreadcrumb={true}
            />
            <div className="rules__container">
                <div>
                    <div className="custom__table_header">
                        <p>Regler</p>
                        <PrimaryButton
                            className="custom__table_button"
                            onClick={onToggleNewRuleDialog}
                        >
                            {" Opret ny regl"}
                        </PrimaryButton>
                    </div>
                    <div className="rules__table">
                        <CustomRuleTable
                            data={rules}
                            columns={ruleTableColumns}
                            rule={true}
                        />
                        <Pagination
                            updatePagination={setRulesPage}
                            currentPage={rulesPage}
                            pages={rulesTotalPages}
                        />
                    </div>
                    <CustomRuleDialog isRuleDialog={true} hidden={!showNewRuleDialog} onToggleDialog={onToggleNewRuleDialog}></CustomRuleDialog>
                </div>
                <div>
                    <div className="custom__table_header">
                        <p>Reglsæt</p>

                        <PrimaryButton
                            className="custom__table_button"
                            onClick={onToggleNewRuleSetDialog}
                        >
                            {" Opret ny reglsæt"}
                        </PrimaryButton>
                    </div>
                    <div className="ruleSet__table">
                        <CustomRuleTable
                            data={ruleSets}
                            columns={ruleSetColumns}
                            rule={false}
                        />
                    </div>
                    <Pagination
                        updatePagination={setRuleSetsPage}
                        currentPage={ruleSetsPage}
                        pages={ruleSetsTotalPages}
                    />
                </div>
                <CustomRuleDialog isRuleDialog={false} hidden={!showNewRuleSetDialog} onToggleDialog={onToggleNewRuleSetDialog}></CustomRuleDialog>
            </div>
        </>
    );
};

export default DepartmentRuleSet;
