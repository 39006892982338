import { IContextualMenuItem, IDropdownOption } from "@fluentui/react";

export const getDropdownForOperators = (
    operators: UserInformation[]
): IDropdownOption[] => {
    let tmp = [...operators];
    const emptyOperator = {} as UserInformation;
    emptyOperator.id = "00000000-0000-0000-0000-000000000000";
    emptyOperator.name = "";
    tmp.splice(0, 0, emptyOperator);
    return tmp.map(operator => ({ key: operator.name, text: operator.name }));
};

export const getMenuItemsForOperators = (
    operators: UserInformation[]
): IContextualMenuItem[] => {
    return operators
        .map(operator => ({ key: operator.name, text: operator.name }))
        .reduce((
            result,
            currentValue //get all uniq values
        ) => {
            if (result.find(x => x.key === currentValue.key) === undefined) {
                return [...result, currentValue];
            }
            return result;
        }, [] as IContextualMenuItem[]);
};
