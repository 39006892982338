import * as React from "react";
import { orderToNotificationModel } from "../../utils/smsHelper";
import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton, TextField } from "@fluentui/react";

interface Props {
    order: Order;
    sendSms: (model: NotificationModel) => void;
    useIcon?: boolean;
    margin?: boolean;
}

const SendTextWindowMobile: React.FunctionComponent<Props> = props => {
    const [showWindow, toggleShowWindow] = React.useState(false);
    const [messageType, setMessageType] = React.useState<NotificationType>(
        "ON_MY_WAY"
    );
    const [editstate, toggleSmsEdit] = React.useState(false);
    const [comment, changeComment] = React.useState("");

    const onCommentChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue) {
            changeComment(newValue);
        }
    };
    const changeShowWindow = () => toggleShowWindow(!showWindow);

    const SendText = () => {
        const smsModel = orderToNotificationModel(
            props.order,
            messageType,
            comment
        );
        props.sendSms(smsModel);

        changeShowWindow();
    };

    const prefillText812 = React.useCallback(
        () => setMessageType("ARRIVE_BETWEEN_8_12"),
        []
    );

    const prefillText1014 = React.useCallback(
        () => setMessageType("ARRIVE_BETWEEN_10_14"),
        []
    );

    const prefillText1216 = React.useCallback(
        () => setMessageType("ARRIVE_BETWEEN_12_16"),
        []
    );

    const onMyWay = React.useCallback(() => setMessageType("ON_MY_WAY"), []);
    const toggleEditState = () => {
        toggleSmsEdit(!editstate);
    };

    if (!showWindow) {
        return props.useIcon ? (
            <PrimaryButton
                iconProps={{ iconName: "SkypeMessage" }}
                onClick={changeShowWindow}
            />
        ) : (
            <PrimaryButton
                style={props.margin ? { marginRight: "10px" } : {}}
                onClick={changeShowWindow}
                text={"SEND 'Jeg er på vej' SMS"}
            />
        );
    } else {
        const iconStyle: React.CSSProperties = {
            color: "green",
            marginLeft: "20px"
        };
        return (
            <Dialog
                hidden={false}
                onDismiss={changeShowWindow}
                minWidth={460}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Send besked",
                    subText: "Tryk på rediger, hvis du vil tilføje en kommentar"
                }}
            >
                <DefaultButton
                    checked={messageType === "ON_MY_WAY"}
                    onClick={onMyWay}
                    style={{ width: "200px" }}
                >
                    Jeg er på vej
                </DefaultButton>
                {props.order.notificationStatus &&
                    props.order.notificationStatus.smsOnMyWay && (
                        <Icon style={iconStyle} iconName="SkypeCheck" />
                    )}
                <br />
                <DefaultButton
                    checked={messageType === "ARRIVE_BETWEEN_8_12"}
                    onClick={prefillText812}
                    style={{ margin: "10px 0", width: "200px" }}
                >
                    Vi kommer mellem 8-12
                </DefaultButton>
                {props.order.notificationStatus &&
                    props.order.notificationStatus.smsArriveBetween8And12 && (
                        <Icon style={iconStyle} iconName="SkypeCheck" />
                    )}
                <br />
                <DefaultButton
                    checked={messageType === "ARRIVE_BETWEEN_10_14"}
                    onClick={prefillText1014}
                    style={{ margin: "0 0 10px 0", width: "200px" }}
                >
                    Vi kommer mellem 10-14
                </DefaultButton>
                {props.order.notificationStatus &&
                    props.order.notificationStatus.smsArriveBetween10And14 && (
                        <Icon style={iconStyle} iconName="SkypeCheck" />
                    )}
                <br />
                <DefaultButton
                    checked={messageType === "ARRIVE_BETWEEN_12_16"}
                    onClick={prefillText1216}
                    style={{ width: "200px" }}
                >
                    Vi kommer mellem 12-16
                </DefaultButton>
                {props.order.notificationStatus &&
                    props.order.notificationStatus.smsArriveBetween12And16 && (
                        <Icon style={iconStyle} iconName="SkypeCheck" />
                    )}
                <DialogFooter>
                    {messageType === "ON_MY_WAY" ? (
                        <>
                            <TextField
                                disabled={!editstate}
                                value={comment}
                                multiline
                                rows={3}
                                onChange={onCommentChange}
                            />
                            {!editstate ? (
                                <div
                                    onClick={toggleEditState}
                                    style={{
                                        fontSize: "12px",
                                        cursor: "pointer"
                                    }}
                                >
                                    Redigér
                                </div>
                            ) : null}
                            <br />
                            {comment}
                        </>
                    ) : null}

                    <DefaultButton onClick={changeShowWindow}>
                        Luk{" "}
                    </DefaultButton>
                    <PrimaryButton onClick={SendText}>Send </PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }
};

export default SendTextWindowMobile;
