import produce from "immer";
import { Actions } from "./action";

export interface TransferState {
    transferlist: Transfer[];
    binlist: BinList[];
}

const initialState: TransferState = {
    transferlist: [],
    binlist: []
};

export const reducer = (
    uiState = initialState,
    action: Actions
): TransferState =>
    produce(uiState, draft => {
        switch (action.type) {
            case "UPDATE_TRANSFERS": {
                draft.transferlist = action.transfers;
                return;
            }
            case "UPDATE_BINLIST": {
                draft.binlist = action.binlist;
                return;
            }
        }
    });

export const getTransfers = (state: TransferState) => state.transferlist;

export const getBinList = (state: TransferState) => state.binlist;

export default reducer;
