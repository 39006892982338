import { FreeCarSku } from "../typings";

export const orderToOrderConfirmationModel = (
    order: Order,
    type: NotificationType
): IOrderConfirmationModel => {
    const {
        additionalData,
        timeAndPlace,
        customer,
        car,
        debitor,
        items
    } = order;
    const {
        orderNr,
        orderId,
        created,
        orderStatus,
        discountPercent: aDicsountPercent,
        discountAmount,
        externalOrderComment
    } = additionalData;
    const {
        department,
        date,
        mobileServiceLocation,
        from = "",
        to = ""
    } = timeAndPlace;
    const {
        name: cName,
        address: cAddress,
        city,
        zipCode,
        email,
        phone
    } = customer;
    const {
        insurance = "",
        registrationNumber = "",
        make = "",
        model = ""
    } = car;
    const { cvr } = debitor;
    let dAddress = "",
        dName = "";
    if (department) {
        const { address, name } = department;
        dAddress = address;
        dName = name;
    }

    let address = department
        ? department.address
            ? department.address
            : "."
        : ".";
    if (mobileServiceLocation && mobileServiceLocation.address) {
        address = mobileServiceLocation.address;
    }
    let order_id = orderId || "";
    let order_number = orderNr || orderId;
    let order_no = order_number || "";
    const oItems = items.map(item => {
        return {
            Description: item.name,
            Amount: item.amount,
            Payment: item.itemPrice,
            Discount_Percent: item.discountPercent,
            ItemNo: item.itemNo
        };
    });

    return {
        Order_Id: order_id,
        Order_No: order_no,
        Order_Status: orderStatus,
        Order_Date: created,
        Order_Comment: externalOrderComment,
        Department_Name: dName,
        Department_Address: dAddress,
        Customer_Name: cName,
        Debitor_CVR: cvr,
        Items: oItems,
        Customer_Address: cAddress,
        Customer_Zipcode: zipCode,
        Customer_City: city,
        Customer_Phone: phone,
        Customer_Email: email,
        Car_Insurance: insurance,
        Car_Reg_nr: registrationNumber,
        Car_Make: make,
        Car_Model: model,
        Address: address,
        Discount_Percent: aDicsountPercent,
        Discount_Amount: discountAmount,
        OrderDueDate: date || undefined,
        TimeSlotFrom: from,
        TimeSlotTo: to,
        Type: type,
        Order_Type: additionalData.orderType,
        HasFreeCar: oItems.findIndex(x => x.ItemNo === FreeCarSku.CAR_ONE) > -1
    };
};

export const orderToOrderReceiptModel = (order: Order): IOrderReceiptModel => {
    const {
        additionalData,
        timeAndPlace,
        customer,
        car,
        debitor,
        items
    } = order;
    const { to = "", from = "", department, date } = timeAndPlace;
    const {
        orderNr,
        orderId,
        created,
        orderType,
        createdBy,
        discountPercent: aDicsountPercent,
        discountAmount
    } = additionalData;
    let navLocationCode = "";
    if (department) {
        navLocationCode = department.navLocationCode;
    }
    const {
        name: cName,
        address: cAddress,
        city,
        zipCode,
        email,
        phone
    } = customer;
    const {
        insurance = "",
        registrationNumber = "",
        model = "",
        make = "",
        leasing = "",
        leaser = "",
        modelYear = 0
    } = car;
    const {
        cvr,
        debitorName,
        address: dAddress,
        zipCode: dZipCode,
        email: dEmail,
        phone: dPhone,
        city: dCity
    } = debitor;
    const order_number = orderNr || orderId;
    const order_no = order_number || "";
    const oItems = items.map(item => {
        return {
            Description: item.name,
            Amount: item.amount,
            Payment: item.itemPrice,
            Discount_Percent: item.discountPercent
        };
    });

    return {
        Order_No: order_no,
        Order_Date: date ? date : undefined,
        TimeSlotFrom: from,
        TimeSlotTo: to,
        Customer_Name: cName,
        Customer_Phone: phone,
        Customer_Email: email,
        Customer_Address: cAddress,
        Customer_Zipcode: zipCode,
        Customer_City: city,
        Order_Type: orderType,
        NavLocationCode: navLocationCode,
        CreatedBy: createdBy,
        Created: created,
        Items: oItems,
        Debitor_Name: debitorName,
        Debitor_Cvr: cvr,
        Debitor_Address: dAddress,
        Debitor_ZipCode: dZipCode,
        Debitor_Email: dEmail,
        Debitor_City: dCity,
        Debitor_Phone: dPhone,
        Car_Reg_nr: registrationNumber,
        Car_Model: model,
        Car_Brand: make,
        Car_Year: modelYear,
        Car_Insurance: insurance,
        Car_Leasing: leasing,
        Car_Leaser: leaser,
        Discount_Percent: aDicsountPercent,
        Discount_Amount: discountAmount
    };
};
