import produce from "immer";
import { Actions } from "./action";

export interface TimeslotState {
    zipCodes?: ZipCodeDepartmentAvailability[];
    availableDepartmentBooking?: AvailableDepartmentBooking[];
    year?: number;
    month?: number;
}

const initialState: TimeslotState = {
};

export const reducer = (
    uiState = initialState,
    action: Actions
): TimeslotState =>
    produce(uiState, draft => {
        switch (action.type) {
            case "UPDATE_AVAILABLE_TIMESLOTS": {
                draft.availableDepartmentBooking = action.timeslots;
                draft.year = action.year;
                draft.month = action.month;
                return;
            }
            case "ZIPCODE_DEPARTMENT": {
                draft.zipCodes = action.zipCodeDepartments;
                return;
            }
        }
    });

export const getAvailableDepartmentBooking = (state: TimeslotState) =>
{
    if(state.zipCodes && state.zipCodes.length > 0 && state.availableDepartmentBooking && state.year && state.month)
    {
        const year = state.year;
        const month = state.month;
        const zip = state.zipCodes[0];

        return state.availableDepartmentBooking.map(data => {
            
            const date = new Date(year, month - 1, data.dayOfMonth);
            switch (date.getDay()) {
                case 0:
                    if (!zip.sunday) {
                        data.stoneChipAvailable = false;
                        data.windowReplaceAvailable = false;
                    }
                    break;
                case 1:
                    if (!zip.monday) {
                        data.stoneChipAvailable = false;
                        data.windowReplaceAvailable = false;
                    }
                    break;
                case 2:
                    if (!zip.tuesday) {
                        data.stoneChipAvailable = false;
                        data.windowReplaceAvailable = false;
                    }
                    break;
                case 3:
                    if (!zip.wednesday) {
                        data.stoneChipAvailable = false;
                        data.windowReplaceAvailable = false;
                    }
                    break;
                case 4:
                    if (!zip.thursday) {
                        data.stoneChipAvailable = false;
                        data.windowReplaceAvailable = false;
                    }
                    break;
                case 5:
                    if (!zip.friday) {
                        data.stoneChipAvailable = false;
                        data.windowReplaceAvailable = false;
                    }
                    break;
                case 6:
                    if (!zip.saturday) {
                        data.stoneChipAvailable = false;
                        data.windowReplaceAvailable = false;
                    }
                    break;
            }
            return data;
        });
    }
   
    return state.availableDepartmentBooking;
} 

export const getZipCodes = (state: TimeslotState) => state.zipCodes;

export default reducer;