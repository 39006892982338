import * as React from "react";
import TableHelper from "../../utils/TableHelper";
import { useEffect } from "react";
import ProductColumn from "../ProductColumn";
import "../style.css"
import { IColumn, Checkbox, TooltipHost, DefaultButton, TextField, DetailsList, DetailsListLayoutMode, CheckboxVisibility } from "@fluentui/react";

interface Props {
    products: Product[];
    selectedProducts: (products: Product[]) => void;
    showStorageWindow: (status: Status[]) => void;
}

const SimpleProducts: React.FunctionComponent<Props> = props => {
    const [items, setItems] = React.useState<Array<Product>>(props.products);

    useEffect(() => {
        const allreadyAdded = items.filter(x => x.added);
        const searchResult = props.products.filter(
            x => allreadyAdded.find(y => y.no === x.no) === undefined
        );
        setItems([...allreadyAdded, ...searchResult]);
    }, [props.products]);

    useEffect(() => {
        const selectedProducts = items.filter(x => x.added);
        props.selectedProducts(selectedProducts);
    }, [items]);

    const changeAmount = (product: Product) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        const newList = [...items];
        const productFromList = items.find(x => x === product);
        productFromList ? (productFromList.amount = parseInt(value, 10)) : null;
        setItems(newList);
    };

    const showStorageWindow = (status: Status[]) => () =>
        props.showStorageWindow(status);

    const changeAdded = (product: Product) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        const newList = [...items];
        const index = items.findIndex(x => x.no === product.no);
        newList[index].added = !newList[index].added;
        setItems(newList);
    };
    const columns: IColumn[] = [
        TableHelper.col("checkbox", "Tilføj", 46, 46, (e: Product) => (
            <Checkbox
                checked={e.added ? e.added : false}
                onChange={changeAdded(e)}
            />
        )),
        TableHelper.col(
            "id",
            "Varenummer",
            16,
            76,
            (e: Product) => <ProductColumn product={e} />,
            "col-id"
        ),
        TableHelper.col(
            "name",
            "Navn",
            16,
            326,
            (e: Product) => (
                <TooltipHost
                    content={e.name}
                    id={"name"}
                    calloutProps={{ gapSpace: 0 }}
                >
                    <p>{e.name}</p>
                    {e.description ? <p>{e.description}</p> : null}
                </TooltipHost>
            ),
            "col-name"
        ),

        TableHelper.col(
            "stock",
            "Lager",
            16,
            50,
            (e: Product) => {
                if (e.isService) {
                    return null;
                }
                return (
                    <>
                        <label>Lager:</label>
                        <div className="storage_button__container">
                            <DefaultButton
                                text={e.status
                                    .reduce(
                                        (sum, current) => sum + current.amount,
                                        0
                                    )
                                    .toString()}
                                onClick={showStorageWindow(e.status)}
                            />
                        </div>
                    </>
                );
            },
            "col-storage"
        ),

        TableHelper.col("amount", "Antal", 20, 20, (e: Product) => (
            <TextField
                style={{ minWidth: "50px" }}
                placeholder="Antal"
                type="number"
                value={e.amount.toString()}
                onChange={changeAmount(e)}
            />
        ))
    ];
    return (
        <DetailsList
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            columns={columns}
            items={items.slice(0, 10)}
            checkboxVisibility={CheckboxVisibility.hidden}
            className="ProductPriceList"
        />
    );
};

export default SimpleProducts;
