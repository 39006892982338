const set = <T extends object, TValue>(
    obj: T,
    propertyName: string,
    propertValue: TValue
) => ({ ...obj, [propertyName]: propertValue });

const setIn = <TObject extends object, TValue>(
    obj: TObject | any,
    path: string[],
    value: TValue
): any => {
    const [p, ...rest] = path;

    if (path.length === 0) {
        return obj;
    }

    return path.length === 1
        ? set(obj, p, value)
        : set(obj, p, setIn(obj[p] || {}, rest, value));
};

export const setInStrict = <TObject extends object, TValue>(
    obj: TObject | any,
    path: string[],
    value: TValue
): TObject => {
    const [p, ...rest] = path;

    if (path.length === 0) {
        return obj;
    }

    if (!obj.hasOwnProperty(p)) {
        throw new Error("Property does not exist");
    }

    return path.length === 1
        ? set(obj, p, value)
        : set(obj, p, setIn(obj[p], rest, value));
};
