import { DialogType, Dialog, Label, TextField, DialogFooter, DefaultButton, PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useCallback, useState } from "react";

interface SetOnHoldProps {
    title: string;
    subText: string;
    hideDialog: boolean;
    onConfirm: () => void;
    onDismiss: () => void;
    updateAdditionalData: (
        additionalDataFieldName: string,
        newValue: any
    ) => void;
    confirmText: string;
    cancelText: string;
    switchButtons?: boolean;
}

const SetOnHoldCommentDialog: React.FunctionComponent<SetOnHoldProps> = props => {
    const dialogContentProps = {
        type: DialogType.normal,
        title: props.title,
        subText: props.subText
    };

    const [comment, setComment] = useState<string>("");

    const onConfirm = useCallback(() => {
        if (comment) {
            props.onConfirm();
        } else {
            alert("Kommentar skal udfyldes");
        }
    }, [comment]);

    const onCommentChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setComment(value);
        props.updateAdditionalData("onHoldComment", value);
    };

    return (
        <Dialog
            hidden={props.hideDialog}
            dialogContentProps={dialogContentProps}
            onDismiss={props.onDismiss}
        >
            <Label>Kommentar</Label>
            <TextField required multiline rows={3} onChange={onCommentChange} />
            <DialogFooter>
                {props.switchButtons ? (
                    <>
                        <DefaultButton
                            onClick={props.onDismiss}
                            text={props.cancelText}
                        />
                        <PrimaryButton
                            onClick={onConfirm}
                            text={props.confirmText}
                        />
                    </>
                ) : (
                    <>
                        <PrimaryButton
                            onClick={onConfirm}
                            text={props.confirmText}
                        />
                        <DefaultButton
                            onClick={props.onDismiss}
                            text={props.cancelText}
                        />
                    </>
                )}
            </DialogFooter>
        </Dialog>
    );
};

export default SetOnHoldCommentDialog;
