import * as React from "react";
import PageHeader from "../layout/PageHeader";
import LoginUser from "./LoginUser";

interface UnauthorizedViewProps {
    login: (userName: string, password: string) => void;
}

const UnauthorizedView: React.FunctionComponent<UnauthorizedViewProps> = props => {
    return (
        <>
            <PageHeader currentPage="Unauthorized Login" noBreadcrumb={true} />
            <div>
                <p>Du har ikke adgang til den ønskede side </p>
                <LoginUser login={props.login}></LoginUser>
            </div>
        </>
    );
};

export default UnauthorizedView;
