import {
    PrimaryButton,
    Stack,
    Label,
    Dropdown,
    IDropdownOption,
    Checkbox
} from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { RootState } from "../../state";
import { updateOperators } from "../../state/user/action";
import DropdownPanel from "../base/DropdownPanel";
import { getDepartments, getOperators } from "../../state/action";
import { getRegionDropdownList } from "../departments/helpers/region";
import { getSpecificStatusText } from "../utils/statusHelper";
import { sortDepartment } from "../utils/departmentHelper";
import {
    complaintOptionList,
    internalComplaintOptions
} from "./helpers/complaintOptions";
import "./style.css";
import CustomDatePicker from "../base/CustomDatePicker";

type ComplaintType = "INTERNAL_COMPLAINT" | "COMPLAINT";

interface ReportFilter {
    issues: string[];
    operators: string[];
    departments: string[];
    complaintType?: ComplaintType;
    status?: OrderStatus;
    region?: string;
    fromDate?: Date;
    toDate?: Date;
    byOtherIssue: boolean;
}

const internalComplainNames = internalComplaintOptions.map(
    complaint => complaint.complaintReason
);
const compalintNames = complaintOptionList.map(
    complaint => complaint.complaintReason
);

const complaintOptions: string[] = Array.from(
    new Set([...internalComplainNames, ...compalintNames])
);

const ComplaintReport: React.FunctionComponent<StateProps> = props => {
    const [reportFilter, setReportFilter] = React.useState<ReportFilter>({
        issues: [],
        operators: [],
        departments: [],
        byOtherIssue: false
    });
    const [isIssueSelected, setIssueSelectedStatus] = React.useState<boolean>(
        false
    );

    const sortedDepartments = props.departments.sort(sortDepartment);
    const uniqueDepartment: Department[] = [];
    sortedDepartments.forEach(element => {
        if (
            !uniqueDepartment.some(
                ele => element.navLocationCode === ele.navLocationCode
            )
        )
            uniqueDepartment.push(element);
    });

    const updateReportFilterList = (property: string, value?: string) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        if (value) {
            if (checked) {
                if (
                    reportFilter["issues"].length < 1 &&
                    property === "issues"
                ) {
                    setIssueSelectedStatus(true);
                    setReportFilter({
                        ...reportFilter,
                        [property]: [...reportFilter[property], value]
                    });
                } else if (property === "operators")
                    setReportFilter({
                        ...reportFilter,
                        [property]: [...reportFilter[property], value]
                    });
            } else if (
                property === "departments" ||
                property === "issues" ||
                property === "operators"
            ) {
                setReportFilter({
                    ...reportFilter,
                    [property]: reportFilter[property].filter(
                        (x: string) => x !== value
                    )
                });

                if (property === "issues") setIssueSelectedStatus(false);
            }
        }
    };

    const updateDepartmentsFilterReportList = (value?: string) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        if (value) {
            const allDeptartmentsWithNavCode = props.departments
                .filter(element => element.navLocationCode === value)
                .map(({ id }) => id) as string[];
            if (checked) {
                setReportFilter({
                    ...reportFilter,
                    ["departments"]: reportFilter["departments"].concat(
                        allDeptartmentsWithNavCode
                    )
                });
            } else {
                setReportFilter({
                    ...reportFilter,
                    ["departments"]: reportFilter["departments"].filter(
                        (x: string) => {
                            return allDeptartmentsWithNavCode.indexOf(x) === -1;
                        }
                    )
                });
            }
        }
    };

    const onOtherIssueToggle = () => {
        setReportFilter({
            ...reportFilter,
            byOtherIssue: !reportFilter.byOtherIssue
        });

        isIssueSelected
            ? setIssueSelectedStatus(false)
            : setIssueSelectedStatus(true);
    };

    const onFromDateChange = (fromDate: Date | undefined) =>
        setReportFilter({ ...reportFilter, fromDate });

    const onToDateChange = (toDate: Date | undefined) =>
        setReportFilter({ ...reportFilter, toDate });

    const onRegionChange = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => setReportFilter({ ...reportFilter, region: option.key as string });

    const onComplaintTypeChange = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) =>
        setReportFilter({
            ...reportFilter,
            complaintType: option.key as ComplaintType
        });
    const onOrderStatusChange = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) =>
        setReportFilter({ ...reportFilter, status: option.key as OrderStatus });
    const onFormSubmit = async () => {
        const res = await fetch("/api/complaint/report", {
            credentials: "same-origin",
            method: "POST",
            headers: {
                Accept: "text/csv",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(reportFilter),
            redirect: "manual"
        });
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Reklamation Rapport.csv";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
    };

    const complaintTypeOptions: IDropdownOption[] = [
        { key: "", text: "" },
        { key: "INTERNAL_COMPLAINT", text: "Intern Reklamation" },
        { key: "COMPLAINT", text: "Reklamation" }
    ];

    return (
        <>
            <h1>Reklamation Rapport</h1>
            <div className="custom_container">
                <Stack
                    className="filter__menu"
                    horizontal
                    tokens={{ childrenGap: 5 }}
                    horizontalAlign="start"
                >
                    <Stack.Item>
                        <Label>Reklamationstype</Label>
                        <Dropdown
                            calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                            options={complaintTypeOptions}
                            onChange={onComplaintTypeChange}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Order status</Label>
                        <Dropdown
                            calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                            options={getSpecificStatusText([
                                "PROCESSING",
                                "FINISHED",
                                "CANCELLED",
                                "ON_HOLD",
                                "SERVICE_CALL"
                            ])}
                            onChange={onOrderStatusChange}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Region</Label>
                        <Dropdown
                            calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                            options={[
                                { key: "", text: "" },
                                ...getRegionDropdownList(props.departments)
                            ]}
                            onChange={onRegionChange}
                        />
                    </Stack.Item>

                    <Stack.Item>
                        <Label>Fra dato</Label>
                        <CustomDatePicker
                            short={true}
                            date={reportFilter.fromDate}
                            onDateChange={onFromDateChange}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Til dato</Label>
                        <CustomDatePicker
                            short={true}
                            date={reportFilter.toDate}
                            onDateChange={onToDateChange}
                        />
                    </Stack.Item>
                </Stack>
            </div>
            <DropdownPanel changed={false} title={"Årsagskoder"}>
                <div className="complaint_tab__container">
                    <ul className="issues_list">
                        {complaintOptions.map((x, index) => (
                            <li key={index}>
                                <Checkbox
                                    className="checkbox_issues"
                                    label={x}
                                    onChange={updateReportFilterList(
                                        "issues",
                                        x
                                    )}
                                    disabled={
                                        isIssueSelected
                                            ? x === reportFilter["issues"][0]
                                                ? false
                                                : true
                                            : false
                                    }
                                />
                            </li>
                        ))}
                        <li key="otherIssue">
                            <Checkbox
                                disabled={
                                    isIssueSelected
                                        ? reportFilter.byOtherIssue
                                            ? false
                                            : true
                                        : false
                                }
                                label={"Andet"}
                                onChange={onOtherIssueToggle}
                            />
                        </li>
                    </ul>
                </div>
            </DropdownPanel>
            <div className="departments_dropdown">
                <DropdownPanel changed={false} title={"Afdeling"}>
                    <div className="complaint_tab__container">
                        <ul className="department_list">
                            {uniqueDepartment.map((department, index) => (
                                <li className="department_items" key={index}>
                                    <Checkbox
                                        label={department.navLocationCode}
                                        onChange={updateDepartmentsFilterReportList(
                                            department.navLocationCode
                                        )}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </DropdownPanel>
            </div>
            <DropdownPanel changed={false} title={"Montør"}>
                <div className="complaint_tab__container">
                    <ul className="worker_list">
                        {props.operators.map((operator, index) => (
                            <li key={index}>
                                <Checkbox
                                    label={operator.name}
                                    onChange={updateReportFilterList(
                                        "operators",
                                        operator.name
                                    )}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </DropdownPanel>
            <PrimaryButton
                target="_blank"
                text="Download"
                onClick={onFormSubmit}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        operators: getOperators(state),
        departments: getDepartments(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateOperators: () => dispatch<any>(updateOperators())
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(ComplaintReport);
