import * as React from "react";
import PageHeader from "../../layout/PageHeader";
import '../Customer/style.css'

import TimeAndDepartmentManager from "../../base/TimeAndDepartmentManager";

interface BookingViewProps {
    timeAndPlace: TimeAndPlace;
    additionalData: AdditionalData;
    car: Car;
    departments: Department[];
    orderItems: PurchaseItem[];
    orderType: AssignmentType;
    debitor: Debitor;
    updateTimeAndPlace: (timeAndPlace: Partial<TimeAndPlace>) => void;
    updateAdditionalData: (AdditionalData: AdditionalData) => void;
    updateMobileServiceProduct: (isMobileervice: boolean) => void;
}

function TimeAndPlace(props: BookingViewProps) {
    return (
        <>
            <div className="styled_customer__form">
                <PageHeader currentPage="Tid og sted" />
                <TimeAndDepartmentManager {...props} updateTimeOnCashDepartmentChange={true} />
            </div>
        </>
    );
}

export default TimeAndPlace;
