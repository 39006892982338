export const assignmentTypeText = [
    { key: "REPLACE_FRONT", text: "Forrude-skift" },
    { key: "REPLACE_SIDE", text: "Siderude-skift" },
    { key: "REPLACE_REAR", text: "Bagrude-skift" },
    { key: "REPLACE_ROOF", text: "Tagrude-skift" },
    { key: "REPAIR", text: "Stenslag" },
    { key: "SALE", text: "Mersalg" },
    { key: "REPLACE", text: "Udskiftning af rude" },
    { key: "INTERNAL_COMPLAINT", text: "Intern Reklamation" },
    { key: "COMPLAINT", text: "Reklamation" }
];

export const getAssignmentTypeText = (type: AssignmentType) => {
    const item = assignmentTypeText.find(x => x.key === type);
    return item ? item.text : "";
};
