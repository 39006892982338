import { Dispatch } from "redux";
import api from "../../api";
import { addMessageStatus } from "../ui/action";
import { MessageBarType } from "@fluentui/react";

export const addLog = (log: Log) => async (dispatch: Dispatch) => {
    try {
        await api.LogApi.postLog(log);

        const statusMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: `Log saved`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "addLog" failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};
