import * as React from "react";
import PageHeader from "../layout/PageHeader";
import SearchButton from "../base/Search/SearchButton";
import { connect } from "react-redux";
import { RootState } from "../../state";
import { Dispatch, Action } from "redux";
import { updateOldProducts } from "../../state/old_products/action";
import { getOldProductsList } from "../../state/action";
import OldProductDetailDialog from "./OldProductDetailDialog";
import { Stack, Label, TextField, DetailsList, DetailsListLayoutMode, CheckboxVisibility } from "@fluentui/react";

const OldProducts: React.FunctionComponent<StateProps> = props => {
    const [query, setQuery] = React.useState("");

    const search = (e: React.FormEvent) => {
        e.preventDefault();
        props.updateOldProducts(query);
    };

    const onQueryChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        query: string
    ) => {
        setQuery(query);
        if (query.length >= 2) {
            props.updateOldProducts(query);
        }
    };

    return (
        <>
            <PageHeader currentPage={"Gl. Prisliste"} noBreadcrumb={true} />
            <div className="custom_container">
                <form onSubmit={search}>
                    <Stack
                        className="filter__menu"
                        horizontal
                        tokens={{ childrenGap: 10 }}
                        horizontalAlign="start"
                    >
                        <Stack.Item>
                            <Label>Søg</Label>
                            <TextField
                                value={query}
                                width={150}
                                onChange={onQueryChange}
                                placeholder={
                                    "Søg på varenummer, mærke eller model"
                                }
                            />
                            <SearchButton type={"Søg"} />
                        </Stack.Item>
                    </Stack>
                </form>
                <br />
                <DetailsList
                    items={props.oldProducts}
                    // groups={getGroups()}
                    layoutMode={DetailsListLayoutMode.justified}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    columns={[
                        {
                            key: "productNumber",
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Vare nr.",
                            fieldName: "productNumber"
                        },
                        {
                            key: "brand",
                            minWidth: 10,
                            maxWidth: 100,
                            name: "Mærke",
                            fieldName: "brand"
                        },
                        {
                            key: "model",
                            minWidth: 10,
                            maxWidth: 100,
                            name: "Model",
                            fieldName: "model"
                        },
                        {
                            key: "year",
                            minWidth: 50,
                            maxWidth: 50,
                            name: "Årgang",
                            fieldName: "year"
                        },
                        {
                            key: "comment",
                            minWidth: 10,
                            maxWidth: 130,
                            name: "Kommentar",
                            fieldName: "comment"
                        },
                        {
                            key: "priceReady",
                            minWidth: 10,
                            maxWidth: 50,
                            name: "Klar Pris",
                            fieldName: "priceReady"
                        },
                        {
                            key: "price1F",
                            minWidth: 10,
                            maxWidth: 50,
                            name: "1F Pris",
                            fieldName: "price1F"
                        },
                        {
                            key: "price2F",
                            minWidth: 10,
                            maxWidth: 50,
                            name: "2F Pris",
                            fieldName: "price2F"
                        },
                        {
                            key: "internalComment",
                            minWidth: 10,
                            name: "Interne bemærkninger",
                            fieldName: "internalComment"
                        },
                        {
                            key: "moreInfo",
                            minWidth: 80,
                            maxWidth: 80,
                            name: "Yderlige info",
                            // eslint-disable-next-line react/display-name
                            onRender: (p: OldProduct) => (
                                <OldProductDetailDialog
                                    oldProduct={p}
                                    buttonText="Vis mere"
                                />
                            )
                        }
                    ]}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        oldProducts: getOldProductsList(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateOldProducts: (searchQuery: string) =>
        dispatch<any>(updateOldProducts(searchQuery))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(OldProducts);
