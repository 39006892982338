import * as React from "react";
import { getServerDate } from "../../utils/DateHelper";
import CustomDatePicker from "../../base/CustomDatePicker";
import TableHelper from "../../utils/TableHelper";
import CreateStorage from "./CreateStorage";
import StorageMobile from "./StorageMobile";
import { FormEvent } from "react";
import { CheckboxVisibility, DefaultButton, DetailsList, Dialog, DialogFooter, DialogType, Dropdown, FontIcon, IColumn, IDropdownOption, Label, PrimaryButton, Stack, TextField, TooltipHost } from "@fluentui/react";
import "./style.css"

interface Props {
    departments: Department[];
    transfers: Transfer[];
    binlist: BinList[];
    departmentGuid?: string;
    products: ProductGroup[];
    query: { searchIn?: string; term?: string };
    createTransfer: (transfers: Transfer[]) => void;
    updateTransfers: (
        departmentGuid: string,
        orderId: string,
        fromDate?: Date,
        toDate?: Date
    ) => void;
    acceptTransfer: (
        isReceipt: boolean,
        orderNo: string,
        orderLine: number,
        amount: number,
        departmentGuid: string
    ) => void;
    updateBinList: (departmentGuid: string) => void;
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => void;
    updateSearchProductQuery: (searchIn?: string, term?: string) => void;
}

const Storage: React.FunctionComponent<Props> = props => {
    const prevMonth = (): Date => {
        const today = new Date();
        today.setMonth(today.getMonth() - 1);
        return today;
    };

    const [current, updateCurrent] = React.useState<Transfer>({} as Transfer);
    const [dialogue, toggleDialog] = React.useState(false);
    const [departmentGuid, setDepartmentGuid] = React.useState(props.departmentGuid);
    const [fromDate, setFromDate] = React.useState(prevMonth());
    const [toDate, setToDate] = React.useState(new Date());
    const [storageChange, setStorageChange] = React.useState(0);
    const [orderNr, setOrderNr] = React.useState("");
    const [createstorage, toggleCreateStorage] = React.useState(false);
    const [buttonActive, toggleButton] = React.useState(false);

    const columns: IColumn[] = [
        TableHelper.col("id", "Order No", 90, 90, (e: Transfer) => (
            <p>{e.order_No}</p>
        )),
        TableHelper.col("type", "Afsend/Modtag", 90, 90, (e: Transfer) => (
            <p>{e.is_Receipt ? "Modtag" : "Afsend"}</p>
        )),
        TableHelper.col("no", "Item No", 56, 90, (e: Transfer) => (
            <p>{e.item_No}</p>
        )),
        TableHelper.col("desc", "Beskrivelse", 56, 156, (e: Transfer) => (
            <p>{e.description}</p>
        )),
        TableHelper.col("date", "Dato", 56, 156, (e: Transfer) => (
            <p>{e.receipt_Date ? getServerDate(e.receipt_Date) : ""}</p>
        )),
        TableHelper.col("amount", "Antal", 56, 106, (e: Transfer) => (
            <p>{e.qty_in_Transit}</p>
        )),
        TableHelper.col("received", "Håndteret", 56, 106, (e: Transfer) => (
            <p>{e.is_Receipt ? e.qty_Received : e.qty_Shipped}</p>
        )),
        TableHelper.col("handle", "", 80, 80, (e: Transfer, index) => {
            const i = index ? index : 0;
            return (
                <>
                    {(e.is_Receipt && e.qty_in_Transit === e.qty_Received) ||
                    (!e.is_Receipt && e.qty_in_Transit === e.qty_Shipped) ? (
                        <DefaultButton
                            onClick={showDialog(i)}
                            text={"Håndteret"}
                        />
                    ) : (
                        <PrimaryButton
                            onClick={showDialog(i)}
                            text={"Håndtér"}
                        />
                    )}
                    {e.in_DB ? (
                        <TooltipHost
                            content={"Overflytnings ordre er registreret"}
                            delay={0}
                            calloutProps={{ gapSpace: 5 }}
                        >
                            <FontIcon
                                iconName="SkypeCircleCheck"
                                style={{ marginLeft: "10px", color: "green" }}
                            />
                        </TooltipHost>
                    ) : null}
                </>
            );
        })
    ];

    const locations = props.departments.map(department => {
        return { key: department.id as string, text: department.name };
    });

    const showDialog = (e: number) => (ev: any) => {
        const cur = props.transfers[e];
        const missing =
            cur.qty_in_Transit -
            (cur.is_Receipt ? cur.qty_Received : cur.qty_Shipped);
        setStorageChange(missing);
        updateCurrent(cur);
        toggleDialog(true);
    };

    const filter = (e: FormEvent) => {
        e.preventDefault();
        if (departmentGuid && departmentGuid !== "00000000-0000-0000-0000-000000000000") {
            props.updateTransfers(departmentGuid, orderNr, fromDate, toDate);
            props.updateBinList(departmentGuid);
        }
    };

    const closeDialog = () => {
        toggleButton(false);
        toggleDialog(false);
    };
    const saveDialog = () => {
        toggleDialog(false);
        if (storageChange !== 0) {
            current.qty_Received += storageChange;
            props.acceptTransfer(
                current.is_Receipt,
                current.order_No,
                current.line_No,
                storageChange,
                departmentGuid as string
            );
            updateCurrent(current);
        }
        setStorageChange(0);
    };

    const onBincodeChange = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        toggleButton(true);
        setDepartmentGuid(option.key.toString())
    };

    const orderChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setOrderNr(value);
    };

    const onStorageChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue) {
            setStorageChange(parseInt(newValue));
        }
    };

    const changeLocation = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        setDepartmentGuid(option.key.toString());
    };

    const onFromDateChange = (date: Date | null | undefined) => {
        if (date) {
            setFromDate(date);
        }
    };

    const onToDateChange = (date: Date | null | undefined) => {
        if (date) {
            setToDate(date);
        }
    };

    const showCreate = () => {
        toggleCreateStorage(!createstorage);
    };

    const options = props.binlist.map(bin => ({
        key: bin.Code,
        text: bin.Code
    }));
    if (options.length === 0 && departmentGuid && departmentGuid !== "00000000-0000-0000-0000-000000000000") {
        props.updateBinList(departmentGuid);
    }

    return (
        <div className="custom_container">
            <CreateStorage
                products={props.products}
                updateSearchList={props.updateSearchList}
                updateSearchProductQuery={props.updateSearchProductQuery}
                query={props.query}
                showWindow={createstorage}
                toggleCreate={showCreate}
                createTransfer={props.createTransfer}
                departments={props.departments}
            />
            <div className="add_button">
                <PrimaryButton text="Opret" onClick={showCreate} />
            </div>
            <form onSubmit={filter}>
                <Stack
                    className="filter__menu"
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign="end"
                >
                    <Stack.Item>
                        <Label>Lokation</Label>
                        <Dropdown
                            calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                            dropdownWidth={200}
                            placeholder="Vælg lokation"
                            options={locations}
                            defaultSelectedKey={departmentGuid}
                            onChange={changeLocation}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Fra dato</Label>
                        <CustomDatePicker
                            short={true}
                            date={fromDate}
                            onDateChange={onFromDateChange}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Til dato</Label>
                        <CustomDatePicker
                            short={true}
                            date={toDate}
                            onDateChange={onToDateChange}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>Ordre nr.</Label>
                        <TextField
                            placeholder="Indtast ordre nr."
                            value={orderNr}
                            onChange={orderChange}
                        />
                        <PrimaryButton text="Filtrer" type={"submit"} />
                    </Stack.Item>
                </Stack>
            </form>

            <h5>Overflytningsordrer</h5>
            <DetailsList
                className="hidden-xs"
                columns={columns}
                items={props.transfers}
                checkboxVisibility={CheckboxVisibility.hidden}
            />
            <StorageMobile items={props.transfers} showDialog={showDialog} />

            <Dialog
                hidden={!dialogue}
                onDismiss={closeDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Bekræft ${
                        current.is_Receipt ? "modtagelse" : "afsendelse"
                    }`,
                    subText: `Indtast antal ${
                        current.is_Receipt ? "modtaget" : "afsendt"
                    } af ${current.description}`
                }}
                modalProps={{
                    isBlocking: false,
                    containerClassName: "ms-dialogMainOverride"
                }}
            >
                <div className="dialog_footer">
                    <DialogFooter className="storage__popup">
                        <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
                            <Stack.Item grow={1}>
                                <TextField
                                    type="number"
                                    onChange={onStorageChange}
                                    value={storageChange + ""}
                                    placeholder={`Antal ${
                                        current.is_Receipt
                                            ? "modtaget"
                                            : "afsendt"
                                    }`}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Dropdown
                                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                    placeholder="Placeringskode"
                                    options={options}
                                    onChange={onBincodeChange}
                                />
                                <br />
                                <PrimaryButton
                                    disabled={!buttonActive}
                                    onClick={saveDialog}
                                    text="Bekræft"
                                />
                            </Stack.Item>
                        </Stack>
                    </DialogFooter>
                </div>
            </Dialog>
        </div>
    );
};

export default Storage;
