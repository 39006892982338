import api from "../../api";
import { Action, Dispatch } from "redux";
import { addMessageStatus } from "../ui/action";
import { formatTransferDate } from "../stateUtils/transferHelper";
import { MessageBarType } from "@fluentui/react";

export interface UpdateTransfersAction extends Action {
    type: "UPDATE_TRANSFERS";
    transfers: Transfer[];
}

export interface UpdateBinListAction extends Action {
    type: "UPDATE_BINLIST";
    binlist: BinList[];
}

export interface AcceptTransferAction extends Action {
    type: "ACCEPT_TRANSFER";
    transfer: Transfer;
}

export const updateTransfers = (
    departmentGuid: string,
    orderNr?: string,
    fromDate?: Date,
    toDate?: Date
) => async (dispatch: Dispatch) => {
    try {
        if (!fromDate) {
            fromDate = new Date();
            fromDate.setMonth(fromDate.getMonth() - 1);
        }
        if (!toDate) {
            toDate = new Date();
        }
        const fromDateString = formatTransferDate(fromDate);
        const toDateString = formatTransferDate(toDate);
        const transfers = await api.NAVAPI.getTransfers(
            departmentGuid,
            fromDateString,
            toDateString,
            orderNr ? orderNr : ""
        );
        const action: UpdateTransfersAction = {
            type: "UPDATE_TRANSFERS",
            transfers
        };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "getTransfers" failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const updateBinList = (departmentGuid: string) => async (
    dispatch: Dispatch
) => {
    try {
        const binlist = await api.NAVAPI.getBinList(departmentGuid);
        const action: UpdateBinListAction = { type: "UPDATE_BINLIST", binlist };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "updateBinList" failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const acceptTransfer = (
    isReceipt: boolean,
    orderNo: string,
    orderLine: number,
    amount: number,
    departmentGuid: string
) => async (dispatch: Dispatch) => {
    try {
        const transfer = await api.NAVAPI.acceptTransfer(
            isReceipt ? "receipt" : "shipment",
            orderNo,
            orderLine,
            amount,
            departmentGuid
        );
        const action: AcceptTransferAction = {
            type: "ACCEPT_TRANSFER",
            transfer
        };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "acceptTransfer" failed`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const createTransfer = (transfers: Transfer[]) => async (
    dispatch: Dispatch
) => {
    try {
        await api.NAVAPI.createTransfer(transfers);
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.success,
            message: `Api: "createTransfer" succeded`,
            dismissTimer: 5000
        };
        addMessageStatus(statusMessage)(dispatch);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "createTransfer" failed`,
            dismissTimer: 5000
        };
        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export type Actions =
    | UpdateTransfersAction
    | AcceptTransferAction
    | UpdateBinListAction;
