import * as React from "react";
import { FormEvent, useEffect } from "react";
import DebitorLookupByName from "../../customer/DebitorLookupByName";
import DepartmentSelector from "../../booking/DepartmentSelector";
import { IDropdownOption, Dropdown, Checkbox, Stack, Label, StackItem } from "@fluentui/react";
import api from "../../../api";
interface Props {
    order: Order;
    departments: Department[];
    car: Car;
    leasers: Leaser[];
    updateCar: (car: Car) => void;
    insurers?: Insurer[];
    onWhoIsPayingChange: (whoIsPaying: WhoIsPaying) => void;
    updateDebitorFromWhoIsPaying: (
        whoIsPaying: WhoIsPaying,
        order: Order,
        updatePrices: boolean
    ) => void;
    onDepartmentChange: (
        department: Department,
        shouldUpdateDebitor: boolean
    ) => void;
    getDebitor: (query: string, type: ApiType) => void;
    debitors?: Debitor[];
    selectedDebitor: Debitor;
    onDebitorChange: (debitor: Debitor) => void;
}

const WhoIsPaying: React.FunctionComponent<Props> = props => {
    const { whoIsPaying } = props.order.additionalData;


    const whoIsPayingOptions: IDropdownOption[] = [
        {
            key: "INSURANCE",
            text: "Forsikring",
            selected: whoIsPaying === "INSURANCE"
        }
    ];
    if (props.order.car.leasing) {
        whoIsPayingOptions.push({
            key: "LEASING",
            text: "Leasing",
            selected: whoIsPaying === "LEASING"
        });
    }
    whoIsPayingOptions.push({
        key: "CASH",
        text: "Kontant betaling",
        selected: whoIsPaying === "CASH"
    });

    whoIsPayingOptions.push({
        key: "DEBITOR",
        text: "Debitor",
        selected: whoIsPaying === "DEBITOR"
    });
    const isOfferChecked = React.useCallback(() => {
        const result = props.insurers && props.insurers.find(x => x.bilStatistikName === props.car.insurance);
        return result && result.cashDebitorId === props.order.debitor.no;
    }, [props.insurers, props.order.debitor.no]);

    const onWhoIsPayingChange = ( event: React.FormEvent<HTMLDivElement>, option: IDropdownOption ) => 
    {
        const whoIsPaying = option.key as WhoIsPaying;
        props.updateDebitorFromWhoIsPaying(whoIsPaying, props.order, true);
    }

    const onDepartmentChange = (department: Department) => {
        props.onDepartmentChange(department, whoIsPaying === "CASH");
        props.updateDebitorFromWhoIsPaying("CASH", {...props.order, timeAndPlace: {...props.order.timeAndPlace, department} }, !(isOfferChecked() === true));
    };

    const changeLeaser = (event: FormEvent<HTMLDivElement>, option: IDropdownOption) => {
        props.updateCar({ ...props.car, leaser: option.text });
        props.updateDebitorFromWhoIsPaying("LEASING", {...props.order, car: {...props.car, leaser: option.text} }, true);
    };

    const changeInsurer = (event: FormEvent<HTMLDivElement>, option: IDropdownOption) => {
        props.updateCar({ ...props.car, insurance: option.text });
        props.updateDebitorFromWhoIsPaying("INSURANCE", {...props.order, car: {...props.car, insurance: option.text } }, true);
    };

    const offerDebibtor = async (ev?: React.FormEvent<HTMLElement>, checked?: boolean) =>
    {
        if(checked)
        {
            if(props.car.insurance && props.insurers)
            {
                const result = props.insurers.find(x => x.bilStatistikName === props.car.insurance);
                if(result && result.cashDebitorId)
                {
                    const debitor = await api.DebitorApi.getDebitor(result.cashDebitorId, "NAV");
                    if(debitor.length>0)
                    {
                        props.onDebitorChange(debitor[0]);
                    }
                }
            }
        }
        else
        {
            props.updateDebitorFromWhoIsPaying("CASH", props.order, true);
        }
    }

   
    let additionInfo = null;
    if (whoIsPaying === "INSURANCE" && props.insurers) {
        additionInfo = (
            <Dropdown
                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                placeholder={
                    props.car.insurance ? props.car.insurance : "Forsikring"
                }
                options={props.insurers.map(x => ({
                    key: x.name,
                    text: x.name
                }))}
                onChange={changeInsurer}
                defaultSelectedKey={
                    props.car.insurance ? props.car.insurance : ""
                }
                style={{ minWidth: "300px" }}
            />
        );
    }
    if (whoIsPaying === "LEASING") {
        additionInfo = (
            <Dropdown
                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                placeholder={
                    props.car.leaser ? props.car.leaser : "Leasing Virksomhed"
                }
                options={props.leasers.map(x => ({
                    key: x.name,
                    text: x.name
                }))}
                onChange={changeLeaser}
                defaultSelectedKey={props.car.leaser ? props.car.leaser : ""}
                style={{ minWidth: "300px" }}
            />
        );
    }
    if (whoIsPaying === "CASH") {

        
        additionInfo = (
            <>
                <Stack>
                    <StackItem>
                        <DepartmentSelector
                            department={props.order.timeAndPlace.department}
                            departments={props.departments}
                            onDepartmentChange={onDepartmentChange}
                        />
                    </StackItem>
                    <StackItem  grow={true}>
                        <Checkbox checked={isOfferChecked()} label="Tilbuds debitor" onChange={offerDebibtor} />
                    </StackItem>
                </Stack>
            </>
        );
    }
    if (whoIsPaying === "DEBITOR") {
        additionInfo = (
            <DebitorLookupByName
                debitors={props.debitors}
                getDebitor={props.getDebitor}
                onDebitorChange={props.onDebitorChange}
                selectedDebitor={props.selectedDebitor}
            />
        );
    }

    return (
        <>
            <h5 style={{ marginRight: "10px" }}>Hvem betaler</h5>
            <Dropdown
                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                title="Hvem betaler"
                selectedKey={whoIsPaying}
                options={whoIsPayingOptions}
                onChange={onWhoIsPayingChange}
                style={{ minWidth: "150px" }}
            />
            {additionInfo}
        </>
    );
};

export default WhoIsPaying;
