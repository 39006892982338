import { DatePicker, Dropdown, Label, PrimaryButton, Stack, StackItem, TextField } from "@fluentui/react";
import * as React from "react";
import convertStringDateToDate from "./helpers/convertStringDateToDate";
import openingHoursDayDropdown from "./helpers/openingHoursDayDropdown";
import convertFloatToTime from "./helpers/convertFloatToTime";
import mapPropertyValue from "./helpers/mapPropertyValue";

interface Props
{
    enableDate: boolean;
    isWindowReplaceTimeSlots: boolean;
    addOrUpdateTimeslot: (timeslot: DamageSpecificTimeSlot) => void;
}
const CreateNewTimeslot: React.FC<Props> = (
    props
): JSX.Element => {
    const [createForm, setCreateForm] = React.useState<DamageSpecificTimeSlot>({
        date: undefined,
        day: 0,
        from: 0,
        to: 0,
        maxBookings: 0,
        reperationTime: 0,
        stoneChipAlternativeWinReplace: 0
    });
    const onCreateFormChanged = React.useCallback((value: any, property: string) => {
        const form = mapPropertyValue<DamageSpecificTimeSlot>(
            { ...createForm },
            value,
            property
        );
        setCreateForm(form);
    },
    [createForm]
);
    const onAddNewTimeSlot = (event: React.FormEvent) => {
        event.preventDefault();
        if (createForm) {
        
            props.addOrUpdateTimeslot(createForm);
            setCreateForm({
                date: undefined,
                day: 0,
                from: 0,
                to: 0,
                maxBookings: 0,
                reperationTime: 0,
                stoneChipAlternativeWinReplace: 0
            });
        }
    };

    return (<form className="departmentsForm" onSubmit={onAddNewTimeSlot}>
            <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                    {props.enableDate ? (
                        <>
                            <Label>Dato</Label>
                            <DatePicker
                                className="date_picker_department__container"
                                placeholder="Vælg dato"
                                value={convertStringDateToDate(
                                    createForm.date
                                )}
                                onSelectDate={newDate =>
                                    onCreateFormChanged(newDate, "date")
                                }
                            />
                        </>
                    ) : (
                        <>
                            <Label>Dag</Label>
                            <Dropdown
                                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                placeholder="Vælg dag"
                                selectedKey={createForm.day}
                                options={openingHoursDayDropdown(
                                    createForm
                                        ? createForm.day
                                        : undefined
                                )}
                                onChange={(e, value) =>
                                    onCreateFormChanged(value, "day")
                                }
                            />
                        </>
                    )}
                </StackItem>
                <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                    <Label>Tid fra</Label>
                    <TextField
                        prefix="Fra"
                        type="time"
                        value={convertFloatToTime(createForm.from)}
                        onChange={(e, value) =>
                            onCreateFormChanged(value, "from")
                        }
                    />
                </StackItem>
                <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                    <Label>Tid til</Label>
                    <TextField
                        prefix="Til"
                        type="time"
                        value={convertFloatToTime(createForm.to)}
                        onChange={(e, value) =>
                            onCreateFormChanged(value, "to")
                        }
                    />
                </StackItem>
                <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                    <Label>Max. bookings</Label>
                    <TextField
                        prefix="Antal"
                        type="number"
                        value={createForm.maxBookings.toString()}
                        onChange={(event, value) =>
                            onCreateFormChanged(value, "maxBookings")
                        }
                    />
                </StackItem>
                <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                    <Label>Reperations tid</Label>
                    <TextField
                        prefix="Antal"
                        type="number"
                        step="0.5"
                        value={createForm.reperationTime.toString()}
                        onChange={(event, value) =>
                            onCreateFormChanged(value, "reperationTime")
                        }
                    />
                </StackItem>
                {props.isWindowReplaceTimeSlots && (
                    <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                        <Label>Konverteringsværdi</Label>
                        <TextField
                            prefix="Antal"
                            type="number"
                            value={createForm.stoneChipAlternativeWinReplace.toString()}
                            onChange={(event, value) =>
                                onCreateFormChanged(
                                    value,
                                    "stoneChipAlternativeWinReplace"
                                )
                            }
                        />
                    </StackItem>
                )}
            </Stack>

            <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                <StackItem className="departmentStackItem" grow={false} disableShrink={true} >
                    <PrimaryButton type="submit">Opret</PrimaryButton>
                </StackItem>
            </Stack>
        </form>
    );;
};

export default CreateNewTimeslot