export interface WorkDetail
{
    title: string;
    value: string | boolean | number;
    data: DetailType;
    type?: Types;
    id: number;
    changed: boolean;
    onChangeFunc?(value: any): void;
}

export enum Types
{
    NONE = 0,
    CHANGED = 2,
    ADDRESS = 1,
    PHONE = 4,
    BOOLEAN = 5
}

export enum ItemType {
    NONE = 0,
    ADDON = 1,
    ASSESSORY = 2,
    CALIB = 3
}

export enum FreeCarSku {
    CAR_ONE = "BIL001"
}

export enum ChargeType
{
    Customer,
    Insurance
}

export enum DetailType
{
    Text,
    Number,
    Date,
    Time,
    List,
    Boolean
}

export interface IExceptionResult<T = any>
{
    type: T;
    id?: string;
    message: string;
    status: number;
    stackTrace?: string;
}

export interface IGenericExceptionResult extends IExceptionResult
{
    type: "GENERIC_ERROR";
}

export interface IUnauthorizedExceptionResult extends IExceptionResult
{
    type: "UNAUTHORIZED_ERROR";
}

export interface IValidationExceptionResult extends IExceptionResult
{
    type: "VALIDATION_ERROR";
    errors: string[]
}

export class CustomHttpException extends Error {
    status: number | undefined;
    type: string | undefined;
    stackTrace: string | undefined;
    errors: string[] | undefined;
}


export type ExceptionResults =
    IGenericExceptionResult |
    IUnauthorizedExceptionResult |
    IValidationExceptionResult | 
    CustomHttpException;