import * as React from "react";
import { getStatusText } from "../utils/statusHelper";
import { getAssignmentTypeText } from "../utils/assignmentTypeHelper";
import { getMapsUrl } from "../utils/LocationHelper";
import SendTextWindowMobile from "../base/Notifications/SendTextWindowMobile";
import { PrimaryButton, DefaultButton, Icon, Panel, PanelType } from "@fluentui/react";

interface Props {
    createMoreSaleOrder: (order: Order) => void;
    orders: Order[];
    selectBooking: (ordreNr: string) => void;
    sendSms: (model: NotificationModel) => void;
    user: UserInformation;
}
let canCopyOrder = true;
const OrderListMobile: React.FunctionComponent<Props> = props => {
    const [mobilepanel, setPanel] = React.useState(false);
    const [bookingIndex, setBookingIndex] = React.useState(-1);

    const handleClick = (e: number) => (ev: React.SyntheticEvent) => {
        setPanel(true);
        setBookingIndex(e);
    };

    const hidePanel = () => {
        setPanel(false);
        setBookingIndex(-1);
    };

    const onOrdreClick = (order: Order) => () => {
        if (order.additionalData.orderId) {
            props.selectBooking(order.additionalData.orderId);
        }
    };
    const onAdditionalSalesClick = React.useCallback(
        (order: Order) => () => {
            if (canCopyOrder) {
                canCopyOrder = false;
                props.createMoreSaleOrder(order);
            }
        },
        [canCopyOrder]
    );

    const getData = () => {
        const selectedBooking = props.orders[bookingIndex];
        const { make, model, registrationNumber } = selectedBooking.car;
        const { mobileServiceLocation } = selectedBooking.timeAndPlace;

        return (
            <>
                <p>
                    <strong>Ordre nr.:</strong>{" "}
                    {selectedBooking.additionalData.orderNr}
                </p>
                <p>
                    <strong>Type: </strong>{" "}
                    {getAssignmentTypeText(
                        selectedBooking.additionalData.orderType
                    )}
                </p>
                {selectedBooking.timeAndPlace.department ? (
                    <p>
                        <strong>Afdeling: </strong>
                        {selectedBooking.timeAndPlace.department
                            .navLocationCode +
                            " " +
                            selectedBooking.timeAndPlace.department.name}{" "}
                    </p>
                ) : null}
                <p>
                    <strong>Bilen: </strong> <br /> {make}
                    {model}
                    <br />
                    {registrationNumber ? (
                        <span className="license_plate">
                            {registrationNumber.toUpperCase().substr(0, 2)}{" "}
                            {registrationNumber.substr(2, 2)}{" "}
                            {registrationNumber.substr(4, 3)}
                        </span>
                    ) : null}
                </p>
                <p>
                    <strong>Tidspunkt:</strong>
                    <br />
                    {selectedBooking.timeAndPlace.from +
                        " - " +
                        selectedBooking.timeAndPlace.to}
                </p>
                <p>
                    <strong>Dato:</strong>
                    <br />
                    {selectedBooking.timeAndPlace.date}
                </p>
                <div>
                    <strong>SMS Status:</strong>
                    <br />
                    {selectedBooking.customer.allowSms &&
                    selectedBooking.timeAndPlace.department &&
                    selectedBooking.timeAndPlace.department.mobileService ? (
                        <SendTextWindowMobile
                            order={selectedBooking}
                            sendSms={props.sendSms}
                        />
                    ) : (
                        <div className="cancel--inline disabled cancel_button">
                            <PrimaryButton disabled text="SMS ikke tilladt" />
                        </div>
                    )}
                </div>

                <div>
                    <strong>Mersalg:</strong>
                    <br />
                    <DefaultButton
                        text="Mersalg"
                        className="additional-sales"
                        onClick={onAdditionalSalesClick(selectedBooking)}
                    />
                </div>
                <p>
                    <strong>Kommentar:</strong>
                    <br />
                    {selectedBooking.additionalData.externalOrderComment}
                </p>
                {selectedBooking.customer.phone ? (
                    <p>
                        <strong>Tlf.nr.</strong>
                        <br />
                        <Icon iconName="Phone" title="Call phone number" />
                        <a href={`tel:${selectedBooking.customer.phone}`}>
                            {selectedBooking.customer.phone}
                        </a>
                    </p>
                ) : null}

                {mobileServiceLocation && mobileServiceLocation.address ? (
                    <p>
                        <strong>Adresse:</strong>
                        <br />
                        <Icon
                            iconName="OpenInNewWindow"
                            className="maps"
                            title="Opens Google Maps in new window"
                        />
                        <a
                            target="_blank"
                            href={getMapsUrl(mobileServiceLocation.address)}
                        >
                            {mobileServiceLocation.address}
                        </a>
                    </p>
                ) : null}
                <p>
                    {selectedBooking.additionalData.twoManJob ? (
                        <Icon iconName="People" style={{ fontSize: "1.5em" }} />
                    ) : null}
                </p>
                <p>
                    <strong>Status:</strong>
                    <br />{" "}
                    {getStatusText(
                        selectedBooking.additionalData.orderStatus
                    )}{" "}
                    <br />
                    <br />
                    {selectedBooking.additionalData.internalOrderComment ? (
                        <>
                            <Icon iconName="AlertSolid" />{" "}
                            <span>
                                {
                                    selectedBooking.additionalData
                                        .internalOrderComment
                                }
                            </span>
                        </>
                    ) : null}
                    <br />
                    <span className="go_to__button">
                        <DefaultButton
                            onClick={onOrdreClick(selectedBooking)}
                            text="Gå til ordre"
                        />
                    </span>
                </p>
            </>
        );
    };

    return (
        <>
            <table className="table hidden-md custom_assignements_table">
                <thead>
                    <tr>
                        <th>Ordre nr.</th>
                        <th>Tid</th>
                        <th>Status</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    {props.orders.map((e, index) => {
                        return (
                            <tr key={index} onClick={handleClick(index)}>
                                <td>
                                    {e.additionalData.orderNr ||
                                        e.additionalData.orderId}
                                </td>
                                <td>
                                    {e.timeAndPlace.from
                                        ? e.timeAndPlace.from +
                                          " - " +
                                          e.timeAndPlace.to +
                                          " "
                                        : null}
                                    {e.timeAndPlace.date}
                                </td>
                                <td>
                                    {getStatusText(
                                        e.additionalData.orderStatus
                                    )}
                                </td>
                                <td>
                                    {getAssignmentTypeText(
                                        e.additionalData.orderType
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Panel
                isOpen={mobilepanel}
                type={PanelType.smallFixedFar}
                onDismiss={hidePanel}
            >
                {bookingIndex > -1 ? getData() : null}
            </Panel>
            <br />
        </>
    );
};

export default OrderListMobile;
