import * as React from "react";
import BreadCrumb from "../Breadcrumb";
import "./style.css";

function PageHeader(props: any) {
    return (
        <div className="header__container">
            {!props.noBreadcrumb ? (
                <BreadCrumb currentPage={props.currentPage} />
            ) : null}
            <h5>{props.currentPage}</h5>
            {props.changes ? (
                <p className="header--changed">
                    {props.changes > 1
                        ? props.changes + " ændringer"
                        : props.changes + " ændring"}{" "}
                </p>
            ) : null}
            <hr />
        </div>
    );
}

export default PageHeader;
