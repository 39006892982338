import * as React from "react";
import { getServerDate } from "../../utils/DateHelper";
import "./style.css"
import { DefaultButton, PrimaryButton, Panel, PanelType } from "@fluentui/react";

interface Props {
    items: Transfer[];
    showDialog: (
        index: number
    ) => (ev: React.MouseEvent<HTMLButtonElement>) => void;
}

const StorageMobile = (props: Props) => {
    const [mobilepanel, setPanel] = React.useState(false);
    const [transferIndex, setTransferIndex] = React.useState(-1);
    const handleClick = (e: number) => (ev: React.SyntheticEvent) => {
        setPanel(true);
        setTransferIndex(e);
    };

    const hidePanel = () => {
        setPanel(false);
        setTransferIndex(-1);
    };

    const getData = () => {
        const selectedTransfer = props.items[transferIndex];
        const {
            receipt_Date,
            description,
            order_No,
            is_Receipt,
            qty_Received,
            qty_Shipped,
            qty_in_Transit
        } = selectedTransfer;
        return (
            <>
                <strong>Ordre nr.:</strong> {order_No}
                <p>
                    <strong>Afsend/Modtag:</strong>{" "}
                    {is_Receipt ? "Modtag" : "Afsend"}
                </p>
                <p>
                    <strong>Beskrivelse:</strong> <br />
                    {description}
                </p>
                <p>
                    <strong>Dato:</strong>{" "}
                    {receipt_Date ? getServerDate(receipt_Date) : ""}
                </p>
                <p>
                    <strong>Antal:</strong> {qty_in_Transit}
                </p>
                <p>
                    <strong>Håndteret:</strong>{" "}
                    {is_Receipt ? qty_Received : qty_Shipped}
                </p>
                <p>
                    {(is_Receipt && qty_in_Transit === qty_Received) ||
                    (!is_Receipt && qty_in_Transit === qty_Shipped) ? (
                        <span className="go_to__button">
                            <DefaultButton
                                onClick={props.showDialog(transferIndex)}
                                text={"Håndteret"}
                            />
                        </span>
                    ) : (
                        <span className="go_to__button">
                            <PrimaryButton
                                onClick={props.showDialog(transferIndex)}
                                text={"Håndtér"}
                            />
                        </span>
                    )}
                </p>
            </>
        );
    };

    return (
        <>
            <table className="table hidden-md custom_storage__table">
                <thead>
                    <tr>
                        <th>Ordre nr.</th>
                        <th>Dato</th>
                        <th>Afsend/Modtag</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((e, index) => {
                        return (
                            <tr key={index} onClick={handleClick(index)}>
                                <td>{e.order_No}</td>
                                <td>
                                    {e.receipt_Date
                                        ? getServerDate(e.receipt_Date)
                                        : ""}
                                </td>
                                <td>{e.is_Receipt ? "Modtag" : "Afsend"}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Panel
                isOpen={mobilepanel}
                type={PanelType.smallFixedFar}
                onDismiss={hidePanel}
            >
                {transferIndex > -1 ? getData() : null}
            </Panel>
            <br />
        </>
    );
};

export default StorageMobile;
