import { CustomCalendarEvent } from "~/state/calendar";
import BaseApi from "./baseApi";

interface ICalendarAPI {
    updateBookingTime: (
        time: string,
        id: string,
        allDay: boolean
    ) => Promise<void>;
    CreateEvent: (event: DepartmentCalendarEvent) => Promise<void>;
    DeleteEvent: (id: string) => Promise<void>;
    UpdateEvent: (event: CustomCalendarEvent) => Promise<void>;
}

export default class CalendarAPI extends BaseApi implements ICalendarAPI {
    DeleteEvent(id: string): Promise<void> {
        const url = `/api/calendar/deleteDepartmentEvent/${id}`;
        return this.fetchDelete(url);
    }
    async updateBookingTime(
        time: string,
        id: string,
        allDay: boolean
    ): Promise<void> {
        const url = `/api/calendar/updateTime/${id}`;
        return await this.fetchPost(url, { time, allDay });
    }

    async moveAllDayBooking(data: {
        fromId: string;
        toId: string;
        currentOrder: string[];
    }): Promise<void> {
        const url = `/api/calendar/move/`;
        return await this.fetchPost(url, data);
    }

    CreateEvent(event: DepartmentCalendarEvent): Promise<void> {
        const url = `/api/calendar/createDepartmentEvent`;
        return this.fetchPut(url, event);
    }

    UpdateEvent(event: CustomCalendarEvent): Promise<void> {
        const url = `/api/calendar/updateEvent`;
        return this.fetchPost(url, {
            id: event.id,
            description: event.description,
            title: event.title
        });
    }
}
