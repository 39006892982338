import BaseApi from "./baseApi";

interface IZipCodeDepartmentApi {
    departmentNavResponsibilityCenterSearch: (
        navResponsibilityCenter: string
    ) => Promise<ZipCodeDepartmentAvailability[]>;
    zipCodeSearch: (
        zipCode: string
    ) => Promise<ZipCodeDepartmentAvailability[]>;
    updateZipCodeDepartmentAvailability: (
        zipCodeDepartment: ZipCodeDepartmentAvailability
    ) => Promise<void>;
}

export default class ZipCodeDepartmentApi extends BaseApi
    implements IZipCodeDepartmentApi {
    departmentNavResponsibilityCenterSearch(
        navResponsibilityCenter: string
    ): Promise<ZipCodeDepartmentAvailability[]> {
        const url = `/api/zipcode/navResponsibilityCenter/${navResponsibilityCenter}`;
        return this.fetchGet(url);
    }

    searchByDepartmentId(
        departmentId: string
    ): Promise<ZipCodeDepartmentAvailability[]> {
        const url = `/api/zipcode/department/${departmentId}`;
        return this.fetchGet(url);
    }

    zipCodeSearch(zipCode: string): Promise<ZipCodeDepartmentAvailability[]> {
        const url = `/api/zipcode/${zipCode}`;
        return this.fetchGet(url);
    }

    updateZipCodeDepartmentAvailability(
        zipCodeDepartment: ZipCodeDepartmentAvailability
    ): Promise<void> {
        const url = `/api/zipcode/`;
        return this.fetchPost(url, zipCodeDepartment);
    }
}
