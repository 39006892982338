import * as React from "react";
import { useCallback, useState } from "react";
import "./style.css";
import { DefaultButton, Dialog, Label, PrimaryButton, StackItem, TextField } from "@fluentui/react";

interface NewDepartmentProps {
    setCurrentDepartment: (department: Department) => void;
}

const newDepartment: React.FunctionComponent<NewDepartmentProps> = props => {
    const [showNewDepartmentDialog, setShowNewDepartmentDialog] = useState<
        boolean
    >(false);
    const [
        newDepartmentNavResponsibilityCenter,
        setNewDepartmentNavResponsibilityCenter
    ] = useState<string>("");

    const onNewDepartmentClick = useCallback(() => {
        if (newDepartmentNavResponsibilityCenter) {
            const foundDepartment: Department = {
                id: undefined,
                name: "",
                address: "",
                phone: "",
                departmentEmail: "",
                mobileService: false,
                lat: 0.0,
                lng: 0.0,
                navLocationCode: newDepartmentNavResponsibilityCenter,
                navResponsibilityCenter: newDepartmentNavResponsibilityCenter,
                debitorId: "",
                windowChangeTimeOffset: 0,
                stoneChipTimeOffset: 0,
                openingHours: [],
                specificOpeningHours: [],
                windowReplaceTimeSlots: [],
                specificWindowReplaceTimeSlots: [],
                stoneChipTimeSlots: [],
                specificStoneChipTimeSlots: [],
                hasWaitingList: false,
                warehouseEmailList: "",
                region: "",
                deliveringDays: "",
                nextDayDeliveryTime: "",
                dailyDeliveryTime: "",
                coffee: false,
                loanCar: false,
                wifi: false,
                inActive: false
            };
            if (foundDepartment) {
                props.setCurrentDepartment(foundDepartment);
                setShowNewDepartmentDialog(false);
                setNewDepartmentNavResponsibilityCenter("");
            }
        }
    }, [newDepartmentNavResponsibilityCenter]);

    const updateNavResponsibilityCenter = useCallback(
        (e: React.SyntheticEvent<HTMLInputElement>, value: string) => {
            setNewDepartmentNavResponsibilityCenter(value);
        },
        []
    );

    const onToggleNewDepartmentDialog = useCallback(() => {
        setShowNewDepartmentDialog(!showNewDepartmentDialog);
    }, [showNewDepartmentDialog]);

    return (
        <>
            <StackItem
                className="departmentStackItem"
                grow={false}
                disableShrink={true}
            >
                <PrimaryButton onClick={onToggleNewDepartmentDialog}>
                    Opret ny afdeling ...
                </PrimaryButton>
            </StackItem>
            <Dialog hidden={!showNewDepartmentDialog}>
                <Label>Navision Ansvars Center</Label>
                <TextField
                    placeholder="NavResponsibilityCenter"
                    onChange={updateNavResponsibilityCenter}
                    value={newDepartmentNavResponsibilityCenter}
                />
                <PrimaryButton
                    onClick={onNewDepartmentClick}
                    text="Opret Ny Afdeling"
                />
                <DefaultButton
                    onClick={onToggleNewDepartmentDialog}
                    text="Anuller"
                />
            </Dialog>
        </>
    );
};

export default newDepartment;
