import * as React from "react";
import NavigationHelper from "../../utils/NavigationHelper";
import "./style.css";
import { Breadcrumb } from "@fluentui/react";

interface Props {
    currentPage: string;
}

const BreadCrumb: React.FunctionComponent<Props> = () => {
    const gotoPage = (route: RoutingType) => () => {
        NavigationHelper.pushRoute(route);
    };

    const isCurrentItem = (route: RoutingType) => {
        return window.location.href.includes(NavigationHelper.getRoute(route));
    };

    return (
        <div className="breadcrumb__containder">
            <Breadcrumb
                items={[
                    {
                        text: "Forside",
                        key: "dashboard",
                        onClick: gotoPage("DASHBOARD")
                    },
                    {
                        text: "Produkter",
                        key: "products",
                        onClick: gotoPage("PRODUCTS"),
                        isCurrentItem: isCurrentItem("PRODUCTS")
                    },
                    {
                        text: "Kontaktinformation",
                        key: "customer",
                        onClick: gotoPage("CUSTOMER"),
                        isCurrentItem: isCurrentItem("CUSTOMER")
                    },
                    {
                        text: "Tid og sted",
                        key: "booking",
                        onClick: gotoPage("BOOKING"),
                        isCurrentItem: isCurrentItem("BOOKING")
                    },
                    {
                        text: "Bekræftelse",
                        key: "confirm",
                        onClick: gotoPage("CONFIRM"),
                        isCurrentItem: isCurrentItem("CONFIRM")
                    }
                ]}
            />
        </div>
    );
};

export default BreadCrumb;
