import { IDropdownOption, Dropdown } from "@fluentui/react";
import * as React from "react";

interface Props
{
    onChange: (orderType: AssignmentType) => void;
    value: AssignmentType;
    title?: string;
}

const OrderType: React.FunctionComponent<Props> = props =>
{
    const onOrderTypeChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption) =>
    {
        props.onChange(option.key as AssignmentType);
    };
    return <Dropdown
        calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
        title={props.title ? props.title : ""}
        placeholder={"Vælg opgave type"}
        onChange={onOrderTypeChange}
        options={[
            { key: "REPLACE_FRONT", text: "Udskiftning af forrude" },
            { key: "REPLACE_SIDE", text: "Udskiftning af siderude" },
            { key: "REPLACE_REAR", text: "Udskiftning af bagrude" },
            { key: "REPLACE_ROOF", text: "Udskiftning af tagrude" },
            { key: "REPAIR", text: "Reparation af stenslag" },
            { key: "SALE", text: "Mersalg" }
        ]}
        selectedKey={props.value}
    />;
};
export default OrderType;