import { MessageBarType } from "@fluentui/react";
import { Action, Dispatch } from "redux";
import api from "../../api";
import { addMessageStatus } from "../ui/action";

export interface UpdateZipCodeDepartmentAvailabilityList extends Action {
    type: "UPDATE_ZIPCODE_DEPARTMENTS";
    zipCodeDepartmentAvailabilityList: ZipCodeDepartmentAvailability[];
}

export interface UpdateZipCodeDepartmentAvailability extends Action {
    type: "UPDATE_ZIPCODE_DEPARTMENT";
    zipCodeDepartmentAvailability: ZipCodeDepartmentAvailability;
}

export const searchZipCode = (zipCode: string) => async (
    dispatch: Dispatch
) => {
    try {
        const zipCodeDepartmentAvailabilityList = await api.ZipCodeDepartmentApi.zipCodeSearch(
            zipCode
        );
        const updateDepartmentAction: UpdateZipCodeDepartmentAvailabilityList = {
            type: "UPDATE_ZIPCODE_DEPARTMENTS",
            zipCodeDepartmentAvailabilityList
        };
        dispatch(updateDepartmentAction);
    } catch (e) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Invalid Search query`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw e;
    }
};

export const searchZipCodeByNavResponsibilityCenter = (
    navResponsibilityCenter: string
) => async (dispatch: Dispatch) => {
    try {
        const zipCodeDepartmentAvailabilityList = await api.ZipCodeDepartmentApi.departmentNavResponsibilityCenterSearch(
            navResponsibilityCenter
        );
        const updateDepartmentAction: UpdateZipCodeDepartmentAvailabilityList = {
            type: "UPDATE_ZIPCODE_DEPARTMENTS",
            zipCodeDepartmentAvailabilityList
        };
        dispatch(updateDepartmentAction);
    } catch (e) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Invalid Search query`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw e;
    }
};

export const searchByDepartmentId = (departmentId: string) => async (
    dispatch: Dispatch
) => {
    try {
        const zipCodeDepartmentAvailabilityList = await api.ZipCodeDepartmentApi.searchByDepartmentId(
            departmentId
        );
        const updateDepartmentAction: UpdateZipCodeDepartmentAvailabilityList = {
            type: "UPDATE_ZIPCODE_DEPARTMENTS",
            zipCodeDepartmentAvailabilityList
        };
        dispatch(updateDepartmentAction);
    } catch (e) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Invalid Search query`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw e;
    }
};

export const updateZipCodeDepartment = (
    zipCodeDepartmentAvailability: ZipCodeDepartmentAvailability
) => async (dispatch: Dispatch) => {
    try {
        await api.ZipCodeDepartmentApi.updateZipCodeDepartmentAvailability(
            zipCodeDepartmentAvailability
        );
        const updateDepartmentAction: UpdateZipCodeDepartmentAvailability = {
            type: "UPDATE_ZIPCODE_DEPARTMENT",
            zipCodeDepartmentAvailability
        };
        dispatch(updateDepartmentAction);
    } catch (e) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Error updating zip code department information`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw e;
    }
};

export type Actions =
    | UpdateZipCodeDepartmentAvailabilityList
    | UpdateZipCodeDepartmentAvailability;
