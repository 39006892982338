import produce from "immer";
import { Actions } from "./action";

export interface CustomCalendarEvent {
    id: string;
    description: string;
    createdBy: string;
    title: string;
    departmentId: string;
}
export interface CalendarState {
    event?: CustomCalendarEvent;
    currentBooking?: string;
}

const initialState: CalendarState = {
    event: undefined,
    currentBooking: undefined
};

export const reducer = (
    uiState = initialState,
    action: Actions
): CalendarState =>
    produce(uiState, draft => {
        if (action.type === "SHOW_EVENT") {
            draft.event = action.event;
            return;
        }
        if (action.type === "SET_BOOKING") {
            draft.currentBooking = action.id;
            return;
        }
    });

export const getEvent = (state: CalendarState) => state.event;

export const getCurrentCalendarBookingId = (state: CalendarState) =>
    state.currentBooking;

export default reducer;
