import BaseApi from "./baseApi";

export default class DebitorApi extends BaseApi {
    async getDebitor(query: string, type: ApiType): Promise<Debitor[]> {
        const url = `/api/debitor/search/${type}?query=${encodeURIComponent(
            query
        )}`;
        return await this.fetchGet(url);
    }

    async getDebitorPrices(
        debitor: string,
        products: ProductPriceRequest[]
    ): Promise<DebitorPrice[]> {
        if (products.length === 0) {
            return [];
        }
        const url = `/api/debitor/prices`;
        return await this.fetchPost(url, {
            DebitorNo: debitor,
            Items: products.map(e => e.productNo)
        });
    }
}
