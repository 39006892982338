import BaseApi from "./baseApi";

export default class OrderAPI extends BaseApi {
    async cancelOrder(id: string): Promise<boolean> {
        const url = "/api/order/order";
        return await this.fetchDelete(url);
    }

    async getOrders(
        filter: OrderFilter,
        signal?: AbortSignal
    ): Promise<{ orders: Order[]; pageCount: number }> {
        const url = "/api/order/orders";
        return await this.fetchPost(url, filter, signal);
    }

    async postOrder(state: Order): Promise<Order> {
        const url = "/api/order/order";
        return await this.fetchPost(url, state);
    }

    async getOrder(orderId: string): Promise<Order> {
        const url = `/api/order/order/${orderId}`;
        return await this.fetchGet(url);
    }

    async updateOrder(state: Order): Promise<Order> {
        const url = "/api/order/order";
        return await this.fetchPut(url, state);
    }

    async getMobileServiceOrders(
        departmentIds: string[],
        days: number[]
    ): Promise<{ orders: Order[] }> {
        const url = "/api/order/mobileserviceorders";
        return await this.fetchPost(url, {
            departmentIds: departmentIds,
            days: days
        });
    }

    async createMoreSaleOrder(orderId: string): Promise<Order> {
        const url = `/api/MoreSaleOrder/create/${orderId}`;
        return await this.fetchGet(url);
    }

    async createComplaint(
        orderId: string,
        model: CreateComplaintModel
    ): Promise<Order> {
        const url = `/api/Complaint/create/${orderId}`;
        return await this.fetchPost(url, model);
    }
}
