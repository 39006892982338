import { IDropdownOption } from "@fluentui/react";

export const getComplaintTypeText = (type: ComplaintSlotType) =>
{
    switch (type) {
        case "REPLACE_SLOT":
            return "Reklamation brug rudeskift slot";
        case "REPAIR_SLOT":
            return "Reklamation brug stenslags slot";
        case "WITHOUT_SLOT":
            return "Reklamation uden et slot";
        case "INTERNAL_COMPLAINT":
            return "Intern Reklamation";
        default:
            return "";
            
    }
}


export const getDropdownForComplaintTypes = (): IDropdownOption[] => {
  
    return [
        { key: "REPLACE_SLOT", text: getComplaintTypeText("REPLACE_SLOT") },
        { key: "REPAIR_SLOT", text: getComplaintTypeText("REPAIR_SLOT") }
    ];
  };
  