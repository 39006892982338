export const removeLastTwoNumbersFromTechdatasWeirdYearFormat = (
    i: number
): number => {
    return (i - (i % 100)) / 100;
};

export const getDateArray = (
    techallianceYearFrom: number,
    techallianceYearTo: number
): number[] => {
    const yearFrom = removeLastTwoNumbersFromTechdatasWeirdYearFormat(
        techallianceYearFrom
    );
    let yearTo = removeLastTwoNumbersFromTechdatasWeirdYearFormat(
        techallianceYearTo
    );

    if (techallianceYearTo === 0) {
        const currentDate = new Date();
        yearTo = currentDate.getFullYear();
    }

    const dateOptions: number[] = [];
    for (let i = yearFrom; i <= yearTo; i++) {
        dateOptions.push(i);
    }

    return dateOptions;
};
