import produce from "immer";
import { Actions } from "./action";

export interface UserState {
    userInformation: UserInformation;
    operators: UserInformation[];
    users: UserInformation[];
}

const initialState: UserState = {
    userInformation: {
        name: "",
        initials: "",
        email: "",
        phone: "",
        roles: [],
        departmentId: "",
        departmentGuid: "",
        calendarDepartmentGuid: "",
    },
    operators: [],
    users: []
};

export const reducer = (userState = initialState, action: Actions): UserState =>
    produce(userState, draft => {
        switch (action.type) {
            case "USER_INFORMATION": {
                draft.userInformation = action.userInformation;
                return;
            }
            case "UPDATE_OPERATORS": {
                draft.operators = action.operators;
                return;
            }
            case "UPDATE_USERS": {
                draft.users = action.users;
                return;
            }
            case "REMOVE_USER": {
                draft.users = draft.users.filter(u => u.id !== action.user.id);
                return;
            }
        }
    });

export const getUserInformation = (state: UserState) => state.userInformation;
export const getOperators = (state: UserState) => state.operators;
export const getUsers = (state: UserState) => state.users;

export default reducer;
