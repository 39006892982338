import * as React from "react";
import { useEffect, useState, SyntheticEvent } from "react";
import "./style.css"
import { ScrollablePane, SearchBox } from "@fluentui/react";

interface Props {
    items: string[];
    value: string;
    placeholder: string;
    onValueChange: (value: string) => void;
    onValueSelected: (value: string, index: number) => void;
    updateValueOnChange?: boolean;
    title?: string;
}

function SearchPane(props: Props)
{
    const [currentValue, setValue] = useState(props.value);
    const [hidePane, togglePane] = useState(false);

    useEffect(() =>
    {
        if (props.updateValueOnChange)
        {
            if (props.value !== currentValue)
            {
                togglePane(true);
            }
            setValue(props.value);
        }
    }, [props.value]);

    const handleClick = (e: any) =>
    {
        const ele = document.querySelector(".ms-ScrollablePane");
        if (!(ele ? ele.contains(e.target) : false))
        {
            togglePane(true);
        }
    };

    useEffect(() =>
    {
        document.addEventListener("click", handleClick);
        return () =>
        {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    const handleSetValue = (
        event?: React.ChangeEvent<HTMLInputElement>,
        newValue?: string
    ) =>
    {
        if (newValue !== undefined)
        {
            props.onValueChange(newValue);
            setValue(newValue);
            togglePane(false);
        }
    };

    const selectValue = (value: string, index: number) => (
        e: SyntheticEvent
    ) =>
    {
        setValue(value);
        props.onValueSelected(value, index);
        togglePane(true);
    };

    const matchedNames = props.items.map((value, index) => (
        <div
            key={index}
            onClick={selectValue(value, index)}
            className="search-item"
        >
            {value}
        </div>
    ));
    const pane =
        currentValue &&
        currentValue.length > 0 &&
        !hidePane &&
        matchedNames.length > 0 ? (
                <ScrollablePane>{matchedNames}</ScrollablePane>
            ) : null;
    return (
        <div className="searchPane__container">
            <SearchBox
                title={props.title ? props.title : ""}
                placeholder={props.placeholder}
                value={currentValue}
                onChange={handleSetValue}
            />
            {pane}
        </div>
    );
}

export default SearchPane;
