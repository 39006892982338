import * as React from "react";

interface Props {
    row: {
        id: string;
        ruleName?: string;
        ruleSetName?: string;
        carMake?: string;
        carModel?: string;
        isWorkAllowed?: boolean;
        minimumWorkLength?: number;
        departments?: DepartmentRuleSet[];
        rules?: Rule[];
    };
    isRuleRow: boolean;
}

function CustomAcordeonRow({ row, isRuleRow }: Props) {
    const [isChecked, setisChecked] = React.useState<boolean | null>(null);
    const [noOfHours, setNoOfHours] = React.useState<number>(0);

    React.useEffect(() => {
        setNoOfHours(row.minimumWorkLength ? row.minimumWorkLength : 0);
        setisChecked(row.isWorkAllowed ? row.isWorkAllowed : false);
    }, []);

    const labelText = isChecked ? "Arbejde er tilladt" : "Arbejde er ikke tilladt";

    const renderAcordeonRow = () => {
        return isRuleRow ? (
            <tr key={`secondaryRow-${row.id}`}>
                <td className="additional__details_number"></td>
                {(row.isWorkAllowed === true ||
                    row.isWorkAllowed === false) && (
                    <td colSpan={1} className="additional__details">
                        <div className="attribute">
                            <div className="attribute-value">
                                <p>
                                    <label>{labelText}</label>
                                </p>
                            </div>
                        </div>
                    </td>
                )}
                {isChecked ? (
                    <td colSpan={2} className="additional__details">
                        <div className="attribute">
                            <div className="attribute-name">
                                <p>Minimum arbejdstid:</p>
                            </div>
                            <div className="attribute-value">
                                <p>{noOfHours}</p>
                            </div>
                        </div>
                    </td>
                ) : (
                    <td colSpan={2} className="additional__details">
                        <div className="attribute">
                            <div className="attribute-name">
                                <p>-</p>
                            </div>
                        </div>
                    </td>
                )}
            </tr>
        ) : (
            <tr key={`secondaryRow-${row.id}`}>
                <td className="additional__details_number"></td>
                <td colSpan={1} className="additional__details">
                    <div className="ruleSet_attribute">
                        <p>
                            <label>{"Udvalgte afdelinger:"}</label>
                        </p>
                        {row.departments &&
                            row.departments.map((department, index) => {
                                return (
                                    <div
                                        key={`ruleSet_acordeonRowKey_${department}-${row.id}-${index}`}
                                        className="attribute-value"
                                    >
                                        <p>
                                            <label>
                                                {department.departmentName}
                                            </label>
                                        </p>
                                    </div>
                                );
                            })}
                    </div>
                </td>
                <td colSpan={1} className="additional__details">
                    <div className="ruleSet_attribute">
                        <p>
                            <label>{"Udvalgte regler:"}</label>
                        </p>
                        {row.rules &&
                            row.rules.map((rule, index) => {
                                return (
                                    <div
                                        key={`ruleSet_acordeonRowKey_${rule}-${row.id}-${index}`}
                                        className="attribute-value"
                                    >
                                        <p>
                                            <label>
                                                {rule.ruleName}
                                            </label>
                                        </p>
                                    </div>
                                );
                            })}
                    </div>
                </td>
            </tr>
        );
    };

    return renderAcordeonRow();
}

export default CustomAcordeonRow;
