export const statusText = [
    { key: "DRAFT", text: "Udkast" },
    { key: "PROCESSING", text: "Afventer" },
    { key: "FINISHED", text: "Udført" },
    { key: "CANCELLED", text: "Annulleret" },
    { key: "OFFER", text: "Tilbud" },
    { key: "PENDING_REVIEW", text: "Gennemsyn" },
    { key: "WEB_REVIEW", text: "Webordregennemsyn" },
    { key: "PROCESSING_MORESALE", text: "Afventer " },
    { key: "ON_HOLD", text: "Hold " },
    { key: "SERVICE_CALL", text: "Servicekald" },
    { key: "OFFER_REQUEST", text: "Tilbudsanmodning" }
];

export const getStatusText = (status: OrderStatus) => {
    const item = statusText.find(x => x.key === status);
    return item ? item.text : "";
};

export const getSpecificStatusText = (orderStatuses: OrderStatus[]) => {
    return statusText.filter(
        x => orderStatuses.indexOf(x.key as OrderStatus) > -1
    );
};

export const IsOffer = (status: OrderStatus) => {
   
    return status === "OFFER" || status === "OFFER_REQUEST";
};