import { Action, Dispatch } from "redux";

import api from "../../api";
import { addMessageStatus } from "../ui/action";
import { MessageBarType } from "@fluentui/react";

export interface GetAllDebitorRuleSetsAction extends Action {
    type: "GET_ALL_DEBITOR_RULE_SETS";
    debitorRuleSets: DebitorRuleSet[];
}

export interface UpdateDebitorRuleSetAction extends Action {
    type: "UPDATE_DEBITOR_RULE_SET";
    debitorRuleSet: DebitorRuleSet;
}

export interface DeleteDebitorRuleSetAction extends Action {
    type: "DELETE_DEBITOR_RULE_SET";
    id: string;
}

export interface SortDebitorRuleSetAction extends Action {
    type: "SORT_DEBITOR_RULE_SET";
    sortBy: DebitorRuleSetSortList;
    sortDirection: SortDirection;
}

export const getAllDebitorRuleSets = () => async (dispatch: Dispatch<any>) => {
  
    try {
        const debitorRuleSets = await api.DebitorRuleSetApi.getAll();
        const action: GetAllDebitorRuleSetsAction = {
            type: "GET_ALL_DEBITOR_RULE_SETS",
            debitorRuleSets:debitorRuleSets
        };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "Get debitor ruleset failed"`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const sortDebitorRuleSet = (sortBy: DebitorRuleSetSortList, sortDirection: SortDirection): SortDebitorRuleSetAction => ({ sortBy, sortDirection, type: "SORT_DEBITOR_RULE_SET"});

export const addOrUpdateDebitorRuleSet = (debitorRuleSet?: DebitorRuleSet) => async (
    dispatch: Dispatch<any>
) => {
    try {
        const debitorRuleSetResult = await api.DebitorRuleSetApi.save(debitorRuleSet);
        const action: UpdateDebitorRuleSetAction = {
            type: "UPDATE_DEBITOR_RULE_SET",
            debitorRuleSet: debitorRuleSetResult
        };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "Save debitor ruleset failed"`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export const deleteDebitorRuleSet = (id: string) => async (
    dispatch: Dispatch<any>
) => {
    try {
        await api.DebitorRuleSetApi.delete(id);
        const action: DeleteDebitorRuleSetAction = {
            type: "DELETE_DEBITOR_RULE_SET",
            id
        };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "Delete debitor ruleset failed"`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};


export type Actions = GetAllDebitorRuleSetsAction | UpdateDebitorRuleSetAction | DeleteDebitorRuleSetAction | SortDebitorRuleSetAction;
