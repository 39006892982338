import { MarkerOrder } from "../service_map/MobileServiceMap";

export const isTwoPersonOrder = (items: PurchaseItem[]): boolean => {
    return items.some(item => item.twoPersonJob === true);
};

export const needsCalibration = (order: MarkerOrder) => {
    let needsCalibration = false;
    if (order && order.items) {
        if (
            order.items.filter(
                x =>
                    x.itemNo === "PK3" ||
                    x.itemNo === "PK5" ||
                    x.itemNo === "PK6"
            ).length > 0
        )
            needsCalibration = true;
    }

    return needsCalibration;
};
export const canBookNextDay = (orderItems: PurchaseItem[]): string[] => {
    let locations:string[] = [];
    orderItems.forEach(item => {
        if (item && item.status && item.status.length > 0) {
           item.status.forEach(current =>
                {
                    if(current.amount>0)
                    {
                        locations.push(current.code);
                    }
                }
            );
        }
    });
    return locations;
};
