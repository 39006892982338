import * as React from "react";
import { FormEvent, useState } from "react";
import { getDateArray } from "../../utils/TechAllianceHelpFunctions";
import SearchButton from "../../base/Search/SearchButton";
import { IDropdownOption, Dropdown, SearchBox } from "@fluentui/react";

interface Props {
    carManufactures: CarManufacture[];
    carManufactureModels?: CarManufactureModel[];
    updateCar: (car: Car) => void;
    car: Car;
    updateCarManufactureModels: (manuId: number) => void;
    updateCarFromReg: (reg: string) => void;
    updateCarId: (car: Car) => void;
    insurers: Insurer[];
    leasers: Leaser[];
}

const ChooseCar: React.FunctionComponent<Props> = props => {
    const [reg, setReg] = useState("");

    const changeBrand = (
        event: FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        const manuId = option.key as number;
        props.updateCar({ ...props.car, make: option.text, manuId });
    };

    const brandDropdown = (
        <div className="carBrand_dropdown__container">
            <Dropdown
                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                errorMessage={props.car.make ? undefined : "Et bilmærke skal først vælges" }
                title="Mærke"
                placeholder={props.car.make ? props.car.make : "Mærke"}
                options={props.carManufactures.map(x => ({
                    key: x.manuId,
                    text: x.manuName
                }))}
                onChange={changeBrand}
                defaultSelectedKey={props.car.manuId ? props.car.manuId : 0}
            />
        </div>
    );

    let modelDropdown = null;
    if (props.carManufactureModels && props.car.make) {
        const changeModel = (
            event: FormEvent<HTMLDivElement>,
            option: IDropdownOption
        ) =>
            props.updateCar({
                ...props.car,
                model: option.text,
                modId: option.key as number
            });

        modelDropdown = (
            <Dropdown
                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                title="Model"
                placeholder={props.car.model ? props.car.model : "Model"}
                options={props.carManufactureModels.map(x => {
                    return { key: x.modelId, text: x.modelName };
                })}
                onChange={changeModel}
                defaultSelectedKey={props.car.modId ? props.car.modId : ""}
            />
        );
    }

    const changeInsurer = (
        event: FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        props.updateCar({ ...props.car, insurance: option.text });
    };

    // const changeLeaser = (event: FormEvent<HTMLDivElement>, option: IDropdownOption) =>
    // {
    //     props.updateCar({...props.car, leaser: option.text});
    // };

    let modelYear = null;
    if (props.carManufactureModels && props.car.model) {
        const changeModelYear = (
            event: FormEvent<HTMLDivElement>,
            option: IDropdownOption
        ) =>
            props.updateCarId({
                ...props.car,
                modelYear: option.key as number
            });

        const selectedModel = props.carManufactureModels.find(
            x => x.modelName === props.car.model
        );
        const dateArray = selectedModel
            ? getDateArray(
                  selectedModel.yearOfConstrFrom,
                  selectedModel.yearOfConstrTo
              )
            : [];
        modelYear = (
            <Dropdown
                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                title="Årgang"
                placeholder={
                    props.car.modelYear
                        ? props.car.modelYear.toString()
                        : "Årgang"
                }
                options={dateArray.map(x => ({ key: x, text: `${x}` }))}
                onChange={changeModelYear}
                defaultSelectedKey={
                    props.car.modelYear ? props.car.modelYear : ""
                }
            />
        );
    }

    React.useEffect(() => {
        if (props.car.manuId) {
            props.updateCarManufactureModels(props.car.manuId);
        }
    }, [props.car.manuId]);

    const insurerDropdown = (
        <Dropdown
            calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
            title="Forsikring"
            placeholder={
                props.car.insurance ? props.car.insurance : "Forsikring"
            }
            options={props.insurers && props.insurers.map(x => ({ key: x.name, text: x.name }))}
            onChange={changeInsurer}
            defaultSelectedKey={props.car.insurance ? props.car.insurance : ""}
        />
    );

    const changeLeasing = (
        event: FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        props.updateCar({
            ...props.car,
            leasing: option.key ? option.key.toString() : ""
        });
    };

    const leasingDropdown = (
        <Dropdown
            calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
            title="Leasing"
            placeholder={props.car.leasing ? props.car.leasing : "Leasing"}
            options={[
                { key: "PrivatLeaset", text: "Privat Leaset" },
                { key: "IkkeLeaset", text: "Ikke Leaset" }
            ]}
            onChange={changeLeasing}
            defaultSelectedKey={props.car.leasing ? props.car.leasing : ""}
        />
    );

    const onRegChange = (
        event?: React.ChangeEvent<HTMLInputElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            setReg(newValue);
        }
    };

    const onRegSearch = () => {
        const searchBox: HTMLDivElement = document.querySelector(
            ".searchCarRegBox"
        ) as HTMLDivElement;
        if (searchBox !== null) {
            if (window.outerHeight < 500 || window.outerWidth < 540) {
                window.scrollTo(0, searchBox.offsetTop);
            }
        }
        props.updateCarFromReg(reg);
    };
    return (
        <>
            <h5>Vælg bil</h5>
            <SearchBox
                title="Reg. nr."
                onSearch={onRegSearch}
                className="searchCarRegBox"
                placeholder="reg nr."
                onChange={onRegChange}
            />
            <SearchButton onClick={onRegSearch} />
            <br />
            <br />
            {brandDropdown}
            {modelDropdown}
            {modelYear}
          
            {insurerDropdown}
            {leasingDropdown}
            {props.car && props.car.user && <p><b>Bruger:</b> {props.car.user}</p>}
            {props.car && props.car.currentOrdersOnReg && <><b>Aktive ordre på dette reg nr:</b><br/>{props.car.currentOrdersOnReg.map(x=><><a href={"/worksheet/" + x.id}>{x.orderNo}</a> </>)}</>}
        </>
    );
};
export default ChooseCar;
