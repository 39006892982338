import * as React from "react";
import DebitorFields from "../../../booking/Customer/DebitorFields";
import Customer from "../../../booking/Customer";
import { orderToOrderReceiptModel } from "../../../utils/emailHelper";
import {
    debitorFieldConfig,
    getCustomerValidation
} from "../../../../util/validate";
import { getOrderPaymentTypeText } from "../../../utils/orderPaymentTypeHelper";
import WhoIsPaying from "../../../dashboard/WhoIsPaying";
import "../../../booking/Customer/style.css";
import "../../../dashboard/style.css";
import "../../style.css";
import { ActionButton, Stack, PrimaryButton } from "@fluentui/react";

interface Props {
    order: Order;
    departments: Department[];
    leasers: Leaser[];
    insurers: Insurer[];
    car: Car;
    debitors?: Debitor[];
    updateCar: (car: Car) => void;
    whoIsPayingChange: (whoIsPaying: WhoIsPaying) => void;
    updateDebitorFromWhoIsPaying: (
        whoIsPaying: WhoIsPaying,
        order: Order,
        updatePrices: boolean
    ) => void;
    onDepartmentChange: (
        department: Department,
        shouldUpdateDebitor: boolean
    ) => void;
    updateDebitor: (debitor: Partial<Debitor>) => void;
    updateCustomer: (customer: Partial<Customer>) => void;
    updateCustomerCityFromZipCode: (zipcode: string) => void;
    sendReceiptEmail: (model: IOrderReceiptModel) => void;
    getDebitor: (query: string, type: ApiType) => void;
    setDebitor: (debitor: Debitor) => void;
}

function Payment(props: Props) {
    const [enableEdit, toggleEdit] = React.useState(false);
    const [sent, toggleSent] = React.useState(false);

    const handleEdit = () => toggleEdit(!enableEdit);

    const SendReceiptEmail = () => {
        const receiptModel = orderToOrderReceiptModel(props.order);
        props.sendReceiptEmail(receiptModel);
        toggleSent(true);
    };

    return (
        <>
            <div className="payment__container">
                <ActionButton
                    data-automation-id="test"
                    iconProps={{
                        iconName: enableEdit ? "BoxCheckmarkSolid" : "Edit"
                    }}
                    onClick={handleEdit}
                ></ActionButton>
                {enableEdit ? (
                    <WhoIsPaying
                        departments={props.departments}
                        onDepartmentChange={props.onDepartmentChange}
                        onWhoIsPayingChange={props.whoIsPayingChange}
                        updateDebitorFromWhoIsPaying={
                            props.updateDebitorFromWhoIsPaying
                        }
                        order={props.order}
                        car={props.car}
                        updateCar={props.updateCar}
                        leasers={props.leasers}
                        insurers={props.insurers}
                        debitors={props.debitors}
                        getDebitor={props.getDebitor}
                        onDebitorChange={props.setDebitor}
                        selectedDebitor={props.order.debitor}
                    />
                ) : (
                    <>
                        <h5 style={{ marginRight: "10px" }}>Hvem betaler</h5>
                        <p>
                            {getOrderPaymentTypeText(
                                props.order.additionalData.whoIsPaying
                            )}
                        </p>
                    </>
                )}
            </div>
            <div className="styled_customer__form">
                <Stack horizontal tokens={{ childrenGap: 50 }}>
                    <Stack.Item grow={1}>
                        <DebitorFields
                            fieldConfig={debitorFieldConfig}
                            debitor={props.order.debitor}
                            updateDebitor={props.updateDebitor}
                        />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <div className="customer__paymentForm">
                            <Customer
                                fieldConfig={getCustomerValidation(
                                    props.order.additionalData.whoIsPaying
                                )}
                                customer={props.order.customer}
                                updateCustomer={props.updateCustomer}
                                updateCustomerCityFromZipCode={
                                    props.updateCustomerCityFromZipCode
                                }
                            />
                        </div>
                        {props.order.customer.allowEmail ? (
                            <PrimaryButton
                                text={
                                    !sent
                                        ? "Send kvitteringsmail"
                                        : "Kvitteringsmail afstendt"
                                }
                                className="receipt-button"
                                disabled={sent}
                                onClick={SendReceiptEmail}
                            />
                        ) : (
                            <>
                                <div className="cancel--inline disabled cancel_button">
                                    <PrimaryButton
                                        disabled
                                        text="EMAIL ikke tilladt"
                                    />
                                </div>
                            </>
                        )}
                    </Stack.Item>
                </Stack>
            </div>
        </>
    );
}

export default Payment;
