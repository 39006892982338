import {
    IColumn,
} from "@fluentui/react";
import TableHelper from "../../utils/TableHelper";
import api from "../../../api";
import { useEffect } from "react";
import * as React from "react";
import CustomSlotsTable from "../CustomSlotsTable";
import "../style.css";

const dataFetchIntervalMinutes = 60000 * 15;

const AvailableSlotsJutland: React.FunctionComponent = () => {
    const [julandDepartments, setJulandDepartments] = React.useState<
        DepartmentAvailableSlots[]
    >([]);

    useEffect(() => {
        const getAllData = async () => {
            api.AvailableSlotsApi.getAllData().then(data => {
                setJulandDepartments(data.julandDepartments);
            });
        };

        getAllData();

        const interval = setInterval(() => {
            getAllData();
        }, dataFetchIntervalMinutes);

        return () => clearInterval(interval);
    }, []);

    const getFormattedDate = (stringDate: string) => {
        const date = new Date(stringDate);
        const day = date.getDate();

        return `${day}.`;
    };

    const generateColumn = (
        slot: AvailableSlots,
        index: number,
        isStoneChipColumn: boolean
    ) => {
        return TableHelper.col(
            `slot${index}`,
            getFormattedDate(slot.date),
            70,
            80,
            (e: DepartmentAvailableSlots) => (
                <p className="textStyle">
                    {isStoneChipColumn
                        ? e.availableSlots[index].stoneChipAvailableSlots
                        : e.availableSlots[index]
                            .windowReplacementAvailableSlots}
                </p>
            )
        );
    };

    const getColumnsStoneChips = (
        deptAvailbeleSlots: DepartmentAvailableSlots[]
    ): IColumn[] => {
        const columns: IColumn[] = [];

        if (
            deptAvailbeleSlots.length > 0 &&
            deptAvailbeleSlots[0].availableSlots.length === 7
        ) {
            for (let i = 0; i < 7; i++) {
                columns.push(
                    generateColumn(
                        deptAvailbeleSlots[0].availableSlots[i],
                        i,
                        true
                    )
                );
            }
        }

        if (
            deptAvailbeleSlots.length > 0 &&
            deptAvailbeleSlots[0].availableSlots.length === 1
        ) {
            columns.push(
                generateColumn(deptAvailbeleSlots[0].availableSlots[0], 0, true)
            );
        }

        return columns;
    };

    const getColumnsWindowReplacemet = (
        deptAvailbeleSlots: DepartmentAvailableSlots[]
    ): IColumn[] => {
        const columns: IColumn[] = [];

        if (
            deptAvailbeleSlots.length > 0 &&
            deptAvailbeleSlots[0].availableSlots.length === 7
        ) {
            for (let i = 0; i < 7; i++) {
                columns.push(
                    generateColumn(
                        deptAvailbeleSlots[0].availableSlots[i],
                        i,
                        false
                    )
                );
            }
        }

        if (
            deptAvailbeleSlots.length > 0 &&
            deptAvailbeleSlots[0].availableSlots.length === 1
        ) {
            columns.push(
                generateColumn(
                    deptAvailbeleSlots[0].availableSlots[0],
                    0,
                    false
                )
            );
        }

        return columns;
    };

    return (
        <>
            <div>
                <CustomSlotsTable
                    regionName="Jylland"
                    items={julandDepartments}
                    stoneChipsColumns={getColumnsStoneChips(julandDepartments)}
                    windowReplacementColumns={getColumnsWindowReplacemet(
                        julandDepartments
                    )}
                />
            </div>
        </>
    );
};

export default AvailableSlotsJutland;