import * as React from "react";
import TableHelper from "../../utils/TableHelper";
import {
    vatConverter,
    vatValueConverter,
    roundToTwoDecimals,
    parsePriceAsString
} from "../../utils/vatConverter";
import { ItemType } from "../../typings";
import ProductColumn from "../ProductColumn";
import "../style.css"
import { IColumn, TextField, DefaultButton, Icon, IDetailsRowProps, IDetailsRowStyles, DetailsRow, DetailsList, DetailsListLayoutMode, CheckboxVisibility } from "@fluentui/react";

interface Props {
    setDiscount: (itemNo: string, discount: number) => void;
    removeProduct: (item: PurchaseItem) => void;
    setAmount: (itemNo: string, amount: number) => void;
    setPrice: (itemNo: string, price: number) => void;
    setName: (itemNo: string, name: string) => void;
    items: PurchaseItem[];
    useScrollableWrapper: boolean; // to prevent conflict f.exs. when list is placed on Dialog must be false
    orderStatus?: OrderStatus;
}

const OrderLines: React.FunctionComponent<Props> = props => {
    const onDiscountChange = (item: PurchaseItem) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        if (!value) {
            props.setDiscount(item.itemNo, 0);
        }
        if (
            /^\d{1,2}(\.|\,)?\d{0,2}$/.test(value) &&
            !isNaN(parseFloat(value.replace(",", ".")))
        ) {
            const discount: number = parseFloat(value.replace(",", "."));
            if (discount > 100) {
                props.setDiscount(item.itemNo, 100);
            } else {
                props.setDiscount(item.itemNo, isNaN(discount) ? 0 : discount);
            }
        }
    };

    const onAmountChange = (item: PurchaseItem) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        const amount = parseFloat(value);
        props.setAmount(item.itemNo, isNaN(amount) ? 0 : amount);
    };

    const onPriceChange = (item: PurchaseItem) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        const price = parseFloat(value) * 0.8;
        props.setPrice(item.itemNo, isNaN(price) ? 0 : price);
    };

    const onNameChange = (item: PurchaseItem) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        props.setName(item.itemNo, value);
    };

    const onRemoveProductClick = (item: PurchaseItem) => () => {
        props.removeProduct(item);
    };
    const isDisabled =
        props.orderStatus === "SERVICE_CALL" ||
        props.orderStatus === "FINISHED";
    const columns: IColumn[] = [
        TableHelper.col(
            "varenummer",
            "Varenummer",
            100,
            100,
            (e: PurchaseItem) => {
                return <ProductColumn product={e} />;
            }
        ),
        TableHelper.col("name", "Produkter", 250, 250, (e: PurchaseItem) => (
            <>
                <label>Navn</label>
                <TextField
                    disabled={isDisabled}
                    type="text"
                    placeholder={e.name}
                    value={e.name}
                    onChange={onNameChange(e)}
                ></TextField>
            </>
        )),
        TableHelper.col("amount", "Antal", 70, 70, (e: PurchaseItem) => (
            <>
                <label>Antal</label>
                <TextField
                    disabled={isDisabled}
                    type="number"
                    placeholder="Antal"
                    value={e.amount.toString()}
                    onChange={onAmountChange(e)}
                />
            </>
        )),
        TableHelper.col(
            "unitprice",
            "Enhedspris",
            136,
            136,
            (e: PurchaseItem) => {
                const price = roundToTwoDecimals(
                    vatValueConverter(
                        e.debitorPrice ? e.debitorPrice.price : e.itemPrice
                    )
                );
                return (
                    <>
                        <label>Enhedspris</label>
                        <TextField
                            disabled={isDisabled}
                            type="number"
                            suffix="kr"
                            placeholder="Pris"
                            value={price.toString()}
                            onChange={onPriceChange(e)}
                        />
                    </>
                );
            }
        ),
        TableHelper.col(
            "newpercentage",
            "Procentrabat",
            90,
            90,
            (e: PurchaseItem) => {
                const discountPercentage = e.debitorPrice
                    ? e.debitorPrice.discountPercent
                    : e.discountPercent;
                return (
                    <>
                        <label>Procentrabat</label>
                        <TextField
                            disabled={isDisabled}
                            placeholder="Procentrabat %"
                            maxLength={4}
                            suffix="%"
                            value={discountPercentage.toLocaleString("da-DK")}
                            onChange={onDiscountChange(e)}
                        />
                    </>
                );
            }
        ),
        TableHelper.col("price", "Pris", 100, 120, (e: PurchaseItem) => {
            const discountPercentage = e.debitorPrice
                ? e.debitorPrice.discountPercent
                : e.discountPercent;
            const price =
                (e.debitorPrice ? e.debitorPrice.price : e.itemPrice) *
                (1 - discountPercentage / 100);
            return (
                <div className="order_line_price__list">
                    <div>
                        <label>Inkl. moms:</label>
                        <p>{vatConverter(price * e.amount)}</p>
                    </div>
                    <div>
                        <label>Eksl. moms:</label>
                        <p>{parsePriceAsString(price * e.amount)}</p>
                    </div>
                </div>
            );
        }),
        TableHelper.col("remove", "", 136, 136, (e: PurchaseItem) => (
            <>
                <DefaultButton
                    disabled={isDisabled}
                    onClick={onRemoveProductClick(e)}
                    text="Fjern"
                />
                <Icon iconName="Delete" style={{ display: "none" }} />
            </>
        ))
    ];

    const onRenderRow = (props: IDetailsRowProps): JSX.Element => {
        const customStyles: Partial<IDetailsRowStyles> = {};

        const item = props.item as PurchaseItem;
        if (item.type === ItemType.CALIB) {
            customStyles.cell = { backgroundColor: "darkgray" };
        }
        return <DetailsRow {...props} styles={customStyles} />;
    };

    return (
        <div data-is-scrollable={props.useScrollableWrapper}>
            <DetailsList
                onRenderRow={onRenderRow}
                layoutMode={DetailsListLayoutMode.justified}
                columns={columns}
                items={props.items}
                checkboxVisibility={CheckboxVisibility.hidden}
            />
        </div>
    );
};

export default OrderLines;
