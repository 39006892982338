import { DatePicker, DateRangeType, DayOfWeek, DefaultButton, Dropdown, IDropdownOption, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { useCallback, useState } from "react";
import openingHoursDayDropdown from "./helpers/openingHoursDayDropdown";
import { connect } from "react-redux";
import { RootState } from "../../state";
import { Action, Dispatch } from "redux";
import { copyTimeSlot } from "../../state/department/action";

interface TimeslotCopyProps {
    departmentId?: string;
    timeslotType: string;
}

const TimeslotCopy: React.FunctionComponent<TimeslotCopyProps & StateProps> = props => {
    const [dayOfWeek, setDayOfWeek] = React.useState<number | undefined | null>(undefined);
    const [copyTo, setCopyTo] = React.useState<Date | undefined>(undefined);

    const onDateChange = (date: Date | undefined) => setCopyTo(date);
    const onDateOfWeekChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption<any>) =>
    {
        setDayOfWeek(option.key as number);
        setCopyTo(undefined);
    } 
    const onCopyDayClick = () => {
        if(dayOfWeek && copyTo && props.departmentId)
        {
            props.copyTimeSlot(props.departmentId, dayOfWeek, copyTo, props.timeslotType)
            setDayOfWeek(null);
            setCopyTo(undefined);
        }

    }


    return (
        <Stack tokens={{ padding: 5, childrenGap: 10 }} horizontal>
            <StackItem className="departmentStackItem" grow={3} disableShrink={true} >
                <Dropdown
                    calloutProps={{ calloutMaxHeight: 250, calloutMinWidth: 100 }}
                    placeholder="Vælg dag"
                    selectedKey={dayOfWeek}
                    defaultSelectedKey={undefined}
                    options={openingHoursDayDropdown()}
                    onChange={onDateOfWeekChange}
                />
            </StackItem>
            <StackItem  className="departmentStackItem" grow={3} disableShrink={true} >
                <DatePicker
                    disabled={dayOfWeek === null || dayOfWeek === undefined}
                    className="date_picker_department__container"
                    placeholder="Vælg dato"
                    calendarProps={{workWeekDays: [dayOfWeek as DayOfWeek], dateRangeType: DateRangeType.WorkWeek, allFocusable: false}}
                    minDate={new Date()}
                    firstDayOfWeek={DayOfWeek.Monday}
                    onSelectDate={onDateChange}
                    value={copyTo}
                />
            </StackItem>
            <StackItem className="departmentStackItem" grow={4} disableShrink={true} >
                <DefaultButton disabled={!(copyTo && dayOfWeek)} onClick={onCopyDayClick}>Copy</DefaultButton>
            </StackItem>

        </Stack>
    );
};

const mapStateToProps = (state: RootState) => {
    return {

    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    copyTimeSlot: (departmentId: string, dayOfWeek: number, copyTodate: Date, timeslotType: string) =>
        dispatch<any>(copyTimeSlot(departmentId, dayOfWeek, copyTodate, timeslotType)),

});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(TimeslotCopy);

