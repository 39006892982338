import { useState, useEffect } from "react";

function getWindowDimensions() {
    const { width, height } = screen;
    return { width, height };
}

export default (): { width: number; height: number } => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};
