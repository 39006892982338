import * as React from "react";
import TableHelper from "../utils/TableHelper";
import { getStatusText, statusText } from "../utils/statusHelper";
import { getAssignmentTypeText } from "../utils/assignmentTypeHelper";
import { orderToNotificationModel } from "../utils/smsHelper";
import SendTextWindowMobile from "../base/Notifications/SendTextWindowMobile";
import { getMapsUrl } from "../utils/LocationHelper";
import { convertUSDateToEU } from "../../util/DateHelper";
import { userHasAccess } from "../utils/UserHelper";
import "./style.css";
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, DetailsRow, Dialog, DirectionalHint, Dropdown, IColumn, IDetailsRowProps, IDetailsRowStyles, IDropdownOption, Icon, Label, PrimaryButton, TooltipHost } from "@fluentui/react";
import { useState } from "react";

interface Props {
    orders: Order[];
    selectBooking: (ordreId: string) => void;
    sendSms: (model: NotificationModel) => void;
    user: UserInformation;
    saveSelectedOrder: (order: Order, status: OrderStatus) => void;
}

const OrderList: React.FunctionComponent<Props> = props => {
    const [statusDialogIsHidden, setStatusDialogHidden] = useState<boolean>(
        true
    );
    const [orderStatus, setOrderStatus] = useState<OrderStatus>();

    const toggleDialogHidden = () => {
        setStatusDialogHidden(!statusDialogIsHidden);
    };
    const onSendOfferReminderSMSClick =  (order:Order) => async () =>
    {
        const sms = orderToNotificationModel(order, "OFFER_SMS", undefined, props.user.name);
        props.sendSms(sms);
    }

    const updateOrderStatus = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
       
        const selectedOption = statusText.find(x => x.key == option.key);
        if (selectedOption !== undefined) {
            setOrderStatus(selectedOption.key as OrderStatus);
        }
    };

    const saveOrderStatus = (order: Order) => {
        if (orderStatus !== undefined) {
            props.saveSelectedOrder(order, orderStatus);
            setStatusDialogHidden(true);
            setOrderStatus(undefined);
        }
    };

    const columns: IColumn[] = [
        TableHelper.col("ordernr", "Ordre nr.", 16, 56, (e: Order) => (
            <p onClick={selectedBooking(e)}>
                {e.additionalData.orderNr || e.additionalData.orderId}
            </p>
        )),
        TableHelper.col(
            "type",
            "Type",
            16,
            86,
            (e: Order) => (
                <p onClick={selectedBooking(e)}>
                    {getAssignmentTypeText(e.additionalData.orderType)}
                </p>
            ),
            "hidden-xs"
        ),
        TableHelper.col("date", "Dato", 16, 76, (e: Order) => (
            <p onClick={selectedBooking(e)}>
                {e.timeAndPlace.date
                    ? convertUSDateToEU(e.timeAndPlace.date)
                    : ""}
            </p>
        )),
        TableHelper.col("time", "Tidspunkt", 16, 66, (e: Order) => (
            <p onClick={selectedBooking(e)}>
                {e.timeAndPlace.from + " - " + e.timeAndPlace.to}
            </p>
        )),
        TableHelper.col("comment", "Kommentar", 16, 66, (e: Order) => (
            <TooltipHost
                content={e.additionalData.internalOrderComment}
                delay={0}
            >
                <p>{e.additionalData.internalOrderComment}</p>
            </TooltipHost>
        )),
        TableHelper.col( "phone", "Tlf. nr.", 16, 76, (e: Order) =>
                e.customer.phone ? (
                    <p>
                        <Icon iconName="Phone" title="Call phone number" />
                        <a href={`tel:${e.customer.phone}`}>
                            {" "}
                            {e.customer.phone}
                        </a>
                    </p>
                ) : null,
            "hidden-xs external-link"
        ),

        TableHelper.col(
            "address",
            "Adresse",
            16,
            146,
            (e: Order) => {
                const { mobileServiceLocation } = e.timeAndPlace;
                return mobileServiceLocation &&  mobileServiceLocation.address && e.timeAndPlace.department && e.timeAndPlace.department.mobileService ? (
                    <>
                        <TooltipHost
                            content={mobileServiceLocation.address}
                            delay={0}
                            calloutProps={{ gapSpace: 5 }}
                        >
                            <Icon
                                iconName="OpenInNewWindow"
                                className="maps"
                                title="Opens Google Maps in new window"
                            />
                            <a
                                target="_blank"
                                href={getMapsUrl(mobileServiceLocation.address)}
                            >
                                {mobileServiceLocation.address}
                            </a>
                        </TooltipHost>
                    </>
                ) : null;
            },
            "hidden-xs external-link alternate-link"
        ),
        TableHelper.col(
            "twoman",
            "",
            16,
            16,
            (e: Order) =>
                e.additionalData.twoManJob ? (
                    <Icon
                        iconName="People"
                        title="To-mands job"
                        style={{ fontSize: "1.5em" }}
                    />
                ) : null,
            "hidden-xs two-man-job"
        ),
        TableHelper.col(
            "sms",
            "SMS Status",
            16,
            100,
            (e: Order) =>
            {
                if(e.customer.allowSms && e.timeAndPlace.department && e.timeAndPlace.department.mobileService)
                {
                    return <TooltipHost
                    content={"SEND 'Jeg er på vej' SMS"}
                    delay={0}
                    directionalHint={DirectionalHint.topLeftEdge}
                    calloutProps={{ gapSpace: 5 }}
                    >
                        <SendTextWindowMobile
                            order={e}
                            sendSms={props.sendSms}
                            useIcon={true}
                        />
                    </TooltipHost>;
                }
                else if(e.additionalData.orderStatus === "OFFER" && (!e.notificationStatus || !e.notificationStatus.smsOffer) && userHasAccess(props.user.roles, [
                    "Complaint",
                    "Adminstrator"
                ]))
                {
                    return <TooltipHost
                    content={"SEND 'Tilbuds' SMS"}
                    delay={0}
                    directionalHint={DirectionalHint.topLeftEdge}
                    calloutProps={{ gapSpace: 5 }}
                    >
                          <PrimaryButton
                            onClick={onSendOfferReminderSMSClick(e)}
                            text="Påmindelse"
                        />
                    </TooltipHost>;
                }
                else
                {
                    return <TooltipHost
                    content={"SMS ikke tilladt"}
                    delay={0}
                    directionalHint={DirectionalHint.topLeftEdge}
                    calloutProps={{ gapSpace: 5 }}
                >
                    <div className="cancel--inline disabled cancel_button">
                        <PrimaryButton
                            iconProps={{ iconName: "Message" }}
                            disabled
                            text=""
                        />
                    </div>
                </TooltipHost>;
                }
            }, "hidden-xs"
        ),
        TableHelper.col(
            "status",
            "Status",
            16,
            86,
            (e: Order) => {
                return (
                    <div className="status_cell">
                        <p>{getStatusText(e.additionalData.orderStatus)}</p>
                        {userHasAccess(props.user.roles, [
                            "CustomerService",
                            "Adminstrator"
                        ]) && (
                            <TooltipHost
                                content={"Skift ordrestatus manuelt"}
                                delay={0}
                                calloutProps={{ gapSpace: 0 }}
                            >
                                <Icon
                                    iconName="Edit"
                                    onClick={toggleDialogHidden}
                                />
                                <Dialog hidden={statusDialogIsHidden} onDismiss={toggleDialogHidden}>
                                    <Label className="dialog__label">Vælg ny order status </Label>
                                    <Dropdown
                                        calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                        disabled={
                                            e.additionalData.orderStatus ===
                                            undefined
                                        }
                                        placeholder={"Order status"}
                                        options={statusText}
                                        selectedKey={orderStatus || e.additionalData.orderStatus}
                                        onChange={updateOrderStatus}
                                    />
                                    <PrimaryButton
                                        onClick={() => saveOrderStatus(e)}
                                    >
                                        {" "}
                                        Gem{" "}
                                    </PrimaryButton>
                                </Dialog>
                            </TooltipHost>
                        )}
                        {e.additionalData.internalOrderComment ? (
                            <div className="custom_tooltip">
                                <TooltipHost
                                    content={
                                        e.additionalData.internalOrderComment
                                    }
                                    delay={0}
                                    calloutProps={{ gapSpace: 5 }}
                                >
                                    <Icon iconName="AlertSolid" />
                                </TooltipHost>
                            </div>
                        ) : null}
                    </div>
                );
            },
            "hidden-xs status"
        )
    ];

    const selectedBooking = (item: Order) => (ev: React.MouseEvent) => {
        if (item.additionalData.orderId) {
            props.selectBooking(item.additionalData.orderId);
        }
    };

    const sendOnMyWaySms = (order: Order) => () => {
        const smsModel = orderToNotificationModel(order, "ON_MY_WAY");
        props.sendSms(smsModel);
    };

    const onRenderRow = (props: IDetailsRowProps): JSX.Element => {
        const customStyles: Partial<IDetailsRowStyles> = {};

        const item = props.item as Order;
        if (item.debitor.debitorName === "DUMMY - SKIFT KONTO") {
            customStyles.root = { border: "1px solid red" };
            customStyles.cell = { color: "red" };
        }
        return <DetailsRow {...props} styles={customStyles} />;
    };

    return (
        <DetailsList
            className="hidden-xs assignments"
            layoutMode={DetailsListLayoutMode.justified}
            columns={columns}
            items={props.orders}
            checkboxVisibility={CheckboxVisibility.hidden}
            onRenderRow={onRenderRow}
        />
    );
};

export default OrderList;
