import * as React from "react";
import PageHeader from "../layout/PageHeader";
import "./style.css";
import { TextField, PrimaryButton } from "@fluentui/react";
import { Link } from "react-router-dom";

interface LoginProps {
    login: (userName: string, password: string) => void;
}

const LoginUser: React.FunctionComponent<LoginProps> = props => {
    const [userName, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");

    const onFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        props.login(userName, password);
    };

    const updateUserName = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setUserName(value);
    };

    const updatePassword = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setPassword(value);
    };

    return (
        <>
            <PageHeader currentPage="Login" noBreadcrumb={true} />
            <form onSubmit={onFormSubmit}>
                <br />
                <br />
                <div className="text_field__container">
                    <TextField
                        width={"200px"}
                        required
                        placeholder="Initialer"
                        onChange={updateUserName}
                    />
                    <br />
                    <TextField
                        type="password"
                        width={"200px"}
                        required
                        placeholder="Kodeord"
                        onChange={updatePassword}
                    />
                </div>
                <Link
                    style={{
                        marginTop: "13px",
                        fontSize: "10px",
                        display: "block"
                    }}
                    href="/forgot"
                    to={"/forgot"}
                >
                    Glemt kodeord
                </Link>
                <br />
                <PrimaryButton type={"submit"}>Login</PrimaryButton>
            </form>
        </>
    );
};

export default LoginUser;
