import * as React from "react";
import CreateAssignments, { Props as CreateAssignmentProps } from "./DailyAssignements/CreateAssignment";
import DailyAssignements from "./DailyAssignements";
import Lookup from "./Lookup";
import Storage from "./Storage";

import
{
    getCarManufactures,
    getCarManufactureModels,
    getOrders,
    getInsurers,
    getLeasers,
    getDepartments,
    getTransfers,
    getOrder,
    getProductSearchQuery,
    getCar,
    getUserInformation,
    getDebitors,
    getBinList,

    getProductList
} from "../../state/action";
import { RootState } from "../../state";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { updateCarManufactures, updateCarManufactureModels } from "../../state/car/action";
import { useEffect } from "react";
import
{
    updateCar,
    updateCarFromReg,
    updateCarId,
    updateAdditional,
    whoIsPayingChange,
    updateOrderDepartment,
    updateDebitorFromWhoIsPaying,
    setDebitor
} from "../../state/order/action";
import { updateProductSearchQuery, getDebitors as getDebitorsAction } from "../../state/ui/action";
import { acceptTransfer, updateTransfers, createTransfer, updateBinList } from "../../state/transfer/action";
import { fetchOrders, saveSelectedOrder } from "../../state/worksheet/action";
import NavigationHelper from "../utils/NavigationHelper";
import { sendSms } from "../../state/sms/action";
import { sendNotificationEmail } from "../../state/email/action";
import { AuthComponent } from "../utils/AuthComponent";
import { updateSearchList, changeProductAmount } from "../../state/product/action";


function Dashboard(props: StateProps)
{
    useEffect(() =>
    {
        props.updateSearchProductQuery();
        props.updateCarManufactures();
        props.updateOrders({ page: 0, orderSortType: "DATE", fromDate: new Date(), toDate: new Date(), department: props.user.departmentGuid });
        if (props.user.departmentGuid && props.user.departmentGuid !== "00000000-0000-0000-0000-000000000000")
        {
            props.updateTransfers(props.user.departmentGuid);
        }
    }, []);

    const setDebitor = (debitor: Debitor) => {
        props.setDebitor(debitor, props.order);
    }

    const updateOrderType = (type: AssignmentType) =>
    {
        props.updateAdditional({ ...props.order.additionalData, orderType: type });
    };

    const onUpdateSearchProductQueryClick = (searchIn: string, term: string) =>
    {
        props.updateSearchProductQuery(searchIn, term);
        NavigationHelper.pushRoute("PRODUCTS");
    };

    const createAssignmentsProps: CreateAssignmentProps = {
        carManufactures: props.carManufactures,
        carManufactureModels: props.carManufactureModels,
        order: props.order,
        insurers: props.insurers,
        departments: props.departments,
        updateCar: props.updateCar,
        updateCarManufactureModels: props.updateCarManufactureModels,
        updateCarFromReg: props.updateCarFromReg,
        updateCarId: props.updateCarId,
        updateOrderType,
        getDebitor: props.getDebitor,
        whoIsPayingChange: props.whoIsPayingChange,
        updateDebitorFromWhoIsPaying: props.updateDebitorFromWhoIsPaying,
        updateOrderDepartment: props.updateOrderDepartment,
        leasers: props.leasers,
        car: props.car,
        setDebitor: setDebitor,
        debitors: props.debitors,
    };

    const lookupProps = {
        productSearchQuery: props.searchProductQuery,
        onUpdateSearchProductQueryClick,
        query: props.searchProductQuery
    };

    const assignmentsProps = {
        orders: props.orders,
        sendSms: props.sendSms,
        user: props.user,
        saveSelectedOrder: props.saveSelectedOrder
    };

    const storageProps = {
        departments: props.departments,
        transfers: props.transfers,
        createTransfer: props.createTransfer,
        updateTransfers: props.updateTransfers,
        acceptTransfer: props.acceptTransfer,
        departmentId: props.user.departmentId,
        departmentGuid: props.user.departmentGuid,
        binlist: props.binlist,
        updateBinList: props.updateBinList,
        query: props.searchProductQuery,
        updateSearchList: props.updateSearchList,
        updateSearchProductQuery: props.updateSearchProductQuery,
        changeProductAmount: props.changeProductAmount,
        products: props.products
    };
    return (
        <>
            {AuthComponent({
                allowedUserRoles: ["Adminstrator", "CustomerService", "Operation"],
                userRoles: props.userInformation.roles,
                component: CreateAssignments,
                props: createAssignmentsProps
            })}

            {AuthComponent({
                allowedUserRoles: ["Adminstrator", "CustomerService", "Operation"],
                userRoles: props.userInformation.roles,
                component: Lookup,
                props: lookupProps
            })}

            {AuthComponent({
                allowedUserRoles: ["Adminstrator", "Operation"],
                userRoles: props.userInformation.roles,
                component: DailyAssignements,
                props: assignmentsProps
            })}

            {AuthComponent({
                allowedUserRoles: ["Adminstrator", "Operation", "Stock"],
                userRoles: props.userInformation.roles,
                component: Storage,
                props: storageProps
            })}
        </>
    );
}

const mapStateToProps = (state: RootState) =>
{
    return {
        transfers: getTransfers(state),
        departments: getDepartments(state),
        carManufactures: getCarManufactures(state),
        carManufactureModels: getCarManufactureModels(state),
        orders: getOrders(state),
        order: getOrder(state),
        insurers: getInsurers(state),
        leasers: getLeasers(state),
        searchProductQuery: getProductSearchQuery(state),
        car: getCar(state),
        user: getUserInformation(state),
        debitors: getDebitors(state),
        binlist: getBinList(state),
        userInformation: getUserInformation(state),
        products: getProductList(state),
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) =>
    ({
        updateSearchProductQuery: (searchIn?: string, term?: string) => dispatch<any>(updateProductSearchQuery(searchIn, term)),
        updateCarManufactures: () => dispatch<any>(updateCarManufactures()),
        updateTransfers: (departmentGuid: string, orderId?: string, fromDate?: Date, toDate?: Date, ) =>
            dispatch<any>(updateTransfers(departmentGuid, orderId, fromDate, toDate)),
        acceptTransfer: (isReceipt: boolean, orderNo: string, orderLine: number, amount: number, departmentGuid: string) =>
            dispatch<any>(acceptTransfer(isReceipt, orderNo, orderLine, amount, departmentGuid)),
        updateCarManufactureModels: (manuId: number) => dispatch<any>(updateCarManufactureModels(manuId)),
        updateCar: (car: Car) => dispatch(updateCar(car)),
        updateCarFromReg: (reg: string) => dispatch<any>(updateCarFromReg(reg)),
        updateCarId: (car: Car) => dispatch<any>(updateCarId(car)),
        updateOrders: (filter: OrderFilter) => dispatch<any>(fetchOrders(filter)),
        updateAdditional: (additional: AdditionalData) => dispatch<any>(updateAdditional(additional)),
        getDebitor: (query: string, type: ApiType) => dispatch<any>(getDebitorsAction(query, type)),
        createTransfer: (transfers: Transfer[]) => dispatch<any>(createTransfer(transfers)),
        whoIsPayingChange: (whoIsPaying: WhoIsPaying) => dispatch<any>(whoIsPayingChange(whoIsPaying)),
        updateDebitorFromWhoIsPaying: (whoIsPaying: WhoIsPaying, order: Order, updatePrices: boolean) => dispatch<any>(updateDebitorFromWhoIsPaying(whoIsPaying, order, updatePrices, true)),
        updateOrderDepartment: (department: Department, shouldUpdateDebitor: boolean) => dispatch<any>(updateOrderDepartment(department, shouldUpdateDebitor)),
        sendSms: (model: NotificationModel) => dispatch<any>(sendSms(model)),
        sendNotificationEmail: (model: NotificationModel) => dispatch<any>(sendNotificationEmail(model)),
        setDebitor: (debitor: Debitor, order: Order) => dispatch<any>(setDebitor(debitor, order)),
        updateBinList: (departmentGuid: string) => dispatch<any>(updateBinList(departmentGuid)),
        updateSearchList: (query: { searchIn: string, term: string }, merge: boolean) => dispatch<any>(updateSearchList(query, merge)),
        changeProductAmount: (productNo: string, amount: number) => dispatch(changeProductAmount(productNo, amount)),
        saveSelectedOrder: (order: Order, status: OrderStatus) => dispatch<any>(saveSelectedOrder(order, status)),
    });

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(Dashboard);