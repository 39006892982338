import { FreeCarSku } from "../typings";

export const orderToNotificationModel = (
    order: Order,
    type: NotificationType,
    comment?: string,
    workerName?: string
): NotificationModel => {
    const { customer, timeAndPlace, additionalData, items } = order;
    const { name, phone, email } = customer;
    const { department, date, from, to } = timeAndPlace;
    const { orderType, orderNr, orderStatus } = additionalData;

    let address = department
        ? department.address
            ? department.address
            : "."
        : ".";
    if (
        ((order.timeAndPlace.department &&
            order.timeAndPlace.department.mobileService) ||
            type === "ON_MY_WAY") &&
        order.timeAndPlace.mobileServiceLocation &&
        order.timeAndPlace.mobileServiceLocation.address
    ) {
        address = order.timeAndPlace.mobileServiceLocation.address;
    }

    const fullName = name || ".";

    return {
        workerName,
        fullName,
        phone,
        email,
        date: date || undefined,
        timeSlotFrom: from,
        timeSlotTo: to,
        address,
        orderNumber: orderNr,
        comment,
        type,
        orderType,
        orderStatus,
        hasFreeCar: items ? items.findIndex(x => x.itemNo === FreeCarSku.CAR_ONE) > -1 : false
    };
};

export const getRepairTypeFromOrder = (order: Order): NotificationType => {
    switch (order.additionalData.orderType) {
        case "REPLACE_FRONT":
        case "REPLACE_SIDE":
        case "REPLACE_REAR":
        case "REPLACE_ROOF":
            if ( order.timeAndPlace.mobileServiceLocation && order.timeAndPlace.mobileServiceLocation.address) {
                return "RECEIPT_WINDOWCHANGE_OPTIONAL_LOCATION";
            }
            return "RECEIPT_WINDOWCHANGE";
        case "REPAIR":
            if (
                order.timeAndPlace.mobileServiceLocation &&
                order.timeAndPlace.mobileServiceLocation.address
            ) {
                return "RECEIPT_CHIP_OPTIONAL_LOCATION";
            }
            return "RECEIPT_CHIP_DEPARTMENT";
        case "SALE":
            return "SALE";
        default:
            return "RECEIPT_CHIP_DEPARTMENT";
    }
};
