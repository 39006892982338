import * as React from "react";
import CustomDatePicker from "../base/CustomDatePicker";
import { DialogType, Dialog, Label, DialogFooter, PrimaryButton, DefaultButton } from "@fluentui/react";

interface ReportModalProps {
    hideDialog: boolean;
    onDismiss: () => void;
    title: string;
    subText: string;
    apiBase: string;
}

const ReportModal: React.FunctionComponent<ReportModalProps> = props => {
    const [fromDate, setFromDate] = React.useState<Date>(new Date());
    const [toDate, setToDate] = React.useState<Date>(new Date());

    const onFromDateChange = (fromDate: Date) => setFromDate(fromDate);

    const onToDateChange = (toDate: Date) => setToDate(toDate);

    const dialogContentProps = {
        type: DialogType.normal,
        title: props.title,
        subText: props.subText
    };

    return (
        <Dialog
            hidden={props.hideDialog}
            onDismiss={props.onDismiss}
            dialogContentProps={dialogContentProps}
        >
            <Label>Fra dato</Label>
            <CustomDatePicker
                short={true}
                date={fromDate}
                onDateChange={onFromDateChange}
            />
            <Label>Til dato</Label>
            <CustomDatePicker
                short={true}
                date={toDate}
                onDateChange={onToDateChange}
            />
            <DialogFooter>
                <PrimaryButton
                    onClick={props.onDismiss}
                    href={`${
                        props.apiBase
                    }?from=${fromDate.toJSON()}&to=${toDate.toJSON()}`}
                    text="Download"
                />
                <DefaultButton onClick={props.onDismiss} text="Anuller" />
            </DialogFooter>
        </Dialog>
    );
};

export default ReportModal;
