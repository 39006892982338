export const orderPaymentTypeText = [
    { key: "LEASING", text: "Leasing" },
    { key: "CASH", text: "Kontant betaling" },
    { key: "INSURANCE", text: "Forsikring" },
    { key: "DEBITOR", text: "Debitor" }
];

export const getOrderPaymentTypeText = (type: WhoIsPaying) => {
    const item = orderPaymentTypeText.find(x => x.key === type);
    return item ? item.text : "";
};
