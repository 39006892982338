import { date } from './validate';

export const convertUSDateToEU = (date: string) =>
{
    const d = new Date(date);
    let month = "0" + (d.getMonth() + 1);
    let day = "0" + (d.getDate());
    return `${day.slice(-2)}/${(month.slice(-2))}-${d.getFullYear()}`;
};

export const convertUSDateToEUWithTime = (date: string) =>
{
    const d = new Date(date);
    let month = "0" + (d.getMonth() + 1);
    let day = "0" + (d.getDate());
    let hour = "0" + d.getHours()  ;
    let minutes = "0" + d.getMinutes()
    return `${day.slice(-2)}/${(month.slice(-2))}-${d.getFullYear()} ${(hour.slice(-2))}:${(minutes.slice(-2))}`;
};

export const stringInputToDate = (dateString: string) =>
{
    // Validate that the date is formatted - dd/MM-YYYY
    if ((date(dateString)) !== undefined || date(dateString) !== null)
    {
        let dateArr = dateString.substring(0, 5).split("/");
        let year = dateString.substring(6, dateString.length);
        let date = new Date(`${dateArr[1]}/${dateArr[0]}/${year}`);
        return date;
    }
}

export const datesAreOnSameDay = (first: Date, second: Date) =>
first.getFullYear() === second.getFullYear() &&
first.getMonth() === second.getMonth() &&
first.getDate() === second.getDate();