import * as React from "react";
import PageHeader from "../layout/PageHeader";
import "./style.css";
import { TextField, PrimaryButton } from "@fluentui/react";

interface ForgotProps {
    resetPassword: (email: string) => void;
}

const Forgot: React.FunctionComponent<ForgotProps> = props => {
    const [resetcomplete, toggleReset] = React.useState(false);
    const [identifier, setIdentifier] = React.useState("");

    const onIdentifierChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        setIdentifier(value);
    };

    const onResetClick = () => {
        props.resetPassword(identifier);
        toggleReset(true);
    };

    return (
        <>
            <PageHeader currentPage="Glemt kodeord" noBreadcrumb={true} />
            <div>
                <br />
                <br />

                {!resetcomplete ? (
                    <>
                        <div className="text_field__container">
                            <TextField
                                required
                                width={"200px"}
                                placeholder="Email / Telefon nr"
                                onChange={onIdentifierChange}
                            />
                            <br />
                        </div>
                        <br />
                        <PrimaryButton onClick={onResetClick}>
                            Nulstil
                        </PrimaryButton>
                    </>
                ) : (
                    <p>
                        Hvis en bruger findes på denne email/telefon vil du
                        modtage
                        <br />
                        en besked med instruktioner om nulstilling af kodeord.
                        <br /> <br /> Modtager du ikke en besked kontakt da
                        venligst
                        <a href="mailto:support@danskbilglas.dk">
                            administratoren
                        </a>
                    </p>
                )}
            </div>
        </>
    );
};

export default Forgot;
