import { IconButton } from "@fluentui/react";
import * as React from "react";
import { useState, useEffect, createRef } from "react";

interface Props {
    src: string;
    standalone?: boolean;
    toggleModal?: () => void;
    deleteOpenImage?: () => void;
}

const wrapperStyle: React.CSSProperties = {
    overflow: "hidden",
    position: "fixed",
    top: "40px",
    width: "100%",
    height: "calc(100vh - 80px)",
    boxSizing: "border-box",
    zIndex: 999
};

const actionsStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "0px",
    left: 0,
    width: "100%",
    justifyContent: "center",
    display: "flex",
    height: "35px",
    zIndex: 999
};

const ImageViewer = (props: Props) => {
    const [orientation, changeOrientation] = useState(false);
    /* on mobile, to override pull to refresh and prevent zoom the entire page the body and viewport needs to have additional properties */
    const viewport: HTMLMetaElement = document.querySelector(
        "meta[name=viewport]"
    ) as HTMLMetaElement;
    const viewportActive =
        "width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no";
    const viewportInActive = viewport.content;
    const body: HTMLBodyElement = document.querySelector(
        "body"
    ) as HTMLBodyElement;
    const ref = createRef<HTMLImageElement>();
    const container = createRef<HTMLDivElement>();
    const [mainZoom, updateZoom] = useState(1.0);
    const [main, updateMain] = useState({ x: 0, y: 0 });
    const [initial, updateInitial] = useState({ x: 0, y: 0 });
    const [offset, updateOffset] = useState({ x: 0, y: 0 });
    const [isDown, toggleDown] = useState(false);
    const [centerX, updateCenter] = useState(0);
    const [scaling, toggleScaling] = useState(false);
    const [scaleValue, updateScale] = useState(0);
    const [lastScale, updateLastScale] = useState(0);

    viewport.content = viewportActive;
    body.classList.add("image-viewer");

    useEffect(() => {
        if (!props.standalone) {
            window.history.pushState(
                null,
                "viewer",
                window.location.href + "/viewer"
            );
            window.addEventListener("popstate", historyBack);
        }

        return () => {
            body.classList.remove("image-viewer");
            viewport.content = viewportInActive;
            window.removeEventListener("popstate", historyBack);
        };
    }, []);

    const historyBack = () => {
        props.toggleModal !== undefined ? props.toggleModal() : null;
    };

    const scaler = (e: React.WheelEvent) => {
        if (e.deltaY > 0) {
            if (mainZoom - 0.5 < 0.5) {
                updateZoom(0.5);
            } else {
                updateZoom(mainZoom => (mainZoom -= 0.5));
            }
        } else {
            updateZoom(mainZoom => (mainZoom += 0.5));
        }
    };
    const mouseDown = (e: React.MouseEvent) => {
        toggleDown(true);
        updateInitial({ x: e.clientX - offset.x, y: e.clientY - offset.y });
    };

    const touchDown = (e: React.TouchEvent) => {
        toggleDown(true);
        if (e.touches.length === 1) {
            updateInitial({
                x: e.touches[0].clientX - offset.x,
                y: e.touches[0].clientY - offset.y
            });
        }
    };
    const end = () => {
        updateInitial({ x: main.x, y: main.y });
        toggleDown(false);
        if (scaling) {
            updateLastScale(mainZoom);
            toggleScaling(false);
        }
    };

    const mouseUp = (e: React.MouseEvent) => end();
    const touchUp = (e: React.TouchEvent) => end();
    const mouseMove = (e: React.MouseEvent) => {
        if (isDown) {
            updateMain({ x: e.clientX - initial.x, y: e.clientY - initial.y });
            updateOffset({
                x: e.clientX - initial.x,
                y: e.clientY - initial.y
            });
        }
    };
    const touchMove = (e: React.TouchEvent) => {
        if (isDown && e.touches.length === 1) {
            updateMain({
                x: e.touches[0].clientX - initial.x,
                y: e.touches[0].clientY - initial.y
            });
            updateOffset({
                x: e.touches[0].clientX - initial.x,
                y: e.touches[0].clientY - initial.y
            });
        }
        if (isDown && e.touches.length > 1) {
            const dist = Math.hypot(
                e.touches[0].pageX - e.touches[1].pageX,
                e.touches[0].pageY - e.touches[1].pageY
            );
            const value = dist / 100;
            updateScale(value);

            const newZoom = value - lastScale;
            updateZoom(newZoom < 0.2 ? 0.2 : newZoom);

            toggleScaling(true);
            updateOffset({
                x: e.touches[0].clientX - initial.x,
                y: e.touches[0].clientY - initial.y
            });
        }
    };

    const init = () => {
        const image = ref.current;
        const wrapper = container.current;
        if (image !== null && wrapper !== null) {
            const ratio = image.clientWidth / image.clientHeight;
            if (ratio >= 1) {
                //portrait
                image.style.width = "auto";
                image.style.height = "100%";
            } else {
                image.style.width = "auto";
                image.style.height = "100%";
            }
            const newCenter = wrapper.clientWidth / 2 - image.clientWidth / 2;
            updateCenter(newCenter);
            updateOffset({ x: newCenter, y: 0 });
            updateInitial({ x: newCenter, y: 0 });
            updateMain({ x: newCenter, y: 0 });
            image.style.left = newCenter + "px";
            image.style.opacity = "1";
        }
    };
    const reset = () => {
        const image = ref.current;
        const wrapper = container.current;
        if (image !== null && wrapper !== null) {
            const reset = {
                x: wrapper.clientWidth / 2 - image.clientWidth / 2,
                y: 0
            };
            updateZoom(1);
            updateMain(reset);
            updateInitial(reset);
            updateOffset(reset);
        }
    };

    const zoomIn = () => {
        updateZoom(mainZoom => mainZoom + 0.5);
    };

    const zoomOut = () => {
        if (mainZoom > 0.5) {
            updateZoom(mainZoom => (mainZoom -= 0.5));
        }
    };
    const closeClick = () => {
        history.back();
    };
    return (
        <div
            className="image-container"
            style={{
                position: "fixed",
                width: "100%",
                height: "100vh",
                top: 0,
                left: 0,
                zIndex: 998,
                background: "rgba(0,0,0,1)"
            }}
        >
            <IconButton
                iconProps={{ iconName: "ChromeClose" }}
                style={{ marginLeft: "calc(100% - 40px)", color: "white" }}
                onClick={closeClick}
            />

            <div id="wrapper" ref={container} style={wrapperStyle}>
                <img
                    id="img"
                    ref={ref}
                    onLoad={init}
                    draggable={false}
                    src={props.src}
                    onWheel={scaler}
                    onMouseDown={mouseDown}
                    onMouseUp={mouseUp}
                    onMouseMove={mouseMove}
                    onTouchStart={touchDown}
                    onTouchEnd={touchUp}
                    onTouchMove={touchMove}
                    style={{
                        position: "absolute",
                        transformOrigin: "center",
                        transition: "transform opacity .08s linear",
                        left: main.x,
                        top: main.y,
                        transform:
                            "scale3d(" + mainZoom + "," + mainZoom + ",1)",
                        opacity: 0
                    }}
                />
            </div>
            <div className="actions" style={actionsStyle}>
                <IconButton
                    title="Zoom ind"
                    iconProps={{ iconName: "ZoomIn" }}
                    style={{ marginLeft: "auto", color: "white" }}
                    onClick={zoomIn}
                />
                <IconButton
                    title="Nulstil"
                    iconProps={{ iconName: "PictureFill" }}
                    style={{ color: "white" }}
                    onClick={reset}
                />
                <IconButton
                    title="Zoom ud"
                    iconProps={{ iconName: "ZoomOut" }}
                    style={{ marginRight: "auto", color: "white" }}
                    onClick={zoomOut}
                />
                {props.deleteOpenImage !== undefined ? (
                    <IconButton
                        title="Slet billede"
                        iconProps={{ iconName: "Delete" }}
                        style={{ color: "white" }}
                        onClick={props.deleteOpenImage}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default ImageViewer;
