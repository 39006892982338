import * as React from "react";
import { FreeCarSku, Types } from "../../../typings";
import MediaManager from "../../../dashboard/MediaManager";
import SendTextWindow from "../../../base/Notifications/SendTextWindow";
import Comment from "../../../fields/Comment";
import SendEmailWindow from "../../../base/Notifications/SendEmailWindow";
import ConfirmDialog from "../../../dialogs/confirm";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import FinishReplaceFrontOrderDialog from "../FinishReplaceFrontOrderDialog";
import SetOnHoldDialog from "../SetOnHoldDialog";
import SetOnHoldCommentDialog from "../SetOnHoldDialog/SetOnHoldCommentDialog";
import { getDropdownForOperators } from "../../../utils/operatorsHelper";
import { userHasAccess } from "../../../utils/UserHelper";
import { getDropdownForWindshieldManufacturer } from "../../../utils/manufacturerHelper";
import "./style.css";
import {
    IDropdownOption,
    PrimaryButton,
    Stack,
    Label,
    Dropdown,
    TextField
} from "@fluentui/react";
import Feedback from "../feedback";

interface Props {
    orderId?: string;
    status?: Types;
    externalOrderComment: string;
    internalOrderComment: string;
    guaranteeId: string;
    internalImages: string[];
    externalImages: string[];
    operators: UserInformation[];
    manufacturers: WindowManufacturer[];
    timeSpent: number;
    orderStatus: OrderStatus;
    workKm: number;
    order: Order;
    products: ProductGroup[];
    user: UserInformation;
    query: { searchIn?: string; term?: string };
    sendSms: (model: NotificationModel) => void;
    sendNotificationEmail: (model: NotificationModel) => void;
    onSaveOrderClick: (status?: OrderStatus) => void;
    uploadImagesAndUpdateOrder: (
        order: Order,
        files: FileList,
        type: ImageType
    ) => void;
    removeImages: (image: string[], imageType: ImageType) => void;
    updateAdditionalData: (
        additionalDataFieldName: string,
        newValue: any
    ) => void;
    updateSearchProductQuery: (searchIn?: string, term?: string) => void;
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => void;
    saveOrder: (order: Order) => void;
    addProducts: (products: Product[], debitorNo: string) => void;
    removeProduct: (itemNo: string) => void;
}

function OrderComplete(props: Props) {
    const [hideFinishOrderDialog, setHideFinishOrderDialog] = useState<boolean>(
        true
    );
    const [
        hideFinishReplaceFrontOrderDialog,
        setHideFinishReplaceFrontOrderDialog
    ] = useState<boolean>(true);
    const [hideAreYouDeleteDialog, setHideAreYouDeleteDialog] = useState<
        boolean
    >(true);
    const [hideSetOnHoldDialog, setHideSetOnHoldDialog] = useState<boolean>(
        true
    );
    const [
        hideSetOnHoldCommentdDialog,
        setHideSetOnHoldCommentDialog
    ] = useState<boolean>(true);
    const [highlightWorkerField, setHighlightWorkerField] = useState<boolean>(
        false
    );
    const [highlightWindowField, setHighlightWindowField] = useState<boolean>(
        false
    );

    useEffect(() => {
        const { timeSlotId, department } = props.order.timeAndPlace;

        if (
            department &&
            department.windowChangeTimeOffset &&
            department.specificWindowReplaceTimeSlots
        ) {
            const selectedTimeSlot = department
                ? department.windowReplaceTimeSlots.find(
                      x => x.id === timeSlotId
                  ) ||
                  department.specificWindowReplaceTimeSlots.find(
                      x => x.id === timeSlotId
                  )
                : undefined;

            const isOrderItemAdded =
                props.order.items.findIndex(
                    x => x.itemNo === FreeCarSku.CAR_ONE
                ) > -1;

            if (!isOrderItemAdded) {
                if (selectedTimeSlot && selectedTimeSlot.hasFreeCar) {
                    props.addProducts(
                        [
                            {
                                name: "INKL. GRATIS LÅNEBIL",
                                description: "",
                                isService: true,
                                twoPersonJob: false,
                                previouslyPurchased: false,
                                amount: 1,
                                type: 0,
                                no: FreeCarSku.CAR_ONE,
                                price: 0,
                                status: []
                            }
                        ],
                        props.order.debitor.no
                    );
                }
            } else {
                if (
                    (selectedTimeSlot && selectedTimeSlot.hasFreeCar == null) ||
                    false
                ) {
                    props.removeProduct(FreeCarSku.CAR_ONE);
                }
            }
        }
    }, [props.order.timeAndPlace]);

    useEffect(() => {
        if (!props.order.additionalData.windowManufacturer)
            setHighlightWindowField(true);
        else if (props.order.additionalData.windowManufacturer === "NONE")
            setHighlightWindowField(true);
        else setHighlightWindowField(false);
    }, [props.order.additionalData.windowManufacturer]);

    useEffect(() => {
        props.order.additionalData.worker
            ? setHighlightWorkerField(false)
            : setHighlightWorkerField(true);
    }, [props.order.additionalData.worker]);

    const onUploadImagesClick = (
        e: React.SyntheticEvent<HTMLInputElement>,
        type: ImageType
    ) => {
        const { files } = e.currentTarget;
        if (files !== null) {
            props.uploadImagesAndUpdateOrder(props.order, files, type);
        }
    };

    const updateAdditionalData = (additionalDataFieldName: string) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => props.updateAdditionalData(additionalDataFieldName, value);

    const setComment = (additionalDataFieldName: string) => (value: string) =>
        props.updateAdditionalData(additionalDataFieldName, value);

    const updateAdditionalDataNumber = (additionalDataFieldName: string) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        const numericValue = parseFloat(value);
        props.updateAdditionalData(additionalDataFieldName, numericValue);
    };

    const setTimeUsed = (
        e: React.SyntheticEvent<HTMLInputElement>,
        option: IDropdownOption
    ) => props.updateAdditionalData("timeSpent", option.key as number);

    const onSetOrderStatus = (status?: OrderStatus) => () => {
        if (props.order.timeAndPlace.from && props.order.timeAndPlace.to) {
            props.onSaveOrderClick(status);
        } else {
            alert("Du mangler at vælge en tid!");
        }
    };

    const finishOrder = useCallback(() => {
        let status: OrderStatus = "FINISHED";

        if (props.order.additionalData.orderType.indexOf("COMPLAINT") > -1) {
            if (
                !props.order.complaint ||
                !props.order.complaint.issues ||
                (props.order.complaint.issues.length === 0 &&
                    !props.order.complaint.otherIssue)
            ) {
                alert("Du skal vælge minimum 1 Reklamation årsag !");
                return;
            }
            if (
                props.order.additionalData.orderType !== "INTERNAL_COMPLAINT" &&
                !props.order.additionalData.internalOrderComment
            ) {
                alert("Du skal udfylde Intern Kommentar !");
                return;
            }
            if (
                props.order.additionalData.orderType === "COMPLAINT" &&
                props.order.additionalData.orderStatus !== "SERVICE_CALL"
            ) {
                status = "SERVICE_CALL";
            }
        }
        if (!props.order.additionalData.worker) {
            alert("Du mangler at vælge en montør !");
            return;
        }

        if (!props.order.additionalData.windowManufacturer) {
            if (
                props.order.complaint &&
                props.order.complaint.complaintType !== "REPAIR_SLOT" &&
                props.order.complaint.complaintType === "WITHOUT_SLOT" &&
                props.order.complaint.referenceJobType &&
                props.order.complaint.referenceJobType.startsWith("REPLACE")
            )
                alert("Du mangler at vælge en rudefabrikat !");
            return;
        }

        if (
            props.order.additionalData.windowManufacturer === "NONE" &&
            (props.order.additionalData.orderType.startsWith("REPLACE") ||
                (props.order.additionalData.orderType === "COMPLAINT" &&
                    props.order.complaint &&
                    props.order.complaint.complaintType === "WITHOUT_SLOT" &&
                    props.order.complaint.referenceJobType &&
                    props.order.complaint.referenceJobType.startsWith(
                        "REPLACE"
                    )) ||
                (props.order.additionalData.orderType === "COMPLAINT" &&
                    props.order.complaint &&
                    props.order.complaint.complaintType &&
                    props.order.complaint.complaintType.startsWith("REPLACE")))
        ) {
            alert("Du mangler at vælge en rudefabrikat !");
            return;
        }

        if (
            props.order.complaint &&
            props.order.complaint.workerFault === undefined &&
            props.orderStatus === "SERVICE_CALL"
        ) {
            alert(
                "Du mangler at vælge, om årsagen til klagen er en montørfejl ! Hvis du ikke kan se muligheden, skal du kontakte din administrator."
            );
            return;
        }

        props.onSaveOrderClick(status);
        setHideFinishOrderDialog(true);
        setHideFinishReplaceFrontOrderDialog(true);
    }, [props.order]);

    const toggleFinishOrderDialog = useCallback(() => {
        setHideFinishOrderDialog(!hideFinishOrderDialog);
    }, [hideFinishOrderDialog]);

    const toggleFinishReplaceFrontOrderDialog = useCallback(() => {
        setHideFinishReplaceFrontOrderDialog(
            !hideFinishReplaceFrontOrderDialog
        );
    }, [hideFinishReplaceFrontOrderDialog]);

    const toggleHideAreYouDeleteDialog = useCallback(() => {
        setHideAreYouDeleteDialog(!hideAreYouDeleteDialog);
    }, [hideAreYouDeleteDialog]);

    const toggleSetOnHoldDialog = useCallback(() => {
        if (
            !props.order.additionalData.worker &&
            !props.order.additionalData.windowManufacturer
        ) {
            alert("Du skal vælge en værdi for de markerede felter !");
            return;
        } else if (
            props.order.additionalData.worker &&
            props.order.additionalData.windowManufacturer
        ) {
            setHideSetOnHoldDialog(!hideSetOnHoldDialog);
        } else {
            props.order.additionalData.worker
                ? alert("Du mangler at vælge en rudefabrikat !")
                : alert("Du mangler at vælge en montør !");
            return;
        }
    }, [
        hideSetOnHoldDialog,
        props.order.additionalData.worker,
        props.order.additionalData.windowManufacturer
    ]);

    const onConfirmSetOnHold = useCallback(
        (onHoldComment: string) => {
            const order: Order = {
                ...props.order,
                additionalData: {
                    ...props.order.additionalData,
                    orderStatus: "ON_HOLD",
                    onHoldComment: onHoldComment
                }
            };
            props.saveOrder(order);
            setHideSetOnHoldDialog(true);
        },
        [props.order]
    );

    const toggleSetOnHoldCommentDialog = useCallback(() => {
        setHideSetOnHoldCommentDialog(!hideSetOnHoldCommentdDialog);
    }, [hideSetOnHoldCommentdDialog]);

    const onConfirmOnHoldComment = useCallback(() => {
        props.onSaveOrderClick();
        setHideSetOnHoldCommentDialog(true);
    }, [props]);

    const onConfirmDelete = useCallback(() => {
        props.onSaveOrderClick("CANCELLED");
        setHideAreYouDeleteDialog(true);
    }, [props.order]);

    const onMoveHoldToProcess = useCallback(() => {
        props.onSaveOrderClick("PROCESSING");
       
    }, [props.order]);

    const setWorker = (
        e: React.SyntheticEvent<HTMLInputElement>,
        option: IDropdownOption
    ) => props.updateAdditionalData("worker", option.key);

    const setHelper = (
        e: React.SyntheticEvent<HTMLInputElement>,
        option: IDropdownOption
    ) => props.updateAdditionalData("helper", option.key);

    const setWindowManufacturer = (
        e: React.SyntheticEvent<HTMLInputElement>,
        option: IDropdownOption
    ) => props.updateAdditionalData("windowManufacturer", option.key);

    const timeSpentInterval = 15;
    const array = Array.from({ length: 12 }, (v, k) => k + 1);
    const timeSpentOptions: IDropdownOption[] =
        //maks available = 180
        array.map((value, index) => ({
            key: (index + 1) * timeSpentInterval,
            text: `${(index + 1) * timeSpentInterval} min.`,
            isSelected: props.timeSpent === (index + 1) * timeSpentInterval
        }));
    timeSpentOptions.push({
        key: 999,
        text: "180 min.+",
        isSelected: props.timeSpent === 999
    });

    const isReplaceFront =
        props.order.additionalData.orderType === "REPLACE_FRONT";

    let buttons = null;

    const saveBtn = (
        <>
            <PrimaryButton text="Gem" onClick={onSetOrderStatus()} />
        </>
    );

    if (props.orderStatus !== "FINISHED" && props.orderStatus !== "CANCELLED") {
        let finishBtn = null;
        const isNotServiceCall =
            props.order.additionalData.orderStatus !== "SERVICE_CALL";
        if (props.order.additionalData.orderStatus === "WEB_REVIEW") {
            finishBtn = (
                <PrimaryButton
                    style={{
                        background: "#31752f",
                        border: "1px solid #31752f"
                    }}
                    text="Send til Ordrer"
                    onClick={onSetOrderStatus("PROCESSING")}
                />
            );
        } else if (isNotServiceCall) {
            finishBtn = (
                <PrimaryButton
                    text={"Afslut/Fakturer"}
                    onClick={
                        isReplaceFront
                            ? toggleFinishReplaceFrontOrderDialog
                            : toggleFinishOrderDialog
                    }
                />
            );
        } else if (userHasAccess(props.user.roles, ["Complaint"])) {
            finishBtn = <PrimaryButton text={"Lukket"} onClick={finishOrder} />;
        }

        buttons = (
            <>
                {isNotServiceCall && (
                    <>
                        <div className="styledButtons">
                            {props.order.additionalData.orderStatus !==
                            "ON_HOLD" ? (
                                <PrimaryButton
                                    className="btn-set-on-hold"
                                    onClick={toggleSetOnHoldDialog}
                                    text="Sæt på hold"
                                />
                            ) : (
                                <>
                                    <PrimaryButton
                                        className="btn-set-on-hold"
                                        onClick={toggleSetOnHoldCommentDialog}
                                        text="Kommenter"
                                    />
                                    <PrimaryButton
                                        className="btn-move-to-processing"
                                        onClick={onMoveHoldToProcess}
                                        text="Tilbage til afventer"
                                    />
                                </>
                            )}
                        </div>
                        <div className="cancel_button cancelOrderBtn">
                            <PrimaryButton
                                onClick={toggleHideAreYouDeleteDialog}
                                text="Slet opgave"
                            />
                        </div>
                    </>
                )}
                {saveBtn}
                {/* <PrimaryButton text="SMS - Opgave udført" onClick={props.handleSendClick}/> */}
                {isNotServiceCall && (
                    <>
                        {props.order.customer.allowSms ? (
                            <SendTextWindow
                                order={props.order}
                                sendSms={props.sendSms}
                                notificationType="REPAIR_COMPLETE"
                                buttonText="SEND 'Reparation er færdig' SMS"
                            />
                        ) : (
                            <PrimaryButton className="disabled cancel_button" text="SMSi kke tilladt" />
                            
                        )}
                        {props.order.customer.allowEmail ? (
                            <SendEmailWindow
                                order={props.order}
                                sendNotificationEmail={
                                    props.sendNotificationEmail
                                }
                                notificationType="REPAIR_COMPLETE"
                                buttonText="SEND 'Reparation er færdig' EMAIL"
                            />
                        ) : (
                            <PrimaryButton className="disabled cancel_button" text="EMAIL ikke tilladt" />
                        )}
                    </>
                )}
                {finishBtn}
            </>
        );
    } else if (props.orderStatus === "FINISHED") {
        buttons = saveBtn;
    }

    if (props.order) {
        return (
            <div className="work__complete">
                <ConfirmDialog
                    hideDialog={hideAreYouDeleteDialog}
                    onDismiss={toggleHideAreYouDeleteDialog}
                    onConfirm={onConfirmDelete}
                    title="Slet ordre"
                    subText="Er du sikker på du vil slette ordren?"
                    confirmText="Ja"
                    cancelText="Nej"
                />
                <ConfirmDialog
                    hideDialog={hideFinishOrderDialog}
                    onDismiss={toggleFinishOrderDialog}
                    onConfirm={finishOrder}
                    title="Afslut ordre"
                    subText="Har du husket at tjekke dine varelinjer for lister, clips, småting mv.?"
                    confirmText="Ja, Afslut ordre"
                    cancelText="Nej, Gå tilbage"
                    switchButtons={true}
                />
                <FinishReplaceFrontOrderDialog
                    products={props.products}
                    order={props.order}
                    hideDialog={hideFinishReplaceFrontOrderDialog}
                    onDismiss={toggleFinishReplaceFrontOrderDialog}
                    updateSearchProductQuery={props.updateSearchProductQuery}
                    updateSearchList={props.updateSearchList}
                    query={props.query}
                    onFinishOrder={finishOrder}
                />
                <SetOnHoldDialog
                    hideDialog={hideSetOnHoldDialog}
                    onDismiss={toggleSetOnHoldDialog}
                    onConfirm={onConfirmSetOnHold}
                    title="Sæt på hold"
                    subText=""
                    confirmText="Ja, Sæt på hold"
                    cancelText="Nej, Gå tilbage"
                    switchButtons={true}
                />
                <SetOnHoldCommentDialog
                    hideDialog={hideSetOnHoldCommentdDialog}
                    onDismiss={toggleSetOnHoldCommentDialog}
                    onConfirm={onConfirmOnHoldComment}
                    updateAdditionalData={props.updateAdditionalData}
                    title="Kommenter"
                    subText=""
                    confirmText="Gem"
                    cancelText="Annuller"
                    switchButtons={false}
                />
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack.Item grow={1}>
                        <Label>Montør:</Label>
                        <Dropdown
                            calloutProps={{
                                calloutMaxHeight: 250,
                                calloutMinWidth: 100
                            }}
                            className={
                                highlightWorkerField ? "highlighted" : ""
                            }
                            options={getDropdownForOperators(props.operators)}
                            onChange={setWorker}
                            defaultSelectedKey={
                                props.order.additionalData.worker
                            }
                        />

                        {props.order.additionalData.twoManJob && (
                            <>
                                <Label>Hjælper:</Label>
                                <Dropdown
                                    calloutProps={{
                                        calloutMaxHeight: 250,
                                        calloutMinWidth: 100
                                    }}
                                    options={getDropdownForOperators(
                                        props.operators
                                    )}
                                    onChange={setHelper}
                                    defaultSelectedKey={
                                        props.order.additionalData.helper
                                    }
                                />
                            </>
                        )}

                        <Label>Km:</Label>
                        <TextField
                            type="number"
                            value={props.workKm.toString()}
                            onChange={updateAdditionalDataNumber("workKM")}
                        />

                        {(props.order.additionalData.orderType.startsWith(
                            "REPLACE"
                        ) ||
                            (props.order.complaint &&
                                props.order.complaint.complaintType ===
                                    "REPLACE_SLOT") ||
                            (props.order.additionalData.orderType.startsWith(
                                "COMPLAINT"
                            ) &&
                                props.order.complaint &&
                                props.order.complaint.complaintType ===
                                    "WITHOUT_SLOT" &&
                                props.order.complaint.referenceJobType &&
                                props.order.complaint.referenceJobType.startsWith(
                                    "REPLACE"
                                ))) && (
                            <>
                                <Label>Rudefabrikat:</Label>
                                <Dropdown
                                    calloutProps={{
                                        calloutMaxHeight: 250,
                                        calloutMinWidth: 100
                                    }}
                                    className={
                                        highlightWindowField
                                            ? "highlighted"
                                            : ""
                                    }
                                    options={getDropdownForWindshieldManufacturer(
                                        props.manufacturers
                                    )}
                                    onChange={setWindowManufacturer}
                                    defaultSelectedKey={
                                        props.order.additionalData
                                            .windowManufacturer
                                    }
                                />
                            </>
                        )}
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item grow={1}>
                                <Label>Tidsforbrug</Label>
                                <Dropdown
                                    calloutProps={{
                                        calloutMaxHeight: 250,
                                        calloutMinWidth: 100
                                    }}
                                    defaultSelectedKey={
                                        props.timeSpent ? props.timeSpent : ""
                                    }
                                    placeholder={"Vælg tidsforbrug"}
                                    onChange={setTimeUsed}
                                    options={timeSpentOptions}
                                />
                            </Stack.Item>
                        </Stack>
                        <Comment
                            onChange={setComment("externalOrderComment")}
                            predefindedText={[]}
                            title="Extern kommentar:"
                            value={props.externalOrderComment}
                        />
                        <br />
                        <Comment
                            onChange={setComment("internalOrderComment")}
                            predefindedText={[]}
                            title="Intern Kommentar:"
                            value={props.internalOrderComment}
                        />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <Label>Garanti nr:</Label>
                        <TextField
                            value={props.guaranteeId}
                            onChange={updateAdditionalData("guaranteeId")}
                        />
                        <br />
                        <Label></Label>

                       
                        <MediaManager
                            upload={onUploadImagesClick}
                            removeImages={props.removeImages}
                            internal={props.internalImages}
                            external={props.externalImages}
                            save={onSetOrderStatus()}
                        />
                    </Stack.Item>
                </Stack>
                <br />
                <br />
                <Stack
                    className="finish-order-buttons"
                    horizontal
                    horizontalAlign="end"
                    tokens={{ childrenGap: 20 }}
                >
                    <Feedback orderId={props.orderId} worker={props.order.additionalData.worker} />
                    {buttons}
                </Stack>
            </div>
        );
    }
    return null;
}

export default OrderComplete;
