import BaseApi from "./baseApi";

interface IAddressApi {
    addressLookup: (
        address: string,
        onlyZip: boolean
    ) => Promise<{ id: string; description: string }[]>;
    getCityByZip(zip: string): Promise<string>;
}

export default class AddressApi extends BaseApi implements IAddressApi {
    async addressLookup(
        address: string,
        onlyZip: boolean = false
    ): Promise<{ id: string; description: string }[]> {
        const url = `/api/departments/address?input=${encodeURIComponent(
            address
        )}&onlyZip=${onlyZip}`;
        return await this.fetchGet(url);
    }

    async getCityByZip(zip: string): Promise<string> {
        const url = `/api/zipcode/city/${zip}`;
        return await this.fetchGet(url);
    }
}
