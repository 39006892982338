import * as React from "react";
import { DetailType } from "../../../typings";
import "../../style.css";

interface Props {
    order: Order;
    owner: string;
}

interface Details {
    title: string;
    value: string | undefined;
    id: number;
    active: boolean;
    type: DetailType;
    changed: boolean;
    skipLengthCheck?: boolean;
}

const CarDetails: React.FunctionComponent<Props> = props => {
    const {
        registrationNumber,
        make,
        model,
        modelYear,
        insurance,
        leasing,
        equipmentLine,
        segment,
        owner,
        user,
        vehicleIdentificationNumber,
        doorQuantity,
        colour,
        bodywork,
        firstRegistrationDate
    } = props.order.car;
    //just for test
    const detailsList: Details[] = [
        {
            title: "Reg. nr.",
            value: registrationNumber,
            active: false,
            id: 0,
            type: DetailType.Text,
            changed: false
        },
        {
            title: "Mærke",
            value: make,
            active: false,
            id: 1,
            type: DetailType.Text,
            changed: false
        },
        {
            title: "Model",
            value: model,
            active: false,
            id: 2,
            type: DetailType.Text,
            changed: false
        },
        {
            title: "Model år",
            value: modelYear + "",
            active: false,
            id: 3,
            type: DetailType.Number,
            changed: false
        },
        {
            title: "Forsikringsselskab",
            value: insurance,
            active: false,
            id: 4,
            type: DetailType.Text,
            changed: false
        },
        {
            title: "Leasing",
            value: leasing,
            active: false,
            id: 5,
            type: DetailType.Text,
            changed: false
        },
        {
            title: "Ejer",
            value: owner,
            active: false,
            id: 6,
            type: DetailType.Text,
            changed: false
        },
        {
            title: "Bruger",
            value: user,
            active: false,
            id: 7,
            type: DetailType.Text,
            changed: false
        },
        {
            title: "Udstyrslinie",
            value: equipmentLine,
            active: false,
            id: 8,
            type: DetailType.Text,
            changed: false
        },
        {
            title: "Vin nr.",
            value: vehicleIdentificationNumber,
            active: false,
            id: 9,
            type: DetailType.Text,
            changed: false,
            skipLengthCheck: true
        },
        {
            title: "Antal døre",
            value: doorQuantity + "",
            active: false,
            id: 10,
            type: DetailType.Number,
            changed: false
        },
        {
            title: "Farve",
            value: colour,
            active: false,
            id: 11,
            type: DetailType.Text,
            changed: false
        },
        {
            title: "Karrosseri",
            value: bodywork,
            active: false,
            id: 12,
            type: DetailType.Text,
            changed: false
        },
        {
            title: "1. reg dato",
            value: firstRegistrationDate,
            active: false,
            id: 13,
            type: DetailType.Date,
            changed: false
        },
        {
            title: "Segment",
            value: segment,
            active: false,
            id: 14,
            type: DetailType.Text,
            changed: false
        }
    ];

    const getLine = (data: Details) => {
        return (
            <div>
                <label
                    dangerouslySetInnerHTML={{
                        __html:
                            data.value !== undefined
                                ? checkLength(data.title)
                                : ""
                    }}
                />
                <span
                    dangerouslySetInnerHTML={{
                        __html:
                            data.value !== undefined
                                ? data.skipLengthCheck ? data.value : checkLength(data.value)
                                : ""
                    }}
                />
            </div>
        );
    };

    const checkLength = (value: string) => {
        const val =
            value !== undefined && value !== null && value.length < 16
                ? value
                : value !== undefined && value !== null
                ? value.substring(0, 15) +
                  "&shy;" +
                  value.substring(15, value.length)
                : null;
        if (val !== null) {
            return val;
        }
        return "";
    };

    return (
        <div className="car_details__container">
            <div>
                {getLine(detailsList[0])}
                {getLine(detailsList[1])}
                {getLine(detailsList[2])}
                {getLine(detailsList[3])}
                {getLine(detailsList[4])}
                {getLine(detailsList[5])}
                {getLine(detailsList[6])}
                {getLine(detailsList[7])}
            </div>
            <div>
                {getLine(detailsList[8])}
                {getLine(detailsList[9])}
                {getLine(detailsList[10])}
                {getLine(detailsList[11])}
                {getLine(detailsList[12])}
                {getLine(detailsList[13])}
                {getLine(detailsList[14])}
            </div>
        </div>
    );
};

export default CarDetails;
