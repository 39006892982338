import * as React from "react";
import SearchPane from "../base/SearchPane";

interface Props {
    getDebitor: (query: string, type: ApiType) => void;
    debitors?: Debitor[];
    selectedDebitor: Debitor;
    onDebitorChange: (debitor: Debitor) => void;
    title?: string;
}

const DebitorLookupByName: React.FunctionComponent<Props> = props => {
    const [debitorName, setDebitorName] = React.useState<string>("");

    React.useEffect(() => {
        setDebitorName(props.selectedDebitor.debitorName);
    }, [props.selectedDebitor]);

    const search = (query: string) => {
        props.getDebitor(query, "NAME");
    };

    const onValueSelected = (item: string, index: number) => {
        if (props.debitors) {
            props.onDebitorChange(props.debitors[index]);
        }
    };

    let searchField: string[] = [];
    if (props.debitors) {
        searchField = props.debitors.map(
            debitor => `${debitor.debitorName} ${debitor.cvr} ${debitor.phone}`
        );
    }
    return (
        <>
            <SearchPane
                title={props.title}
                updateValueOnChange={true}
                placeholder="CVR-Debitornavn"
                value={debitorName}
                onValueChange={search}
                onValueSelected={onValueSelected}
                items={searchField}
            />
        </>
    );
};

export default DebitorLookupByName;
