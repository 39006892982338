import * as React from "react";
import {
    logReasonOptionsMoved, logReasonOptionsCancelled, logSaveReasonChangeOL, logReasonWindowChangeMoved, logReasonStoneChipMoved
} from "../utils/logHelper";
import { DialogType, Dialog, Dropdown, TextField, DialogFooter, PrimaryButton, DefaultButton, IDropdownOption } from "@fluentui/react";

interface LogModalProps {
    hideDialog: boolean;
    onDismiss: () => void;
    onConfirm: (logReason: string, logComment: string) => void;
    logType: LogType;
}

const dialogContentProps = {
    type: DialogType.normal,
    title: "Kræver logning",
    subText: "Log grunden til denne ændring"
};

const LogModal: React.FunctionComponent<LogModalProps> = props => {
    const [logReason, setLogReason] = React.useState<string | undefined>(
        undefined
    );
    const [logComment, setLogComment] = React.useState<string>("");

    const onReasonChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ) => {
        if (option && option.key !== "") {
            setLogReason(option.text as LogReason);
        }
    };

    const onCommentChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => setLogComment(value);

    const onConfirm = () => {
        if (logReason) {
            if (logReason === "Andet" && logComment === "") {
                alert("Når du vælger andet skal du tilknytte en kommentar");
            } else {
                props.onConfirm(logReason, logComment);
            }
        } else {
            alert("Du skal vælge en årsag");
        }
    };

    const logReasons = () => {
        switch (props.logType) {
            case "CANCELLED":
                return logReasonOptionsCancelled;
            case "MOVED":
                return logReasonOptionsMoved;
            case "MAINITEMCHANGED":
                return logSaveReasonChangeOL;
            case "WINDOWREPLACEMOVED":
                return logReasonWindowChangeMoved;
            case "STONECHIPMOVED":
                return logReasonStoneChipMoved;
            default:
                return [];
        }
    }

    return (
        <Dialog
            hidden={props.hideDialog}
            onDismiss={props.onDismiss}
            dialogContentProps={dialogContentProps}
        >
            <Dropdown
                calloutProps={{ calloutMaxHeight: 250, calloutMinWidth: 100 }}
                label="Årsag til ændring"
                placeholder="Vælg en årsag til ændring"
                options={
                    logReasons()
                }
                required={true}
                onChange={onReasonChange}
            />
            <TextField
                required={logReason === "Andet"}
                label="Kommentar"
                multiline
                rows={3}
                value={logComment}
                onChange={onCommentChange}
            />
            <DialogFooter>
                <PrimaryButton onClick={onConfirm} text="Log og udfør" />
                <DefaultButton onClick={props.onDismiss} text="Anuller" />
            </DialogFooter>
        </Dialog>
    );
};

export default LogModal;
