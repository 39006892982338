import * as React from "react";
import { useState, useEffect } from "react";
import SearchPane from "../SearchPane";
import api from "../../../api";
import SearchButton from "./SearchButton";
import "./style.css";
import { IDropdownOption, Stack, Dropdown } from "@fluentui/react";

interface Props {
    onSearch: (searchIn: string, term: string) => void;
    query: { searchIn?: string; term?: string };
}

const Search: React.FunctionComponent<Props> = props => {
    const [firstRun, setFirstRun] = useState(true);
    const [searchIn, setSearchIn] = useState(
        props.query.searchIn ? props.query.searchIn : "No"
    );
    const [searchTerm, setSearchTerm] = useState(props.query.term || "");
    const [autoComplete, setAutoComplete] = useState([] as string[]);

    useEffect(() => {
        if (!firstRun) {
            if (searchTerm.length >= 4) {
                api.ProductAPI.autoComplete(searchIn, searchTerm).then(
                    setAutoComplete
                );
            } else {
                setAutoComplete([]);
            }
        }
        setFirstRun(false);
    }, [searchIn, searchTerm]);

    const onSearchInChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ) => {
        if (option) {
            setSearchIn(option.key as string);
        }
    };

    let timer = 0;
    const onSearchFieldValueChange = (searchTerm: string) => {
        clearTimeout(timer);
        timer = window.setTimeout(async () => {
            setSearchTerm(searchTerm);
        }, 500);
    };

    const onSearchValueSelected = (value: string, index: number) => {
        setSearchTerm(value);
    };

    const search = () => {
        props.onSearch(searchIn, searchTerm);
    };

    const dropdownOptions: IDropdownOption[] = [
        { key: "No", text: "Produkt nummer" },
        { key: "Description", text: "Beskrivelse" },
        { key: "OES", text: "OES nummer" },
        { key: "EuroCode", text: "EuroCode nummer" },
        { key: "SameAs", text: "SameAs nummer" },
        { key: "AGC", text: "AGC nummer" },
        { key: "OEM", text: "OEM nummer" },
        { key: "AUTOVERSAPCode", text: "AUTOVERSAPCode nummer" },
        { key: "Nags", text: "Nags nummer" },
        { key: "AW", text: "AW nummer" }
    ];
    const selectedOption = dropdownOptions.find(x => x.key === searchIn);

    return (
        <div className="search__container">
            <Stack horizontal tokens={{ padding: 20, childrenGap: 10 }}>
                <Stack.Item>
                    <Dropdown
                        calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                        options={dropdownOptions}
                        selectedKey={searchIn}
                        onChange={onSearchInChange}
                        dropdownWidth={300}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item grow>
                            <SearchPane
                                placeholder={
                                    selectedOption ? selectedOption.text : ""
                                }
                                title={
                                    selectedOption ? selectedOption.text : ""
                                }
                                value={searchTerm}
                                items={autoComplete}
                                onValueChange={onSearchFieldValueChange}
                                onValueSelected={onSearchValueSelected}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <SearchButton onClick={search} />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>
    );
};

export default Search;
