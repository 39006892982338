
import * as React from "react";
import './style.css'
import { TextField, PrimaryButton } from "@fluentui/react";

interface Props
{
    title: string;
    value: string;
    onChange: (value: string) => void; 
    predefindedText: { name: string, text: string }[];
}

function Comment(props: Props)
{
    const onChange = (e: React.SyntheticEvent<HTMLInputElement>, value: string) =>  props.onChange(value);

    const useDefaultText = (text: string) => () => props.onChange(text);
    return (
        <>
            <h5>{props.title}</h5>
            <TextField value={props.value} multiline={true} onChange={onChange} />
            {props.predefindedText.map((btn, index) => <PrimaryButton className="comment_button" key={index} text={btn.name} onClick={useDefaultText(btn.text)}/>)}
        </>
    );
}

export default Comment;