import { IDropdownOption } from "@fluentui/react";

export const getRegionDropdownList = (departments: Department[], selectedValue?: string):IDropdownOption[] =>
{
    let uniqValues = departments
        .map(x => x.region)
        .filter((value, index, result) => value && result.indexOf(value) === index) //get uniq
        .map( (x) => ({ key: x, text: x, selected: x === selectedValue } as IDropdownOption) );
    if(selectedValue)
    {
        if(!uniqValues.find(x=>x.text === selectedValue))
        {
            uniqValues.push({ key: selectedValue, text: selectedValue, selected: true})
        }
    }
    return uniqValues;
};