import {
    Button,
    DatePicker,
    DayOfWeek,
    DefaultButton,
    Dropdown,
    Icon,
    Label,
    PrimaryButton,
    Stack,
    StackItem,
    TextField
} from "@fluentui/react";
import * as React from "react";
import PageHeader from "../layout/PageHeader";
import convertFloatToTime from "./helpers/convertFloatToTime";
import convertStringDateToDate from "./helpers/convertStringDateToDate";
import mapPropertyValue from "./helpers/mapPropertyValue";
import openingHoursDayDropdown from "./helpers/openingHoursDayDropdown";
import "./style.css";

interface DepartmentOpeningHoursProps {
    timeSlots: DepartmentTimeSlot[];
    updateTimeSlots(
        timeSlots: DepartmentTimeSlot[],
        propertyName: string
    ): void;
    propertyName: string;
    enableDate: boolean;
    pageTitle: string;
}

const DepartmentOpeningHours: React.FC<DepartmentOpeningHoursProps> = (
    props
): JSX.Element => {
    const [createForm, setCreateForm] = React.useState<DepartmentTimeSlot>({
        date: undefined,
        day: 0,
        from: 0,
        to: 0
    });
    const [showCreateForm, setShowCreateForm] = React.useState<boolean>(false);

    const onTimeSlotChanged = React.useCallback(
        (value: any, property: string, index: number) => {
            const { timeSlots, propertyName } = props;
            let row = timeSlots[index];
            row = mapPropertyValue<DepartmentTimeSlot>(row, value, property);

            const result: DepartmentTimeSlot[] = [...timeSlots];
            result[index] = row;
            props.updateTimeSlots(result, propertyName);
        },
        [props.timeSlots]
    );

    const onDeleteTimeSlot = React.useCallback(
        (givenIndex: number) => {
            if (
                confirm(
                    "Du er ved at slette en standard åbningstid.\nØnsker du at udføre handlingen?"
                )
            ) {
                const { timeSlots, propertyName } = props;
                const result = timeSlots.filter(
                    (value, index: number) => index !== givenIndex
                );
                props.updateTimeSlots(result, propertyName);
            }
        },
        [props.timeSlots]
    );

    const onCreateFormChanged = React.useCallback(
        (value: any, property: string) => {
            const form = mapPropertyValue<DepartmentTimeSlot>(
                { ...createForm },
                value,
                property
            );
            setCreateForm(form);
        },
        [createForm]
    );

    const onAddNewTimeSlot = React.useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();
            const { timeSlots, propertyName } = props;

            const result: DepartmentTimeSlot[] = timeSlots
                ? [...timeSlots]
                : [];

            if (createForm) {
                result.push(createForm);
                props.updateTimeSlots(result, propertyName);
                setCreateForm({ date: undefined, day: 0, from: 0, to: 0 });
            }
        },
        [props.timeSlots, createForm]
    );

    const renderTimeSlots = React.useCallback(() => {
        const { timeSlots } = props;
        if (timeSlots) {
            // sorting
            if (props.enableDate) {
                timeSlots.sort(
                    (x, y) => +new Date(x.date || "") - +new Date(y.date || "")
                );
            } else {
                timeSlots.sort((a, b) => a.day - b.day);
            }

            return timeSlots.map((entry: DepartmentTimeSlot, index: number) => {
                return (
                    <Stack
                        key={`doh_${index}`}
                        horizontal
                        tokens={{ padding: 5, childrenGap: 10 }}
                    >
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            {props.enableDate ? (
                                <DatePicker
                                    className="date_picker_department__container"
                                    placeholder="Vælg dato"
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    value={convertStringDateToDate(entry.date)}
                                    onSelectDate={newDate =>
                                        onTimeSlotChanged(
                                            newDate,
                                            "date",
                                            index
                                        )
                                    }
                                />
                            ) : (
                                <Dropdown
                                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                    placeholder="Vælg dag"
                                    selectedKey={entry.day}
                                    options={openingHoursDayDropdown(entry.day)}
                                    onChange={(event, value) =>
                                        onTimeSlotChanged(value, "day", index)
                                    }
                                />
                            )}
                        </StackItem>
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            <TextField
                                prefix="Fra"
                                type="time"
                                value={convertFloatToTime(entry.from)}
                                onChange={(event, value) =>
                                    onTimeSlotChanged(value, "from", index)
                                }
                            />
                        </StackItem>
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            <TextField
                                prefix="Til"
                                type="time"
                                value={convertFloatToTime(entry.to)}
                                onChange={(event, value) =>
                                    onTimeSlotChanged(value, "to", index)
                                }
                            />
                        </StackItem>
                        <StackItem
                            className="departmentStackItem"
                            grow={false}
                            disableShrink={true}
                        >
                            <Button
                                className="departmentDeleteButton"
                                onClick={() => onDeleteTimeSlot(index)}
                            >
                                <Icon iconName="Delete" />
                            </Button>
                        </StackItem>
                    </Stack>
                );
            });
        }
    }, [props.timeSlots]);

    const renderCreateForm = React.useCallback(() => {
        if (showCreateForm) {
            return (
                <form className="departmentsForm" onSubmit={onAddNewTimeSlot}>
                    <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            {props.enableDate ? (
                                <>
                                    <Label>Dato</Label>
                                    <DatePicker
                                        className="date_picker_department__container"
                                        placeholder="Vælg dato"
                                        value={convertStringDateToDate(
                                            createForm.date
                                        )}
                                        onSelectDate={newDate =>
                                            onCreateFormChanged(newDate, "date")
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <Label>Dag</Label>
                                    <Dropdown
                                        calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                        placeholder="Vælg dag"
                                        selectedKey={createForm.day}
                                        options={openingHoursDayDropdown(
                                            createForm
                                                ? createForm.day
                                                : undefined
                                        )}
                                        onChange={(e, value) =>
                                            onCreateFormChanged(value, "day")
                                        }
                                    />
                                </>
                            )}
                        </StackItem>
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            <Label>Tid fra</Label>
                            <TextField
                                prefix="Fra"
                                type="time"
                                value={convertFloatToTime(createForm.from)}
                                onChange={(e, value) =>
                                    onCreateFormChanged(value, "from")
                                }
                            />
                        </StackItem>
                        <StackItem
                            className="departmentStackItem"
                            grow={true}
                            disableShrink={true}
                        >
                            <Label>Tid til</Label>
                            <TextField
                                prefix="Til"
                                type="time"
                                value={convertFloatToTime(createForm.to)}
                                onChange={(e, value) =>
                                    onCreateFormChanged(value, "to")
                                }
                            />
                        </StackItem>
                    </Stack>

                    <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                        <StackItem
                            className="departmentStackItem"
                            grow={false}
                            disableShrink={true}
                        >
                            <PrimaryButton type="submit">Opret</PrimaryButton>
                        </StackItem>
                    </Stack>
                </form>
            );
        }
    }, [showCreateForm, createForm]);

    return (
        <div className="custom_container">
            <PageHeader currentPage={props.pageTitle} noBreadcrumb={true} />

            <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                <StackItem
                    className="departmentStackItem"
                    grow={true}
                    disableShrink={true}
                >
                    {props.enableDate ? (
                        <Label>Dato</Label>
                    ) : (
                        <Label>Dag</Label>
                    )}
                </StackItem>
                <StackItem
                    className="departmentStackItem"
                    grow={true}
                    disableShrink={true}
                >
                    <Label>Åben fra</Label>
                </StackItem>
                <StackItem
                    className="departmentStackItem"
                    grow={true}
                    disableShrink={true}
                >
                    <Label>Åben til</Label>
                </StackItem>
            </Stack>

            {renderTimeSlots()}

            {!showCreateForm ? (
                <Stack tokens={{ padding: 5, childrenGap: 10 }}>
                    <DefaultButton onClick={() => setShowCreateForm(true)}>
                        Tilføj ekstra åbningstid
                    </DefaultButton>
                </Stack>
            ) : null}

            {renderCreateForm()}
        </div>
    );
};

export default DepartmentOpeningHours;
