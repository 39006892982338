import {
    DefaultButton,
    Checkbox,
    TextField,
    DetailsList,
    DetailsListLayoutMode,
    CheckboxVisibility,
    IColumn,
    Dropdown,
    IDropdownOption
} from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { RootState } from "../../state";
import { getUserInformation, getOperators, getOrder } from "../../state/action";
import { updateComplaint } from "../../state/order/action";
import { formatComplaintDate } from "../utils/DateHelper";
import { getDropdownForOperators } from "../utils/operatorsHelper";
import TableHelper from "../utils/TableHelper";
import { userHasAccess } from "../utils/UserHelper";
import { getDropdownForComplaintTypes } from "./helpers/complaintHelper";
import {
    complaintOptionList,
    internalComplaintOptions
} from "./helpers/complaintOptions";
import "./style.css";
import { saveSelectedOrder } from "../../state/worksheet/action";

export const workerFaultOptions: IDropdownOption[] = [
    { key: "false", text: "Nej" },
    { key: "true", text: "Ja" }
];

interface ComplaintTabProps {
    complaint?: Complaint;
    orderType: string;
    userName: string;
    orderStatus: OrderStatus;
}

const ComplaintTab: React.FunctionComponent<ComplaintTabProps &
    StateProps> = props => {
    const [showOtherIssueField, setShowOtherIssueField] = useState( props.complaint && props.complaint.otherIssue ? true : false );
    const [descriptionFieldValue, setDescriptionFieldValue] = useState<string>(
        ""
    );

    const complaintList =
        props.orderType === "INTERNAL_COMPLAINT"
            ? internalComplaintOptions
            : complaintOptionList;

    const complaintIssues = props.complaint && props.complaint.issues ? props.complaint.issues : [];

    const issues =
        props.orderType === "INTERNAL_COMPLAINT"
            ? complaintIssues.filter(x =>
                  internalComplaintOptions.find(
                      elem => elem.complaintReason === x.issue
                  )
              )
            : complaintIssues.filter(x =>
                  complaintOptionList.find(
                      elem => elem.complaintReason === x.issue
                  )
              );

    useEffect(() => {
        if (issues.length === 0) {
            props.updateComplaint({ issues: [] });
        }
    }, []);

    const logs =  props.complaint && props.complaint.logs ? props.complaint.logs : [];

    const otherText = props.complaint && props.complaint.otherIssue;

    const isDisabled =
        (props.orderStatus === "SERVICE_CALL" ||
            props.orderStatus === "FINISHED") &&
        !userHasAccess(props.userInformation.roles, ["Complaint"]);

    const [isIssueSelected, setIssueSelectedStatus] = React.useState<boolean>(
        issues[0] !== undefined || (props.complaint && props.complaint.otherIssue !== null) ? true : false
    );

    const changeWorkerFaultWithValue = (value: boolean | undefined) => {
        props.updateComplaint({ workerFault: value });
    };

    const onCheckBoxClick = (value: ComplaintOptions) => (e: React.SyntheticEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            props.updateComplaint({
                issues: [
                    ...issues,
                    { issue: value.complaintReason, art: value.complaintArt }
                ]
            });
            changeWorkerFaultWithValue(value.workerFault);
            setIssueSelectedStatus(true);
        } else {
            props.updateComplaint({ issues: issues.filter(x => x.issue !== value.complaintReason) });
            changeWorkerFaultWithValue(undefined);
            setIssueSelectedStatus(false);
        }
    };

    const toggleOtherIssue = () => {
        if (otherText) {
            setIssueSelectedStatus(false);
            setShowOtherIssueField(false);
            props.updateComplaint({ otherIssue: undefined });
        } else if (!otherText && isIssueSelected && showOtherIssueField) {
            setShowOtherIssueField(false);
            setIssueSelectedStatus(false);
        } else {
            setShowOtherIssueField(true);
            setIssueSelectedStatus(true);
        }
    };

    const setOtherText = (event: React.FormEvent<HTMLInputElement>, newValue: string ) => {
        props.updateComplaint({ otherIssue: newValue });
    };

    const onDescriptionChange = (event: React.FormEvent<HTMLInputElement>,value: string) => setDescriptionFieldValue(value);

    const addDescriptionValue = () => {
        if (descriptionFieldValue) {
            const comment: ComplaintLog = {
                date: new Date(),
                log: descriptionFieldValue,
                by: props.userName
            };
    
            setDescriptionFieldValue("");
            const order:Order = {...props.order, complaint: {...props.complaint, logs: [...logs, comment]}};
            props.updateOrder(order);
        }
    };

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && e.shiftKey === false) {
            addDescriptionValue();
        }
    };
    const setRefWorker = (e: React.SyntheticEvent<HTMLInputElement>, option: IDropdownOption ) => {
        props.updateComplaint({ referenceWorker: option.key as string });
    };

    const changeComplaintType = (e: React.SyntheticEvent<HTMLInputElement>, option: IDropdownOption ) => {
        props.updateComplaint({ complaintType: option.key as ComplaintSlotType });
    };
    const changeWorkerFault = (e: React.SyntheticEvent<HTMLInputElement>, option: IDropdownOption ) => {
        if (option.key === "true")
        {
            props.updateComplaint({ workerFault: true });
        } 
        else if (option.key === "false") {
            props.updateComplaint({ workerFault: false });
        }
    };

    let description = null;
    if (logs.length > 0) {
        const columns: IColumn[] = [
            TableHelper.col("date", "Dato", 100, 100, (e: ComplaintLog) => {
                return <div>{formatComplaintDate(e.date)}</div>;
            }),
            TableHelper.col("by", "Skrevet af", 150, 150),
            TableHelper.col("log", "Beskrivelse", 70, 70)
        ];
        description = (
            <div className="description__container">
                <DetailsList
                    layoutMode={DetailsListLayoutMode.justified}
                    columns={columns}
                    items={logs}
                    checkboxVisibility={CheckboxVisibility.hidden}
                />
            </div>
        );
    }

    

    const refWorker = props.complaint ? props.complaint.referenceWorker : "";
    let workerRender: any = refWorker;

    if ( userHasAccess(props.userInformation.roles, ["Complaint", "Adminstrator" ])) {
        workerRender = (
            <Dropdown
                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                options={getDropdownForOperators(props.operators)}
                onChange={setRefWorker}
                defaultSelectedKey={refWorker}
            />
        );
    }

    return (
        <div className="complaint_tab__container">
            <div className="worker">
                {(props.complaint &&
                    props.complaint.complaintType === "REPLACE_SLOT") ||
                    (props.complaint &&
                        props.complaint.complaintType === "WITHOUT_SLOT" &&
                        props.complaint.referenceJobType &&
                        props.complaint.referenceJobType.startsWith(
                            "REPLACE"
                        ) && (
                            <div className="header__mainOptions">
                                <label>Gammel Rudefabrikat:</label>
                                <span className="manufacturer__span">
                                    {props.complaint.oldManufacturer &&
                                    props.complaint.oldManufacturer !== "NONE"
                                        ? props.complaint.oldManufacturer
                                        : "Ikke specificeret"}
                                </span>
                            </div>
                        ))}
                <div className="header">
                    <div className="header__mainOptions">
                        Oprindelig montør: {workerRender}
                    </div>
                    {props.complaint &&
                        props.complaint.complaintType !==
                            "INTERNAL_COMPLAINT" && (
                            <div className="header__mainOptions">
                                Tid og sted slot type:{" "}
                                <Dropdown
                                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                    options={getDropdownForComplaintTypes()}
                                    onChange={changeComplaintType}
                                    selectedKey={props.complaint.complaintType}
                                />
                            </div>
                        )}
                </div>

                <div className="header">
                    {props.complaint &&
                        props.userInformation.roles.includes("Complaint") && (
                            <div className="header__secondaryOptions">
                                Montørfejl:{" "}
                                <Dropdown
                                    calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                    disabled={isDisabled}
                                    placeholder="Vælg værdi"
                                    options={workerFaultOptions}
                                    onChange={changeWorkerFault}
                                    selectedKey={
                                        props.complaint.workerFault !== undefined
                                            ? props.complaint.workerFault !== null
                                                ? props.complaint.workerFault.toString() : null
                                            : null
                                    }
                                />
                            </div>
                        )}
                </div>
            </div>

            <ul>
                {complaintList.map((x, index) => (
                    <li key={index}>
                        <Checkbox
                            disabled={
                                isDisabled === false
                                    ? isIssueSelected
                                        ? issues[0] &&
                                          x.complaintReason === issues[0].issue
                                            ? false
                                            : true
                                        : false
                                    : true
                            }
                            checked={ issues.find( element => element.issue === x.complaintReason ) !== undefined }
                            label={x.complaintReason}
                            onChange={onCheckBoxClick(x)}
                        />
                    </li>
                ))}
                <li className="otherTextField" key={"Andet"}>
                    <Checkbox
                        disabled={
                            isDisabled === false
                                ? isIssueSelected === true
                                    ? showOtherIssueField
                                        ? false
                                        : true
                                    : false
                                : true
                        }
                        checked={(otherText !== null && otherText !== undefined) ||showOtherIssueField }
                        label="Andet"
                        onChange={toggleOtherIssue}
                    />
                    {otherText || showOtherIssueField ? (
                        <TextField disabled={isDisabled} onChange={setOtherText} defaultValue={otherText} />
                    ) : null}
                </li>
            </ul>
            {description}
            <div className="descriptionField">
                Beskrivelse:{" "}
                <TextField
                    onKeyDown={onEnterPress}
                    value={descriptionFieldValue}
                    multiline={true}
                    onChange={onDescriptionChange}
                />
                <DefaultButton onClick={addDescriptionValue}>
                    Tilføj
                </DefaultButton>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        userInformation: getUserInformation(state),
        operators: getOperators(state),
        order: getOrder(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateComplaint: (complaint: Partial<Complaint>) => dispatch<any>(updateComplaint(complaint)),
    updateOrder: (order: Order) => dispatch<any>(saveSelectedOrder(order)),
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(ComplaintTab);
