import produce from "immer";
import { Actions } from "./action";

export interface DebitorRuleSetState {
    ruleSets: DebitorRuleSet[];
}

const initialState: DebitorRuleSetState = {
    ruleSets: [],
   
};

export const reducer = (state = initialState, action: Actions): DebitorRuleSetState => produce(state, draft => {
    if (action.type === "GET_ALL_DEBITOR_RULE_SETS") {
        action.debitorRuleSets.forEach((element, index) => {
            draft.ruleSets.push({...element, key: element.id})
        });
       
        return;
    }
    if(action.type === "SORT_DEBITOR_RULE_SET")
    {
        draft.ruleSets = draft.ruleSets.sort((a, b) =>
        {
            const sortbyB = b[action.sortBy];
            const valueB: string = sortbyB === undefined || sortbyB === null ?  "" :  sortbyB;
            const sortbyA = a[action.sortBy];
            const valueA: string = sortbyA === undefined || sortbyA === null ?  "" :  sortbyA;
            const value = valueA.localeCompare(valueB);
            return value;
        });
        if(action.sortDirection === "DOWN")
        {
            draft.ruleSets = draft.ruleSets.reverse();
        }
        return;
    }

    if (action.type === "UPDATE_DEBITOR_RULE_SET") {
        const index = draft.ruleSets.findIndex(x=>x.id === action.debitorRuleSet.id)
        const ruleset = {...action.debitorRuleSet, key: action.debitorRuleSet.id};
        if(index > -1)
        {
            draft.ruleSets[index] = ruleset;
        }
        else
        {
            draft.ruleSets.push(ruleset)
        }
        return;
    }
    if (action.type === "DELETE_DEBITOR_RULE_SET") {
        draft.ruleSets = draft.ruleSets.filter(x => x.id !== action.id);
        return;
    }
});

export const GetDebitorRuleSets = (state: DebitorRuleSetState) =>
    state.ruleSets;

export default reducer;
