import { EventApi } from "@fullcalendar/core";
import { Action, Dispatch } from "redux";
import { CustomCalendarEvent } from ".";
import API from "../../api";

interface ShowEventAction extends Action {
    type: "SHOW_EVENT";
    event?: CustomCalendarEvent;
}

interface SetCurrentBookingAction extends Action {
    type: "SET_BOOKING";
    id?: string;
}

export const setShowEvent = (event?: EventApi): ShowEventAction => {
    if (event) {
        const customEvent: CustomCalendarEvent = {
            id: event.id,
            description: event.extendedProps.description,
            createdBy: event.extendedProps.createdBy,
            title: event.title,
            departmentId: event.extendedProps.departmentId
        };
        return { type: "SHOW_EVENT", event: customEvent };
    }
    return { type: "SHOW_EVENT", event: undefined };
};

export const updateEvent = (event: CustomCalendarEvent) => async (
    dispatch: Dispatch<any>
) => {
    await API.CalendarApi.UpdateEvent(event);
    dispatch({ type: "SHOW_EVENT", event: undefined });
};

export const setCurrentBooking = (id?: string): SetCurrentBookingAction => {
    return { type: "SET_BOOKING", id };
};

export type Actions = ShowEventAction | SetCurrentBookingAction;
