import {
    Dropdown,
    IDropdownOption,
    Dialog,
    TextField,
    PrimaryButton,
    DefaultButton,
    Label
} from "@fluentui/react";
import * as React from "react";

interface DropdownWithNewProps {
    options: IDropdownOption[];
    newValueText: string;
    dialogTitle: string;
    selectedValue: string;
    onValueChange: (newValue: string) => void;
}

const DropdownWithNew: React.FunctionComponent<DropdownWithNewProps> = props => {
    const [showAddNewItem, setShowAddNewItem] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IDropdownOption[]>([]);
    const [newValue, setNewValue] = React.useState<string>("");

    React.useEffect(() => {
        setOptions(props.options);
        setNewValue("");
    }, [props.options]);

    const onDropdownChange = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        if (option.key === 9999) {
            setShowAddNewItem(true);
        } else {
            props.onValueChange(option.text);
        }
    };

    const onChangeNewValueText = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue: string
    ) => setNewValue(newValue);

    const onCloseDialogClick = () => setShowAddNewItem(false);

    const onSaveNewValueClick = () => {
        setShowAddNewItem(false);
        props.onValueChange(newValue);
    };

    return (
        <>
            <Dropdown
                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                onChange={onDropdownChange}
                selectedKey={props.selectedValue}
                options={[...options, { text: props.newValueText, key: 9999 }]}
            ></Dropdown>

            <Dialog hidden={!showAddNewItem} onDismiss={onCloseDialogClick}>
                <Label>{props.dialogTitle}</Label>
                <TextField onChange={onChangeNewValueText} value={newValue} />
                <PrimaryButton onClick={onSaveNewValueClick} text="Gem" />
                <DefaultButton onClick={onCloseDialogClick} text="Anuller" />
            </Dialog>
        </>
    );
};

export default DropdownWithNew;
