import produce from "immer";
import { Actions } from "./action";

export interface WorksheetState {
    orders: Order[];
    pageCount: number;
}

const initialState: WorksheetState = {
    orders: [],
    pageCount: 0
};

export const reducer = (
    uiState = initialState,
    action: Actions
): WorksheetState =>
    produce(uiState, draft => {
        switch (action.type) {
            case "GET_ORDERS": {
                draft.orders = action.orders;
                draft.pageCount = action.pageCount;
                return;
            }
            case "SMS_NOTFICATION_STATUS": {
                const order = draft.orders.find(x=>x.additionalData.orderNr === action.orderNumber);
                if(order)
                {
                    order.notificationStatus = action.status;
                }
                return;
            }
        }
    });

export const getOrders = (state: WorksheetState) => state.orders;

export const getPages = (state: WorksheetState) => state.pageCount;

export default reducer;
