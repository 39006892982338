import { Action, Dispatch, AnyAction } from "redux";
import api from "../../api";
import { addMessageStatus } from "../ui/action";
import { MessageBarType } from "@fluentui/react";

export interface UpdateOldProductsAction extends Action {
    type: "UPDATE_OLD_PRODUCTS";
    oldProducts: OldProduct[];
}

export const updateOldProducts = (searchQuery: string) => async (
    dispatch: Dispatch<AnyAction>
) => {
    try {
        dispatch({
            type: "UPDATE_OLD_PRODUCTS",
            oldProducts: []
        } as UpdateOldProductsAction);
        const oldProducts = await api.OldProductsApi.getProducts(searchQuery);
        const action: UpdateOldProductsAction = {
            type: "UPDATE_OLD_PRODUCTS",
            oldProducts
        };
        dispatch(action);
    } catch (ex) {
        const statusMessage: StatusMessage = {
            messageType: MessageBarType.error,
            message: `Api: "getOldProducts" failed with input ${searchQuery}`,
            dismissTimer: 5000
        };

        addMessageStatus(statusMessage)(dispatch);

        throw ex;
    }
};

export type Actions = UpdateOldProductsAction;
