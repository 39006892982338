import BaseApi from "./baseApi";

export default class OrderSatisfactionApi extends BaseApi {
    async addOrderSatisfaction(
        orderNo: string,
        model: OrderSatisfaction
    ): Promise<OrderSatisfaction> {
        const url = "/api/ordersatisfaction";
        return await this.fetchPut(url, {
            OrderNo: orderNo,
            OrderSatisfaction: model
        });
    }

    async getOrderSatisfaction(orderNo: string): Promise<boolean> {
        const url = `/api/ordersatisfaction/${orderNo}`;
        return await this.fetchGet(url);
    }
}
