import Select from 'react-select';
import { Icon } from '@fluentui/react/lib/Icon';
import * as React from "react";

interface Option {
  value: string | { id: string };
  label?: string; 
}

interface selectOptionsProps {
  options: Option[];
  value: string | { id: string };
  label: string;
  onChange: (selectedOption: Option | null) => void;
  isDisabled: boolean;
  placeholder: string;
  isSearchable: boolean;
}

const ReactSelectDropdown: React.FunctionComponent<selectOptionsProps> = props => {

  return (
    <div className="select-dropdown">
      <Icon iconName="MapPin" aria-hidden="true" className="ms-Icon root-89 css-236 ms-Button-icon icon-129" style={{ fontFamily: 'FabricMDL2Icons-0', color: "rgb(0, 120, 212)" }} />
      <Select
        classNamePrefix="react-select"
        options={props.options}
        value={{value: props.value, label: props.label}}
        onChange={props.onChange}
        isDisabled={props.isDisabled}
        placeholder={props.placeholder}
      />
    </div>
    
  )
};

export default ReactSelectDropdown;
