export const internalComplaintOptions: ComplaintOptions[] = [
    {
        complaintReason: "Flimmer i ruden",
        complaintArt: "Intern reklamation",
        workerFault: undefined
    },
    {
        complaintReason: "Revnet rude",
        complaintArt: "Intern reklamation",
        workerFault: undefined
    },
    {
        complaintReason: "Ridset rude",
        complaintArt: "Intern reklamation",
        workerFault: undefined
    },
    {
        complaintReason: "Spejlbeslag",
        complaintArt: "Intern reklamation",
        workerFault: undefined
    },
    {
        complaintReason: "Varmefejl",
        complaintArt: "Intern reklamation",
        workerFault: undefined
    },
    {
        complaintReason: "Kamerabeslag",
        complaintArt: "Intern reklamation",
        workerFault: undefined
    },
    {
        complaintReason: "Defekt lister på ruden",
        complaintArt: "Intern reklamation",
        workerFault: undefined
    },
    {
        complaintReason: "Defekte løse lister",
        complaintArt: "Intern reklamation",
        workerFault: undefined
    },
    {
        complaintReason: "Forkert rude monteret",
        complaintArt: "Intern reklamation",
        workerFault: undefined
    },
];

export const complaintOptionList: ComplaintOptions[] = [
    {
        complaintReason: "Utæt rude",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Rude ridset",
        complaintArt: "Kundeklage",
        workerFault: false
    },
    {
        complaintReason: "Rude revnet uden synlige stenslag",
        complaintArt: "Kundeklage",
        workerFault: false
    },
    {
        complaintReason: "Rudefejl - eks flimmer, spejlbeslag faldet af, flimmer o.s.v",
        complaintArt: "Kundeklage",
        workerFault: false
    },
    {
        complaintReason: "Revnet rude under montering",
        complaintArt: "Kundeklage",
        workerFault: false
    },
    {
        complaintReason: "Rude monteret skævt/højt/lav",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Forkert rude monteret",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Løse lister",
        complaintArt: "Kundeklage",
        workerFault: undefined
    },
    {
        complaintReason: "Bakspejl, P-skive/Sapheetc.",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Regnsensor",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Varmefejl",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Kalibrering",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Kalibrering på ekstern værksted",
        complaintArt: "Uafsluttet opgave",
        workerFault: false
    },
    {
        complaintReason: "Interiørskade",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Lakskade",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Ødelagte plastdele",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Viskertræk/arm/motor",
        complaintArt: "Kundeklage",
        workerFault: true
    },
    {
        complaintReason: "Viskerblade",
        complaintArt: "Kundeklage",
        workerFault: false
    },
    {
        complaintReason: "Stenslag",
        complaintArt: "Kundeklage",
        workerFault: undefined
    },
    {
        complaintReason: "Afvist reklamation",
        complaintArt: "Afvist reklamation",
        workerFault: false
    }
];