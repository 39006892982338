export const userHasAccess = (
    userRoles: UserRole[],
    allowedRoles: UserRole[]
): boolean => {
    return (
        userRoles.some(role => allowedRoles.includes(role)) ||
        allowedRoles.length <= 0
    );
};

export const loadUser = (
    users: UserInformation[],
    userId: string
): UserInformation | undefined => {
    return users.find(x => x.id === userId);
};

export const userLoggedIn = (user: UserInformation): Boolean => {
    return !!user.id;
};
