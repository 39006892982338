import BaseApi from "./baseApi";

export default class NAVAPI extends BaseApi {
    async getBinList(departmentGuid: string): Promise<BinList[]> {
        const url = `/api/transfer/binlist/${departmentGuid}`;
        return await this.fetchGet(url);
    }

    async getTransfers(
        departmentGuid: string,
        fromDate: string,
        toDate: string,
        orderNr: string
    ): Promise<Transfer[]> {
        const url = `/api/transfer/transfers?departmentGuid=${departmentGuid}&from_date=${fromDate}&to_date=${toDate}&order_nr=${encodeURIComponent(
            orderNr
        )}`;
        return await this.fetchGet(url);
    }

    async createTransfer(transfers: Transfer[]): Promise<boolean> {
        const url = `/api/transfer/transfer`;
        return await this.fetchPost(url, transfers);
    }

    async acceptTransfer(
        type: string,
        orderNo: string,
        orderLine: number,
        amount: number,
        departmentGuid: string
    ): Promise<Transfer> {
        const url = `/api/transfer/transfer/${type}/${orderNo}/${orderLine}/${amount}?departmentGuid=${departmentGuid}`;
        return await this.fetchPost(url, {});
    }
}
