import * as React from "react";
import { parsePriceAsString, vatConverter } from "../../utils/vatConverter";
import "../style.css"
import { Icon, DefaultButton } from "@fluentui/react";

interface Props {
    no: string;
    subItems?: SubItem[];
    amount: number; // client usage
    debitorPrice?: DebitorPrice;
    debitorNo: string;
    debitorPriceRequest: (request: ProductPriceRequest[]) => void;
}

const DebitorPrice: React.FunctionComponent<Props> = props => {
    const [gettingPrice, setGettingPrice] = React.useState<boolean>(false);

    const {
        debitorNo,
        subItems,
        debitorPrice,
        amount,
        no,
        debitorPriceRequest
    } = props;
    const request: ProductPriceRequest[] = [
        {
            amount,
            productNo: no
        }
    ];
    if (subItems) {
        subItems.forEach(product =>
            request.push({ amount: product.amount, productNo: product.no })
        );
    }
    const onReqestDebitorPrice = () => {
        setGettingPrice(true);
        debitorPriceRequest(request);
    };
    if (debitorPrice) {
        let price =
            debitorPrice.price * (1 - debitorPrice.discountPercent / 100);
        if (subItems) {
            price += subItems.reduce(
                (sum, sub) =>
                    sum +
                    (sub.debitorPrice
                        ? sub.debitorPrice.price *
                          (1 - sub.debitorPrice.discountPercent / 100)
                        : sub.price) *
                        sub.amount,
                0
            );
        }
        return (
            <>
                <div>
                    <p className="debitor_price__field">{vatConverter(price)}</p>
                </div>
                <div>
                    <p className="debitor_price__field">
                        {parsePriceAsString(price)}
                    </p>
                </div>
            </>
        );
    }
    if (debitorNo !== undefined || debitorNo !== null || debitorNo === "") {
        return <Icon iconName="AlertSolid" title="Ingen debitor valgt"></Icon>;
    }
    if (gettingPrice) {
        return <p className="debitor_price__field">Henter pris...</p>;
    }
    return <DefaultButton text="Hent pris" onClick={onReqestDebitorPrice} />;
};

export default DebitorPrice;
