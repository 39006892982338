import * as React from "react";
import PageHeader from "../layout/PageHeader";
import NavigationHelper from "../utils/NavigationHelper";
import CustomDatePicker from "../base/CustomDatePicker";
import {
    getOrders,
    getPages,
    getDepartments,
    getUserInformation
} from "../../state/action";
import { RootState } from "../../state";
import { Dispatch, Action } from "redux";
import { connect } from "react-redux";
import { useEffect, FormEvent } from "react";
import { fetchOrders, saveSelectedOrder } from "../../state/worksheet/action";
import OrderList from "./OrderList";
import OrderListMobile from "./OrderListMobile";
import { getSpecificStatusText } from "../utils/statusHelper";
import { assignmentTypeText } from "../utils/assignmentTypeHelper";
import { sendSms } from "../../state/sms/action";
import SearchButton from "../base/Search/SearchButton";
import ReportModal from "../log/ReportModal";
import { createMoreSaleOrder } from "../../state/order/action";
import { RouteComponentProps } from "react-router";
import { getDepartmentDropdownOptions } from "../utils/departmentHelper";
import Pagination from "../base/Pagination";
import { Dropdown, IDropdownOption, Icon, Label, Link, Stack, TextField } from "@fluentui/react";

function ListAssignments(
    props: StateProps & RouteComponentProps<{ phoneOrMail: string }>
) {
    const [filter, setFilter] = React.useState<OrderFilter>({
        page: 0,
        orderSortType: "DATE"
    });
    const checkOrderView = window.matchMedia("(min-width: 768px)");
    const [desktopView, toggleOrderView] = React.useState(
        checkOrderView.matches
    );
    const [hideLogModal, setHideLogModal] = React.useState<boolean>(true);
    const [hideOrderModal, setHideOrderModal] = React.useState<boolean>(true);
    const [hideFeedbackModal, setHideFeedbackModal] = React.useState<boolean>(true);

    const updateList = () => {
        toggleOrderView(checkOrderView.matches);
    };
    const toggleFeedbackModel = () => setHideFeedbackModal(!hideFeedbackModal);
    const sortFilter = (): OrderFilter => {
        const fromDate = new Date();
        const toDate = new Date();
        toDate.setMonth(fromDate.getMonth() + 1);

        switch (NavigationHelper.lookup(window.location.pathname)) {
            case "PENDING":
                return {
                    ...filter,
                    status: "PENDING_REVIEW",
                    fromDate,
                    toDate,
                    department: props.user.departmentGuid
                };
            case "OFFERS":
                return {
                    ...filter,
                    status: "OFFER_REQUEST",
                    fromDate,
                    toDate
                };
            case "WEB_ORDERS":
                return {
                    ...filter,
                    status: "WEB_REVIEW",
                    fromDate,
                    toDate,
                    department: props.user.departmentGuid
                };
            default:
                return {
                    ...filter,
                    status: "PROCESSING",
                    fromDate,
                    toDate,
                    department: props.user.departmentGuid
                };
        }
    };

    useEffect(() => {
        window.addEventListener("resize", updateList);

        if (props.match.params.phoneOrMail) {
            const filer: OrderFilter = {
                orderSortType: "DATE",
                page: 0,
                phoneOrMail: props.match.params.phoneOrMail,
                descendingSort: true
            };
            setFilter(filer);
            props.updateOrders(filer);
        } else {
            const newFilter = sortFilter();
            setFilter(newFilter);
            props.updateOrders(newFilter);
        }

        return () => {
            window.removeEventListener("resize", updateList);
        };
    }, []);

    const selectOrder = (ordreId: string) => {
        NavigationHelper.pushRoute("WORKSHEET", `/${ordreId}`);
    };

    const changeLocation = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        const department =
            option.key !== "all" ? (option.key as string) : undefined;
        setFilter({ ...filter, department });
    };
    const changeType = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        const assignmentType =
            option.key !== "all" ? (option.key as AssignmentType) : undefined;
        setFilter({ ...filter, assignmentType });
    };
    const changeStatus = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        const status =
            option.key !== "all" ? (option.key as OrderStatus) : undefined;
        setFilter({ ...filter, status });
    };

    const onFromDateChange = (fromDate: Date | undefined) =>
        setFilter({ ...filter, fromDate });

    const onToDateChange = (toDate: Date | undefined) =>
        setFilter({ ...filter, toDate });

    const onQueryChange = (
        e: React.SyntheticEvent<HTMLInputElement>,
        query: string
    ) => setFilter({ ...filter, query });

    const setPage = (page: number) => props.updateOrders({ ...filter, page });

    const search = (e: FormEvent) => {
        e.preventDefault();
        props.updateOrders(filter);
    };

    const getDepartments = (): IDropdownOption[] => {
        const departments: IDropdownOption[] = getDepartmentDropdownOptions(
            props.departments
        );
        departments.unshift({ key: "all", text: "Alle" });
        return departments;
    };

    const getTypes = (): IDropdownOption[] => {
        const types = [...assignmentTypeText];
        types.unshift({ key: "all", text: "Alle" });
        return types;
    };

    const getTitle = () => {
        switch (filter.status) {
            case "OFFER":
                return "Tilbud";
            case "OFFER_REQUEST":
                return "Tilbudsanmodning";
            case "PENDING_REVIEW":
                return "Gennemsyn";
            case "WEB_REVIEW":
                return "Webordregennemsyn";
            default:
                return "Opgaver";
        }
    };

    const getStatus = (): IDropdownOption[] => {
        switch (NavigationHelper.lookup(window.location.pathname)) {
            case "ASSIGNMENTS":
                const status = [
                    ...getSpecificStatusText([
                        "PROCESSING",
                        "FINISHED",
                        "CANCELLED",
                        "ON_HOLD",
                        "SERVICE_CALL"
                    ])
                ];
                status.unshift({ key: "ALLASSIGNMENTS", text: "Alle" });
                return status;
            case "PENDING":
                return getSpecificStatusText(["PENDING_REVIEW"]);
            case "OFFERS":
                return getSpecificStatusText(["OFFER", "OFFER_REQUEST"]);
            case "WEB_ORDERS":
                return getSpecificStatusText(["WEB_REVIEW"]);
            default:
                return getSpecificStatusText([]);
        }
    };

    return (
        <>
            <ReportModal
                onDismiss={() => setHideLogModal(true)}
                hideDialog={hideLogModal}
                title="Download Logs"
                subText="Download Logs"
                apiBase="api/report/logs"
            />
            <ReportModal
                onDismiss={() => setHideOrderModal(true)}
                hideDialog={hideOrderModal}
                title="Download Ordrer"
                subText="Download Ordrer"
                apiBase="/api/report"
            />
              <ReportModal
                onDismiss={toggleFeedbackModel}
                hideDialog={hideFeedbackModal}
                title="Download Feedback"
                subText="Download Feedback"
                apiBase="api/feedback"
            />
            <PageHeader currentPage={getTitle()} noBreadcrumb={true} />
            <div className="csv_download">
                <Link href="#" onClick={() => setHideLogModal(false)}>
                    Logs <Icon iconName="AnalyticsView" />
                </Link>
                <Link href="/api/report/ordersatisfactions" target="_blank">
                    Satisfactions Report <Icon iconName="AnalyticsView" />
                </Link>
                <Link  onClick={toggleFeedbackModel} target="_blank">
                   Feedback <Icon iconName="AnalyticsView" />
                </Link>
                <Link href="#" onClick={() => setHideOrderModal(false)}>
                    Orders Report <Icon iconName="AnalyticsView" />
                </Link>
            </div>
            <div className="custom_container">
                <form onSubmit={search}>
                    <Stack
                        className="filter__menu"
                        horizontal
                        tokens={{ childrenGap: 10 }}
                        horizontalAlign="end"
                    >
                        <Stack.Item>
                            <Label>Type</Label>
                            <Dropdown
                                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                dropdownWidth={200}
                                placeholder="Vælg opgavetype"
                                options={getTypes()}
                                onChange={changeType}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Label>Afdeling</Label>
                            <Dropdown
                                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                dropdownWidth={200}
                                placeholder="Vælg afdeling"
                                options={getDepartments()}
                                defaultSelectedKey={filter.department}
                                onChange={changeLocation}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Label>Status</Label>
                            <Dropdown
                                calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                                dropdownWidth={200}
                                placeholder="Vælg status"
                                defaultSelectedKey={filter.status}
                                options={getStatus()}
                                onChange={changeStatus}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Label>Fra dato</Label>
                            <CustomDatePicker
                                short={true}
                                date={filter.fromDate}
                                onDateChange={onFromDateChange}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Label>Til dato</Label>
                            <CustomDatePicker
                                short={true}
                                date={filter.toDate}
                                onDateChange={onToDateChange}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Label>Ordre-/Reg nr. /Email /Debitor no</Label>
                            <TextField
                                value={filter.query}
                                onChange={onQueryChange}
                            />
                            <SearchButton type={"submit"} />
                        </Stack.Item>
                    </Stack>
                </form>
                <br />
                {desktopView ? (
                    <OrderList
                        orders={props.orders}
                        selectBooking={selectOrder}
                        sendSms={props.sendSms}
                        user={props.user}
                        saveSelectedOrder={props.saveSelectedOrder}
                    />
                ) : (
                    <OrderListMobile
                        orders={props.orders}
                        selectBooking={selectOrder}
                        sendSms={props.sendSms}
                        createMoreSaleOrder={props.createMoreSaleOrder}
                        user={props.user}
                    />
                )}
                {props.orders ? (
                    <Pagination
                        updatePagination={setPage}
                        currentPage={filter.page}
                        pages={props.ordreCount}
                    />
                ) : null}
            </div>
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        user: getUserInformation(state),
        departments: getDepartments(state),
        orders: getOrders(state),
        ordreCount: getPages(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateOrders: (filter: OrderFilter) => dispatch<any>(fetchOrders(filter)),
    saveSelectedOrder: (order: Order, status: OrderStatus) => dispatch<any>(saveSelectedOrder(order, status)),
    sendSms: (model: NotificationModel) => dispatch<any>(sendSms(model)),
    createMoreSaleOrder: (order: Order) =>
        dispatch<any>(createMoreSaleOrder(order))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(ListAssignments);
