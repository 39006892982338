import BaseApi from "./baseApi";

interface IDepartmentApi {
    getNearestLocation: (
        model: TimeSlotDepartmentFilterRequestModel,
        signal?: AbortSignal
    ) => Promise<Department[]>;
    getReservedTimeSlots: (filter: TimeSlotSearchFilter ) => Promise<AvailableDepartmentBooking[]>;
    updateDepartment: (department: Department) => Promise<Department>;
    deleteDepartment: (department: Department) => Promise<void>;

}

export default class DepartmentApi extends BaseApi implements IDepartmentApi {
    async copyTimeslot(departmentId: string, dayOfWeek: number, copyToDate: Date, timeslotType: string): Promise<DamageSpecificTimeSlot[]> {
        const url = `/api/departments/copyTimeslot`;
        return await this.fetchPost(url, { departmentId, dayOfWeek, copyToDate, timeslotType});
    }
    async getReservedTimeSlots(filter: TimeSlotSearchFilter): Promise<AvailableDepartmentBooking[]> {
        const {year, month, ...rest} = filter;
        const url = `/api/timeslot/${encodeURIComponent(filter.departmentId)}/${year}/${month}`;
        return await this.fetchPost(url, rest);
    }

    async updateDepartment(department: Department): Promise<Department> {
        const url = `/api/departments/departments`;
        return await this.fetchPut(url, department);
    }

    async getNearestLocation(
        model?: TimeSlotDepartmentFilterRequestModel,
        signal?: AbortSignal
    ): Promise<Department[]> {
        const url = `/api/timeslot/nearest`;
        return await this.fetchPost(url, model, signal);
    }

    async deleteDepartment(department: Department): Promise<void> {
        const url = `/api/departments/departments/${department.id}`;
        return await this.fetchDelete(url);
    }

    async getCoordinates(input: string): Promise<Coordinates> {
        const url = `/api/departments/coordinates?input=${input}`;
        return await this.fetchGet(url);
    }

    async getDepartments(): Promise<Department[]> {
        const url = `/api/departments/departments`;
        return await this.fetchGet(url);
    }

    async addOrUpdateTimeSlot(departmentId: string, type: string, timeslot: DamageSpecificTimeSlot): Promise<DamageSpecificTimeSlot[]> {
        const url = `/api/departments/addOrUpdateTimeslot/${departmentId}/${type}`;
        return await this.fetchPost(url, timeslot);
    }

    async deleteTimeSlot(departmentId: string, type: string, timeslotId: string): Promise<void> {
        const url = `/api/departments/remove/${departmentId}/${type}/${timeslotId}`;
        return await this.fetchDelete(url);
    }

    async alreadyUsedTimeslots(departmentId: string): Promise<AlreadyUsedTimeslot> {
        const url = `/api/departments/alreadyUsedTimeslots/${departmentId}`;
        return await this.fetchGet(url);
    }
   
}
