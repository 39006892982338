import {
    IGenericExceptionResult,
    ExceptionResults,
    IUnauthorizedExceptionResult
} from "../components/typings";

export default class BaseApi {
    private async baseFetch(
        method: string,
        url: string,
        body?: string,
        signal?: AbortSignal
    ): Promise<any> {
        const response = await fetch(url, {
            credentials: "same-origin",
            method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body,
            redirect: "manual",
            signal
        });

        return this.handleServerResponse(method, url, response);
    }

    public async fetchDelete(url: string): Promise<any> {
        return await this.baseFetch("DELETE", url, undefined);
    }

    public async fetchGet(url: string, signal?: AbortSignal): Promise<any> {
        return await this.baseFetch("GET", url, undefined, signal);
    }

    public async fetchPut(url: string, body: object): Promise<any> {
        return await this.baseFetch("PUT", url, JSON.stringify(body));
    }

    public async fetchPost(
        url: string,
        body: object = {},
        signal?: AbortSignal
    ): Promise<any> {
        return await this.baseFetch("POST", url, JSON.stringify(body), signal);
    }

    public async fetchPutFile(url: string, formData: FormData): Promise<any> {
        const response = await fetch(url, {
            credentials: "same-origin",
            method: "PUT",
            headers: {
                "Content-Disposition": "multipart/form-data"
            },
            body: formData
        });

        return await this.handleServerResponse("PUT", url, response);
    }

    private async handleServerResponse(
        method: string,
        requestUrl: string,
        response: Response
    ) {
        //if (response.redirected)
        //{
        //    const newLocation = response.headers["Location"];
        //    if (newLocation.startsWith("/"))
        //    {
        //        NavigationHelper.pushRoute(response.headers["Location"]);
        //    }
        //    else
        //    {
        //        console.log(newLocation);
        //    }
        //}
        switch (response.status) {
            case 200:
                return response.json();

            case 201: // Created
            case 202: // Accepted
            case 203: // Partial
            case 204: // No Content
                return Promise.resolve();
            case 401:
                // Unauthorized

                // in a sutuation that user is trying to do PUT in an unauthorized status we are going to force they to login page
                if (method === "PUT") {
                    window.location.replace("/login");
                }
                // for GET we have an error
                const exp: IUnauthorizedExceptionResult = {
                    type: "UNAUTHORIZED_ERROR",
                    message: `${method} ${requestUrl} HTTP/1.1 \n\t responded with: ${response.statusText}`,
                    status: response.status
                };
                return Promise.reject(exp);
            default:
                try {
                    const json = await response.json();
                    json.url = requestUrl;
                    json.status = response.status;
                    return Promise.reject<ExceptionResults>(json);
                } catch {
                    const exp: IGenericExceptionResult = {
                        type: "GENERIC_ERROR",
                        message: `${method} ${requestUrl} HTTP/1.1 \n\t responded with: ${response.statusText}`,
                        status: response.status
                    };
                    return Promise.reject(exp);
                }
        }
    }
}
