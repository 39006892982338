import * as React from "react";
import PageHeader from "../layout/PageHeader";
import { getOrder } from "../../state/action";
import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import { RootState } from "../../state";
import { Icon, PrimaryButton } from "@fluentui/react";

const Receipt: React.FunctionComponent<StateProps> = props => {
    const navigateToDashboard = () => {
        window.location.href = "/";
    };

    return (
        <>
            <PageHeader
                currentPage="Ordren er gennemført."
                noBreadcrumb={true}
            />
            <div>
                <p> Ordre er blevet fuldført</p>
                <p>
                    <Icon
                        style={{ color: "green", marginRight: "5px" }}
                        iconName="SkypeCircleCheck"
                    />{" "}
                    Blev leveret korrekt til NAV
                </p>
                <p>
                    <Icon
                        style={{ color: "green", marginRight: "5px" }}
                        iconName="SkypeCircleCheck"
                    />{" "}
                    Blev leveret korrekt til Outlook
                </p>
                <br />
                <p>
                    Link:{" "}
                    <a
                        href={
                            "/worksheet/" + props.order.additionalData.orderId
                        }
                    >
                        {props.order.additionalData.orderNr}
                    </a>
                </p>
                <br />
                <PrimaryButton onClick={navigateToDashboard}>
                    Til Forsiden
                </PrimaryButton>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        order: getOrder(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(Receipt);
