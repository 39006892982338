import { userHasAccess } from "./UserHelper";

interface IAuthComponent {
    userRoles: UserRole[];
    allowedUserRoles: UserRole[];
    component: React.FunctionComponent<any>; // | JSX.Element;
    props?: any;
}

export const AuthComponent = (authComponent: IAuthComponent) => {
    if (
        userHasAccess(authComponent.userRoles, authComponent.allowedUserRoles)
    ) {
        return authComponent.component(authComponent.props);
    }
    return null;
};
