import BaseApi from "./baseApi";

export default class AddressApi extends BaseApi {
    async getAll(): Promise<DebitorRuleSet[]> {
        const url = `/api/debitorruleset/all`;
        return await this.fetchGet(url);
    }
    async save(debitorRuleSet?: DebitorRuleSet): Promise<DebitorRuleSet> {
        const url = `/api/debitorruleset/save`;
        return await this.fetchPost(url, debitorRuleSet);
    }
    async delete(id: string): Promise<DebitorRuleSet> {
        const url = `/api/debitorruleset/${id}`;
        return await this.fetchDelete(url);
    }
}
