//base64 encoded placeholder image 
export const defaultImage = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAGXRFWHRTb2Z0d2F
yZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzV
NME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCB
Db3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8
vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJ
odHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM
6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGh
vdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjdDRTcwMjE3Qjc4QjExRTk4OEEzRThDODE1QUZ
GNzlBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjdDRTcwMjE4Qjc4QjExRTk4OEEzRThDODE1QUZGNzlBIj4gPHhtcE1NOkRlcml2ZWR
Gcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6N0NFNzAyMTVCNzhCMTFFOTg4QTNFOEM4MTVBRkY3OUEiIHN0UmVmOmRvY3VtZW50SUQ
9InhtcC5kaWQ6N0NFNzAyMTZCNzhCMTFFOTg4QTNFOEM4MTVBRkY3OUEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94Onh
tcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7dxLFQAAAE/0lEQVR42uyZXUxbZRjH3/PVLnyUymdLt7K14UNPQ4sb5UC4MBr1zoTMEBtj4kf
CsrjolTdL3O0Wol5sBIMfF0um6MV0mR9Ew0KMF6M9pwXMlg2ESFuCrKWcAaelHzunPqeA4syg0B5cyXmTJs05p+37e5//8zz/9y2WTqfRQRg
4OiBDBVFBVBAVRAVRQXIZZLYPDg4OTkuSRKYlCSEsx9XDcIThGEpLaSSlJYRhWOaaPCRJRLLZwHH5Gfw3l8v1Ul5B4NutlRUVqLi4GH5ctjV
7sTYYTBohQRBQNBpDGopCer0e3kdRNBZFWo0W6crKEEmSiOeX0MryynLeIwLRSFmslvMWi7UX/Bm1x2CIqVTqidHRm35RFPkTx0+8XlVdPQw
gFp/X++nq6ipD0/SL5eXlI+NjY4P80lJd3kEy64nhIkEQ0VxkBZNuWF5eQSaTachgNF6Xr+l0ultHjx373MN6GLhfX1lZ+XNGeoole1oicq4
uOJ6S9R+PrxkgytrN67FYrA5bz434upLTyiT7DqvcBLJ40mAwfLvTs5BjUzU1NTdmZ/94bmJi4qLJVHsVImSfnLx7VqcrjYOsflW0aj1qxBM
Jk8/n/Tp0L9Tc6nSeMpvNn+wQkTjkwbsQge9h8j3Tv0/1iFAJS0tKUXOz/U0ZdN9B1tbWzF6O+25hYaGZJEjk9XIDoAnCXFf38XafKykpud3
R0dE+Nzf3BgAdpSjqvqm2drBUpxtXvI/8JxLxuJnj2B+DgSBts9kuVUP18bDsF5Cw/ZCo4pEj20dGq9UuWK3W8/9LZ4f8yyR7IpEwwOp/Eww
E6IbGhitP0fRZuQK1tra+SpFUgvWwA8Fg8K3H1qJQGioCfUDPsuyQ3+8/3tjYdNnR8vRpaGCCfN8IMEx7exeUaIBxfxYI+E/vfWqYMiByXRe
E6Ec+n4+HCTrq6xuu2B0tZ0iCELY+BxVpqI1hThIEmeBYrj8QCOwJBmyLqAwIeKGZmWk0659FAPGlw+E4Q5L/htgcUIZ/cLa1deMEnoI86ge
Z9exmUmBh6MXF8Atyv1FAWmmUTCSR1WK93NLScgoqzbY+KCMzBmSGg8xYz0DAn53MoB/Rbrf7p8hiRA8SzT8IdGEEPeLqeiRIIZvPyJHJyAw
nYhzH9UNevb1TJDxu98h9njcVFRXtqrvjuwgIqqyq+iVbiIdk5pILAFS6vmAw0PMIiCaIxHA4HD4E5fwd0+HDH4CxVKj8SuKhvSSuLLPNyED
FG/A/JLN1iNGRcChkAIgLjU1NlwA8qUxEchwbMuuWc8a7RWYbchoOh8IG6Kzvw6t3Y4Ol2XfTuEuZvQIT/wr2G32pZKJ6/s+Fk/dCIZNNhqD
pXii7DzbT8rHes4PMrrUxbd2wjRXGxifORSKLtN1uf89moy9AuU0W1OGDwWC87gQ7o9VqBEmUwEQWz0KfelCQpygb3uw16EcxL+f9EJpmV8E
eBxmNtdecTqcLZFbDejwXwYR2FSTIZmSYjDfDa2AL8DcMSE0qKJB/qhnzMvQOGaYPNmrPAliy4EC2ejPYaVZ4PO4b8/Pz58BBF+aR6UbT7MI
QJvA8jxRyvwgRJLW6DzBDnZ2dz+vL9Jnj07x3dui4FBi6Z8A0rmw9j1JiaDQaXna/kUhEk38QHJ+Zmppk7t65w+R6iJ1V9AnSD4vmz3p+6v/
sKogKooKoICqICnKAQP4SYADVTi/zCXck6AAAAABJRU5ErkJggg==`;