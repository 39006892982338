import * as React from "react";
import NavigationHelper from "../../utils/NavigationHelper";
import OrderList from "../../assignments/OrderList";
import OrderListMobile from "../../assignments/OrderListMobile";
import { Link } from "@fluentui/react";

interface Props {
    orders: Order[];
    sendSms: (model: NotificationModel) => void;
    createMoreSaleOrder: (order: Order) => void;
    user: UserInformation;
    saveSelectedOrder: (order: Order, status: OrderStatus) => void;
}

const DailyAssignements: React.FunctionComponent<Props> = props => {
    const selectOrder = (orderId: string) => {
        NavigationHelper.pushRoute("WORKSHEET", `/${orderId}`);
    };

    const navigateToAssignment = () => {
        NavigationHelper.pushRoute("ASSIGNMENTS");
    };

    return (
        <div className="custom_container">
            <h2>Dagens opgaver</h2>
            <OrderList
                orders={props.orders}
                selectBooking={selectOrder}
                sendSms={props.sendSms}
                user={props.user}
                saveSelectedOrder={props.saveSelectedOrder}
            />
            <OrderListMobile
                orders={props.orders}
                selectBooking={selectOrder}
                sendSms={props.sendSms}
                createMoreSaleOrder={props.createMoreSaleOrder}
                user={props.user}
            />
            <Link onClick={navigateToAssignment}>Se alle opgaver</Link>
        </div>
    );
};

export default DailyAssignements;
