import * as React from "react";
import PageHeader from "../layout/PageHeader";
import NavigationHelper from "../utils/NavigationHelper";
import { PrimaryButton } from "@fluentui/react";

const UnauthorizedWorkSheet: React.FunctionComponent<{}> = () => {
    const navigateToDashboard = () => {
        NavigationHelper.pushRoute("DASHBOARD");
    };

    return (
        <>
            <PageHeader
                currentPage="Unauthorized Worksheet"
                noBreadcrumb={true}
            />
            <div>
                <p>Der er ikke valgt nogen order </p>
                <PrimaryButton onClick={navigateToDashboard}>
                    Til Dashboard
                </PrimaryButton>
            </div>
        </>
    );
};

export default UnauthorizedWorkSheet;
